import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux';
import Select from "react-select";
import { DashboardProductTypes } from '../redux/dashboard/product';
import React, { memo, useState, useEffect } from 'react';
import { Row, Col, Form, Nav, Button, Spinner } from 'react-bootstrap';


const SelectionField = memo(({
    field,
    label,
    data,
    placeholder,
    value,
    onSelectionChange,
    fetching
}) => {
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={data}
                isDisabled={!data[0] || fetching}
                defaultValue={value}
                placeholder={placeholder}
                value={data?.find(el => el?.value == value)}
                onChange={({value}) => onSelectionChange({field, value})}
            />
        </Form.Group>
    )
})


const InputField = memo(({
    field,
    label,
    placeholder,
    textarea,
    rows,
    disabled,
    onInput,
    value,
    type
}) => {
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control
                name={field}
                type={type || "text"}
                as={textarea ? "textarea" : "input"}
                rows={rows}
                placeholder={placeholder}
                className="mb-3"
                value={value || ""}
                onChange={onInput}
                disabled={disabled}
            />
        </Form.Group>
    )
})

const DashboardProductDetails = memo(() => {
    const dispatch = useDispatch();

    const product = useSelector(state => state.dashboard.product.product)
    const fetching = useSelector(state => state.dashboard.product.updatingProduct)
    const [productValues, setProductValue] = useState({...product})
    const devices = useSelector(state => state.dashboard.categories.devices)
    const categories = devices?.find(el => el?.id == productValues?.device_id)?.categories
    const types = categories?.find(el => el?.id == productValues?.category_id)?.types

    useEffect(() => {
        if (!_.isEmpty(product)) {
          setProductValue({...product})
        }
    }, [product])

    const onInput = ({target: {name, value}}) => {
        setProductValue({...productValues, [name]: value})
    }

    const onSelectionChange = ({field, value}) => {
        if (field == "device_id") {
            setProductValue({...productValues, [field]: value, category_id: null, type_id: null})
        } else if (field == "category_id") {
            setProductValue({...productValues, [field]: value, type_id: null})
        } else {
            setProductValue({...productValues, [field]: value})
        }
    }
    
    const updatingProduct = useSelector(state => state.dashboard.product.updatingProduct);
    const updateProductSuccess = useSelector(state => state.dashboard.product.updateProductSuccess);
    const updateProductError = useSelector(state => state.dashboard.product.updateProductError);
    const fields = [
        { field: "reference", label: "Reference", placeholder: "Reference..." },
        { field: "code", label: "Code", placeholder: "Code..." },
        { field: "description", label: "Description", placeholder: "Description...", textarea: true, rows: 3 },
        { field: "quantity_by_kg", label: "Quantity (Kg)", placeholder: "0.00" },
        { field: "weight_by_piece_grams", label: "Weight (g)", placeholder: "0.00" },
        { field: "disassembly_charge", label: "Disassembly charge (%)", type: "number", placeholder: "0" },
        { field: "market_alignment", label: "Market alignment (%)", type: "number", placeholder: "0" },
        { field: "price", label: "Price", placeholder: "$ 0.00", disabled: true },
        { field: "device_id", selection: devices, label: "Devices", placeholder: "Devices" },
        { field: "category_id", selection: categories, label: "Categories", placeholder: "Categories" },
        { field: "type_id", selection: types, label: "Types", placeholder: "Types" },
    ]?.filter(el => product?.hasOwnProperty(el?.field))
    

    const onSubmit = () => {
        const id = productValues?.id
        const data = Object.keys(product).reduce((r, k) => {
            if (product[k] != productValues[k]) { r[k] = productValues[k] }
            return r
        }, {});
        dispatch({type: DashboardProductTypes.UPDATE_PRODUCT_REQUEST_DASHBOARD, id, data})
    }

    return (
        <Form>
            <Row>
                <Col>
                    {fields.map((el, i) => {
                        if (el?.hasOwnProperty("selection")) {
                            if (el?.selection?.length > 0) {
                                return (
                                    <Col 
                                        className='mb-2' 
                                        key={el?.field}
                                    >
                                        <SelectionField
                                            field={el?.field}
                                            label={el?.label}
                                            placeholder={el?.placeholder}
                                            data={el.selection?.map(item => ({
                                                value: item?.id, 
                                                label: item?.name
                                            }))}
                                            onSelectionChange={onSelectionChange}
                                            value={productValues?.[el?.field]}
                                            fetching={fetching}
                                        />
                                    </Col>
                                )
                            } 
                            return
                        } 

                        return  (
                            <InputField 
                                key={el?.field}
                                field={el?.field}
                                label={el?.label}
                                placeholder={el?.placeholder}
                                textarea={el?.textarea}
                                type={el?.type}
                                rows={el?.rows}
                                disabled={el?.disabled || fetching}
                                onInput={onInput}
                                value={productValues?.[el?.field]}
                            />
                        )
                    })}
                    {/* <CategoriesSelector 
                        devices={devices}
                        deviceValue
                        categories={categories}
                        types={types}
                    /> */}
                </Col>
            </Row>
            <Button  
              disabled={updatingProduct}
              onClick={onSubmit}
              variant="outline-primary"
              className="mt-3"
              style={{width: 120, height: 40}}
            >
              {updatingProduct ? "Updating" : 'Save changes'}
            </Button>
            {updateProductError && 
                <p className="pt-3" style={{color: "red"}}>
                    {updateProductError}
                </p>}
            {updateProductSuccess && 
                <p className="pt-3" style={{color: "green"}}>
                    {updateProductSuccess}
                </p>}
        </Form>
    )
})
          
  
export default DashboardProductDetails
