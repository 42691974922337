import { useSelector, useDispatch } from 'react-redux';
import Select from "react-select";
import { DashboardProductTypes } from '../redux/dashboard/product';
import { ProductCategoriesTypes } from '../redux/dashboard/categories';
import React, { memo, useEffect } from 'react';
import { Row, Col, Form, Card, Nav, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API } from '../services/api';
import axios from 'axios';


const SelectionField = ({
    field,
    label,
    data,
    placeholder
}) => {
    const dispatch = useDispatch();

    const fetchCategoryLastCode = async (category_id) => {
        await axios.get(`${API}/categories/${category_id}/lastcode`)
        .then(res => dispatch({ type: DashboardProductTypes.SET_PRODUCT_FIELD, field: "code", value: res?.data}))
    }

    const handleSelectionChange = ({field, value, category_id}) => {
        dispatch({ type: DashboardProductTypes.SET_PRODUCT_FIELD, field, value})
        if (field == "category_id") fetchCategoryLastCode(category_id)
    }

    const value = useSelector(state => state.dashboard.product.product[field]);
    
    return (
        <Form.Group className="mb-3">
            <Form.Label>
              {label}
            </Form.Label>
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={data}
                isDisabled={!data[0]}
                defaultValue={value}
                placeholder={placeholder}
                value={data?.find(el => el?.value == value)}
                onChange={({value, category_id}) => handleSelectionChange({field, value, category_id})}
            />
        </Form.Group>
    )
}

const CategoriesSelector = memo(() => {
    const product = useSelector(state => state.dashboard.product.product);
    const devices = useSelector(state => state.dashboard.categories.devices)
    const categories = devices?.find(el => el?.id == product?.device_id)?.categories
    const types = categories?.find(el => el?.id == product?.category_id)?.types

    return (
        <>
            <Col 
                className='mb-2' 
                key={"devices"}
            >
                <SelectionField
                    field={"device_id"}
                    label={"Devices"}
                    placeholder={"Devices"}
                    data={devices?.map(item => ({
                        value: item?.id, 
                        label: item?.name
                    }))}
                />
            </Col>  
            {categories?.length ?
                <Col 
                    className='mb-2' 
                    key={"categories"}
                >
                    <SelectionField
                        field={"category_id"}
                        label={"Categories"}
                        placeholder={"Categories"}
                        data={categories?.map(item => ({
                            value: item?.id, 
                            label: item?.name,
                            category_id: item?.id
                        }))}
                    />
                </Col>
            : null}
            {types?.length ?
                <Col 
                    className='mb-2' 
                    key={"types"}
                >
                    <SelectionField
                        field={"type_id"}
                        label={"Types"}
                        placeholder={"Types"}
                        data={types?.map(item => ({
                            value: item?.id, 
                            label: item?.name
                        }))}
                    />
                </Col>
            : null}
        </>
    )
})

const InputField = ({
    field,
    label,
    placeholder,
    textarea,
    rows,
    disabled
}) => {
    const dispatch = useDispatch();
    const handleFieldChange = (e) => dispatch({ type: DashboardProductTypes.SET_PRODUCT_FIELD, field, value: e.target.value})
    const value = useSelector(state => state.dashboard.product.product[field]);
    
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type="text"
                as={textarea ? "textarea" : "input"}
                rows={rows}
                placeholder={placeholder}
                className="mb-3"
                value={value || ""}
                onChange={handleFieldChange}
                disabled={disabled}
            />
        </Form.Group>
    )
}

const DashboardProductUpload = memo(() => {
  
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const createdProduct = useSelector(state => state.dashboard.product.createdProduct);
    const creatingProduct = useSelector(state => state.dashboard.product.creatingProduct);
    const createProductSuccess = useSelector(state => state.dashboard.product.createProductSuccess);
    const createProductError = useSelector(state => state.dashboard.product.createProductError);
    
    useEffect(()=> {
      const createdProductId = createdProduct?.id

      if (createdProductId) {
        alert(createProductSuccess)
        return navigate(`/dashboard/product/${createdProductId}`)
      } 
      
      dispatch({ type: ProductCategoriesTypes.GET_PRODUCT_CATEGORIES_REQUEST})
    }, [createdProduct])

    const onSubmit = () => {
      dispatch({ type: DashboardProductTypes.CREATE_PRODUCT_REQUEST_DASHBOARD })
    }
    
    const fields = [
        { field: "reference", label: "Reference", placeholder: "Reference..." },
        { field: "code", label: "Code", placeholder: "Code..." },
        { field: "description", label: "Description", placeholder: "Description...", textarea: true, rows: 3 },
        { field: "quantity_by_kg", label: "Quantity (Kg)", placeholder: "0.00" },
        { field: "weight_by_piece_grams", label: "Weight (g)", placeholder: "0.00" },
        // { field: "price", label: "Price", placeholder: "$ 0.00", disabled: true },
    ]

    return (
      <Card>
        <Card.Header>
            <h1>
                Product Upload
            </h1>
        </Card.Header>
        <Card.Body>
          <Form>
              <Row xs={1} xl={2}>
                  <Col>
                      {fields.map((el, i) => 
                          <InputField 
                              key={el?.field}
                              field={el?.field}
                              label={el?.label}
                              placeholder={el?.placeholder}
                              textarea={el?.textarea}
                              rows={el?.rows}
                              disabled={el?.disabled}
                          />)}
                      <CategoriesSelector />
                  </Col>
              </Row>
              <Button  
                disabled={creatingProduct}
                onClick={onSubmit}
                className="mt-3"
                style={{width: 120, height: 40}}
              >
                {creatingProduct ? "Creating Product" : 'Create Product'}
              </Button>
              {createProductError && 
                  <p className="pt-3" style={{color: "red"}}>
                      {createProductError}
                  </p>}
              {createProductSuccess && 
                  <p className="pt-3" style={{color: "green"}}>
                      {createProductSuccess}
                  </p>}
          </Form>
        </Card.Body>
      </Card>  
    )
})
          
  
export default DashboardProductUpload