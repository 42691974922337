import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-feather";
import { Card, Row, Button, Form, InputGroup, Badge, ListGroup, Table } from 'react-bootstrap';
import { LogsTypes } from '../redux/dashboard/logs';

const DashboardSettingsLogs = () => {

    const dispatch = useDispatch();
    const actions = useSelector(state => state.dashboard.logs.actions)


    useEffect(()=> {
        getActionLogs()
    }, [])

    const getActionLogs = () => {
        dispatch({ type: LogsTypes.ACTION_LOGS_REQUEST })
    }


    return (
        <Card>
            <Card.Header>
                <h1 className="card-subtitle color-black fw-bolder">
                    Logs
                </h1>
            </Card.Header>
            <Card.Body>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    {actions?.map(log => {
                        return (
                            <tr key={log?.timestamp}>
                                <td>{log?.message}</td>
                                <td>{log?.timestamp}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            </Card.Body>
        </Card>
    );
}

export default DashboardSettingsLogs
