import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getUsersRequestDashboard: ["params"],
    getUsersSuccessDashboard: ["results"],
    getUsersFailureDashboard: ["error"],
    selectActiveFilterDashboard: ["filter", "value"],
    resetActiveFiltersDashboard: null
})

export const DashboardUsersTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetching: null,
    error: null,
    activeFilters: {
    },
    users: [],
    currentPage: 1,
    totalItems: null,
    totalPages: null,
    searchLimit: 20
})

/* ------------- Reducers ------------- */


/* Filters */
export const selectActiveFilter = (state, {filter, value}) => {
  const activeFilters = {...state.activeFilters, [filter]: value || undefined}
  return state.merge({ activeFilters })
}

export const resetActiveFilters = (state) => 
  state.merge({  
    activeFilters: {},
  })

  
/* Users Search */
export const getUsersRequest = (state, {page, limit}) => {
  const currentPage = page || state.currentPage
  const searchLimit = limit || state.searchLimit
  
  return state.merge({ fetching: true, error: null, users: [], currentPage, searchLimit })
}

export const getUsersSuccess = (state, {results}) => {
  const { users, currentPage, totalItems, totalPages } = results
  return state.merge({ fetching: false, users, currentPage, totalItems, totalPages })
}

export const getUsersFailure = (state, {error}) =>
  state.merge({ error, users: [], fetching: false })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST_DASHBOARD]: getUsersRequest,
  [Types.GET_USERS_SUCCESS_DASHBOARD]: getUsersSuccess,
  [Types.GET_USERS_FAILURE_DASHBOARD]: getUsersFailure,
  [Types.SELECT_ACTIVE_FILTER_DASHBOARD]: selectActiveFilter,
  [Types.RESET_ACTIVE_FILTERS_DASHBOARD]: resetActiveFilters
})
