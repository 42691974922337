import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  
  getCartRequestDashboard: ["id"],
  getCartSuccessDashboard: ["cart"],
  getCartErrorDashboard: ["error"],
})

export const DashboardCartTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    cart: {},
    fetching: null,
    error: null
})

/* ------------- Reducers ------------- */


/* Get Cart */
export const getCartRequest = (state) =>
  state.merge({ cart: {}, fetching: true, error: null})

export const getCartSuccess = (state, {cart}) => {
  return state.merge({ fetching: false, cart })
}

export const getCartError = (state, {error}) =>
  state.merge({ fetching: false, error})



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.GET_CART_REQUEST_DASHBOARD]: getCartRequest,
  [Types.GET_CART_SUCCESS_DASHBOARD]: getCartSuccess,
  [Types.GET_CART_ERROR_DASHBOARD]: getCartError,
})