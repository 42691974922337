import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  currenciesRequest: null,
  currenciesSuccess: ["data"],
  currenciesError: ["error"]
})

export const CurrenciesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: [],
    fetching: null,
    error: null
})

/* ------------- Reducers ------------- */


/* Currencies */
export const currenciesRequest = (state) =>
  state.merge({ fetching: true, data: [], error: null})

export const currenciesSuccess = (state, {data}) =>
  state.merge({ fetching: false, data})

export const currenciesError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CURRENCIES_REQUEST]: currenciesRequest,
  [Types.CURRENCIES_SUCCESS]: currenciesSuccess,
  [Types.CURRENCIES_ERROR]: currenciesError
})