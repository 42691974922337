import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardCartsTypes } from '../redux/dashboard/carts';
import { Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../services/api';
import { placeholderImage } from './ui/Utils';
import { getFlagEmoji } from '../utils/commons';


const DashboardCartsTrends = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data, fetching } = useSelector(state => state.dashboard.carts.trendingProducts)

    useEffect(()=> {
        dispatch({ type: DashboardCartsTypes.TRENDING_PRODUCTS_REQUEST_DASHBOARD })
    }, [])

    return (
        <Card className='p-4'>
            <h1 className="card-subtitle text-muted mb-4">
                Products Trends
            </h1> 
            <Table striped hover={!fetching} responsive>
                <thead>
                    <tr className="text-center">
                        <th>{t("image")?.toUpperCase()}</th>
                        <th>{"Reference"?.toUpperCase()}</th>
                        <th>{"Added N times"?.toUpperCase()}</th>
                        <th>{"Added in"?.toUpperCase()}</th>
                        <th>{t("quantityInKg")?.toUpperCase()}</th>
                        <th>{t("priceLocalKg")?.toUpperCase()}</th>
                        <th>{"Total added price"?.toUpperCase()}</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map(({id, image, reference, added, addedIn, quantity, price_usd, total_added_usd}) => {
                        const img = image?.url ? (BASE_URL+image?.url) : placeholderImage
                        
                        const cardImageStyle =  {
                            objectFit: 'cover',
                            height: 80,
                            width: 120
                        }

                        return (
                            <tr 
                                key={id} 
                                className="align-items-center text-center"
                            >
                                <td>
                                    <Card.Img 
                                        style={cardImageStyle}
                                        src={img} 
                                    />
                                </td>
                                <td>{reference}</td>
                                <td>
                                    {added}
                                </td>
                                <td>{Object?.entries(addedIn)?.map(([country, nTimes]) => <div>{getFlagEmoji(country)} {nTimes}</div>)}</td>
                                <td>
                                    {quantity}
                                </td>
                                <td>
                                    {isNaN(price_usd) ? "N/A" : ("USD " + +(Number(price_usd))?.toFixed(2))}
                                </td>
                                <td>
                                    {isNaN(total_added_usd) ? "N/A" : ("USD " + +(Number(total_added_usd))?.toFixed(2))}
                                </td>
                            </tr>
                    )})}
                </tbody>
            </Table>
        </Card>
    );
};


export default DashboardCartsTrends;