/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, delay } from 'redux-saga/effects'
import ProductCategoriesActions from '../../redux/dashboard/categories'
import { toast } from 'react-toastify'

export function * getProductCategories (api) {
    const response = yield call(api.getProductCategories)
    
    if (response.ok) {
        yield put(ProductCategoriesActions.getProductCategoriesSuccess(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(ProductCategoriesActions.getProductCategoriesError(message))
    }
}

export function * addDevice (api, {name}) {
    const response = yield call(api.addDevice, name)

    if (response.ok) {
        yield put(ProductCategoriesActions.addDeviceSuccess())
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    } else {
        const message = response?.data?.message || "Error"
        alert(JSON.stringify(response.data))
        yield put(ProductCategoriesActions.addDeviceError())
    }
}

export function * updateDevice (api, {id, name}) {
    const response = yield call(api.updateDevice, {id, name: name?.trim()})
    
    if (response.ok) {
        toast.success("Device updated")
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    } else {
        toast.error("Update device error")
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    }
}

export function * deleteDevice (api, {id}) {
    const response = yield call(api.deleteDevice, id)

    if (response.ok) {
        yield put(ProductCategoriesActions.deleteDeviceSuccess())
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    } else {
        const message = response?.data?.message || "Error"
        alert(JSON.stringify(response.data))
        yield put(ProductCategoriesActions.deleteDeviceError())
    }
}


export function * addCategory (api, {name, device_id, codeFormat}) {
    const response = yield call(api.addCategory, {name, device_id, codeFormat})

    if (response.ok) {
        yield put(ProductCategoriesActions.addCategorySuccess())
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    } else {
        const message = response?.data?.message || "Error"
        alert(JSON.stringify(response.data))
        yield put(ProductCategoriesActions.addCategoryError())
    }
}

export function * updateCategory (api, {id, name, codeFormat}) {
    const response = yield call(api.updateCategory, {id, name: name?.trim(), codeFormat: codeFormat?.trim()})
    
    if (response.ok) {
        toast.success("Category updated")
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    } else {
        toast.error("Update category error")
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    }
}


export function * deleteCategory (api, {device_id, category_id}) {
    const response = yield call(api.deleteCategory, {device_id, category_id})

    if (response.ok) {
        yield put(ProductCategoriesActions.deleteCategorySuccess())
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    } else {
        const message = response?.data?.message || "Error"
        alert(JSON.stringify(response.data))
        yield put(ProductCategoriesActions.deleteCategoryError())
    }
}


export function * addType (api, {name, category_id}) {
    const response = yield call(api.addType, {name, category_id})

    if (response.ok) {
        yield put(ProductCategoriesActions.addTypeSuccess())
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    } else {
        const message = response?.data?.message || "Error"
        alert(JSON.stringify(response.data))
        yield put(ProductCategoriesActions.addTypeError())
    }
}


export function * deleteType (api, id) {
    const response = yield call(api.deleteType, id)

    if (response.ok) {
        yield put(ProductCategoriesActions.deleteTypeSuccess())
        yield put(ProductCategoriesActions.getProductCategoriesRequest())
    } else {
        const message = response?.data?.message || "Error"
        alert(JSON.stringify(response.data))
        yield put(ProductCategoriesActions.deleteTypeError())
    }
}
