import _, { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import SearchBar from "../../components/SearchBar";
import { useTranslation } from "react-i18next";


const TosTh = () => {
  const { t } = useTranslation();

  return (
    <>
      <SearchBar title={capitalize(t("termsOfPurchase"))} noBar/>
      <Container className="p-4 pt-5 fs-4" style={{whiteSpace: "pre-wrap"}}>
        <h1 className="fw-bolder">ข้อกำหนดสำหรับการซื้อ</h1>
        <h4 className="pb-3">
          เงื่อนไขการซื้อทั่วไป{"\n"}
          ปรับปรุงล่าสุด: 26/01/2564
        </h4>
        <br/>
        <h3 className="fw-bolder">บททั่วไป</h3>
          <p>
            เว็บไซต์นี้บริหารจัดการโดย อีโค่เทรด กรุ๊ป
            <br/>คำนิยาม “เรา” “พวกเรา” “ของเรา” ที่ปรากฏในเว็บไซต์นี้ หมายความถึง อีโค่เทรด กรุ๊ป
            <br/>อีโค่เทรด กรุ๊ป นำเสนอเว็ปไซต์นี้ รวมถึงข้อมูลข่าวสาร เครื่องมือ และบริการทั้งหมดที่มีอยู่ในเว็บไซต์นี้ให้แก่ท่าน
            <br/>ในฐานะผู้ใช้งาน ภายใต้เงื่อนไขว่า ท่านต้องตกลงที่จะยอมรับข้อกำหนด เงื่อนไข นโยบาย และประกาศทั้งหมดที่ปรากฏในเว็บไซต์นี้
            <br/>หากท่านไม่ตกลงหรือยอมรับข้อกำหนดสำหรับการซื้อ บริษัทขอให้ท่านยุติการใช้บริการของบริษัท
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 1: คำนิยาม</h3>
          <p>
            « ผู้ซื้อ » หมายถึง สาขาของอีโค่เทรด หรือ กลุ่มพันธมิตรของอีโค่เทรด
            <br/>« สินค้า » หมายถึง วัตถุทั้งหมดที่ผู้ขายส่งไปยังสาขาของอีโค่เทรด หรือ กลุ่มพันธมิตรของอีโค่เทรด
            <br/>« ผู้ขาย » หมายถึง ลูกค้าที่เป็นคู่สัญญากับสาขาของอีโค่เทรด หรือ กลุ่มพันธมิตรของอีโค่เทรด
            <br/>« เงื่อนไขการซื้อทั่วไป » หมายถึง เงื่อนไขการซื้อทั่วไปที่ใช้บังคับกับความสัมพันธ์ระหว่างสาขาของอีโค่เทรด หรือ พันธมิตรของอีโค่เทรดกับผู้ขาย
            <br/>« วันชำระเงิน » หมายถึง วันที่สาขาของอีโค่เทรด หรือ กลุ่มพันธมิตรของอีโค่เทรดทำการชำระเงิน
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 2: ขอบเขตการใช้งาน</h3>
          <p>
            เงื่อนไขการซื้อทั่วไป เป็นข้อบังคับใช้กับการเสนอซื้อ ระหว่างผู้ขายและผู้ซื้อ
            <br/>เงื่อนไขการซื้อทั่วไป นิยามถึงความสัมพันธ์ของการซื้อขาย ระหว่างผู้ขายและผู้ซื้อ
            <br/>ที่บังคับใช้ระหว่างผู้ซื้อและผู้ขายในการได้มาซึ่งสินค้า
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 3: เงื่อนไข ข้อบังคับเบื้องต้น</h3>
          <p>
            ผู้ขายให้การรับประกันกับผู้ซื้อว่า
            <br/>- ผู้ขายเป็นผู้บรรลุนิติภาวะและสามารถทำนิติกรรมตามกฎหมาย
            <br/>- ผู้ขายเป็นเจ้าของกรรมสิทธิ์ในสินค้า หรือได้รับอนุญาตโดยชอบธรรมให้จำหน่ายสินค้า (โดยผู้ซื้อสามารถใช้ดุลยพินิจแต่เพียงผู้เดียวในการเรียกหลักฐาน แสดงกรรมสิทธิ์หรือสิทธิในการจำหน่ายสินค้า เพื่อรับรองว่าสินค้านั้นไม่อยู่ภายใต้กฎบังคับแห่งสิทธิใดๆ ไม่ถูกแยกส่วน มิใช่สินค้าที่ถูกจัดสรรไว้เพื่อการชำระหนี้ ไม่มีภาระผูกพัน หรือเพื่อการรับประกันใดๆ และมิได้ถูกเรียกร้องโดยบุคคลอื่นใด)
            <br/>- สินค้านั้นมิใช่ทรัพย์สินที่ได้มาโดยการกระทำความผิดใดๆ ที่กำหนดห้ามไว้ตามกฎหมายอาญา อาทิเช่น การลักทรัพย์ และ/หรือ ไม่มีความเกี่ยวเนื่องกับการกระทำความผิดไม่ว่าทางใด
            <br/>- การขายสินค้าไม่ได้มีวัตถุประสงค์เพื่อการกระทำอันมิชอบด้วยกฎหมายซึ่งกำหนดห้ามไว้ตามกฎหมายอาญา เช่น การปกปิดซ่อนเร้น ทั้งนี้ ผู้ขายยืนยันว่าการส่งพัสดุ และ/หรือ การจัดหา และ/หรือ การยกมอบซากผลิตภัณฑ์ไฟฟ้าและอิเล็กทรอนิกส์ (Waste of Electrical and Electronic Equipment(s)) ให้กับผู้ซื้อนั้น ไม่มีส่วนเกี่ยวข้องกับการฟอกเงิน หรือจะไม่ส่งผลให้ผู้ซื้อละเมิดกฎหมายใดๆ ที่ใช้บังคับ
            <br/>- ผู้ขายกระทำการโดยชื่อและในนามของตนเอง และไม่ได้ทำการในฐานะตัวแทนหรือผู้แทน
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 4: คำสั่งซื้อ</h3>
          <p>
            <span style={{fontWeight: "bold", textDecorationLine: "underline"}}>
              4.1 บททั่วไป
            </span>
            <br/>ผู้ขายจะต้องจัดส่งสินค้าไปยังสถานที่ของผู้ซื้อ
            <br/>เพื่อให้เป็นไปตามบทบัญญัติแห่งกฎหมายที่ใช้บังคับกับการป้องกันและปราบปรามการฟอกเงิน และการปฏิบัติตามข้อกำหนดของกฎหมาย ผู้ขายจะต้องส่งมอบสำเนาบัตรประจำตัวประชาชนของตนให้แก่ผู้ซื้อ
            <br/>ผู้ซื้อแนะนำให้ผู้ขายถ่ายรูปของสินค้าก่อนการจัดส่งสินค้า เพื่อการพิสูจน์ยืนยันความถูกต้องเพิ่มเติม
            <br/>ผู้ขายต้องเก็บหลักฐานการส่ง ซึ่งเป็นการพิสูจน์ยืนยันความถูกต้อง เพื่อรับรองว่าได้มีการจัดส่งสินค้าให้แก่ผู้ซื้อแล้วจริง
            <br/>ผู้ซื้อขอสงวนสิทธิ์ทุกประการที่จะไม่รับพัสดุที่จัดส่งซึ่งมีความเสียหาย ถูกเปิด หรือถูกจัดการอย่างผิดพลาดระหว่างการขนส่ง
            <br/>พัสดุที่จัดส่งดังกล่าวจะถูกส่งคืนไปยังผู้ขาย โดยอีโค่เทรดจะไม่มีส่วนเกี่ยวข้องกับความรับผิดแต่อย่างใด
            {"\n\n"}
            <span style={{fontWeight: "bold", textDecorationLine: "underline"}}>
              4.2 คำสั่งซื้อภายนอกเว็บไซต์
            </span>
            <br/>ซื้อจะรับผิดชอบแต่เพียงเฉพาะคำสั่งซื้อที่กระทำโดยผู้แทนที่ได้รับมอบอำนาจอย่างถูกต้องตามกฎหมายในการทำสัญญาซื้อเท่านั้น
            {"\n\n"}
            <span style={{fontWeight: "bold", textDecorationLine: "underline"}}>
              4.3 คำสั่งซื้อทางอิเล็กทรอนิกส์
            </span>
            <br/>ผู้ขายสามารถระบุจำแนกสินค้าที่ตนต้องการขาย
            <br/>โดยการสร้างตะกร้าสินค้าผ่านทางเว็บแอปพลิเคชันของเราหรือทาง
            <br/>แอปพลิเคชันบนอุปกรณ์สื่อสารเคลื่อนที่
            <br/>ผู้ขายจะต้องรับผิดชอบสำหรับการบรรจุหีบห่อสินค้าและการจัดส่งสินค้า โดยไม่ช้าไปกว่าสาม (3) วันทำการหลังจากยื่นส่งตะกร้าสินค้า
            <br/>อาจจะมีการหักค่าธรรมเนียม ในกรณีที่ใช้บริการฉลากเพื่อส่งคืนสินค้าแบบชำระเงินล่วงหน้า
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อ 5: ลักษณะของสินค้า</h3>
          <p>
            ผู้ซื้อประสงค์ซื้อสินค้าที่ใช้แล้วเพื่อนำไปรีไซเคิล และผู้ซื้อจะประเมินมูลค่าราคาซื้อขายโดยอ้างอิงจากน้ำหนักของโลหะมีค่าแต่ละชนิดที่มีอยู่ในสินค้าที่ใช้แล้วดังกล่าว โดยผู้ซื้อจะไม่อ้างอิงราคาจากมูลค่าสมมุติของสินค้าที่ใช้แล้วที่ยังไม่ได้รับความเสียหาย
            <br/>ดังนั้น ราคาที่อีโค่เทรดเสนอ อาจต่ำกว่ามูลค่าที่ปรากฏบนแอปพลิเคชันของอีโค่เทรดและแอปพลิเคชันบนอุปกรณ์สื่อสารเคลื่อนที่
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อ 6: สัญญาซื้อขาย</h3>
          <p>
            ในการส่งสินค้าให้แก่ผู้ซื้อ ให้ถือว่าผู้ขายทำคำเสนอขั้นสุดท้ายในการขายสินค้าให้แก่ผู้ซื้อ
            <br/>หลังจากที่ได้มีการประเมินมูลค่าสินค้าโดยผู้เชี่ยวชาญแล้ว ผู้ซื้อจะตัดสินใจว่าจะรับซื้อสินค้าหรือไม่
            <br/>ในกรณีที่ผู้ซื้อตัดสินใจรับซื้อสินค้า ผู้ซื้อจะแจ้งให้ผู้ขายทราบทางไปรษณีย์หรือทางอีเมลภายในระยะเวลาสี่สิบแปด (48) ชั่วโมงหลังจากการประเมินมูลค่าและจะดำเนินการชำระเงินต่อไป
            <br/>วันที่ผู้ซื้อแจ้งให้ผู้ขายทราบถึงการรับซื้อสินค้า ถือเป็นวันที่ทำการซื้อขาย
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 7: การโอนกรรมสิทธิ์</h3>
          <p>
            การโอนกรรมสิทธิ์ในสินค้าและการโอนความเสี่ยงภัยในความสูญเสียและความเสียหายเกี่ยวกับสินค้าจะมีผลบังคับใช้ก็ต่อเมื่อผู้ซื้อได้ตอบรับซื้อสินค้า
            <br/>ดังนั้น ผู้ขายจะเป็นผู้รับความเสี่ยงภัยที่เกิดขึ้นในระหว่างการขนส่ง ในกรณีที่มีความเสียหายเกิดขึ้น โดยผู้ขายจะสามารถเรียกร้องเอากับผู้รับขนภายใต้หลักเกณฑ์กฎหมายที่เกี่ยวข้อง
            <br/>ผู้ซื้อจะไม่มีหน้าที่ต้องรับผิดชอบไม่ว่าในกรณีใดๆ
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 8: ราคา</h3>
          <p>
            ราคาสินค้าจะถูกกำหนดไว้เป็นราคาคงที่ โดยการประเมินจากปริมาณของโลหะมีค่าและราคาตลาดของโลหะมีค่าในตลาดหลักทรัพย์
            <br/>ในการชำระเงินค่าราคาซื้อขาย ผู้ซื้อจะส่งอีเมลให้แก่ผู้ขาย ซึ่งจะประกอบไปด้วย สัญญาซื้อขาย รวมถึงข้อมูลที่เกี่ยวข้อง: คำอธิบายรายละเอียดสินค้า ปริมาณสำหรับการอ้างอิงทุกประเภท มูลค่าของสินค้า และข้อกำหนดการชำระเงิน
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 9: ข้อกำหนดการชำระเงิน</h3>
          <p>
            สำหรับการซื้อ ผู้ขายจะดำเนินการชำระเงินตามที่มุ่งหมายไว้ภายหลังการประเมินมูลค่าสินค้าโดยทันทีหลังจากแจ้งความประสงค์ของตนที่จะรับซื้อสินค้าให้แก่ผู้ขาย ซึ่งหมายถึง ภายในสี่สิบแปด (48) ชั่วโมงหลังการประเมินมูลค่าสินค้า
            <br/>ระยะเวลาสี่สิบแปด (48) ชั่วโมงที่ประวิงให้ข้างต้น มิได้หมายความถึงการที่มีผลให้เงินเข้าไปยังบัญชีธนาคารของผู้ขาย แต่หมายความถึงการที่ผู้ซื้อได้ทำคำสั่งโอนเงิน
            <br/>หากระยะเวลาสี่สิบแปด (48) ชั่วโมงที่ประวิงให้ข้างต้นสิ้นสุดลงในวันสุดสัปดาห์หรือวันหยุดทำการของธนาคาร
            <br/>วันเหล่านี้จะไม่ถูกนำมาพิจารณาในการคำนวณระยะเวลา
            <br/>การชำระเงินจะกระทำโดยวีธีการที่ผู้ขายเป็นผู้กำหนดเลือก
            <br/>ผู้ซื้อประสงค์ที่จะให้ผู้ขายใส่ใจในการทราบถึงข้อมูลและการปฏิบัติตามภาระหน้าที่ทางภาษีของผู้ขายตามหลักเกณฑ์ที่เกี่ยวข้อง
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 10: การปฏิบัติตามและคุณภาพของผลิตภัณฑ์</h3>
          <p>
          พัสดุจะส่งถึงผู้ซื้อโดยบริการจัดส่ง หลังจากได้รับ ผู้ซื้อจะตรวจสอบคุณภาพและปริมาณของสินค้าที่ส่งมาถึง
          <br/>ภายหลังการตรวจสอบคุณภาพนี้ หากผู้ซื้อตระหนักว่ามีข้อผิดพลาดเกี่ยวกับความบกพร่องของปริมาณหรือคุณภาพ ผู้ซื้อจะทำคำเสนอใหม่ไปยังผู้ขาย
          <br/>หากไม่มีการตอบรับภายในเจ็ด (7) วันหลังจากการส่งคำเสนอใหม่ สินค้าอาจถูกส่งคืนไปยังผู้ขาย
          <br/>ค่าธรรมเนียมที่เกี่ยวข้องกับการส่งคืนสินค้า ให้ผู้ขายเป็นผู้รับผิดชอบแต่เพียงฝ่ายเดียว
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 11: ความรับผิดชอบ</h3>
          <p>
            ผู้ซื้อไม่ต้องรับผิดชอบต่อความเสียหายโดยตรงที่ผู้ขายได้รับอันเป็นผลมาจากการที่ผู้ซื้อไม่ปฏิบัติหน้าที่หรือปฏิบัติผิดหน้าที่ของตนเกี่ยวกับเงื่อนไขทั่วไปเหล่านี้และสัญญาซื้อขายที่เกี่ยวข้องกับผู้ซื้อ
            <br/>หากสินค้าถูกปฏิเสธโดยผู้ซื้อตามที่ระบุไว้ในข้อ 10 ข้างต้น ผู้ขายจะไม่สามารถให้ผู้ซื้อเป็นผู้รับผิดชอบโดยอ้างเหตุของเวลาที่สูญสิ้นไปเนื่องจากความผิดพลาดในการดำเนินการ
            <br/>ผู้ซื้อไม่ต้องรับผิดชอบต่อความล่าช้าเนื่องจากการไม่ดำเนินการอันเป็นผลมาจากเหตุสุดวิสัย
            <br/>ผู้ซื้อขอสงวนสิทธิ์ที่จะดำเนินการทางกฎหมายใดๆ ต่อผู้ขายผู้ซึ่งไม่สามารถพิสูจน์ยืนยันความถูกต้องของประวัติความเป็นเจ้าของของสินค้าของตน หรือ มีส่วนเกี่ยวข้องโดยมิชอบด้วยกฎหมายในสิทธิความเป็นเจ้าของ
            <br/>อีกทั้ง ผู้ซื้อจะไม่มีส่วนต้องรับผิดชอบ ไม่ว่าในทางใดก็ตาม หากผู้ขายไม่มีส่วนเกี่ยวข้องกับที่มาของสินค้าของตน
            <br/>นอกจากนี้ ผู้ขายรับประกันต่อผู้ซื้อเกี่ยวกับการผิดสัญญา และ/หรือ การผิดสัญญาที่มีการปกปิดซ่อนเร้น โดยเฉพาะเรื่องที่เกี่ยวกับที่มาของสินค้าที่นำมาขาย
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อที่ 12: การปรับแก้ข้อกำหนด</h3>
          <p>
            ผู้ซื้อขอสงวนสิทธิ์ในการปรับแก้ข้อกำหนดเหล่านี้ได้ทุกเมื่อ และในพฤติการณ์เช่นนั้นผู้ขายจะยังคงตกอยู่ภายใต้บังคับของข้อกำหนดที่มีผลใช้บังคับ ณ วันที่ทำการส่งสินค้าของตนให้แก่ผู้ซื้อ
          </p>
        <br/>
        <h3 className="fw-bolder">ข้อ 13: การโอน – การโอนโดยผลของกฎหมาย</h3>
          <p>
            ผู้ซื้อมีสิทธิที่จะขายทั้งหมดหรือบางส่วนของสิทธิและภาระหน้าที่ที่ตนมีอยู่ภายใต้เงื่อนไขเหล่านี้ที่ไม่กระทบต่อขอบเขตของสิทธิและหน้าที่ของผู้ขาย
          </p>
        <br/> 
        <h3 className="fw-bolder">ข้อ 14: การสละสิทธิ์ – การผ่อนผันให้ – ความเป็นโมฆะ</h3>
          <p>
            การที่ผู้ซื้ออาจทำการสละสิทธิ์ในการเรียกร้องเกี่ยวการกระทำผิดบทบัญญติใดๆ ของเงื่อนไขเหล่านี้ ย่อมไม่กระทบต่อสิทธิในการบังคับตามข้อกำหนดนี้ในภายหลัง และไม่อาจนำมาพิจารณาว่าเป็นการสละสิทธิ์ต่อการกระทำผิดสัญญาอื่นใด
            <br/>เช่นเดียวกัน การผ่อนผันให้สำหรับการใช้บังคับข้อกำหนดและเงื่อนไขเหล่านี้ ไม่ว่าจะมีระยะเวลาหรือความถี่เช่นไร จะไม่ถูกนำมาพิจารณาว่าเป็นการปรับแก้หรือตัดทอนข้อกำหนดและเงื่อนไขเหล่านี้
            <br/>ในท้ายที่สุด ความเป็นโมฆะที่อาจกระทบต่อบทบัญญัติใดๆ ของข้อกำหนดเหล่านี้ ไม่ว่าทั้งหมดหรือบางส่วน จะไม่กระทบต่อข้อกำหนดที่เหลืออยู่ ซึ่งจะยังคงมีผลใช้บังคับโดยไม่มีการเปลี่ยนแปลง
          </p>
      </Container>
    </>
  );
}

export default TosTh
