import React, { useState } from "react";
import { Badge, Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import * as Icon from "react-feather";
import { ReactComponent as Logo } from "../assets/img/logo.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {Link, useNavigate } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import NavbarCurrencies from "./navbar/NavbarCurrencies";
import NavbarLanguages from "./navbar/NavbarLanguages";
import AppStoreLogo from "../assets/img/stores/app-store-badge.png";
import PlayStoreLogo from "../assets/img/stores/play-store-badge.png";
import HuaweiAppGalleryLogo from "../assets/img/stores/huawei-appgallery-badge.png";

const MobileStores = () => (
    <>
        <a href="https://appgallery.huawei.com/#/app/C110729587">
            <img 
                className="my-1"
                src={HuaweiAppGalleryLogo} 
                style={{height: "2.2em", marginRight: "1em"}} 
                alt="huawei-appgallery-badge"
            />
        </a>
        <a href="https://apps.apple.com/app/eco-cat-app/id1562611420">
            <img 
                className="my-1"
                src={AppStoreLogo} 
                style={{height: "2.2em", marginRight: "1em"}} 
                alt="app-store-badge"
            />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.ecoweeecatalogue">
            <img 
                className="my-1"
                src={PlayStoreLogo} 
                style={{height: "2.2em", marginRight: "0.5em"}} 
                alt="play-store-badge"
            />
        </a>
    </>
)

const MiniNavBar = ({hidden, noCurrencies}) => {

    const { t, i18n} = useTranslation();
    const locale = i18n?.language;
    const openCart = useSelector(state => state.account.cart.openCart)
    const cartTotalQuantity = openCart?.total_quantity
    const navigate = useNavigate();
    const { isAuthenticated, signOut, type, user } = useAuth()
    const {fullname, first_name, last_name} = user || {}
    const isAdmin = type == "admin"
    const isTranslator = type == "translator"

    // LOGIN MODAL
    const [showLoginModal, setShowLoginModal] = useState(false);
    const handleLoginModal = () => setShowLoginModal(!showLoginModal);

    return (
        <Container fluid style={{backgroundColor: '#1f1f1f'}}>
            {hidden ?
                <div className="pt-5"/>
            :   
                <Row className="mx-2 p-1 align-items-center">
                    <Col>
                        <Row className="my-1 justify-content-start align-items-center">
                            <Col className="me-5">
                                <a className="sidebar-brand" style={{padding: 0, whiteSpace: 'nowrap'}} href="/">
                                    <Logo style={{height: "2rem", width: "2rem"}}/>
                                    <span className="m-1 align-middle" style={{fontSize: "1.8rem"}}>Eco Weee</span>
                                </a>
                            </Col>
                            <Col className="my-2">
                                <Link
                                    to={"/?scrollToElement=weeeInfo"}
                                    className="text-white text-uppercase text-decoration-none cursor-pointer"
                                    style={{padding: 0, whiteSpace: 'nowrap'}} 
                                >
                                    <Icon.Info size={"1.5rem"}/>
                                    <span className="align-middle p-2" style={{fontSize: "1.2rem"}}>
                                        About
                                    </span>
                                </Link>
                            </Col>
                            <Col className="my-2">
                                <Link
                                    to={"/weee-impact"}
                                    className="text-white text-uppercase text-decoration-none cursor-pointer"
                                    style={{padding: 0, whiteSpace: 'nowrap'}} 
                                >
                                    <Icon.Globe size={"1.5rem"}/>
                                    <span className="align-middle p-2" style={{fontSize: "1.2rem"}}>
                                        Weee Impact
                                    </span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row
                            style={{cursor: "pointer"}} 
                            className="justify-content-end"
                        >
                            <Col 
                                md="auto"
                                className="d-flex justify-content-end align-items-center text-white"
                            >
                                <div className="me-md-2">
                                    <MobileStores />
                                </div>
                                <div className="me-md-2">
                                    {!noCurrencies && <NavbarCurrencies />}
                                </div>
                                <div className="me-md-2">
                                    <NavbarLanguages />
                                </div>
                                {(isAuthenticated && !isAdmin && cartTotalQuantity > 0) &&
                                    <div className="px-4 ps-2 pt-1 me-md-2" onClick={()=> navigate("/cart")}>
                                        
                                            <Badge 
                                                pill bg="primary" 
                                                className="position-absolute"
                                                style={{
                                                    position: "absolute",
                                                    marginLeft: "1.2rem", 
                                                    marginTop: "-0.6rem",
                                                    minWidth: '1.2rem', 
                                                    minHeight: '1.2rem',
                                                    paddingTop: '0.3rem',
                                                }}
                                            >
                                                {+(+cartTotalQuantity)?.toFixed(2)}  
                                            </Badge>
                                        <Icon.ShoppingCart size={"1.6rem"}/>
                                    </div>}
                                <div className="me-md-2 pt-1 fs-4">
                                    {isAuthenticated ?  
                                            <Dropdown>
                                                <Dropdown.Toggle 
                                                    id="dropdown-basic"
                                                    className="ms-3 p-1 bg-transparent border-2 text-white"
                                                >
                                                    <Icon.User size={"1.5em"} className="me-2"/>
                                                    <span className="pe-1">
                                                        {first_name || fullname || "USER"}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item className="px-3" onClick={()=> navigate(isAdmin ? "/dashboard" : isTranslator ? `/dashboard/products/translations/${locale}` : "/account")}>
                                                        {(isAdmin || isTranslator) ? 
                                                            <>
                                                                <Icon.Monitor size={"1.6em"} className="me-2"/>
                                                                {t('dashboard')?.toUpperCase()}
                                                            </> 
                                                        :   <>
                                                                <Icon.User size={"1.6em"} className="me-2"/>
                                                                {t('account')?.toUpperCase()}
                                                            </>}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="px-3" onClick={signOut}>
                                                        <Icon.LogOut size={"1.6em"} className="me-2"/>
                                                        {t('logOut')?.toUpperCase()}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                    : <div onClick={handleLoginModal} className="d-flex">
                                        <Dropdown>
                                            <Dropdown.Toggle 
                                                id="dropdown-basic"
                                                className="ms-3 me-2 p-2 bg-transparent border-2 text-white"
                                            >
                                                <Icon.User size={"1.5em"} className="me-2"/>
                                                <span style={{paddingRight: "0.2rem"}}>
                                                    {t('logIn')?.toUpperCase()}
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{minWidth: 400}}>
                                                <p className="text-center fs-3 pt-4 pb-3 fw-bolder">
                                                    {t('logInOptions')}
                                                </p>
                                                <div className="pb-4">
                                                    <SignIn />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown>
                                            <Dropdown.Toggle 
                                                id="dropdown-basic"
                                                className="p-2 bg-transparent border-2 text-white"
                                            >
                                                <Icon.User size={"1.5em"} className="me-2"/>
                                                <span style={{paddingRight: "0.2rem"}}>
                                                    {t('signUp')?.toUpperCase()}
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{minWidth: 400}}>
                                                <p className="text-center fs-3 pt-4 pb-3 fw-bolder">
                                                    {t('signUpOptions')}
                                                </p>
                                                <div className="pb-4">
                                                    <SignUp />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
        </Container>
    )
}

export default MiniNavBar;
