import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    filtersRequestDashboard: null,
    filtersSuccessDashboard: ["filters"],
    filtersFailureDashboard: ["filtersError"],
    setSearchFieldDashboard: ["searchField"],
    searchRequestDashboard: ["params"],
    searchSuccessDashboard: ["results"],
    searchFailureDashboard: ["searchError"],
    selectActiveFilterDashboard: ["filter", "value"],
    resetActiveFiltersDashboard: null,
    productCreatedDashboard: ["data"],
    productUpdatedDashboard: ["data"],
    productDeletedDashboard: ["id"]
})

export const DashboardSearchTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingFilters: false,
    filtersError: null,
    fetchingProducts: null,
    searchError: null,
    searchField: "",
    filters: [],
    activeFilters: {},
    products: [],
    currentPage: 1,
    totalItems: null,
    totalPages: null,
    searchLimit: 20
})

/* ------------- Reducers ------------- */


/* Filters */
export const filtersRequest = state =>
  state.merge({ fetchingFilters: false, filtersError: null, filters: [] })

export const filtersSuccess = (state, {filters}) => 
  state.merge({ fetchingFilters: false, filtersError: null, filters })

export const filtersFailure = (state, {error}) =>
  state.merge({ fetchingFilters: false, filtersError: error })

export const selectActiveFilter = (state, {filter, value}) => {
  const activeFilters = {...state.activeFilters, [filter]: value || undefined}
  if (filter == "devices") {
    activeFilters.categories = undefined
    activeFilters.types = undefined
  }
  if (filter == "categories") activeFilters.types = undefined
  return state.merge({ activeFilters })
}

export const removeActiveFilter = (state, {filter}) => 
  state.merge({ activeFilters: { ...state.activeFilters, [filter]: null } })

export const resetActiveFilters = (state) => 
  state.merge({ 
    searchField: "", 
    activeFilters: {},
    products: [],
    currentPage: 1,
    totalItems: null,
    totalPages: null,
    searchLimit: 20
  })

  
/* Search */
export const setSearchField = (state, {searchField}) => 
  state.merge({ searchField })

export const searchRequest = (state, {page, limit}) => {
  const currentPage = page || state.currentPage
  const searchLimit = limit || state.searchLimit
  
  return state.merge({ fetchingProducts: true, searchError: null, products: [], currentPage, searchLimit })
}

export const searchSuccess = (state, {results}) => {
  const { products, currentPage, totalItems, totalPages } = results
  
  let productsResults = products?.map(el => {
    el = { 
      ...el,
      // au_ppm: el?.analyses?.[0]?.au_ppm,
      // ag_ppm: el?.analyses?.[0]?.ag_ppm,
      // pd_ppm: el?.analyses?.[0]?.pd_ppm,
      // pt_ppm: el?.analyses?.[0]?.pt_ppm,
      // cu_perc: el?.analyses?.[0]?.cu_perc,
      device: el?.device?.name,
      category: el?.category?.name,
      type: el?.type?.name
    }

    delete el?.analyses
    return el
  })

  return state.merge({ fetchingProducts: false, searchError: null, products: productsResults, currentPage, totalItems, totalPages })
}

export const searchFailure = (state, {searchError}) =>
  state.merge({ fetchingFilters: false, searchError, products: [], fetchingProducts: false })


/* Products */
export const productCreated = (state, {data}) => 
  state.merge({ products: [...data, ...state.product]})

export const productUpdated = (state, {data}) => {
  // alert(JSON.stringify(data))
  return state.merge({  })
}

export const productDeleted = (state, {id}) => 
  state.merge({ products: [...state.products]?.filter(el => el?.id != id)})




/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SEARCH_FIELD_DASHBOARD]: setSearchField,
  [Types.SEARCH_REQUEST_DASHBOARD]: searchRequest,
  [Types.SEARCH_SUCCESS_DASHBOARD]: searchSuccess,
  [Types.SEARCH_FAILURE_DASHBOARD]: searchFailure,
  [Types.FILTERS_REQUEST_DASHBOARD]: filtersRequest,
  [Types.FILTERS_SUCCESS_DASHBOARD]: filtersSuccess,
  [Types.FILTERS_FAILURE_DASHBOARD]: filtersFailure,
  [Types.SELECT_ACTIVE_FILTER_DASHBOARD]: selectActiveFilter,
  [Types.RESET_ACTIVE_FILTERS_DASHBOARD]: resetActiveFilters,
  [Types.PRODUCT_CREATED_DASHBOARD]: productCreated,
  [Types.PRODUCT_UPDATED_DASHBOARD]: productUpdated,
  [Types.PRODUCT_DELETED_DASHBOARD]: productDeleted,
})
