import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingOverlay= ({active, overlay, children}) => {

    const overlayStyle = {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `rgba(0, 0, 0, ${overlay || "0.2"})`,
        zIndex: 1
    }

    return (
        <div>
            {children}
            {active &&
                <div style={overlayStyle}>
                    <Spinner
                        animation="border"
                        variant={"primary"} 
                    />
                </div>}
        </div>
    )
};

export default LoadingOverlay;
