import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-feather";
import { Card, Row, Button, Form, InputGroup, Badge, ListGroup, Modal } from 'react-bootstrap';

import { ProductCategoriesTypes } from '../redux/dashboard/categories';

const DashboardSettingsAdmin = () => {

    const [device, setDevice] = useState(null) 
    const [category, setCategory] = useState(null) 
    const [type, setType] = useState(null) 

    const [addItem, setAddItem] = useState(null) 
    const [newItemName, setNewItemName] = useState(null)
    const [categoryCodeFormat, setCategoryCodeFormat] = useState(null) 

    const [editDevice, setEditDevice] = useState(null)
    const [editCategory, setEditCategory] = useState(null)

    const dispatch = useDispatch();
    const {devices, fetching} = useSelector(state => state.dashboard.categories)
    const categories = devices?.find(el => el?.id == device)?.categories
    const types = categories?.find(el => el?.id == category)?.types


    useEffect(()=> {
        getDevices()
    }, [])

    const addDevice = () => {
        dispatch({ type: ProductCategoriesTypes.ADD_DEVICE_REQUEST, name: newItemName })
    }

    const deleteDevice = (id) => {
        if (window.confirm('Are you sure you want to delete this device?')) {
            dispatch({ type: ProductCategoriesTypes.DELETE_DEVICE_REQUEST, id })
        }
    }

    const addCategory = (device_id) => {
        dispatch({ type: ProductCategoriesTypes.ADD_CATEGORY_REQUEST, name: newItemName, device_id, codeFormat: categoryCodeFormat })
    }

    const deleteCategory = (device_id, category_id) => {
        if (window.confirm('Are you sure you want to delete this category?')) {
            dispatch({ type: ProductCategoriesTypes.DELETE_CATEGORY_REQUEST, device_id, category_id })
        }
    }

    const addType = (category_id) => {
        dispatch({ type: ProductCategoriesTypes.ADD_TYPE_REQUEST, name: newItemName, category_id })
    }

    const deleteType = (id) => {
        if (window.confirm('Are you sure you want to delete this type?')) {
            // dispatch({ type: ProductCategoriesTypes.DELETE_TYPE_REQUEST, id })
        }
    }

    const getDevices = () => {
        dispatch({ type: ProductCategoriesTypes.GET_PRODUCT_CATEGORIES_REQUEST})
    }

    const changeCategoryNameAndCodeFormat = () => {
        const {name, id, codeFormat} = editCategory
        if (name < 3) return
        if (window.confirm(`Are you sure you want to make this changes?`)) {
            setEditCategory(null)
            dispatch({ type: ProductCategoriesTypes.UPDATE_CATEGORY_REQUEST, id, name, codeFormat})
        }
    }

    const changeDeviceName = () => {
        const {name, id} = editDevice
        if (name < 3) return
        if (window.confirm(`Are you sure you want to change this device name to "${name}"?`)) {
            setEditDevice(null)
            dispatch({ type: ProductCategoriesTypes.UPDATE_DEVICE_REQUEST, id, name })
        }
    }

    return (
        <Card>
            <Card.Header>
                <h1 className="card-subtitle color-black fw-bolder">
                    Admin
                </h1>
            </Card.Header>
            <Card.Body>
                <Row xs={1} sm={3}>
                    <ListGroup defaultActiveKey={devices?.[0]?.id}>
                        <div className="fw-bolder fs-4 p-3">Devices</div>
                        {devices?.map(dev => {
                            const active = device == dev?.id
                            const categoriesLength = dev?.categories?.length
                            return (
                                <ListGroup.Item
                                    action 
                                    active={active}
                                    key={dev?.id}
                                    onClick={()=> {
                                        setDevice(device == dev.id ? null : dev.id)
                                        setCategory(null)
                                        setAddItem(null)
                                    }}
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className={`ms-2 me-auto ${active ? "fw-bolder" : ""}`}>
                                        {dev?.name}
                                    </div>
                                    {active &&
                                        <>
                                            <Icon.Edit
                                                className='cursor-pointer'
                                                style={{marginRight: "0.5rem", marginLeft: "0.5rem"}}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    setEditDevice(editDevice ? null : {id: dev?.id, name: dev?.name})
                                                }}
                                            />
                                            <Icon.Trash2
                                                className='cursor-pointer'
                                                onClick={()=> deleteDevice(dev?.id)}
                                            />
                                        </>}
                                    {!active &&
                                        <Badge bg="primary" pill>
                                            {categoriesLength}
                                        </Badge>}
                                    <Icon.ChevronRight className='ms-3'/>
                                </ListGroup.Item>
                            )
                        })}
                        {!device &&
                            <>
                                {addItem == "device" ?
                                <ListGroup.Item
                                    className="d-flex align-items-center p-0 m-0"
                                >
                                    <Form.Control
                                        className='border-0 p-3 me-1 ps-4'
                                        placeholder="Device name"
                                        onChange={e => setNewItemName(e?.target?.value)}
                                    />
                                    <Button 
                                        className='m-0 p-2 mx-1'
                                        onClick={addDevice}
                                    >
                                        Upload
                                    </Button>
                                    <Icon.X
                                        className='m-2 cursor-pointer'
                                        onClick={()=> setAddItem(null)}
                                    />
                                </ListGroup.Item> :
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        Add Device
                                    </div>
                                    <Icon.PlusCircle  
                                        className='ms-3 cursor-pointer'
                                        onClick={()=> setAddItem('device')}
                                    />
                                </ListGroup.Item>}
                            </>}
                        
                    </ListGroup>
                    {device && 
                        <ListGroup>
                            <div className="fw-bolder fs-4 p-3">Categories</div>
                            {categories?.map(cat => {
                                const active = category == cat?.id
                                const typesLength = categories?.find(item => item?.id == cat?.id)?.types?.length
                                return (
                                    <ListGroup.Item
                                        action 
                                        key={cat?.id}
                                        active={active}
                                        onClick={()=> setCategory(category == cat.id ? null : cat.id)}
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className={`ms-2 me-auto ${active ? "fw-bolder" : ""}`}>
                                            {cat?.name}
                                        </div>
                                        {active &&
                                            <>
                                                <Icon.Edit
                                                    className='cursor-pointer'
                                                    style={{marginRight: "0.5rem", marginLeft: "0.5rem"}}
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        setEditCategory(editCategory ? null : {id: cat?.id, name: cat?.name, codeFormat: cat?.codeFormat})
                                                    }}
                                                />
                                                <Icon.Trash2
                                                    className='cursor-pointer'
                                                    onClick={()=> deleteCategory(device, cat?.id)}
                                                />
                                            </>}
                                        {!active && 
                                            <Badge bg="primary" pill>
                                                {typesLength}
                                            </Badge>}
                                        <Icon.ChevronRight className='ms-3'/>
                                    </ListGroup.Item>
                                )
                            })}
                            {!category &&
                                <>
                                    {addItem == "category" ?
                                    <ListGroup.Item
                                        className="d-flex align-items-center p-0 m-0"
                                    >
                                        <div className='w-100'>
                                            <Form.Control
                                                className='border-0 p-3 me-1 ps-4'
                                                placeholder="Category name"
                                                onChange={e => setNewItemName(e?.target?.value)}
                                            />
                                            <Form.Control
                                                className='border-0 p-3 me-1 ps-4'
                                                placeholder="Code Format"
                                                onChange={e => setCategoryCodeFormat(e?.target?.value)}
                                            />
                                        </div>
                                        <Button 
                                            className='m-0 p-2 mx-1'
                                            onClick={()=> addCategory(device)}
                                        >
                                            Upload
                                        </Button>
                                        <Icon.X
                                            className='m-2 cursor-pointer'
                                            onClick={()=> setAddItem(null)}
                                        />
                                    </ListGroup.Item> :
                                    <ListGroup.Item
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            Add Category
                                        </div>
                                        <Icon.PlusCircle  
                                            className='ms-3 cursor-pointer'
                                            onClick={()=> setAddItem('category')}
                                        />
                                    </ListGroup.Item>}
                                </>}
                        </ListGroup>}
                    {category && 
                        <ListGroup>
                            <div className="fw-bolder fs-4 p-3">Types</div>
                            {types?.map(el =>
                                <ListGroup.Item
                                    action 
                                    key={el?.id}
                                    // onClick={()=> setType(type == el.id ? null : el.id)}
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        {el?.name}
                                    </div>
                                    <Icon.Trash2
                                        className='cursor-pointer'
                                        onClick={()=> deleteType(el?.id)}
                                    />
                                </ListGroup.Item>
                            )}
                            {addItem == "type" ?
                                <ListGroup.Item
                                    className="d-flex align-items-center p-0 m-0"
                                >
                                    <Form.Control
                                        className='border-0 p-3 me-1 ps-4'
                                        placeholder="Type name"
                                        onChange={e => setNewItemName(e?.target?.value)}
                                    />
                                    <Button 
                                        className='m-0 p-2 mx-1'
                                        onClick={()=> addType(category)}
                                    >
                                        Upload
                                    </Button>
                                    <Icon.X
                                        className='m-1 cursor-pointer'
                                        onClick={()=> setAddItem(null)}
                                    />
                                </ListGroup.Item> :
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        Add Type
                                    </div>
                                    <Icon.PlusCircle  
                                        className='ms-3 cursor-pointer'
                                        onClick={()=> setAddItem('type')}
                                    />
                                </ListGroup.Item>}
                        </ListGroup>}
                </Row>
            </Card.Body>
            <Modal 
                animation={false} 
                show={editCategory} 
                onHide={()=> setEditCategory(null)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change category name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        className='border-0 p-1 px-2'
                        placeholder="Type name"
                        autoFocus
                        defaultValue={editCategory?.name}
                        onChange={e => setEditCategory({...editCategory, name: e?.target?.value})}
                    />
                    <Form.Control
                        className='border-0 p-1 px-2 mt-2'
                        placeholder="Type code format"
                        defaultValue={editCategory?.codeFormat}
                        onChange={e => setEditCategory({...editCategory, codeFormat: e?.target?.value})}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> setEditCategory(null)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={changeCategoryNameAndCodeFormat}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal 
                animation={false} 
                show={editDevice} 
                onHide={()=> setEditDevice(null)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change device name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        className='border-0 p-1 px-2'
                        placeholder="Type name"
                        autoFocus
                        defaultValue={editDevice?.name}
                        onChange={e => setEditDevice({...editDevice, name: e?.target?.value})}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> setEditDevice(null)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={changeDeviceName}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
}

export default DashboardSettingsAdmin
