import _ from 'lodash'
import moment from 'moment'
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Pagination, Card, Spinner, Form, Row, Col, Button, InputGroup, Container, ListGroup } from 'react-bootstrap';
import { DashboardCartTypes } from '../redux/dashboard/cart';
import { BASE_URL } from '../services/api';
import { CartTypes } from '../redux/account/cart';
import { useTranslation } from 'react-i18next';
import * as Icon from "react-bootstrap-icons";
import * as FeatherIcon from "react-feather";
import DashboardCartImages from './DashboardCartImages';
import { placeholderImage } from './ui/Utils';

const CartActivity = ({status, id}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [cartStatus, setCartStatus] = useState(status)

    const handleSelectionChange = ({value}) => {
        setCartStatus(value)
        dispatch({type: CartTypes.UPDATE_CART_REQUEST, id, cart: {status: value}})
    }

    useEffect(()=> setCartStatus(status), [status, id])

    const options = [
        "open",
        "submitted",
        "pending_receipt",
        "received",
        "pending_payment",
        "advance_payment",
        "payment_made",
        "cancelled",
        "sent_back"
    ]

    return (
        <div className='pt-2'>
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={options?.map(value => ({value, label: t(value)}))}
                // isDisabled={!}
                defaultValue={{value: cartStatus, label: t(cartStatus)}}
                placeholder={"Status"}
                value={{value: cartStatus, label: t(cartStatus)}}
                onChange={handleSelectionChange}
            />
        </div>
    )
}

const Cart = ({fetching, submissions, cart_id, images, cart, status, products}) => {

    const { t } = useTranslation();
        
    return (
        <>
            <Card className='mt-3 p-0'>
                <h3 className='p-3 bg-light'>
                    Cart Details
                </h3>
                {status == "open" &&
                    <Table striped hover={!fetching} responsive>
                        <thead>
                            <tr className="text-center">
                                <th>{t("image")?.toUpperCase()}</th>
                                <th>{t("priceUsdKg")?.toUpperCase()}</th>
                                <th>{t("priceLocalKg")?.toUpperCase()}</th>
                                <th>{t("reference")?.toUpperCase()}</th>
                                <th>{t("quantityInKg")?.toUpperCase()}</th>
                                <th>{t("total")?.toUpperCase()}</th>
                                <th>{t("totalWinningLocalCurrency")?.toUpperCase()}</th>
                                <th>{t("delete")?.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart?.products?.map(({id, quantity, image, price_usd, price_converted, total_price_usd, total_price_converted, reference}) => {
                                const img = image?.url ? (BASE_URL+image?.url) : placeholderImage
                                
                                const cardImageStyle =  {
                                    objectFit: 'cover',
                                    height: 80,
                                    width: 120
                                }

                                return (
                                <tr 
                                    key={id} 
                                    className="align-items-center text-center"
                                >
                                    <td>
                                        <Card.Img 
                                            style={cardImageStyle}
                                            src={img} 
                                        />
                                    </td>
                                    <td>
                                        {"USD " + +(+price_usd)?.toFixed(2) || "N/A"}
                                    </td>
                                    <td>
                                        {price_converted?.toFixed(2)}
                                    </td>
                                    <td>
                                        {reference}
                                    </td>
                                    <td>
                                        {quantity}
                                    </td>
                                    <td>
                                        {"USD " + +(+total_price_usd)?.toFixed(2) || "N/A"}
                                    </td>
                                    <td>
                                        {total_price_converted?.toFixed(2)}
                                        {/* {(cart?.cart_currency + " " + +(+el?.total_price_converted)?.toFixed(2)) || "N/A"} */}
                                    </td>
                                    <td className="text-center">
                                        <FeatherIcon.Trash2
                                            className='cursor-pointer'
                                            // onClick={()=> !fetching && handleDeleteProduct(el?.cart_product_id)}
                                        />
                                    </td>
                                </tr>
                            )})}
                        </tbody>
                    </Table>}
                {submissions?.map((el, i) => (
                    <Card.Body key={el?.id}>
                        <Card.Title className='p-2 fw-bolder'>
                            {i == 0 && submissions?.length > 1 ? "Last Submission On " : "Submitted On "} 
                            {moment(el?.updatedAt).format("MMM D, YYYY - hh:mm a")}
                        </Card.Title>
                        <Row>
                            <Col>
                                <div className='fs-3 p-2'>{t('Products')}</div>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Img</th>
                                            <th>Quantity</th>
                                            <th>Price USD</th>
                                            <th>Total Price USD</th>
                                            <th>Price Converted {el?.financialData?.currency}</th>
                                            <th>Total Price Converted {el?.financialData?.currency}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {el?.products?.map((item) => (
                                            <tr key={item?.id}>
                                                <td>{item?.id}</td>
                                                <td>
                                                    <img
                                                        src={item?.image?.url ? (BASE_URL + item?.image?.url) : null}
                                                        width={50}
                                                        height={50}
                                                        alt="product"
                                                    />
                                                </td>
                                                <td>{item?.quantity}</td>
                                                <td>{item?.price_usd?.toFixed(2)}</td>
                                                <td>{item?.total_price_usd?.toFixed(2)}</td>
                                                <td>{item?.price_converted?.toFixed(2)}</td>
                                                <td>{item?.total_price_converted?.toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>  
                        </Row>
                        <Row>
                            <Col>
                                <div className='fs-3 p-2'>Findancial data</div>
                                <ListGroup>
                                    {Object?.entries(el?.financialData)?.map(item => (
                                        <ListGroup.Item key={item[0]}>
                                            {t(item[0])} - {item[1]}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Col>
                            <Col>
                                <div className='fs-3 p-2'>Payment info</div>
                                <ListGroup>
                                    {Object?.entries(el?.paymentInfo)?.map(item => (
                                        <ListGroup.Item key={item[0]}>
                                            {t(item[0])} - {item[1]}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Col>
                            <Col>
                                <div className='fs-3 p-2'>Shipping info</div>
                                <ListGroup>
                                    {Object?.entries(el?.shippingInfo)?.map(item => (
                                        <ListGroup.Item key={item[0]}>
                                            {t(item[0])} - {item[1]}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                ))}
            </Card>
            <DashboardCartImages cart_id={cart_id} images={images}/>
        </>
    )
}

const DashboardCart = () => {

    const { t } = useTranslation()

    const dispatch = useDispatch();
    let { id } = useParams();
    const {cart, fetching, error} = useSelector(state => state.dashboard?.cart);

    useEffect(()=> {
        if (id) {
            dispatch({ type: DashboardCartTypes.GET_CART_REQUEST_DASHBOARD, id })
        }
    }, [id])

    const handleInvoiceDownload = () => {
        const { id, ref } = cart || {}
        if (id && ref) dispatch({type: CartTypes.GET_CART_INVOICE_REQUEST, id, ref})
    }
    

    return (
        <Card className='shadow-none'>
            <Card.Header>
                <Container fluid>
                    <Row>
                        <Col className='mt-3 me-4'>
                            <h1 className="card-subtitle text-muted">
                                {t('cart')} - {cart?.ref} 
                                {/* - {cart?.user?.fullname} - {cart?.user?.email} */}
                            </h1>
                        </Col>
                        <Col md="auto" className='d-flex'>
                            <Button 
                                className='m-2'
                                onClick={handleInvoiceDownload}
                            >
                                <Icon.FileEarmarkArrowDown size={"2.1em"} className={"py-1"}/>
                            </Button>
                            <CartActivity 
                                status={t(cart?.status)}
                                id={cart?.id}
                            />
                        </Col>
                        {fetching ? 'fetching cart' 
                            : cart ? 
                                <Cart 
                                    user={cart?.user || {}}
                                    submissions={cart?.submissions || []}
                                    cart_id={cart?.id}
                                    images={cart?.images}
                                    products={cart?.products}
                                    status={cart?.status}
                                    cart={cart}
                                    fetching={fetching}
                                />
                            : error ? <div>Cart error</div>
                        : null}
                    </Row>
                </Container>
            </Card.Header>
        </Card>
    );
};

  
export default DashboardCart
