import moment from 'moment';
import _, {capitalize} from 'lodash'
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { DashboardSearchTypes } from '../redux/dashboard/search'
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Pagination, Card, Spinner, Form, Row, Col, Button, InputGroup, Container } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom'
import * as Icon from "react-feather";
import Select from "react-select";
import { SpinnerCircular } from 'spinners-react';
import { Search, Trash } from 'react-feather';
import { DashboardProductTypes } from '../redux/dashboard/product';
import SortByFilterDashboard from '../components/SortByFilterDashboard';
import { BASE_URL } from '../services/api';
import { useTranslation } from 'react-i18next';


const SelectionFilter = ({
    filter,
    label,
    options,
    placeholder
,}) => {
    const dispatch = useDispatch();
    
    const handleSelectionChange = (filter, value) => {
        dispatch({ type: DashboardSearchTypes.SELECT_ACTIVE_FILTER_DASHBOARD, filter, value})
        dispatch({ type: DashboardSearchTypes.SEARCH_REQUEST_DASHBOARD })
    }
    
    return (
        <Form.Group>
            <Select
                styles={{menu: ({ width, ...css }) => ({
                        ...css,
                        width: "max-content",
                        minWidth: "100%"
                })}}
                className="react-select-container"
                classNamePrefix="react-select"
                options={[{label}, ...options]}
                placeholder={placeholder}
                // value={options?.find(el => el?.value == value)}
                onChange={({value}) => handleSelectionChange(filter, value)}
            />
        </Form.Group>
    )
}


const DashboardProducts = () => {
    const { t, i18n} = useTranslation();
    const locale = i18n?.language;

    const placeholderImage = 'https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640'

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { 
        products, 
        currentPage, 
        totalPages,
        totalItems, 
        searchLimit,
        fetchingProducts,
        searchError,
    } = useSelector(state => state.dashboard.search);

    const currency = useSelector(state => state.global.currency);
    const { deletingProducts } = useSelector(state => state.dashboard.product);
    const { searchField } = useSelector(state => state.dashboard.search);
    const filters = useSelector(state => state.dashboard.search.filters);
    const activeFilters = useSelector(state => state.dashboard.search.activeFilters);

    const [page, setPage] = useState(currentPage)

    useEffect(()=> {
        setPageAndSearch(1);
        // Refresh categories
        dispatch({ type: DashboardSearchTypes.FILTERS_REQUEST_DASHBOARD})
    }, [currency])
    

    const setPageAndSearch = (page, limit) => {
        setPage(page)
        dispatch({ type: DashboardSearchTypes.SEARCH_REQUEST_DASHBOARD, page, limit })
    }

    const handleProductAvailability = (id, available) => {
        dispatch({ type: DashboardProductTypes.UPDATE_PRODUCT_AVAILABILITY_REQUEST_DASHBOARD, id, available })
    }

    const handleProductDelete = (id, code) => {
        dispatch({ type: DashboardProductTypes.DELETE_PRODUCT_REQUEST_DASHBOARD, id, code })
    }


    const setSearchField = (e) => {
        dispatch({ type: DashboardSearchTypes.SET_SEARCH_FIELD_DASHBOARD, searchField: e.target.value })
    }

    const searchRequest = () => {
        dispatch({ type: DashboardSearchTypes.SEARCH_REQUEST_DASHBOARD })
    }

    const resetFilters = () => {
        dispatch({ type: DashboardSearchTypes.RESET_ACTIVE_FILTERS_DASHBOARD })
        dispatch({ type: DashboardSearchTypes.SEARCH_REQUEST_DASHBOARD })
    }

    const PageArrows = () => (
        <div className='d-flex justify-content-end'>
            <Pagination>
            <Pagination.First
                onClick={() => setPageAndSearch(1)}
                disabled={currentPage == 1}
            />
            <Pagination.Prev
                onClick={() => setPageAndSearch(currentPage-1)}
                disabled={currentPage <= 1}
            />
            <Pagination.Next
                onClick={() => setPageAndSearch(currentPage+1)}
                disabled={currentPage >= totalPages}
            />
            <Pagination.Last
                onClick={() => setPageAndSearch(totalPages)}
                disabled={currentPage >= totalPages}
            />
            </Pagination>
        </div>
    )

    const fields = [
        {field: "image", label: "image"},
        {field: "id", label: "ID"},
        {field: "code", label: "Code"},
        {field: "reference", label: "ref"},
        {field: "device", label: "Device"},
        {field: "category", label: "Category"},
        // {field: "type", label: "Type"},
        {field: "price", label: "Price"},
        {field: "createdAt", label: "Created On"},
        {field: "updatedAt", label: "Updated On"},
        // {field: "available", label: "Avail"},
        // {field: "delete", label: "Delete"},
    ]

    

    const showFilters = useCallback(() => {
        const categories = filters?.find(el => el?.id == activeFilters["devices"])?.categories
        const types = categories?.find(el => el?.id == activeFilters["categories"])?.types
        return (
            <>
                <Col 
                    className='mb-2' 
                    key={"sortBy"}
                >
                    <SortByFilterDashboard />
                </Col>
                <Col 
                    className='mb-2' 
                    key={"devices"}
                >
                    <SelectionFilter
                        filter={"devices"}
                        label={"Devices"}
                        placeholder={"Devices"}
                        options={filters?.map(item => ({
                            value: item?.id, 
                            label: item?.name
                        }))}
                    />
                </Col>  
                {categories?.length ?
                    <Col 
                        className='mb-2' 
                        key={"categories"}
                    >
                        <SelectionFilter
                            filter={"categories"}
                            label={"Categories"}
                            placeholder={"Categories"}
                            options={
                                categories?.map(item => ({
                                    value: item?.id, 
                                    label: item?.name
                                }))
                            }
                        />
                    </Col>
                : null}
                {types?.length ?
                    <Col 
                        className='mb-2' 
                        key={"types"}
                    >
                        <SelectionFilter
                            filter={"types"}
                            label={"Types"}
                            placeholder={"Types"}
                            options={
                                types?.map(item => ({
                                    value: item?.id, 
                                    label: item?.name
                                }))
                            }
                        />
                    </Col>
                : null}
            </>
        )
    }, [filters, activeFilters])

    return (
        <Card>
            <Card.Header>
                <Row className='d-flex justify-content-end'>
                    <Col>
                        <Button  
                            className="mt-2 mx-2"
                            onClick={() => navigate(`/dashboard/product/upload`)}
                            size='lg'
                            style={{minWidth: "12vw", minHeight: '6vh'}}
                        >
                            New
                        </Button>
                    </Col>
                    <Col md="auto">
                        <Button  
                            className="p-1 justify-content-center"
                            onClick={() => navigate(`/dashboard/products/translations/${locale}`)}
                        >
                            <Icon.Globe size={'1.2rem'}/>
                            <span className='px-1'>
                                All Translations
                            </span>
                        </Button>
                    </Col>
                </Row>
                <Container fluid>
                    <Row  className="align-items-center">
                        <Col md="auto" className='mt-3 me-4'>
                            <h2 className="card-subtitle text-muted">
                                {t('Products')}
                            </h2>
                        </Col>
                        <Col md="auto" className='mt-3'>
                            <Form 
                                inline="true"
                                onSubmit={(e)=> {
                                    e.preventDefault();
                                    searchRequest();
                                }}
                            >
                                <InputGroup className="input-group-navbar">
                                    <Form.Control 
                                        placeholder={t("search")} 
                                        aria-label="search"
                                        value={searchField}
                                        onSubmit={searchRequest}
                                        onChange={e => {
                                            setSearchField(e)
                                            // searchRequest()
                                        }} 
                                    />
                                    <Button>
                                        <Search 
                                            className="feather" 
                                            onClick={searchRequest} 
                                        />
                                    </Button>
                                </InputGroup>
                            </Form>
                        </Col>
                        <Col md="auto" className='mt-3'>
                            <Row className='mt-1'>
                                {showFilters()}
                                <Col>
                                    <Button  
                                        onClick={resetFilters}
                                        variant="outline-primary"
                                        className='mt-1'
                                    >
                                        Reset
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='mt-3'>
                            <PageArrows />
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            {fetchingProducts ? 
                <div 
                    style={{height: "70vh", justifyContent: "center"}}
                    className="d-flex pb-7"
                >
                    <SpinnerCircular 
                        size={100} 
                        thickness={100}
                        className="p-3"
                        color={"#F4BC2B"}
                        secondaryColor={"transparent"}
                    />
                </div>
            :
            Array.isArray(products) && products?.length ?
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            {fields?.map(({label}, i) => {
                                return (
                                    <th 
                                        key={label} 
                                        className="text-truncate"
                                    >
                                        {label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((row, i) => {
                            const rowId = row.id
                            const rowCode = row.code
                            const deletingProduct = deletingProducts?.includes(rowId)
                            const tableCells = row

                            return (
                                <tr 
                                    key={rowId}
                                    className='cursor-pointer'
                                    onClick={()=> !deletingProduct && navigate(`/dashboard/product/${rowId}`)}
                                >
                                    {fields?.map(({field, label}, i) => {
                                        const cellvalue = tableCells[field]

                                        return (
                                            <td key={i}>
                                                
                                                {/* DELETE */}
                                                {field == "delete" ?
                                                    <>
                                                        {deletingProduct ?
                                                            <Spinner
                                                                animation="border"
                                                                variant={"primary"} 
                                                            />
                                                        :
                                                            <Trash
                                                                className="align-middle" 
                                                                size={25} 
                                                                onClick={(e)=> {
                                                                    e.stopPropagation()
                                                                    const text = 'Delete the item?'
                                                                    if (window.confirm(text)) handleProductDelete(rowId, rowCode)
                                                                }}
                                                            />}
                                                    </>

                                                // IMAGE
                                                : field == "image" ?
                                                    <span 
                                                        className="align-left"
                                                        onClick={(e)=> e.stopPropagation()}
                                                    >
                                                        <Zoom 
                                                            overlayBgColorStart={'rgba(108, 122, 137, 0)'}
                                                            overlayBgColorEnd={'rgba(108, 122, 137, 0.7)'}
                                                        >
                                                            <img
                                                                src={cellvalue?.url ? (BASE_URL + cellvalue?.url) : placeholderImage}
                                                                width="70"
                                                                height="70"
                                                                className="rounded-circle me-2"
                                                                alt="product"
                                                            />
                                                        </Zoom>
                                                    </span>

                                                : field == "updatedAt" ?
                                                    <span className="truncate">
                                                        {moment(cellvalue).format("MMM D, YYYY")}
                                                    </span>

                                                : field == "createdAt" ?
                                                    <span className="truncate">
                                                        {moment(cellvalue).format("MMM D, YYYY")}
                                                    </span>

                                                : field == "available" ?
                                                    <div 
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            handleProductAvailability(rowId, !cellvalue);
                                                        }}
                                                    >
                                                        <Form.Check 
                                                            readOnly 
                                                            type={'checkbox'}
                                                            checked={cellvalue}
                                                        />
                                                    </div>
                                                    
                                                
                                                // ANY OTHER ROW
                                                : <span className="truncate">
                                                    {cellvalue}
                                                </span>}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                :
                searchError ?
                    <div className='text-center p-4'>
                        <span style={{fontWeight: "bold"}}>Search Error: </span> 
                        {searchError}
                    </div>
                : <div className='text-center p-4'>No result</div>}

            <Row className='mt-3 ms-2 me-2 d-flex justify-content-end'>
                <Col>
                    <span className="ms-3 me-2">Showing: {totalItems}</span>
                    <span className="mx-2">
                        Page{" "}
                    <strong>
                        {currentPage} of {totalPages}
                    </strong>
                    </span>
                </Col>
                <Col md="auto">
                    <span className="ms-3 me-2">Show:</span>
                    <Form.Select
                        className="d-inline-block w-auto"
                        value={searchLimit}
                        onChange={(e) => setPageAndSearch(currentPage, Number(e.target.value))}
                    >
                        {[10, 20, 30, 40, 50].map((searchLimit) => (
                            <option key={searchLimit} value={searchLimit}>
                                {searchLimit}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="ms-3 me-2">Go to page:</span>
                    <Form.Control
                        className="d-inline-block"
                        type="number"
                        value={page}
                        onChange={(e) => {
                            const newPage = e.target.value ? Number(e.target.value) : "";
                            setPage(newPage)
                        }}
                        onKeyPress={(target)=> {
                            if (target.charCode==13) {
                                page >= 1 && page <= totalPages && setPageAndSearch(page);
                            }
                            
                        }}
                        style={{ width: "75px" }}
                    />
                </Col>
                <Col>
                    <PageArrows />
                </Col>
            </Row>
        </Card>
    );
};

  
export default DashboardProducts
