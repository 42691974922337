import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import languages from "./languages";

// export const bestAvailableLanguage = RNLocalize.findBestAvailableLanguage(Object.keys(languages))?.languageTag

export const allLanguages = Object.entries(languages).map(el => ({
  code: el[0], name: el[1].name, popular: el[1].popular, flag: el[1].flag,
}))
// .sort((x,y) => x.code == bestAvailableLanguage ? -1 : y.code == bestAvailableLanguage ? 1 : 0);


export const popularLanguages = Object.entries(languages).map(el => ({
  code: el[0], name: el[1].name, popular: el[1].popular, flag: el[1].flag,
}))
.filter(el => el.popular /* || el.code == bestAvailableLanguage */)
// .sort((x,y) => x.code == bestAvailableLanguage ? -1 : y.code == bestAvailableLanguage ? 1 : 0);

const resources = Object.assign({}, ...Object.keys(languages).map(tag => ({[tag]: {translation: languages[tag].file()}})))

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  // lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
