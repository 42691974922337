import { call, delay, put } from 'redux-saga/effects'
import ProfileActions from '../../redux/account/profile'
import CartActions from '../../redux/account/cart'
import { toast } from 'react-toastify'

export function * getProfile(api) {
    const response = yield call(api.getProfile)

    // console.log(response.data)
    if (response.ok) {
        yield put(ProfileActions.getProfileSuccess(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(ProfileActions.getProfileError(message))
    }
}

export function * updateProfile(api, {profile}) {
    const response = yield call(api.updateProfile, profile)

    if (response.ok) {
        yield put(ProfileActions.getProfileRequest())
        yield put(CartActions.getOpenCartRequest())
        toast.success(response?.data?.message)
    } else {
        const message = response?.data?.message || "Error"
        yield put(ProfileActions.updateProfileError(message))
    }
}



export function * updateProfileBankInfo(api, {bankInfo}) {
    const response = yield call(api.updateProfileBankInfo, bankInfo)

    if (response.ok) {
        yield put(ProfileActions.getProfileRequest())
        yield put(CartActions.getOpenCartRequest())
        toast.success(response?.data?.message)
    } else {
        const message = response?.data?.message || "Error"
        yield put(ProfileActions.updateProfileError(message))
    }
}
