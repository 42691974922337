import React from "react";
import { Accordion, Card, Col, Container, Image, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const WeeeInfo = () => {

  const { t } = useTranslation();
  
  return (
    <div>
      {/* <hr className="bg-primary" style={{height: "10px"}}/> */}
      <Container className="px-3 pt-2 pb-7 fs-4">
        <h1 className="fw-bolder px-2">
          The electronic age
        </h1>
        <p className='pt-4 fs-4'>
          In today’s digital age, we have become accustomed to instant communications via internet, email and social media at the touch of a button. In turn this has led to a massive increase in the demand for electronic devices, something that is only going to expand further as the Internet of Things (IoT) concept becomes part of our lives - providing the means for household appliances to collect and exchange data using a computer network or intelligent electrical installation, and for cars to connect to the internet to provide information about traffic conditions, to aid in navigation and even deliver self-steering capabilities. At the heart of most electronic devices is the printed circuit board (PCB). This is used to support and connect electronic components using conductive pathways, tracks or signal traces etched from copper sheets laminated onto a non-conductive substrate. Most frequently associated with computers, they are also present in TVs, radios, digital cameras, cell phones as well as other applications such as medical devices. Without PCBs, the electronic world in which we live, and have so readily adapted to, would not exist!
        </p>
        <Image
            src={"https://www.ecotradegroup.com/uploads/files/pcb_min.jpg"} 
            className={"rounded py-3"}
            style={{ width: "100%" }}
        />
        <Row className="pt-4">
          <Col md={6}>
            <h1 className="fw-bolder">Waste from Electrical and Electronic Equipment (WEEE)</h1>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <h3 className="pt-2 fw-bolder">Recycling of Waste from Electrical and Electronic Equipment (WEEE)</h3>
            <p>
              End-of-life computers, mobile phones, TV sets, fridges and more contribute to the generation of waste from electrical and electronic equipment, the amount of which is growing rapidly as our consumer goods-oriented society continues to drive markets. These products contain a mix of components, some of which are hazardous and can cause both environmental and health problems, In addition some components, including palladium, rhodium, ruthenium, iridium, gold, silver and platinum are both rare and expensive.
            </p>
            <h1 className="fw-bolder">European Union “WEEE Directive”</h1>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <p>
              The European Union, in recognizing the problems of indiscriminate disposal of waste, has issued a WEEE Directive, a set of rules that are designed to contribute to “sustainable production and consumption” in the future. Specifically these rules address environmental and other issues that result from discarded electrical and electronic equipment across the region.
              At the heart of this, the EU believes that “the collection, treatment and recycling of electrical and electronic equipment (EEE) at the end of life can improve sustainable production and consumption, increase resource efficiency and contribute to a circular economy”.
              To achieve this, the WEEW Directive has introduced a number of control measures:
              - Separate collection and proper treatment of WEEE, with targets set for collection, recovery and recycling
              - Support for EU members in the battle against illegal waste exporting by making it more difficult for exporters to hide illegal shipments
              - Introduction of compatible national EEE registers and harmonization of reporting formats, in order to remove unnecessary administration
            </p>
          </Col>
          <Col md={6}>
            <h1 className="fw-bolder">The Printed Circuit Boards (PCB)</h1>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <p>
              The increasing usage of electronic products is now generating 50 million tonnes of electronic waste (e-waste) per annum globally. As well as natural obsolescence, consumer habits and expectations are influencing this, as they demonstrate a desire to replace perfectly-functioning devices with the latest “new’ technology.
              The management of this e-waste has become a hotly-debated topic, with the recycling of scrap PCBs playing a critical role in both environmental protection and economic development. Part of this debate includes the recognition that we need to obtain precious metals in a more cost-effective and environmentally-friendly way than through traditional mining to meet the future demand.
              Nowadays, the sheer volume of e-waste being generated makes recycling financially viable despite the relatively low quantity of precious metals on each board; the ecological benefits are profound; and the technology exists to make recycling both possible and profitable, helped by price rises of the precious metals due to the imbalance of demand and supply.
            </p>
            <h3 className="pt-2 fw-bolder">Recycling PCBs</h3>
            <p>
              The increasing usage of electronic products is now generating 50 million tonnes of electronic waste (e-waste) per annum globally. As well as natural obsolescence, consumer habits and expectations are influencing this, as they demonstrate a desire to replace perfectly-functioning devices with the latest “new’ technology.
              The management of this e-waste has become a hotly-debated topic, with the recycling of scrap PCBs playing a critical role in both environmental protection and economic development. Part of this debate includes the recognition that we need to obtain precious metals in a more cost-effective and environmentally-friendly way than through traditional mining to meet the future demand.
              Nowadays, the sheer volume of e-waste being generated makes recycling financially viable despite the relatively low quantity of precious metals on each board; the ecological benefits are profound; and the technology exists to make recycling both possible and profitable, helped by price rises of the precious metals due to the imbalance of demand and supply.
            </p>
          </Col>
        </Row>
        <hr style={{ backgroundColor: 'gray', height: 1 }} />
        <Col className="text-center py-4">
          <h1 className="fw-bolder">
            WEEE CATEGORIES
          </h1>
          <hr className="mx-auto" style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
          <p>
            Effective from 1st January 2018, the EU introduced electrical and electronic equipment into six specific sectors:
          </p>
        </Col>
        <Row className="pt-4">
          <Col md={6}>
            <h3 className="pt-2 fw-bolder">Equipment Operating On A Temperature-Exchange Basis</h3>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <Table bordered className="my-4 mb-5" style={{  }}>
              <tbody>
                <tr>
                  <td>Fridges</td>
                </tr>
                <tr>
                  <td>Refrigerators</td>
                </tr>
                <tr>
                  <td>Freezers</td>
                </tr>
                <tr>
                  <td>Air conditioners</td>
                </tr>
                <tr>
                  <td>Oil heaters</td>
                </tr>
                <tr>
                  <td>Drying equipment</td>
                </tr>
                <tr>
                  <td>Heat pumps</td>
                </tr>
              </tbody>
            </Table>
            <h3 className="pt-2 fw-bolder">Screens, Monitors And Equipment With A Surface Area Greater Than 100 Cm</h3>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <Table bordered className="my-4 mb-5" style={{  }}>
              <tbody>
                <tr>
                  <td>Screens</td>
                </tr>
                <tr>
                  <td>Monitors</td>
                </tr>
                <tr>
                  <td>TV receivers</td>
                </tr>
                <tr>
                  <td>Laptops</td>
                </tr>
                <tr>
                  <td>Notebooks</td>
                </tr>
              </tbody>
            </Table>
            <h3 className="pt-2 fw-bolder">Lamps</h3>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <Table bordered className="my-4 mb-5" style={{  }}>
              <tbody>
                <tr>
                  <td>Fluorescent lamps</td>
                </tr>
                <tr>
                  <td>Pressure sodium lamps</td>
                </tr>
                <tr>
                  <td>LEDs</td>
                </tr>
                <tr>
                  <td>Metahalogenic lamps</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={6}>
          <h3 className="pt-2 fw-bolder">Large-Size Equipment (External Dimensions Exceeding 50 Cm)</h3>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <Table bordered className="my-4 mb-5" style={{  }}>
              <tbody>
                <tr>
                  <td>Washing machines</td>
                  <td>Drying machines</td>
                  <td>Dishwashers</td>
                </tr>
                <tr>
                  <td>Cookers</td>
                  <td>Large-size computers</td>
                  <td>Ovens</td>
                </tr>
                <tr>
                  <td>Printers</td>
                  <td>Copying machines</td>
                  <td>PV panels</td>
                </tr>
                <tr>
                  <td>Medical equipment</td>
                  <td>Automatic dispensers</td>
                  <td>Equipment to reproduce sound and pictures</td>
                </tr>
              </tbody>
            </Table>
            <h3 className="pt-2 fw-bolder">Equipment Operating On A Temperature-Exchange Basis</h3>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <Table bordered className="my-3" style={{ }}>
              <tbody>
                <tr>
                  <td>Vacuum cleaners</td>
                  <td>Sweepers</td>
                  <td>Sewing machines</td>
                </tr>
                <tr>
                  <td>Lighting fixtures</td>
                  <td>Cookers and microwaves</td>
                  <td>Ventilators</td>
                </tr>
                <tr>
                  <td>Irons</td>
                  <td>Toasters</td>
                  <td>Kettles</td>
                </tr>
                <tr>
                  <td>Clocks</td>
                  <td>Scales</td>
                  <td>Shavers</td>
                </tr>
                <tr>
                  <td>Calculators</td>
                  <td>Radios</td>
                  <td>Cameras</td>
                </tr>
                <tr>
                  <td>Players</td>
                  <td>Smoke sensors</td>
                  <td>Thermostats</td>
                </tr>
              </tbody>
            </Table>
            <h3 className="pt-2 fw-bolder">Small-Size Computer And Communication Equipment (External Dimensions Less Than 50 Cm)</h3>
            <hr style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
            <Table bordered className="my-3" style={{ }}>
              <tbody>
                <tr>
                  <td>Mobile phones</td>
                  <td>GPSs</td>
                  <td>Calculators</td>
                </tr>
                <tr>
                  <td>Routes</td>
                  <td>Personal computers</td>
                  <td>Printers</td>
                </tr>
                <tr>
                  <td>Telephones</td>
                  <td colSpan="2"></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Image
            src={"https://www.ecotradegroup.com/uploads/files/recycling_min.jpg"} 
            className={"rounded pt-5 pb-4"}
            style={{ width: "100%" }}
        />
        <Col className="text-center pt-4 pb-2">
          <h1 className="fw-bolder">
            FAQ
          </h1>
          <hr className="mx-auto" style={{ backgroundColor: 'gray', height: 4, width: 40, margin: '10px 0' }} />
        </Col>
        <Accordion flush defaultActiveKey="0" className="mt-lg-3 mt-4">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h3>How is WEEE Recycled?</h3>
            </Accordion.Header>
            <Accordion.Body>
              <p className="Body">
                According to the EU WEEE directive, a recycling protocol and process should be adhered to, as follows:
                <br />
                - fully documented collection
                <br />
                - on-site reception, weighing and initial sorting of entire devices by category
                <br />
                - preparation and removal of electrical wiring
                <br />
                - decontamination followed by complete mechanical or manual dismantling to extract recoverable materials
                <br />
                - shredding of recoverable materials, followed by metal extraction, using strong magnets to remove ferrous metals and electronic currents for non-magnetic metals
                <br />
                - sorting of recovered materials to be forwarded to specific reuse activities
                <br />
                - plastic, once removed, sorted into types by methods such as near-infrared light or density separation
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h3>Can the Printed Circuit Boards themselves be Recycled?</h3>
            </Accordion.Header>
            <Accordion.Body>
              <p className="Body">
                This is possible but involves a very laborious process and consequently is only a small scale activity. To create a new product from the PCB, material must be hand-sorted, cut to a standard shape, drilled and pinned and edges de-burred. The value of the new product is likely to be low, and margins, after this process extremely small, making it a questionable operation.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <h3>Can Printed Circuit Boards be Reused?</h3>
            </Accordion.Header>
            <Accordion.Body>
              <p className="Body">
                Again, this is unlikely to be economically-viable as a highly manual process makes labor costs prohibitive. In addition, the components of a PCB are fragile and easy to damage, and each would need re-testing to ensure they continued to function.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <h3>What valuable metals are used in computers</h3>
            </Accordion.Header>
            <Accordion.Body>
              <p className="Body">
                A wide selection of rare metals are to be found in computers, albeit it in very small quantities. These are:
              </p>
              <table className="table table-bordered mt-3" style={{ fontSize: '14px' }}>
                <tbody>
                  <tr>
                    <td>Gold</td>
                    <td>Silver</td>
                    <td>Platinum</td>
                  </tr>
                  <tr>
                    <td>Palladium</td>
                    <td>Copper</td>
                    <td>Nickel</td>
                  </tr>
                  <tr>
                    <td>Tantalum</td>
                    <td>Cobalt</td>
                    <td>Aluminum</td>
                  </tr>
                  <tr>
                    <td>Tin</td>
                    <td>Zinc</td>
                    <td>Neodymium</td>
                  </tr>
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </div>
  )
}

export default WeeeInfo;