import React from 'react';
import { Container, Row, Col, Image, Breadcrumb } from 'react-bootstrap';
import WeeBackground from '../../assets/img/photos/search-background.jpeg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import MiniNavBar from '../../components/MiniNavBar';

const HowToDeleteAccount = () => {
    const {pathname} = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${WeeBackground})`,
                    backgroundSize: "cover"
                }}
            >
                <MiniNavBar noCurrencies/>
                <div className="text-center p-7 bg-dark bg-opacity-50">
                    <h1 className="fw-bolder text-white">
                        {t("howToDeleteMyAccount")}
                    </h1>
                </div>
                {pathname?.includes('how-to-delete-my-account') &&
                    <Breadcrumb className='px-7 pb-3 text-white bg-dark bg-opacity-50'>
                        <Breadcrumb.Item onClick={()=> navigate('/',{ pathname: ""})}>
                            {t("home")}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {t("howToDeleteMyAccount")}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
            </div>
            <Container className="p-2 pt-5 fs-4">
                <h1 className='font-bolder text-uppercase py-2'>
                    {t("howToDeleteMyAccount")}
                </h1>
                <hr style={{ backgroundColor: 'gray', height: 4, width: "100%", margin: '10px 0' }} />
                <a href={"https://play.google.com/store/apps/details?id=com.ecoweeecatalogue"} target='_blank'>
                    <h2 className='font-bolder text-primary py-2'>
                        {t("ecoWeeeInGooglePlay")}
                    </h2>
                </a>
                <p>
                    {/* {t("howToDeleteMyAccountDescription", {email: "<a href=''>support@ecotradegroup.com</>"})} */}
                
                    {/* <Trans i18nKey="howToDeleteMyAccountDescription">
                        Text <u><a href="href=https://www.example.com/">link</a></u>
                    </Trans> */}
                    <Trans i18nKey="howToDeleteMyAccountDescription">link<a href="mailto:support@ecotradegroup.com"></a></Trans>
                </p>
            </Container>
        </>
    );
};

export default HowToDeleteAccount;