import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  openCountryModal: null,
  closeCountryModal: null
})

export const ModalsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  country: false
})

/* ------------- Reducers ------------- */


/* Country Modal */
export const openCountryModal = (state) =>
  state.merge({ country: true })

export const closeCountryModal = (state) =>
  state.merge({ country: false })



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_COUNTRY_MODAL]: openCountryModal,
  [Types.CLOSE_COUNTRY_MODAL]: closeCountryModal
})