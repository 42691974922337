import moment from 'moment';
import _, {capitalize} from 'lodash'
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { DashboardCartsTypes } from '../redux/dashboard/carts'
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Pagination, Card, Spinner, Form, Row, Col, Button, InputGroup, Container } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom'
import Select from "react-select";
import { SpinnerCircular } from 'spinners-react';
import * as Icon from "react-feather";
// import { DashboardProductTypes } from '../redux/dashboard/product';
import SortByFilterDashboard from '../components/SortByFilterDashboard';
import { BASE_URL } from '../services/api';
import { useTranslation } from 'react-i18next';


const SelectionFilter = ({
    filter,
    label,
    options,
    placeholder
,}) => {
    const dispatch = useDispatch();
    
    const handleSelectionChange = (filter, value) => {
        dispatch({ type: DashboardCartsTypes.SELECT_ACTIVE_FILTER_DASHBOARD, filter, value})
        dispatch({ type: DashboardCartsTypes.SEARCH_REQUEST_DASHBOARD })
    }
    
    return (
        <Form.Group>
            <Select
                styles={{menu: ({ width, ...css }) => ({
                        ...css,
                        width: "max-content",
                        minWidth: "100%"
                })}}
                className="react-select-container"
                classNamePrefix="react-select"
                options={[{label}, ...options]}
                placeholder={placeholder}
                // value={options?.find(el => el?.value == value)}
                onChange={({value}) => handleSelectionChange(filter, value)}
            />
        </Form.Group>
    )
}


const DashboardCarts = () => {

    const { t } = useTranslation();

    const placeholderImage = 'https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640'

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { 
        carts, 
        currentPage, 
        totalPages,
        totalItems, 
        searchLimit,
        fetchingCarts,
        searchError,
        activeFilters
    } = useSelector(state => state.dashboard.carts);

    // const { searchField } = useSelector(state => state.dashboard.search);
    // const filters = useSelector(state => state.dashboard.search.filters);

    const [page, setPage] = useState(currentPage)
    const [searchField, setSearchField] = useState("")

    useEffect(()=> {
        setPageAndSearch(1);
        // Refresh categories
        // dispatch({ type: DashboardCartsTypes.FILTERS_REQUEST_DASHBOARD})
    }, [activeFilters])
    

    const setPageAndSearch = (page, limit) => {
        setPage(page)
        dispatch({ type: DashboardCartsTypes.CARTS_SEARCH_REQUEST_DASHBOARD, page, limit })
    }

    const searchRequestWithField = () => {
        if (!searchField?.trim()) return setPageAndSearch(currentPage)
        dispatch({ type: DashboardCartsTypes.CARTS_SEARCH_REQUEST_DASHBOARD, page: currentPage, searchField})
    }


    const handleStatusFilter = ({target: {id}}) => {
        dispatch({ type: DashboardCartsTypes.SELECT_ACTIVE_FILTER_DASHBOARD, filter: "status", value: id })
    }


    // const resetFilters = () => {
    //     dispatch({ type: DashboardCartsTypes.RESET_ACTIVE_FILTERS_DASHBOARD })
    //     dispatch({ type: DashboardCartsTypes.SEARCH_REQUEST_DASHBOARD })
    // }

    const PageArrows = () => (
        <div className='d-flex justify-content-end'>
            <Pagination style={{margin: 0}}>
                <Pagination.First
                    onClick={() => setPageAndSearch(1)}
                    disabled={currentPage == 1}
                />
                <Pagination.Prev
                    onClick={() => setPageAndSearch(currentPage-1)}
                    disabled={currentPage <= 1}
                />
                <Pagination.Next
                    onClick={() => setPageAndSearch(currentPage+1)}
                    disabled={currentPage >= totalPages}
                />
                <Pagination.Last
                    onClick={() => setPageAndSearch(totalPages)}
                    disabled={currentPage >= totalPages}
                />
            </Pagination>
        </div>
    )

    const fields = [
        {field: "ref", label: "ref"},
        {field: "supplier", label: "Supplier"},
        {field: "total_winning", label: "total"},
        {field: "total_quantity", label: "quantity"},
        {field: "total_winning_usd", label: "Total ($)"},
        {field: "shipping", label: "Shpping"},
        {field: "status", label: "status"},
        {field: "sentOn", label: "Sent On"},
        {field: "receivedOn", label: "Received On"},
        {field: "paidOn", label: "Paid On"},
        {field: "createdAt", label: "Created On"},
        {field: "updatedAt", label: "Updated On"},
    ]

    const statusFilters = [
        {name: "open", color: "#2E383C"},
        {name: "submitted", color: "#0E8ED7"},
        {name: "pending_receipt", color: "#0E8ED7"},
        {name: "received", color: "#339933"},
        {name: "pending_payment", color: "#F09609"},
        {name: "advance_payment", color: "#F09609"},
        {name: "payment_made", color: "#339933"},
        {name: "cancelled", color: "#F62640"},
        {name: "sent_back", color: "#F62640"},
    ]

    // const showFilters = useCallback(() => {
    //     const categories = filters?.find(el => el?.id == activeFilters["devices"])?.categories
    //     const types = categories?.find(el => el?.id == activeFilters["categories"])?.types
    //     return (
    //         <>
    //             <Col 
    //                 className='mb-2' 
    //                 key={"sortBy"}
    //             >
    //                 <SortByFilterDashboard />
    //             </Col>
    //             <Col 
    //                 className='mb-2' 
    //                 key={"devices"}
    //             >
    //                 <SelectionFilter
    //                     filter={"devices"}
    //                     label={"Devices"}
    //                     placeholder={"Devices"}
    //                     options={filters?.map(item => ({
    //                         value: item?.id, 
    //                         label: item?.name
    //                     }))}
    //                 />
    //             </Col>  
    //             {categories?.length ?
    //                 <Col 
    //                     className='mb-2' 
    //                     key={"categories"}
    //                 >
    //                     <SelectionFilter
    //                         filter={"categories"}
    //                         label={"Categories"}
    //                         placeholder={"Categories"}
    //                         options={
    //                             categories?.map(item => ({
    //                                 value: item?.id, 
    //                                 label: item?.name
    //                             }))
    //                         }
    //                     />
    //                 </Col>
    //             : null}
    //             {types?.length ?
    //                 <Col 
    //                     className='mb-2' 
    //                     key={"types"}
    //                 >
    //                     <SelectionFilter
    //                         filter={"types"}
    //                         label={"Types"}
    //                         placeholder={"Types"}
    //                         options={
    //                             types?.map(item => ({
    //                                 value: item?.id, 
    //                                 label: item?.name
    //                             }))
    //                         }
    //                     />
    //                 </Col>
    //             : null}
    //         </>
    //     )
    // }, [filters, activeFilters])

    return (
        <Card>
            <Card.Header>
                {/* <Button  
                    className="mt-2 mx-2"
                    onClick={() => navigate(`/dashboard/product/upload`)}
                    size='lg'
                    style={{minWidth: "12vw", minHeight: '6vh'}}
                >
                    New
                </Button> */}
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md="auto" className='mt-3 me-4'>
                            <h1 className="card-subtitle text-muted">
                                Carts
                            </h1>
                        </Col>
                        <Col md="auto" className='mt-3 me-4'>
                            <Form 
                                inline="true"
                                onSubmit={(e)=> {
                                    e.preventDefault();
                                    searchRequestWithField();
                                }}
                            >
                                <InputGroup className="input-group-navbar">
                                    <Form.Control 
                                        placeholder={t("search")} 
                                        aria-label="search"
                                        value={searchField}
                                        onSubmit={searchRequestWithField}
                                        onChange={e =>  setSearchField(e.target.value)} 
                                    />
                                    {searchField &&
                                        <Button className='m-0 px-1'>
                                            <Icon.X onClick={()=> setSearchField("")}/>
                                        </Button>}
                                    <Button className='m-0 px-1'>
                                        <Icon.Search onClick={searchRequestWithField}/>
                                    </Button>
                                </InputGroup>
                            </Form>
                        </Col>
                        {/* <Col md="auto" className='mt-3'>
                            <Row className='mt-1'>
                                {showFilters()}
                                <Col>
                                    <Button  
                                        onClick={resetFilters}
                                        variant="outline-primary"
                                        className='mt-1'
                                    >
                                        Reset
                                    </Button>
                                </Col>
                            </Row>
                        </Col> */}
                        <Col className='mt-3 d-flex justify-content-end'>
                            <div>
                                <Button  
                                    className="p-2 mb-3 justify-content-center"
                                    onClick={() => navigate(`/dashboard/carts/trends`)}
                                >
                                    <Icon.TrendingUp size={'1.2rem'} className='mx-1'/>
                                    <span className='px-1 fw-bolder'>
                                        Products Trends
                                    </span>
                                </Button>
                                <PageArrows />
                            </div>
                        </Col>
                    </Row>
                    <Row xs="auto">
                        {statusFilters?.map(({name, color}) => (
                            <Col 
                                key={name}
                                id={name}
                                className={`my-2 px-2 me-3 fs-7 ${activeFilters?.status == name && 'text-white'}`}
                                style={{
                                    backgroundColor: activeFilters?.status == name ? color : null, 
                                    cursor: "pointer",
                                    border: `0.1rem solid ${color}`
                                }}
                                onClick={handleStatusFilter}
                            >
                                {t(name)}
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Card.Header>
            {fetchingCarts ? 
                <div 
                    style={{height: "70vh", justifyContent: "center"}}
                    className="d-flex pb-7"
                >
                    <SpinnerCircular 
                        size={100} 
                        thickness={100}
                        className="p-3"
                        color={"#F4BC2B"}
                        secondaryColor={"transparent"}
                    />
                </div>
            :
            Array.isArray(carts) && carts?.length > 0 ?
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            {fields?.map(({label}, i) => {
                                return (
                                    <th 
                                        key={label} 
                                        className="text-truncate"
                                    >
                                        {label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {carts?.map((row, i) => {
                            const cartId = row.id
                            const tableCells = row

                            return (
                                <tr 
                                    key={cartId}
                                    className='cursor-pointer'
                                >
                                    {fields?.map(({field}, i) => {
                                        const cellvalue = tableCells[field]

                                        return (
                                            <td key={i}>
                                                
                                                {field == "ref" ?
                                                    <a 
                                                        className="truncate text-dark" 
                                                        onClick={()=> cartId && navigate(`/dashboard/cart/${cartId}`)}
                                                    >
                                                        {cellvalue}
                                                    </a>
                                                : field == "updatedAt" ?
                                                    <span className="truncate">
                                                        {moment(cellvalue).format("MMM D, YYYY")}
                                                    </span>

                                                : field == "createdAt" ?
                                                    <span className="truncate">
                                                        {moment(cellvalue).format("MMM D, YYYY")}
                                                    </span>

                                                // ANY OTHER ROW
                                                :   <span className="truncate">
                                                    {cellvalue}
                                                </span>}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                :
                searchError ?
                    <div className='text-center p-4'>
                        <span style={{fontWeight: "bold"}}>Search Error: </span> 
                        {searchError}
                    </div>
                : <div className='text-center p-4'>No result</div>}

            <Row className='mt-3 ms-2 me-2 mb-3 d-flex justify-content-end'>
                <Col>
                    <span className="ms-3 me-2">Showing: {totalItems}</span>
                    <span className="mx-2">
                        Page{" "}
                    <strong>
                        {currentPage} of {totalPages}
                    </strong>
                    </span>
                </Col>
                <Col md="auto">
                    <span className="ms-3 me-2">Show:</span>
                    <Form.Select
                        className="d-inline-block w-auto"
                        value={searchLimit}
                        onChange={(e) => setPageAndSearch(currentPage, Number(e.target.value))}
                    >
                        {[10, 20, 30, 40, 50].map((searchLimit) => (
                            <option key={searchLimit} value={searchLimit}>
                                {searchLimit}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="ms-3 me-2">Go to page:</span>
                    <Form.Control
                        className="d-inline-block"
                        type="number"
                        value={page}
                        onChange={(e) => {
                            const newPage = e.target.value ? Number(e.target.value) : "";
                            setPage(newPage)
                        }}
                        onKeyPress={(target)=> {
                            if (target.charCode==13) {
                                page >= 1 && page <= totalPages && setPageAndSearch(page);
                            }
                            
                        }}
                        style={{ width: "75px" }}
                    />
                </Col>
                <Col>
                    <PageArrows />
                </Col>
            </Row>
        </Card>
    );
};

  
export default DashboardCarts
