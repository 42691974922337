import _, { capitalize } from 'lodash'
import axios from "axios";
import React, { useEffect, useState } from 'react';
import {  Card, Form, Row, Col, Button,  Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { API, BASE_URL } from "../services/api"
import { placeholderImage } from './ui/Utils';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const supplierImagesEndpoint = `${API}/dashboard/supplier/image`

const ImageUploadBox = ({user_id, type, url, checked, imageId}) => {

    const { t } = useTranslation()

    const [selectedFile, setSelectedFile] = useState()
    const [progress, setProgress] = useState()
    const [preview, setPreview] = useState()
    const [imageUrl, setImageUrl] = useState(url)
    const [imageChecked, setImageChecked] = useState(checked)
    const [imgId, setImgId] = useState(imageId)

    useEffect(() => {
        return () => preview && URL.revokeObjectURL(preview)
      }, [])

    const submitHandler = e => {
        e.preventDefault() //prevent the form from submitting
        setProgress(null)
        if (!selectedFile || !preview) return toast.error("Please select file")
        if (!user_id || !type) return toast.error("Missing params")
        let formData = new FormData()
        
        if (user_id) formData.append("user_id", user_id)
        if (type) formData.append("type", type)
        if (selectedFile) formData.append("file", selectedFile)

        setProgress(30)
        axios.post(supplierImagesEndpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization":  `Bearer ${window.localStorage.getItem("accessToken")}`
            },
            onUploadProgress: data => setProgress(Math.round((100 * data.loaded) / data.total))
        })
        .then((res) => {
            setTimeout(() => {
                setProgress(100)
                setTimeout(() => {
                    setProgress()
                    setSelectedFile()
                    setImageUrl(BASE_URL + res?.data?.url)
                    setImgId(res?.data?.id)
                    toast.success("Image successfully uploaded")
                }, 100);
            }, 700);
        })
        .catch(error => {
            setProgress()
            toast.error("Upload image error")
        })
    }

    const deleteImage = () => {
        if (!user_id || !type) return toast.error("Cannot delete")
    
        if (selectedFile || preview) {
            setPreview()
            setSelectedFile()
        } else if (window.confirm('Are you sure you want to delete this image?')) {
            setProgress(30)
            setTimeout(() => setProgress(50), 200);
            setTimeout(() => setProgress(80), 400);
            axios.delete(
                supplierImagesEndpoint, 
                {
                    headers: { "Authorization":  `Bearer ${window.localStorage.getItem("accessToken")}`},
                    data: { user_id, type}
                }
            )
            .then((res) => {
                setTimeout(() => {
                    setProgress(100)
                    setTimeout(() => {
                        setProgress()
                        setPreview()
                        setSelectedFile()
                        setImageUrl()
                        setImageChecked()
                        setImgId()
                        toast.success("Image successfully deleted")
                    }, 300);
                }, 700);
            })
            .catch(error => {
                setProgress()
                toast.error("Delete image error")
            })
        }
      }

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return setSelectedFile(undefined)
        }
        setSelectedFile(e.target.files[0])
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setPreview(objectUrl)
    } 

    
    return (
        <Card.Body className='p-4 me-3 rounded shadow-lg'>
                <Form
                    // action={url}
                    method="post"
                    encType="multipart/form-data"
                    onSubmit={submitHandler}
                >
                    <div className='d-flex pb-4 justify-content-between align-items-center'>
                        {t(type)?.toUpperCase()}
                        {imgId &&
                            <Form.Check 
                                type={"checkbox"}
                                id={`checked-${imageId}`}
                                disabled={true}
                                checked={imageChecked || false}
                                label={capitalize(t('checked'))}
                            />}
                    </div>
                    <Image
                        src={preview || imageUrl || placeholderImage} 
                        className={"rounded"}
                        style={{
                            width: "100%", 
                            objectFit: "contain",
                            height: '100%',
                            maxHeight: '200px'
                        }}
                    />
                    <Form.Group className='my-3'>
                        <Form.Label>Select a File</Form.Label>
                        <Form.Control
                            type="file" 
                            onChange={onSelectFile}
                            disabled={progress}
                        />
                    </Form.Group>
                    <Form.Group className='d-flex justify-content-center align-items-center'>
                        {selectedFile &&
                            <Button 
                                className='me-2'
                                variant="primary" 
                                type="submit" 
                                disabled={progress}
                            >
                            <FontAwesomeIcon icon={faUpload} /> 
                                {" " + t("upload")}
                            </Button>}
                        {(preview || imageUrl) && 
                            <Button 
                                variant="primary" 
                                onClick={deleteImage} 
                                className='align-middle' 
                                disabled={progress}
                            >
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </Button>}
                    </Form.Group>
                </Form>
            </Card.Body>
    )
}


const AccountProofs = () => {
    const { t } = useTranslation();
    const {id, supplierImages} = useSelector(state => state.account.profile.data) || {}

    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0 fs-3">
                    {t("compliance")}
                </Card.Title>
                <div className='mt-2'>
                    {t("supplierProofComplianceNotice")}
                </div>
            </Card.Header>
            <Card.Body>
                <Row className='p-3'>
                    {['front', 'back']?.map(el => {
                        let images = supplierImages
                        let url = null
                        let imageId = null
                        let checked = null
                        if (Array.isArray(images)) {
                            const img = images?.find(img => img?.type == el)
                            if (img?.url) url = BASE_URL + img?.url 
                            if (img?.id) imageId = img?.id
                            if (img?.checked) checked = true
                        }
                        return (
                            <Col className='mt-3' sm key={url || el}>
                                <ImageUploadBox 
                                    type={el}
                                    user_id={id}
                                    url={url}
                                    imageId={imageId}
                                    checked={checked}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </Card.Body>
        </Card>
    )
}

export default AccountProofs;