import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { ProductTypes } from '../redux/product';
import React, { useEffect, useState } from 'react';
import { 
  Container,
  Spinner,
  ListGroup,
  Row,
  Col,
  Card,
  Table,
  Button,
  Form
} from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import * as Icon from "react-feather";
import ReactImageProcess from 'react-image-process';
import { SpinnerCircular } from 'spinners-react';
import SearchBar from "../components/SearchBar";
import { placeholderImage } from "./ui/Utils";
import { BASE_URL } from "../services/api";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { CartTypes } from "../redux/account/cart";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";


const Background = "https://play-lh.googleusercontent.com/6joGRmSpUSCVzV-fcm_VlkjwZplku-xlbiWgYUd12PfzHe7H2lias7_z1IUuTxQPV0g"


const CartUpdater = ({id}) => {
  const  { t } = useTranslation()
  const { isAuthenticated, type } = useAuth();

  const dispatch = useDispatch();

  const { fetching } = useSelector(state => state.account.cart.openCart) || {}

  const [quantity, setQuantity] = useState(1)

  const incrementQuantity = () => setQuantity(+quantity + 1)
  const decrementQuantity = () => quantity >= 1 && setQuantity(+quantity - 1)

  const updateCart = () => {
    dispatch({ 
      type: CartTypes.ADD_CART_PRODUCT_REQUEST, 
      product_id: id, 
      quantity
    })
  }

  if (!isAuthenticated || type != "user") return null

  return (
    <Col className="d-flex justify-content-center m-4">
      <div className="border rounded d-flex me-3">
        <Button
          color="#EC8088"
          className="rounded"
          disabled={fetching}
          onClick={incrementQuantity}
        >
          <Icon.Plus
            fill="#fff"
            className='my-1'
            color="#fff"
          />
        </Button>
        <Form.Control 
          onChange={(e) => setQuantity(e?.target?.value)}
          value={quantity || ""}
          // placeholder='1'
          aria-label="Amount (to the nearest dollar)" 
          className='border-0 text-center'
          disabled={fetching}
        />
        <div className="d-flex align-items-center p-2">
          Kg
        </div>
        <Button
          color="#EC8088"
          className="rounded"
          onClick={decrementQuantity}
          disabled={fetching}
        >
          <Icon.Minus
            fill="#fff"
            className='my-1'
            color="#fff"
          />
        </Button>
      </div>
      <Button
        onClick={updateCart}
        className="p-2"
        variant="primary" 
        // type="submit"
        disabled={fetching}
      >
        <Icon.ShoppingCart
          // fill="#fff"
          className='me-2'
          color="#fff"
        />
        <>
          {t("addToCart")?.toUpperCase()}
        </>
      </Button>
    </Col>
  )
}

const Product = () => { 
  const { t, i18n } = useTranslation()
  const locale = i18n?.language;
  
  const { code } = useParams();
  let { 
    product, 
    gettingProduct,
    models,
    gettingModels
  } = useSelector(state => state.product);
  const currency = useSelector(state => state.global.currency);
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch({ type: ProductTypes.GET_PRODUCT_REQUEST, code })
    return ()=> dispatch({ type: ProductTypes.PRODUCT_RESET });
  }, [currency, locale])

  const reference = product?.ref_translation?.[locale] || product?.reference

  let images = product?.images?.length > 0 ? product?.images?.map(el => ({ 
    original: BASE_URL + el?.url, 
    thumbnail: BASE_URL + el?.url,
    renderItem: () => (
      <>
      
        <Zoom 
          overlayBgColorStart={'rgba(108, 122, 137, 0)'}
          overlayBgColorEnd={'rgba(108, 122, 137, 0.7)'}
          
        >
          <img 
            src={BASE_URL + el?.url}
            style={{
              width: "100%",
              // backgroundImage: `url(${Background})`,
              // backgroundPosition: "fixed",
              // backgroundRepeat: "no-repeat",
              // backgroundAttachment: "fixed",
              // backgroundSize: "100%"
              // width: "100%",
              // height: "100%",
              // zIndex: 10000
            }}
          />
          {/* <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              backgroundImage: `url(${Background})`,
              // backgroundPosition: "center",
                // backgroundRepeat: "no-repeat",
                
                // backgroundAttachment: "fixed",
              backgroundSize: "10%",
              width: "100%",
              height: "100%",
              zIndex: 10000
            }}
          /> */}
        </Zoom>
        </>
      
    )
  })) : [placeholderImage]?.map(el => ({ original: el, thumbnail: el})) 

  return (
      <div>
        <Helmet>
          <title>{reference}</title>
          <meta name="description" content={`${t("ecotradeGroupBuys")} ${reference}`} />
        </Helmet>
        <SearchBar productName={product?.cat_translation?.[locale] || product?.category}/>
        {gettingProduct ? 
            <p className="text-center p-4">
              <SpinnerCircular 
                size={100} 
                thickness={100}
                className="pt-7"
                color={"#F4BC2B"}
                secondaryColor={"transparent"}
              />
            </p>
        :
        <Container className="mt-3">
          {/* <h1
            className="text-uppercase mb-3 fs-1" 
            style={{textAlign: "center", fontWeight: "bold"}}
          >
            {product?.code}
          </h1> */}
          <Row xs={1} sm={1} md={2} className="p-3 pt-4">
            <Col 
              className="pb-4 pe-4" 
            >
              <ImageGallery 
                items={images} 
                showIndex={false}
                showBullets={images}
                infinite={true}
                showThumbnails={images}
                showFullscreenButton={false}
                showGalleryFullscreenButton={false}
                showPlayButton={false}
                showGalleryPlayButton={false}
                showNav={false}
                isRTL={false}
                slideDuration={450}
                slideInterval={2000}
                slideOnThumbnailOver={false}
                thumbnailPosition={'left'}
                useWindowKeyDown={true}
              />
              {/* <Image 
                src={img} 
                style={{width: "100%", minWidth: "10rem", maxWidth: "30rem"}}
              /> */}
              <CartUpdater id={product?.id}/>
            </Col>
            <Col className="ps-3">
              <h4 className="fw-bolder fs-2 text-uppercase">
                {reference}
              </h4>
              <Table>
                <tbody>
                  <tr><td></td><td></td></tr>
                  {Object.keys(product)?.map((el,i) => {
                    let item = product?.[el]
                    const locale_description = product?.desc_translation?.[locale];
                    const locale_category = product?.cat_translation?.[locale];
                    if (el == "description" && locale_description) { item = locale_description }
                    if (el == "category" && locale_category) { item = locale_category } 
                    if (el == "reference" || el == "id" || el == "images") return
                    if (typeof item == "string") return (
                      <tr key={product?.id+i}>
                        <td className="col-1 align-top fw-bolder fs-4 text-capitalize">
                          {t(el)}
                        </td> 
                        <td className={`col-2 ${el == "price" ? "fw-bold fs-1" : "fs-4"}`}>
                          {item.toString().includes("•") ?
                              item?.toString().split("•")?.map((el, i) => i > 0 && (
                                <Row key={el}>
                                    <Col xs={2} sm={1}>•</Col>
                                    <Col xs={8} sm={10}>{el}</Col>
                                </Row>
                              ))
                          : el == "device" ? t(item?.toLowerCase()?.replace(' ','_')) 
                        : item}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          {/* {models?.length > 0 &&
            <Card border>
              <Card.Header className="fs-1 text-uppercase">
                Models
              </Card.Header>
              <Card.Body className="pt-0">
                <Row xs={2} sm={3} md={4} xl={5} xxl={6}>
                  {models?.map(el => 
                    <Col 
                      key={el?.id}
                      className={"pb-3"}
                    >
                      {el?.model}
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>} */}
        </Container>}
      </div>
  )
};

export default Product