import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Page500 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title="500 Error" />
      <div className="text-center">
        <h1 className="display-1 fw-bold">500</h1>
        <p className="h1">{t("internalServerError")}</p>
        <p className="h2 fw-normal mt-3 mb-4">
          {t("theServerEncounteredSomethingUnexpected")}
        </p>
        <Link to="/dashboard/default">
          <Button variant="primary" size="lg">
            Return to website
          </Button>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default Page500;
