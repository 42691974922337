import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SettingsTypes } from '../redux/dashboard/settings';
import { Card, Form, Row, Col, Button, Table } from 'react-bootstrap';
import MetalsRates from '../components/MetalsRates';

const DashboardSettingsPricing = () => {

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch({ type: SettingsTypes.GET_METALS_SETTINGS_REQUEST })
        dispatch({ type: SettingsTypes.GET_PRICING_SETTINGS_REQUEST })
    }, [])

    return (
        <>
            <MetalsSection />
            <Row xs={1} sm={2}>
                <SellingPriceSettings />
                <BuyingPriceSettings />
            </Row>
        </>
    );
}

const MetalsSection = memo(() => {

    const dispatch = useDispatch();
    const { metalsSettings } = useSelector(state => state.dashboard.settings);

    return (
        <Row xs={1} sm={2}>
            <Col>
                <Card>
                    <Card.Header>
                        <h1 className="card-subtitle color-black fw-bolder">
                            Metals
                        </h1>
                    </Card.Header>
                    <Card.Body>
                        <Form className='text-capitalize'>
                            {metalsSettings?.map((el, i) => 
                                <MetalSettings 
                                    key={el?.name} 
                                    metal={el} 
                                    i={i}
                                />
                            )}
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <MetalsRates rows={10}/>
            </Col>
        </Row>
    )
})

const MetalSettings = memo(({metal, i}) => {

    const dispatch = useDispatch();
    const setMetalSettingsValue = (e) => {
        const id = e.target.id
        const field = e.target.name
        const value = e.target.value
        dispatch({ type: SettingsTypes.SET_METAL_SETTINGS_VALUE, id, field, value})
    }
    const updateMetalSettings = () => {
        const id = metal?.id
        const data = metal
        dispatch({ type: SettingsTypes.UPDATE_METAL_SETTINGS_REQUEST, id, data})
    }
    
    const showFields = ["recovery", "lease", "minus"]

    return (
        <Row 
            key={metal?.name} 
            className={i != 0 ? "mt-5" : null}
        >
            <Col>
                <div className='d-flex justify-content-between'>
                    <h3>{metal?.name}</h3>
                    <Button variant="primary" className='py-0 mb-4' onClick={updateMetalSettings}>
                        Save
                    </Button>
                </div>
                <Row>
                    {showFields.map(field => (
                        <Col 
                            key={field} 
                            // className='mb-0'
                        >
                            <Form.Label>
                                {field}
                            </Form.Label>
                            <Form.Control
                                id={metal?.id}
                                name={field}
                                type="text"
                                placeholder="0.00"
                                onChange={setMetalSettingsValue}
                                value={metal?.[field]}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    )
})

const SellingPriceSettings = memo(() => {

    const dispatch = useDispatch();
    const {fetching, pricing: {selling}} = useSelector(state => state.dashboard.settings)
    const [sellingSettings, setSellingSettings] = useState(selling)

    useEffect(() => setSellingSettings(selling), [selling])

    const setPricingValue = ({target: {id, value}}) => setSellingSettings({...sellingSettings, [id]: value})

    const updateSellingSettings = () => {
        dispatch({ type: SettingsTypes.UPDATE_PRICING_SETTINGS_REQUEST, id: "selling", data: sellingSettings})
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <div className='d-flex justify-content-between'>
                        <h1 className="card-subtitle color-black fw-bolder">
                            Selling
                        </h1>
                        <Button 
                            disabled={fetching}
                            variant="primary" 
                            className='py-0 mb-4' 
                            onClick={updateSellingSettings}
                        >
                            Save
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row xs={2} md={4}>
                            <Col className={"mt-3"}>
                                <Form.Label>
                                    USD Lease
                                </Form.Label>
                                <Form.Control
                                    disabled={fetching}
                                    type="number"
                                    name="text"
                                    placeholder="0.00"
                                    onChange={setPricingValue}
                                    id="margin"
                                    value={sellingSettings?.usd_lease || ""}
                                />
                            </Col>
                            <Col className={"mt-3"}>
                                <Form.Label>
                                    Treatment Charge
                                </Form.Label>
                                <Form.Control
                                    disabled={fetching}
                                    type="number"
                                    name="text"
                                    placeholder="0.00"
                                    onChange={setPricingValue}
                                    id="margin"
                                    value={sellingSettings?.treatment_charge || ""}
                                />
                            </Col>
                            <Col className={"mt-3"}>
                                <Form.Label>
                                    Margin
                                </Form.Label>
                                <Form.Control
                                    disabled={fetching}
                                    type="number"
                                    name="text"
                                    placeholder="0.00"
                                    onChange={setPricingValue}
                                    id="margin"
                                    value={sellingSettings?.margin || ""}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
})

const BuyingPriceSettings = memo(() => {

    const dispatch = useDispatch();
    const {fetching, pricing: {buying}} = useSelector(state => state.dashboard.settings)
    const [buyingSettings, setBuyingSettings] = useState(buying)

    useEffect(() => setBuyingSettings(buying), [buying])

    const setPricingValue = ({target: {id, value}}) => setBuyingSettings({...buyingSettings, [id]: value})

    const updateBuyingSettings = () => {
        dispatch({ type: SettingsTypes.UPDATE_PRICING_SETTINGS_REQUEST, id: "buying", data: buyingSettings})
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <div className='d-flex justify-content-between'>
                        <h1 className="card-subtitle color-black fw-bolder">
                            Buying
                        </h1>
                        <Button 
                            disabled={fetching}
                            variant="primary" 
                            className='py-0 mb-4' 
                            onClick={updateBuyingSettings}
                        >
                            Save
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row xs={2} md={4}>
                            <Col>
                                <Form.Label>
                                    Margin
                                </Form.Label>
                                <Form.Control
                                    disabled={fetching}
                                    type="number"
                                    name="text"
                                    placeholder="0.00"
                                    onChange={setPricingValue}
                                    id="margin"
                                    value={buyingSettings?.margin || ""}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
})
         

  
export default DashboardSettingsPricing
