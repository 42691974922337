import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['global']
}

const searchPersistConfig = {
  key: 'search',
  storage,
  whitelist: ['devicesSections']
}

/* ------------- Assemble The Reducers ------------- */
const reducers = combineReducers({
  global: require('./global').reducer,
  search: persistReducer(searchPersistConfig, require('./search').reducer),
  product: require('./product').reducer,
  metals: require('./metals').reducer,
  modals: require('./modals').reducer,
  account: combineReducers({
    profile: require('./account/profile').reducer,
    cart: require('./account/cart').reducer
  }),
  dashboard: combineReducers({
    search: require('./dashboard/search').reducer,
    product: require('./dashboard/product').reducer,
    users: require('./dashboard/users').reducer,
    user: require('./dashboard/user').reducer,
    carts: require('./dashboard/carts').reducer,
    cart: require('./dashboard/cart').reducer,
    analysis: require('./dashboard/analysis').reducer,
    database: require('./dashboard/database').reducer,
    settings: require('./dashboard/settings').reducer,
    categories: require('./dashboard/categories').reducer,
    currencies: require('./dashboard/currencies').reducer,
    logs: require('./dashboard/logs').reducer,
    translations: require('./dashboard/translations').reducer,
  })
})

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);
const configureStore = () => ({ persistor, store });

export default configureStore;




// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["publications"],
//   stateReconciler: autoMergeLevel2,
// };
// const sagaMiddleware = createSagaMiddleware();
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = createStore(
//   persistedReducer,
//   composeWithDevTools(applyMiddleware(sagaMiddleware))
// );

// sagaMiddleware.run(rootSaga);
// let persistor = persistStore(store);
// export { store, persistor };

