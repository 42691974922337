import React from "react";
import { Outlet } from "react-router-dom";
import Main from "../components/Main";
import MiniNavBar from "../components/MiniNavBar";

const Landing = ({ children }) => {
  return (
    <Main>
      {children}
      <Outlet />
    </Main>
  )
}

export default Landing;
