import { createContext, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CartTypes } from "../redux/account/cart";
import { API } from "../services/api";
import i18n from "i18next";
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";


axios.interceptors.response.use(response => response, error => {
  const errorStatus = error?.response?.status
  if (errorStatus == 500) {
    toast.error("Internal Server Error");
  } else if ([400, 401, 403]?.includes(errorStatus)) {
    toast.error(JSON.stringify(error.response?.data?.message || "Error"));
  }
  return error;
});

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  type: null
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        type: action.payload.type
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        type: action.payload.type
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        type: null
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        type: action.payload.type
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const reduxDispatch = useDispatch()
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await axios.get(`${API}/auth/user`)
          if (JSON.parse(JSON.stringify(response))?.status == 500) {
            dispatch({ type: INITIALIZE, payload: { isAuthenticated: false, user: null, type: null} });
            return setSession(null)
          }
          const { user, type } = response?.data || {};
          if (type == "user") { reduxDispatch({type: CartTypes.GET_OPEN_CART_REQUEST}) }
          dispatch({ type: INITIALIZE, payload: { isAuthenticated: true, user, type }});
        } else {
          dispatch({ type: INITIALIZE, payload: { isAuthenticated: false, user: null, type: null} });
        }
      } catch (err) {
        dispatch({ type: INITIALIZE, payload: { isAuthenticated: false, user: null, type: null } });
      }
    };

    initialize();
  }, []);

  const signUp = async ({email, password, first_name, last_name, country}) => {
    try {
      const response = await axios.post(`${API}/auth/signup`, { email, password, first_name, last_name, country })

      const verificationLinkSent = response?.data?.verificationLinkSent
      const verificationLinkEmail = response?.data?.email

      if (verificationLinkSent && verificationLinkEmail) {
        toast.success(`We have sent a verification link to ${verificationLinkEmail}`, {autoClose: 5000});
        navigate('/')
      }
    } catch (e) {
      console.log(e)
    }
    
  };

  const signIn = async ({email, password}) => {
    try {
      const response = await axios.post(`${API}/auth/signin`, {
        email,
        password,
      });
      
      const { token, temporaryPasswordSent, user, type } = response?.data || {}

      if (token) {
        setSession(token);
        dispatch({ type: SIGN_IN, payload: { user, type }});
        if (type) navigate(type == "user" ? "/" : "/dashboard")
        if (type == "user") { reduxDispatch({type: CartTypes.GET_OPEN_CART_REQUEST}) }
      } else if (temporaryPasswordSent) {
        const temporaryPasswordEmail = response?.data?.email
        toast.success(`We have sent a temporary password to ${temporaryPasswordEmail}`, {autoClose: 5000});
      }
    } catch (e) {
      console.log(e)
    }
  };

  const googleSignIn = async (idToken) => {
    try {
      const response = await axios.post(`${API}/auth/google-auth`, {idToken})
      const { token, user, type } = response?.data

      if (token) {
        // console.log(user)
        setSession(token);
        dispatch({ type: SIGN_IN, payload: { user, type }});
        if (type) navigate(type == "user" ? "/" : "/dashboard")
        if (type == "user") { reduxDispatch({type: CartTypes.GET_OPEN_CART_REQUEST}) }
      }
    } catch (e) {
      console.log(e)
    }
  };

  const facebookSignIn = async ({first_name, last_name, email}) => { 
    try {
      const response = await axios.post(`${API}/auth/facebook-auth`, {first_name, last_name, email})
      const accessToken = response?.data?.token
      const user = response?.data?.user
      const type = response?.data?.type

      if (accessToken) {
        setSession(accessToken);
        dispatch({ type: SIGN_IN, payload: { user, type }});
        if (type) navigate(type == "user" ? "/" : "/dashboard")
        if (type == "user") { reduxDispatch({type: CartTypes.GET_OPEN_CART_REQUEST}) }
      }
    } catch (e) {
      console.log(e)
    }
  };

  const connectAsUser = async (id) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      const response = await axios.get(`${API}/auth/connectas/${id}`)
      const { token, user, type } = response?.data || {}
      if (token) {
        setSession(token);
        dispatch({ type: SIGN_IN, payload: { user, type }});
        if (type) navigate(type == "user" ? "/" : "/dashboard")
        if (type == "user") { reduxDispatch({type: CartTypes.GET_OPEN_CART_REQUEST}) }
        toast.success(`Connect as ${user?.email}`, {autoClose: 5000});
      }
    }
  };

  const resetPassword = async (email) => {
    try {
      const response = await axios.post(`${API}/auth/reset-password`, {email}, {
        headers: { "Accept-Language": i18n?.language || "en"}
      });


      if (response?.status == 200) {
        toast.success(response?.data?.message)
        navigate("/")
      }
    } catch (e) {
      const message = e || "Internal server Error"
      toast.error(message);
    }
  }

  const resetPasswordProcess = async ({password, token}) => {
    try {
      const response = await axios.post(`${API}/auth/reset-password-process`, {
        token,
        password,
      },
      { headers: { "Accept-Language": i18n?.language || "en"}});
      
      if (response?.status == 200) {
        toast.success(response?.data?.message)
        navigate("/")
      }
    } catch (e) {
      const message = e || "Internal server Error"
      toast.error(message);
    }
  }

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
    navigate('/')
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        googleSignIn,
        facebookSignIn,
        connectAsUser,
        signOut,
        signUp,
        resetPassword,
        resetPasswordProcess
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
