import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({

  getCategoriesTranslationsRequestDashboard: null,
  getCategoriesTranslationsSuccessDashboard: ["translations"],
  getCategoriesTranslationsErrorDashboard: null,
  updateCategoriesTranslationsRequestDashboard: null,
  autofillMissingCategoriesTranslationsRequestDashboard: null,

})

export const DashboardTranslationsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    categories: {
      translations: null,
      loading: null
    }
})

/* ------------- Reducers ------------- */


/* Get or Update Categories Translations */
export const getCategoriesTranslationsRequest = (state) => 
  state.merge({ categories: { translations: null, loading: true } })

export const getCategoriesTranslationsSuccess = (state, {translations}) => 
  state.merge({ categories: { translations, loading: false } })

export const getCategoriesTranslationsError = (state) => 
  state.merge({ categories: { translations: null, loading: false } })

export const updateCategoriesTranslationsRequest = (state) => 
state.merge({ categories: { ...state.categories.translations, loading: true } }) 

export const autofillMissingCategoriesTranslationsRequest = (state) => 
  state.merge({ categories: { ...state.categories.translations, loading: false } }) 


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.GET_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD]: getCategoriesTranslationsRequest,
  [Types.GET_CATEGORIES_TRANSLATIONS_SUCCESS_DASHBOARD]: getCategoriesTranslationsSuccess,
  [Types.GET_CATEGORIES_TRANSLATIONS_ERROR_DASHBOARD]: getCategoriesTranslationsError,
  [Types.UPDATE_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD]: updateCategoriesTranslationsRequest,
  [Types.AUTOFILL_MISSING_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD]: autofillMissingCategoriesTranslationsRequest
})