import React from "react";
import { Dropdown } from "react-bootstrap";
import getSymbolFromCurrency from 'currency-symbol-map'
import { useDispatch, useSelector } from "react-redux";
import { GlobalTypes } from "../../redux/global";


const NavbarCurrencies = () => {
  
  const dispatch = useDispatch();
  const selectedCurrency = useSelector(state => state.global.currency);
  const currencies = [
    "USD", "EUR", "JPY", "GBP", "CAD", "AUD", "ZAR", "THB"
  ].filter(el => el != selectedCurrency) 

  const setCurrency = (currency) => {
    dispatch({ type: GlobalTypes.SET_CURRENCY, currency })
  }

  return (
    <Dropdown className="me-2 nav-item" align="end">
      <Dropdown.Toggle as="a" className="nav-link nav-flag text-white">
        {/* <img 
          src={require(`../../assets/img/currencies_flags/${selectedCurrency}.png`).default} 
          alt={"currency"} 
        /> */}
        <span className="align-middle">
          {getSymbolFromCurrency(selectedCurrency) + " " + selectedCurrency}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {currencies.map(currency => (
          <Dropdown.Item
            key={currency}
            onClick={() => setCurrency(currency)}
          >
            <img
              src={require(`../../assets/img/currencies_flags/${currency}.png`).default}
              alt={"currency"}
              width="20"
              className="align-middle me-1"
            />
            <span className="align-middle">
              {" " + getSymbolFromCurrency(currency) + " " + currency}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarCurrencies;
