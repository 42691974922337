import { DashboardSearchTypes } from '../redux/dashboard/search'
import React from 'react';
import Select from "react-select";
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";



const SortByFilterDashboard = () => {

  const sortBySelectionFilter = [
    {value: "id_low_to_high", label: "ID low to high"},
    {value: "price_low_to_high", label: "Price low to high"},
    {value: "price_high_to_low", label: "Price high to low"},
    {value: "device_name_a/z", label: "Device name A/Z"},
  ]

  const dispatch = useDispatch()

  const selection = useSelector(state => state.dashboard.search.activeFilters.price)

  const handleSortByFilterChange = (value) => {
    dispatch({ type: DashboardSearchTypes.SELECT_ACTIVE_FILTER_DASHBOARD, filter: "sortBy", value})
    dispatch({ type: DashboardSearchTypes.SEARCH_REQUEST_DASHBOARD })
  }

  return (
    <Form.Group>
      <Select
          styles={{menu: ({ width, ...css }) => ({
            ...css,
            width: "max-content",
            minWidth: "100%",
          })}}
          className="react-select-container"
          classNamePrefix="react-select"
          options={sortBySelectionFilter}
          placeholder={"ID"}
          value={sortBySelectionFilter?.find(el => selection == el?.value)}
          onChange={({value}) => handleSortByFilterChange(value)}
      />
    </Form.Group>
  )
};

export default SortByFilterDashboard