import moment from 'moment';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Table } from 'react-bootstrap';
import { CurrenciesTypes } from '../redux/dashboard/currencies';

const Currencies = memo(() => {
    const dispatch = useDispatch();
    let currencies = useSelector(state => state.dashboard.currencies.data);
    useEffect(() => 
        dispatch({ type: CurrenciesTypes.CURRENCIES_REQUEST })
    , [])

    // alert(moment("2022-06-23T09:53:53Z").local())
    

    return (
        <Card>
            <Card.Header>
                <h1 className="card-subtitle color-black fw-bolder">
                    Currencies
                </h1>
            </Card.Header>
            <Card.Body>
                <Table responsive bordered hover>
                    <>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Code</th>
                                <th>Rate</th>
                                <th style={{minWidth: "40%"}}>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currencies?.map(el => {

                                const findImage = (path) => {
                                    try {
                                        return require(`../assets/img/currencies_flags/${el?.code}.png`).default;
                                    } catch (err) {
                                        return null;
                                    }
                                };

                                if (!findImage()) return

                                return ( 
                                    <tr key={el.id}>
                                        <td>{el?.id}</td>
                                        <td className='fw-bold fs-4'>
                                            <img
                                                src={findImage()}
                                                alt={"currency"}
                                                width="30rem"
                                                className="align-middle me-1"
                                            /> 
                                            {" " + el?.code}
                                        </td>
                                        <td>{el?.rate}</td>
                                        <td>{moment(el?.date).format("D MMM YYYY -  HH:mm")}</td>
                                    </tr>
                                )}
                            )}
                        </tbody>
                    </>
                </Table>
            </Card.Body>
        </Card>
    )
})

export default Currencies