import ga4 from 'react-ga4'
import { isProduction } from '../services/api'
const TRACKING_ID = 'G-XC3LW1D37F'


export const init = () => ga4.initialize(TRACKING_ID, { 
  testMode: !isProduction 
})

export const sendEvent = (name) => ga4.event('screen_view', {
  app_name: "myApp",
  screen_name: name,
})

export const sendPageview = (path) => ga4.send({ 
  hitType: 'pageview', 
  page: path 
})