import React from "react";
import { useParams } from "react-router-dom";
import _, { capitalize } from "lodash";
import { Button, Card, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import SearchBar from "../components/SearchBar";
import MapFrame from "../components/MapFrame";


const Contact = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n?.language;

  return (
    <>
      <SearchBar title={capitalize(t("contact"))} noBar noTopBar/>
      <Container className="p-4 pt-3 fs-4" style={{whiteSpace: "pre-wrap"}}>
        <div className="mt-4 mb-5">
          {locale == "th" ?
            <>
              <h1>บริษัท อีโค่เทรด เทค จำกัด</h1>
              <br/>เลขที่ 555/56 อาคารเอสเอสพี ทาวเวอร์ ชั้นที่ 21 ซอยสุขุมวิท 63 (เอกมัย) แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพมหานคร 10110
              <br/>{t("phone")}: 0954489039
            </>
          :
            <>
              <h1>Ecotrade Tech Co., Ltd.</h1>
              <br/>555 Soi Sukhumvit 63, Khlong Tan Nuea, Khet Khlong Toei, Bangkok 10110
              <br/>{t("phone")}: 0954489039
            </>}
        </div>
        <MapFrame 
          coordinates={{
            lat: 13.7388905,
            lng: 100.588773
          }}
        />
      </Container>
    </>
  );
}

export default Contact;