/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, delay, select } from 'redux-saga/effects'
import ProductActions from '../redux/product'

export function * getProduct (api, {code}) {
    const reducers = yield select();
    const currency = reducers?.global.currency
    const response = yield call(api.getProduct, code, currency)

    if (response.ok) {
        // yield delay(5000)
        yield put(ProductActions.getProductSuccess(response.data))
        const product_id = response?.data?.id
        if (product_id) yield put(ProductActions.getModelsRequest(product_id))
    } else {
        const message = response?.data?.message || "Error"
        yield put(ProductActions.getProductError(message))
    }
}

export function * getModels (api, {product_id}) {
    const response = yield call(api.getModels, product_id)

    if (response.ok) {
        yield put(ProductActions.getModelsSuccess(response.data))
    } else {
        const message = response?.data?.message || "Error"
        alert(message)
        yield put(ProductActions.getModelsError())
    }
}