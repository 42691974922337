/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import SearchActions from '../redux/search'

export function * getProducts(api) {
    const reducers = yield select();
    const currency = reducers?.global.currency
    
    const { searchField, currentPage,  activeFilters } = reducers?.search

    let params = { 
        searchField, 
        size: 20, 
        page: currentPage, 
        filters: activeFilters,
        currency
    }

    const response = yield call(api.getProducts, params)
    // yield delay(2000)
    if (response.ok) {
        yield put(SearchActions.searchSuccess(response.data))
    } else {
        const error = response?.data?.message || "Server error"
        yield put(SearchActions.searchFailure(error))
    }
}


export function * getDevicesSections(api) {
    const response = yield call(api.getDevicesSections)
    // yield delay(2000)
    if (response.ok) {
        yield put(SearchActions.devicesSectionsSuccess(response.data))
    } else {
        const error = response?.data?.message || "Server error"
        yield put(SearchActions.devicesSectionsFailure(error))
    }
}
