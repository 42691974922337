import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileTypes } from '../redux/account/profile'
import { Modal, Button, Card, Form } from 'react-bootstrap'
import { ReactCountryDropdown } from 'react-country-dropdown'
import 'react-country-dropdown/dist/index.css'
import { ModalsTypes } from '../redux/modals'
import { getFlagEmoji } from '../utils/commons'
import allCountries from 'country-region-data/data.json'
import { useTranslation } from 'react-i18next'

const CountrySelectorModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const open = useSelector(state => state.modals.country)
  const {fetching, data: {country}} = useSelector(state => state.account.profile)

  const [selectedCountry, setSelectedCountry] = useState(country)

  const topCountries = [country, "TH"]
  const countries = allCountries?.map(({countryName, countryShortCode}) => ({countryName, countryShortCode}))
                    .sort((x) => topCountries.includes(x.countryShortCode) ? -1 : 0)
                    .filter(el => el.countryShortCode != country)

  useEffect(()=> setSelectedCountry(country), [country])

  const handleClose = () => dispatch({type: ModalsTypes.CLOSE_COUNTRY_MODAL})

  const handleSaveChanges = () => {
    dispatch({type: ProfileTypes.UPDATE_PROFILE_REQUEST, profile: {country: selectedCountry}})
    dispatch({type: ModalsTypes.CLOSE_COUNTRY_MODAL})
  }

  return (
    <Modal 
      show={open} 
      onHide={handleClose} 
      animation={false}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Card className="m-4 p-4 pt-3 pb-3 align-self-center shadow-none">
        <Card.Title className="fw-bolder pb-3">
          {t("pleaseSelectAcountry")}
        </Card.Title>
        <Form.Select 
          disabled={fetching}
          onChange={e => setSelectedCountry(e?.target?.value)}
          name="country" 
          id="country"
          placeholder={""}
          className={"mt-3 mb-4"}
        >
          <option value={selectedCountry}>
            {selectedCountry ? `${getFlagEmoji(selectedCountry)} ${allCountries?.find(el => el.countryShortCode == selectedCountry)?.countryName || "--"}` : "--"}
          </option>
          {countries?.map(({countryShortCode, countryName}) => (
            <option 
              value={countryShortCode} 
              key={countryShortCode}
            >
              {countryShortCode ? `${getFlagEmoji(countryShortCode)} ${countryName}` : null}
            </option>
          ))}
        </Form.Select>
        <Button disabled={fetching} variant="primary" onClick={handleSaveChanges}>
          {t("saveChanges")}
        </Button>
      </Card>
    </Modal>
  )
}

export default CountrySelectorModal;

