import moment from 'moment';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Table } from 'react-bootstrap';
import { MetalsTypes } from '../redux/metals';

const MetalsRates = memo(({rows}) => {
    const dispatch = useDispatch();
    let data = useSelector(state => state.metals.data);
    useEffect(() => dispatch({ type: MetalsTypes.METALS_RATES_REQUEST }), [])


    return (
        <Card>
            <Card.Header>
                <h1 className="card-subtitle color-black fw-bolder">
                    Metals Rates (oz)
                </h1>
            </Card.Header>
            <Card.Body>
                <Table responsive bordered hover>
                    <>
                        <thead>
                            <tr>
                                <th>Gold (XAU)</th>
                                <th>Silver (XAG)</th>
                                <th>Palladium (XPD)</th>
                                <th>Copper (XCU)</th>
                                <th>Platinum (XPT)</th>
                                <th style={{minWidth: "40%"}}>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(el => 
                                <tr key={el.id}>
                                    <td>$ {el?.XAU?.toFixed(0)}</td>
                                    <td>$ {el?.XAG?.toFixed(0)}</td>
                                    <td>$ {el?.XPD?.toFixed(0)}</td>
                                    <td>$ {el?.XCU?.toFixed(3)}</td>
                                    <td>$ {el?.XPT?.toFixed(0)}</td>
                                    <td>{moment(el?.date).format("MMM D, YYYY hh:mm a")}</td>
                                </tr>
                            ).slice(0, rows ? rows : data?.length)}
                        </tbody>
                    </>
                </Table>
            </Card.Body>
        </Card>
    )
})

export default MetalsRates