import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  
  getUserRequestDashboard: ["id"],
  getUserSuccessDashboard: ["data"],
  getUserErrorDashboard: ["error"],

  updateUserRequestDashboard: ["id", "data"],
  updateUserSuccessDashboard: [],
  updateUserErrorDashboard: [],
})

export const DashboardUserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: {},
    fetching: null,
    error: null
})

/* ------------- Reducers ------------- */


/* Get User */
export const getUserRequest = (state) =>
  state.merge({ data: {}, fetching: true, error: null})

export const getUserSuccess = (state, {data}) => {
  return state.merge({ fetching: false, data })
}

export const getUserError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* Update User */
export const updateUserRequest = (state) =>
  state.merge({ fetching: true})

export const updateUserSuccess = (state) => {
  return state.merge({ fetching: false })
}

export const updateUserError = (state) =>
  state.merge({ fetching: false})



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.GET_USER_REQUEST_DASHBOARD]: getUserRequest,
  [Types.GET_USER_SUCCESS_DASHBOARD]: getUserSuccess,
  [Types.GET_USER_ERROR_DASHBOARD]: getUserError,

  [Types.UPDATE_USER_REQUEST_DASHBOARD]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS_DASHBOARD]: updateUserSuccess,
  [Types.UPDATE_USER_ERROR_DASHBOARD]: updateUserError,

})