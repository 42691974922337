import { takeLatest, all } from 'redux-saga/effects'
import API from '../services/api'

/* ------------- Types ------------- */
import { SearchTypes } from '../redux/search'
import { ProductTypes } from '../redux/product'
import { MetalsTypes } from '../redux/metals'
import { DashboardSearchTypes } from '../redux/dashboard/search'
import { DashboardProductTypes } from '../redux/dashboard/product'
import { DashboardUsersTypes } from '../redux/dashboard/users'
import { DashboardUserTypes } from '../redux/dashboard/user'
import { DashboardCartsTypes } from '../redux/dashboard/carts'
import { DashboardCartTypes } from '../redux/dashboard/cart'
import { ProductAnalysisTypes } from '../redux/dashboard/analysis'
import { DatabaseTypes } from '../redux/dashboard/database'
import { SettingsTypes } from '../redux/dashboard/settings'
import { ProductCategoriesTypes } from '../redux/dashboard/categories'
import { CurrenciesTypes } from '../redux/dashboard/currencies'
import { LogsTypes } from '../redux/dashboard/logs'
import { ProfileTypes } from '../redux/account/profile'
import { CartTypes } from '../redux/account/cart'
import { DashboardTranslationsTypes } from '../redux/dashboard/translations'

/* ------------- Sagas ------------- */
import { getDevicesSections, getProducts } from './search'
import { getModels, getProduct } from './product'
import { getMetalsRates } from './metals'
import { resetDatabase } from './dashboard/database'
import { getFiltersDashboard, getProductsDashboard } from './dashboard/search'
import { getMetalsSettings, getPricingSettings, updateMetalSettings, updatePricingSettings } from './dashboard/settings'
import { getProductDashboard, getProductAnalysesDashboard, createProductDashboard, duplicateProductDashboard, updateProductDashboard, deleteProductDashboard, sortProductImagesDashboard, getProductModelsDashboard, addProductModelsDashboard, deleteProductModelsDashboard, updateProductAvailabilityDashboard, getProductTranslationsDashboard, updateProductTranslationsDashboard, getProductsTranslationsDashboard, updateProductsTranslationsDashboard, autofillMissingProductsTranslationsDashboard } from './dashboard/product'
import { getProductAnalysis, createProductAnalysis, updateProductAnalysis, deleteProductAnalysis, selectProductAnalysis } from './dashboard/analysis'
import { addCategory, addDevice, addType, deleteCategory, deleteDevice, deleteType, getProductCategories, updateCategory, updateDevice } from './dashboard/categories'
import { getCurrencies } from './dashboard/currencies'
import { getActionLogs } from './dashboard/logs'
import { getProfile, updateProfile, updateProfileBankInfo } from './user/profile'
import { getOpenCart, getCart, updateCart, submitCart, addCartProduct, deleteCartProduct, getCartsHistory, getCartInvoice} from './user/cart'
import { getUsersDashboard, getUserDashboard, updateUserDashboard } from './dashboard/users'
import { getCartsDashboard, getCartDashboard, getTrendingProductsDashboard } from './dashboard/carts'
import { autofillMissingCategoriesTranslationsDashboard, getCategoriesTranslationsDashboard, updateCategoriesTranslationsDashboard } from './dashboard/translations'


/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function * rootSaga () {
  yield all([

    // Global
    // takeLatest(GlobalTypes.POSTS_REQUEST, getPosts, api)

    // Login
    // takeLatest(LoginTypes.LOGIN_SIMULATION_REQUEST, loginSimulation),
    // takeLatest(LoginTypes.LOGOUT_SIMULATION_REQUEST, logoutSimulation),
    // takeLatest(LoginTypes.LOGIN_REQUEST, login, api),
    // takeLatest(LoginTypes.FACEBOOK_LOGIN_REQUEST, facebookLogin, api),
    // takeLatest(LoginTypes.GOOGLE_LOGIN_REQUEST, googleLogin, api),
    // takeLatest(LoginTypes.APPLE_LOGIN_REQUEST, appleLogin, api),

    // Startup
    // takeLatest(StartupTypes.STARTUP, startup),

    // Categories
    // takeLatest(HomeTypes.CATEGORIES_REQUEST, getCategories, api),

    // Filters
    // takeLatest(SearchTypes.FILTERS_REQUEST, getFilters, api),
    
    // Metal prices
    // takeLatest(ChartTypes.METALS_REQUEST, getMetals, api),

    // Chart prices
    // takeLatest(ChartTypes.CHART_DATA_REQUEST, getChartData, api),

    // Search
    takeLatest(SearchTypes.DEVICES_SECTIONS_REQUEST, getDevicesSections, api),
    takeLatest(SearchTypes.SEARCH_REQUEST, getProducts, api),

    // Product
    takeLatest(ProductTypes.GET_PRODUCT_REQUEST, getProduct, api),
    takeLatest(ProductTypes.GET_MODELS_REQUEST, getModels, api),

    // Database
    takeLatest(DatabaseTypes.RESET_DATABASE_REQUEST, resetDatabase, api),

    // Database
    takeLatest(MetalsTypes.METALS_RATES_REQUEST, getMetalsRates, api),

    // User profile & data
    takeLatest(ProfileTypes.GET_PROFILE_REQUEST, getProfile, api),
    takeLatest(ProfileTypes.UPDATE_PROFILE_REQUEST, updateProfile, api),
    takeLatest(ProfileTypes.UPDATE_PROFILE_BANK_INFO_REQUEST, updateProfileBankInfo, api),

    // User cart and history
    takeLatest(CartTypes.GET_OPEN_CART_REQUEST, getOpenCart, api),
    takeLatest(CartTypes.GET_CART_REQUEST, getCart, api),
    takeLatest(CartTypes.UPDATE_CART_REQUEST, updateCart, api),
    takeLatest(CartTypes.ADD_CART_PRODUCT_REQUEST, addCartProduct, api),
    takeLatest(CartTypes.DELETE_CART_PRODUCT_REQUEST, deleteCartProduct, api),
    takeLatest(CartTypes.SUBMIT_CART_REQUEST, submitCart, api),
    takeLatest(CartTypes.GET_CARTS_HISTORY_REQUEST, getCartsHistory, api),
    takeLatest(CartTypes.GET_CART_INVOICE_REQUEST, getCartInvoice, api),

    // Dashboard Search
    takeLatest(DashboardSearchTypes.SEARCH_REQUEST_DASHBOARD, getProductsDashboard, api),
    takeLatest(DashboardSearchTypes.FILTERS_REQUEST_DASHBOARD, getFiltersDashboard, api),
    takeLatest(DashboardProductTypes.GET_PRODUCT_REQUEST_DASHBOARD, getProductDashboard, api),
    takeLatest(DashboardProductTypes.GET_PRODUCT_ANALYSES_REQUEST_DASHBOARD, getProductAnalysesDashboard, api),
    takeLatest(DashboardProductTypes.CREATE_PRODUCT_REQUEST_DASHBOARD, createProductDashboard, api),
    takeLatest(DashboardProductTypes.DUPLICATE_PRODUCT_REQUEST_DASHBOARD, duplicateProductDashboard, api),
    takeLatest(DashboardProductTypes.UPDATE_PRODUCT_REQUEST_DASHBOARD, updateProductDashboard, api),
    takeLatest(DashboardProductTypes.DELETE_PRODUCT_REQUEST_DASHBOARD, deleteProductDashboard, api),
    takeLatest(DashboardProductTypes.UPDATE_PRODUCT_AVAILABILITY_REQUEST_DASHBOARD, updateProductAvailabilityDashboard, api),
    takeLatest(DashboardProductTypes.GET_PRODUCT_MODELS_REQUEST_DASHBOARD, getProductModelsDashboard, api),
    takeLatest(DashboardProductTypes.ADD_PRODUCT_MODELS_REQUEST_DASHBOARD, addProductModelsDashboard, api),
    takeLatest(DashboardProductTypes.DELETE_PRODUCT_MODELS_REQUEST_DASHBOARD, deleteProductModelsDashboard, api),
    takeLatest(DashboardProductTypes.GET_PRODUCT_TRANSLATIONS_REQUEST_DASHBOARD, getProductTranslationsDashboard, api),
    takeLatest(DashboardProductTypes.GET_PRODUCTS_TRANSLATIONS_REQUEST_DASHBOARD, getProductsTranslationsDashboard, api),
    takeLatest(DashboardProductTypes.UPDATE_PRODUCTS_TRANSLATIONS_REQUEST_DASHBOARD, updateProductsTranslationsDashboard, api),
    takeLatest(DashboardProductTypes.UPDATE_PRODUCT_TRANSLATIONS_REQUEST_DASHBOARD, updateProductTranslationsDashboard, api),
    takeLatest(DashboardProductTypes.AUTOFILL_MISSING_PRODUCTS_TRANSLATIONS_REQUEST_DASHBOARD, autofillMissingProductsTranslationsDashboard, api),
    takeLatest(DashboardProductTypes.SORT_PRODUCT_IMAGES_REQUEST_DASHBOARD, sortProductImagesDashboard, api),

    // Dashboard product analysis
    takeLatest(ProductAnalysisTypes.GET_PRODUCT_ANALYSIS_REQUEST, getProductAnalysis, api),
    takeLatest(ProductAnalysisTypes.CREATE_PRODUCT_ANALYSIS_REQUEST, createProductAnalysis, api),
    takeLatest(ProductAnalysisTypes.UPDATE_PRODUCT_ANALYSIS_REQUEST, updateProductAnalysis, api),
    takeLatest(ProductAnalysisTypes.DELETE_PRODUCT_ANALYSIS_REQUEST, deleteProductAnalysis, api),
    takeLatest(ProductAnalysisTypes.SELECT_PRODUCT_ANALYSIS_REQUEST, selectProductAnalysis, api),

    // Dashboard Users
    takeLatest(DashboardUsersTypes.GET_USERS_REQUEST_DASHBOARD, getUsersDashboard, api),
    takeLatest(DashboardUserTypes.GET_USER_REQUEST_DASHBOARD, getUserDashboard, api),
    takeLatest(DashboardUserTypes.UPDATE_USER_REQUEST_DASHBOARD, updateUserDashboard, api),
   
    // Dashboard Carts
    takeLatest(DashboardCartsTypes.CARTS_SEARCH_REQUEST_DASHBOARD, getCartsDashboard, api),
    takeLatest(DashboardCartsTypes.TRENDING_PRODUCTS_REQUEST_DASHBOARD, getTrendingProductsDashboard, api),
    takeLatest(DashboardCartTypes.GET_CART_REQUEST_DASHBOARD, getCartDashboard, api),

    // Dashboard settings
    takeLatest(SettingsTypes.GET_PRICING_SETTINGS_REQUEST, getPricingSettings, api),
    takeLatest(SettingsTypes.UPDATE_PRICING_SETTINGS_REQUEST, updatePricingSettings, api),
    takeLatest(SettingsTypes.GET_METALS_SETTINGS_REQUEST, getMetalsSettings, api),
    takeLatest(SettingsTypes.UPDATE_METAL_SETTINGS_REQUEST, updateMetalSettings, api),

    // Dashboard currencies
    takeLatest(CurrenciesTypes.CURRENCIES_REQUEST, getCurrencies, api),

    // Dashboard logs
    takeLatest(LogsTypes.ACTION_LOGS_REQUEST, getActionLogs, api),

    // Dashboard product categories
    takeLatest(ProductCategoriesTypes.GET_PRODUCT_CATEGORIES_REQUEST, getProductCategories, api),
    takeLatest(ProductCategoriesTypes.ADD_DEVICE_REQUEST, addDevice, api),
    takeLatest(ProductCategoriesTypes.UPDATE_DEVICE_REQUEST, updateDevice, api),
    takeLatest(ProductCategoriesTypes.ADD_CATEGORY_REQUEST, addCategory, api),
    takeLatest(ProductCategoriesTypes.UPDATE_CATEGORY_REQUEST, updateCategory, api),
    takeLatest(ProductCategoriesTypes.ADD_TYPE_REQUEST, addType, api),
    takeLatest(ProductCategoriesTypes.DELETE_DEVICE_REQUEST, deleteDevice, api),
    takeLatest(ProductCategoriesTypes.DELETE_CATEGORY_REQUEST, deleteCategory, api),
    takeLatest(ProductCategoriesTypes.DELETE_TYPE_REQUEST, deleteType, api),

    // Dashboard trasnlations
    takeLatest(DashboardTranslationsTypes.GET_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD, getCategoriesTranslationsDashboard, api),
    takeLatest(DashboardTranslationsTypes.UPDATE_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD, updateCategoriesTranslationsDashboard, api),
    takeLatest(DashboardTranslationsTypes.AUTOFILL_MISSING_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD, autofillMissingCategoriesTranslationsDashboard, api)

    // Return Calculator
    // takeLatest(CalculatorTypes.CALCULATE_RETURN_REQUEST, calculateReturn, api),

    // Reet comparator settings to Ecotrade setings
    // takeLatest(ComparatorSettingsTypes.RESET_TO_ECOTRADE_TERMS_REQUEST, resetToEcotradeTerms, api)

  ])
}
