/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/
import { toast } from 'react-toastify';
import { call, put, select, delay } from 'redux-saga/effects'
import DashboardUsersActions from '../../redux/dashboard/users'
import DashboardUserActions from '../../redux/dashboard/user'

// Get all users using filters
export function * getUsersDashboard(api, {searchField}) {
    const reducers = yield select();
    const { 
        searchLimit, 
        currentPage, 
        activeFilters
    } = reducers?.dashboard.users

    let params = { 
        searchField, 
        size: searchLimit, 
        page: currentPage, 
        filters: activeFilters
    }

    const response = yield call(api.getUsersDashboard, params)
    if (response.ok) {
        yield put(DashboardUsersActions.getUsersSuccessDashboard(response.data))
    } else {
        const error = response?.data?.message || "Server error"
        yield put(DashboardUsersActions.getUsersFailureDashboard(error))
    }
}


// Get one user by ID
export function * getUserDashboard (api, {id}) {
    const response = yield call(api.getUserDashboard, id)

    if (response.ok) {
        yield put(DashboardUserActions.getUserSuccessDashboard(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(DashboardUserActions.getUserErrorDashboard(message))
    }
}

// Update User Profile by ID
export function * updateUserDashboard (api, {id, data}) {
    const response = yield call(api.updateUserDashboard, {id, data})

    if (response.ok) {
        toast.success(response?.data?.message)
        yield put(DashboardUserActions.updateUserSuccessDashboard())
    } else {
        yield put(DashboardUserActions.updateUserErrorDashboard())
    }
}