import moment from "moment";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as Icon from "react-feather";
import { FaLine } from "react-icons/fa";
import { MdMail } from "react-icons/md";

const Footer = () => (
  <footer className="footer" style={{backgroundColor: "#484848"}}>
    <Container fluid>
      <Row className="text-white">
        <Col xs="4" className="text-start">
          <h4 className="fw-bolder text-white">
            ECOTRADE GROUP (THAILAND) - สำนักงานใหญ่
          </h4>
          <hr className="bg-primary" style={{ height: 4, width: 40, margin: '15px 0' }} />
          <div style={{ display: 'flex', alignItems: 'flex-start'}}>
            <span className="bg-primary rounded-circle p-2 me-2">
              <Icon.MapPin/>
            </span>
            <p className="fs-4">
              99/126 หมู่ที่ 2 ตำบลพันท้ายนรสิงห์<br />
              อำเภอเมืองสมุทรสาคร จังหวัดสมุทรสาคร 74000
            </p>
          </div>
        </Col>
        <Col xs="4" className="fs-4">
          <h4 className="fw-bolder text-white">
            HELP CENTER
          </h4>
          <hr className="bg-primary" style={{ height: 4, width: 40, margin: '15px 0' }} />
          <p href="#" className="py-2">
            <span className="bg-primary rounded-circle p-2 me-2">
              <MdMail className="fs-2"/>
            </span>
            support@ecotradegroup.com
          </p>
          <p href="#" className="pb-2">
            <span className="bg-primary rounded-circle p-2 me-2">
              <Icon.Phone className="fs-2"/>
            </span>
            0618308999
          </p>
          <p href="#">
            <span className="bg-primary rounded-circle p-2 me-2">
              <FaLine className="fs-2"/>
            </span>
            @pgmrecycling
          </p>
        </Col>
        <Col xs="4" className="text-end">
          <p className="mb-0">
            <span href="/">
              &copy; {moment().format('YYYY')} - PROVIDED BY ECOTRADE GROUP<br />ALL RIGHTS RESERVED.
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
