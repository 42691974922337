
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Page500 from "../../pages/auth/Page500";
import * as Icon from "react-feather";


// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const navigate = useNavigate()

  const { isAuthenticated, isInitialized, type } = useAuth();

  if (isInitialized && isAuthenticated && type) {
    
    if (type == "user") {
      return <Navigate to="/"/>;
    }
  
    if (type == "admin") {
      return <Navigate to="/dashboard" />;
    }

    return <Page500 />
  }
  
  return (
    <>
      <div onClick={()=> navigate('/')} className="d-flex p-5 cursor-pointer">
        <Icon.ArrowLeft size={"2.5rem"} className="me-3"/>
        <p style={{fontSize: "1.5rem"}}>Back to home</p>
      </div>
      {children}
    </>
  )
}

export default AuthGuard;
