import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import * as Icon from "react-feather";


const ResetPassword = () => {
  const navigate = useNavigate()
  const { resetPasswordProcess } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation()

  const handleChangePassword = ({password, token}) => {
    resetPasswordProcess({password, token})
  }

  return (
    (
      <div>
        <div onClick={()=> navigate('/')} className="d-flex p-5 cursor-pointer">
          <Icon.ArrowLeft size={"2.5rem"} className="me-3"/>
          <p style={{fontSize: "1.5rem"}}>Back to home</p>
        </div>
        <Container className="d-flex justify-content-center">
          <div className="text-center">
            <p className="h1 p-2 fw-bolder">{t("resetPassword")}</p>
            <div className="m-3">
              
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                  submit: false
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string().max(255).min(4).required(t("passwordIsRequired")),
                  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], t("passwordMustMatch")).required(t("confirmPasswordIsRequired")),
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                  const { password } = values
                  const token = searchParams.get("token")

                  try {
                    await handleChangePassword({password, token});
                  } catch (error) {
                    const message = error.message || "Something went wrong";
          
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert className="mx-5 mb-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}

                    <div className="mb-4 mx-5">

                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bolder">
                        {t("password")}
                      </Form.Label>
                      <Form.Control
                        disabled={isSubmitting}
                        size="lg"
                        type="password"
                        name="password"
                        placeholder={t("password")}
                        value={values.password}
                        isInvalid={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bolder">
                        {t("confirmPassword")}
                      </Form.Label>
                      <Form.Control
                        disabled={isSubmitting}
                        size="lg"
                        type="password"
                        name="confirmPassword"
                        placeholder={t("password")}
                        value={values.confirmPassword}
                        isInvalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.confirmPassword && (
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                      
                    </div>
                    <div className="text-center mt-4">
                      <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        disabled={isSubmitting}
                      >
                        {"Reset"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </div>
    )
  )
}

export default ResetPassword;
