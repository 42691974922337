import moment from 'moment';
import _, {capitalize} from 'lodash'
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { CartTypes } from '../redux/account/cart';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Pagination, Card, Spinner, Form, Row, Col, Button, InputGroup, Container, Collapse, ListGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Icon from "react-bootstrap-icons";
import { BASE_URL } from '../services/api';
import { useTranslation } from 'react-i18next';

const CartDetailsRow = ({el, i, row}) => {
    const { t } = useTranslation();
    return  (
        <Card.Body key={el?.id}>
            <Card.Title className='p-2 fw-bold'>
                {i == 0 && row?.submissions?.length > 1 ? (t('lastSubmissionOn') + " ") : (t('submittedOn') + " ")} 
                {moment(el?.updatedAt).format("MMM D, YYYY - hh:mm a")}
            </Card.Title>
            <Col>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{t('image')}</th>
                            <th>{t('quantity')}</th>
                            <th>{t('priceUsd')}</th>
                            <th>{t('totalPriceUsd')}</th>
                            <th>{t('priceConverted')} {el?.financialData?.currency}</th>
                            <th>{t('totalPriceConverted')} {el?.financialData?.currency}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {el?.products?.map((item) => (
                            <tr key={item?.id}>
                                <td>{item?.id}</td>
                                <td>
                                    <img
                                        src={item?.image?.url ? (BASE_URL + item?.image?.url) : null}
                                        width={50}
                                        height={50}
                                        alt="product"
                                    />
                                </td>
                                <td>{item?.quantity}</td>
                                <td>{item?.price_usd?.toFixed(2)}</td>
                                <td>{item?.total_price_usd?.toFixed(2)}</td>
                                <td>{item?.price_converted?.toFixed(2)}</td>
                                <td>{item?.total_price_converted_with_format}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>  
            <Row>
                <Col>
                    <div className='fs-3 p-2'>{t("financialData")}</div>
                    <ListGroup>
                        {Object?.entries(el?.financialData)?.map(item => (
                            <ListGroup.Item key={item[0]}>
                                {t(item[0])} - {item[1]}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col>
                    <div className='fs-3 p-2'>{t("paymentInfo")}</div>
                    <ListGroup>
                        {Object?.entries(el?.paymentInfo)?.map(item => (
                            <ListGroup.Item key={item[0]}>
                                {t(item[0])} - {item[1]}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col>
                    <div className='fs-3 p-2'>{t("shippingInfo")}</div>
                    <ListGroup>
                        {Object?.entries(el?.shippingInfo)?.map(item => (
                            <ListGroup.Item key={item[0]}>
                                {t(item[0])} - {item[1]}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        </Card.Body>
    )
}

const AccountCarts = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const carts = useSelector(state => state.account.cart.history)
    
    useEffect(()=> dispatch({type: CartTypes.GET_CARTS_HISTORY_REQUEST}), [])

    const [rowCollapsed, setRowCollapsed] = useState(null)

    const handleInvoiceDownload = ({id, ref}) => {
        if (id) dispatch({type: CartTypes.GET_CART_INVOICE_REQUEST, id, ref})
    }

    // const fields = [
    //     {field: "ref", label: "ref"},
    //     {field: "status", label: "status"},
    //     {field: "updatedAt", label: "date"},
    //     {field: "total_quantity", label: "quantity"},
    //     {field: "total_winning", label: "total"},
    // ]

    const columns = [
        {dataField: "ref", text: t("ref")},
        {dataField: "status", text: t("status")},
        {dataField: "updatedAt", text: t("date")},
        {dataField: "total_quantity", text: t("quantity")},
        {dataField: "total_winning", text: t("total")},
        {dataField: "invoice", text: t("invoice")},
    ]
    
    

    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    {t("carts")}
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {carts?.length > 0 ?
                    <BootstrapTable 
                        keyField='id'
                        data={carts?.map(el => ({
                            ...el,
                            status: t(el?.status),
                            updatedAt: moment(el?.updatedAt).format("MMM D, YYYY"),
                            total_quantity: el?.submissions?.[0]?.financialData?.total_quantity, 
                            total_winning: el?.submissions?.[0]?.financialData?.total_winning_with_format,
                            invoice: (
                                <Button onClick={() => handleInvoiceDownload(el)}>
                                    <Icon.FileEarmarkArrowDown size={"2.1em"} className={"py-1"}/>
                                </Button>
                            )
                        }))} 
                        wrapperClasses="table-responsive"
                        rowStyle={{cursor: "pointer"}}
                        columns={columns} 
                        expandRow={{
                            renderer: row => (
                                <div>
                                    {row?.submissions?.map((el, i) => 
                                        <CartDetailsRow 
                                            key={el?.id}
                                            el={el} 
                                            i={i} 
                                            row={row}
                                        />
                                    )}
                                </div>
                            )
                        }}
                    />
                : "No cart submitted"}
            </Card.Body>
        </Card>
    )
};

  
export default AccountCarts
