import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalTypes } from "../../redux/global";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import usFlag from "../../assets/img/flags/us.png";
import esFlag from "../../assets/img/flags/es.png";
import frFlag from "../../assets/img/flags/fr.png";
import deFlag from "../../assets/img/flags/de.png";
import nlFlag from "../../assets/img/flags/nl.png";
import itFlag from "../../assets/img/flags/it.png";
import zhFlag from "../../assets/img/flags/cn.png";
import ptFlag from "../../assets/img/flags/pt.png";
import ruFlag from "../../assets/img/flags/ru.png";
import jpFlag from "../../assets/img/flags/jp.png";
import hiFlag from "../../assets/img/flags/in.png";
import arFlag from "../../assets/img/flags/ae.png";
import myFlag from "../../assets/img/flags/my.png";
import thFlag from "../../assets/img/flags/th.png";
import { capitalize } from "lodash";


const languageOptions = {
  en: {name: "english", icon: usFlag},
  th: {name: "thai", icon: thFlag},
  es: {name: "spanish", icon: esFlag},
  fr: {name: "french", icon: frFlag},
  zh: {name: "chinese", icon: zhFlag},
  pt: {name: "portuguese", icon: ptFlag},
  de: {name: "german", icon: deFlag},
  it: {name: "italian", icon: itFlag},
  ru: {name: "russian", icon: ruFlag},
  ja: {name: "japanese", icon: jpFlag},
  hi: {name: "hindi", icon: hiFlag},
  nl: {name: "dutch", icon: nlFlag},
  ar: {name: "arabic", icon: arFlag},
  ms: {name: "malay", icon: myFlag}
};

const NavbarLanguages = () => {
  const { i18n, t } = useTranslation();
  const language = i18n.language
  const selectedLanguage = languageOptions[language] || languageOptions[language.split("-")[0]];

  // const dispatch = useDispatch();
  // const selectedLanguage = useSelector(state => state.global.language);
 

  const setLanguage = (language) => {
    // dispatch({ type: GlobalTypes.SET_LANGUAGE, language })
    i18n.changeLanguage(language)
  }

  return (
    <Dropdown className="me-2 nav-item" align="end">
      <Dropdown.Toggle as="a" className="nav-link nav-flag">
        <img 
          src={selectedLanguage?.icon} 
          alt={selectedLanguage?.name} 
          style={{ border: '0.15rem solid white', height: "1.7rem", width: "1.7rem" }}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(languageOptions).map((language) => (
          <Dropdown.Item
            key={language}
            onClick={() => setLanguage(language)}
          >
            <img
              src={languageOptions[language].icon}
              alt="English"
              width="25rem"
              className="align-middle me-3"
            />
            <span className="align-middle">
              {`${capitalize(t(languageOptions?.[language].name))} (${language?.toUpperCase()})`}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarLanguages;
