import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      error || error?.response?.data || error?.response?.message || "Something went wrong"
    )
);

export default axiosInstance;
