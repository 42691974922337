import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Image } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { gapi } from 'gapi-script';
import googleLogo from '../../assets/img/social/google-logo.png';
import facebookLogo from '../../assets/img/social/facebook-logo.png';
import { useTranslation } from "react-i18next";

// GOOGLE LOGIN CLIENT ID
const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID

// FACEBOOK APP ID
const facebookAppId = process.env.REACT_APP_FACEBOOK_AUTH_APP_ID

function SignIn() {
  const { t } = useTranslation()
  const { signIn, googleSignIn, facebookSignIn, resetPassword } = useAuth();

  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false)

  useEffect(() => {
    const initClient = () => gapi.auth2.init({ clientId, scope: '' });
    gapi.load('client:auth2', initClient);
  });

  const onGoogleSuccess = async (res) => {
    try {
      googleSignIn(res?.tokenId)
    } catch (error) {
    }
  }

  const onGoogleFailure = (err) => {
    console.log('failed', err);
  };

  const responseFacebook = async (response) => {
    try {
      if (response?.accessToken) {
        const {first_name, last_name, email} = response
        facebookSignIn({first_name, last_name, email})
      }
    } catch (error) {
      console.log(error)
    }
  };

  
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
        resetPasswordEmail: ""
      }}
      validationSchema={Yup.object().shape(
        forgotPasswordClicked ?
        {
          resetPasswordEmail: Yup.string()
            .email(t("mustBeAValidEmail"))
            .max(255)
            .required(t("emailIsRequired"))
        }
        : {
          email: Yup.string()
            .email(t("mustBeAValidEmail"))
            .max(255)
            .required(t("emailIsRequired")),
          password: Yup.string().max(255).min(4).required(t("passwordIsRequired")),
        }
      )}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await forgotPasswordClicked ? resetPassword(values?.resetPasswordEmail) : signIn({email: values?.email, password: values?.password});
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="mx-5 mb-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <div className="mb-4 mx-5">
            <Form.Group className="mb-3">
              <Form.Label className="fw-bolder">
                {t("email")}
              </Form.Label>
              <Form.Control
                disabled={isSubmitting}
                size="lg"
                type="email"
                name="email"
                placeholder={t("email")}
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label className="fw-bolder">
                {t('password')}
              </Form.Label>
              <Form.Control
                disabled={isSubmitting}
                size="lg"
                type="password"
                name="password"
                placeholder={t("password")}
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
              <div 
                className="mt-2" 
                style={{fontSize: "0.8rem", textAlign: "center"}}
              >
                <div 
                  onClick={()=> setForgotPasswordClicked(!forgotPasswordClicked)}
                  className="mt-3" 
                >
                  {t("forgotPassword")}
                </div>
                {forgotPasswordClicked &&
                  <>
                    <div className="my-3">
                      {t("forgotPasswordHint")}
                    </div>
                    <Form.Group>
                      <Form.Control
                        disabled={isSubmitting}
                        size="lg"
                        type="email"
                        name="resetPasswordEmail"
                        placeholder={t("email")}
                        value={values.resetPasswordEmail}
                        isInvalid={Boolean(touched.resetPasswordEmail && errors.resetPasswordEmail)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.resetPasswordEmail && (
                        <Form.Control.Feedback type="invalid">
                          {errors.resetPasswordEmail}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </>}
              </div>
            </Form.Group>

          </div>
          <div className="text-center mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {forgotPasswordClicked ? t("resetPassword") : t("signIn")}
            </Button>
          </div>
          <div className="m-4 mt-4 fw-bolder text-center">
            {t("orSignInWithOneClick")}
          </div>
          <div className="d-flex justify-content-center">
            <div className="m-2">
              <GoogleLogin
                clientId={clientId}
                buttonText="Sign in with Google"
                onSuccess={onGoogleSuccess}
                onFailure={onGoogleFailure}
                cookiePolicy={'single_host_origin'}
                // isSignedIn={true}
                // style={{width: 300}}
                render={({disabled, onClick}) => (
                  <Button
                    onClick={onClick} 
                    disabled={disabled || isSubmitting}
                    className={"text-black fs-3 shadow-lg"}
                    style={{
                      backgroundColor: "#fff", 
                      borderColor: "#fff"
                    }}
                  >
                    <Image
                      src={googleLogo} 
                      style={{width: "3rem"}}
                    />
                  </Button>
                )}
              />
            </div>
            <div className="m-2">
              <FacebookLogin
                appId={facebookAppId}
                fields="name,email,first_name,last_name"
                autoLoad={false}
                callback={responseFacebook} 
                icon="fa-facebook"
                disableMobileRedirect={true}
                render={({isDisabled, onClick}) => (
                  <Button
                    onClick={onClick} 
                    disabled={isDisabled || isSubmitting}
                    className={"text-black fs-3 shadow-lg"}
                    style={{
                      backgroundColor: "#fff", 
                      borderColor: "#fff",
                    }}
                  >
                    <Image
                      src={facebookLogo} 
                      style={{width: "3rem", padding: "0.32rem"}}
                    />  
                  </Button>
                )}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
