import moment from 'moment';
import _, { isEqual } from 'lodash'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { DashboardProductTypes } from '../redux/dashboard/product';
import React, { useEffect, useState } from 'react';
import { Container, Tab, Tabs, Row, Col, Modal, Button, Form } from 'react-bootstrap';
import DashboardProductDetails from './DashboardProductDetails';
import DashboardProductImages from './DashboardProductImages';
import DashboardProductAnalyses from './DashboardProductAnalyses';
import DashboardProductFormulas from './DashboardProductFormulas';
import { ProductCategoriesTypes } from '../redux/dashboard/categories';
import MetalsRates from '../components/MetalsRates';
import DashboardProductModels from './DashboardProductModels';
import { toast } from 'react-toastify';
import { Trash } from 'react-feather';
import DashboardProductTranslations from './DashboardProductTranslations';

const DuplicateProductModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    
    const currentProductCode = useSelector(state => state.dashboard.product?.product?.code)
    const {duplicatingProduct, duplicatedProductId} = useSelector(state => state.dashboard.product) || {}

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [newCode, setNewCode] = useState(currentProductCode);

    useEffect(()=> { 
        if (duplicatedProductId) { 
            setShow(false)
            navigate(`/dashboard/product/${duplicatedProductId}`)
            window.location.reload();
        } else {
            setNewCode(currentProductCode)
        }
    }, [duplicatedProductId, currentProductCode])

    const handleDuplicateProduct = () => {
        if (!currentProductCode) return toast.error("Missing product code to duplicate")
        if (newCode?.length < 4) return toast.error("New code must be at least 4 characters")
        dispatch({ type: DashboardProductTypes.DUPLICATE_PRODUCT_REQUEST_DASHBOARD, oldCode: currentProductCode, newCode  })
    }

    return (
        <>
        <Button className='me-2' variant="primary" onClick={handleShow}>
            Duplicate Product
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>
                Duplicate Product
                {duplicatingProduct && ` (${"Duplicating"})`}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="validationCustom01">
                    <Form.Label>New Code {newCode == currentProductCode && ` (must me different from original)`}</Form.Label>
                    <Form.Control
                        disabled={duplicatingProduct}
                        required
                        type="text"
                        placeholder="Code"
                        onChange={e => setNewCode(e?.target?.value?.trim())}
                        value={newCode || ""}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} disabled={duplicatingProduct}>
                Close
            </Button>
            <Button 
                disabled={(currentProductCode == newCode) || duplicatingProduct}
                variant="primary" 
                onClick={handleDuplicateProduct}
            >
                Start Duplication
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
          

const ProductTabs = () => {
    const navigate = useNavigate()
    const location = useLocation()?.pathname
    const initialTab = () => {
        if (location?.includes("details")) return "details"
        if (location?.includes("images")) return "images"
        if (location?.includes("models")) return "models"
        if (location?.includes("translations")) return "translations"
        return "details"
    }
    const [key, setKey] = useState(initialTab);

    const { analyses } = useSelector(state => state.dashboard.product);

    const selectedAnalysis = analyses?.find(el => el.selected)
    const showMetalsAndPrices = key == "details" && selectedAnalysis

    return (
        <div className={"tab shadow"}>
            <Row xs={1} sm={key == "details" ? 2 : 1}>
                <Col>
                    <Tabs
                        defaultActiveKey="details"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                        activeKey={key}
                        onSelect={(k) => {
                            navigate({pathname: `${k}`})
                            setKey(k)
                        }}
                    >
                        <Tab eventKey="details" title="Details">
                            <DashboardProductDetails />
                        </Tab>
                        <Tab eventKey="images" title="Images">
                            {key == "images" && <DashboardProductImages />}
                        </Tab>
                        <Tab eventKey="models" title="Models">
                            {key == "models" && <DashboardProductModels />}
                        </Tab>
                        <Tab eventKey="translations" title="Translations">
                            {key == "translations" && <DashboardProductTranslations />}
                        </Tab>
                    </Tabs>
                </Col>
                <Col>
                    {showMetalsAndPrices && <MetalsRates rows={1}/>}
                </Col>
            </Row>
            {key == "details" && <DashboardProductAnalyses />}
        </div>
    );
}



const DashboardProduct = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    let { id } = useParams();

    const currency = useSelector(state => state.global.currency);
    const product = useSelector(state => state.dashboard.product.product)
    const deleteProductSuccess = useSelector(state => state.dashboard.product.deleteProductSuccess)


    const handleProductAvailability = () => {
        const { id, available } = product;
        dispatch({ type: DashboardProductTypes.UPDATE_PRODUCT_AVAILABILITY_REQUEST_DASHBOARD, id, available: !available })
    }

    const handleProductDelete = () => {
        if (window.confirm('Are you sure you want to delete this product?')) {
            const { id, code } = product;
            dispatch({ type: DashboardProductTypes.DELETE_PRODUCT_REQUEST_DASHBOARD, id, code })
        }
    }
    
    useEffect(()=> {

        // Refresh categories
        dispatch({ type: ProductCategoriesTypes.GET_PRODUCT_CATEGORIES_REQUEST})
        
        if (id) {
            dispatch({ type: DashboardProductTypes.GET_PRODUCT_REQUEST_DASHBOARD, id })
        }
        return ()=> {
            dispatch({ type: DashboardProductTypes.UPDATE_PRODUCT_RESET_DASHBOARD })
            dispatch({ type: DashboardProductTypes.PRODUCT_RESET_DASHBOARD })
        } 
    }, [currency])

    useEffect(() => {
        if (deleteProductSuccess) { navigate('/dashboard')}
    }, [deleteProductSuccess])

    return (
        <React.Fragment>
            <Container fluid className="p-0">
                <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <h2 as="h2" className="m-0 me-3">
                            Product - ID {id}
                        </h2>
                        <Button 
                            onClick={handleProductAvailability}
                            variant="primary"
                            className='me-2 bg-white text-dark border-2 d-flex'
                        >
                            <Form.Check 
                                readOnly 
                                type={'checkbox'}
                                checked={product?.available}
                                className='me-2'
                            />
                            Available
                        </Button>
                    </div>
                    <div className='d-flex mb-3'>
                        <DuplicateProductModal />
                        <DashboardProductFormulas />
                        <Button 
                            onClick={handleProductDelete}
                            variant="primary"
                            className='ms-2 bg-danger d-flex justify-content-center'
                        >
                            <Trash 
                                size={"1.1rem"} 
                                className='p-0 m-0 pt-1 me-1'
                            />
                            Delete
                        </Button>
                    </div>
                </div>
                <ProductTabs />
            </Container>
        </React.Fragment>
    )
}

  
export default DashboardProduct
