import moment from 'moment';
import _, { isEqual } from 'lodash'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Container, Modal, Table, Tab, Row, Col, Card, Form, Nav, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Check, Edit2, Trash, Globe, X } from 'react-feather';
import DashboardProductAnalysis from './DashboardProductAnalysis';
import { ProductAnalysisTypes } from '../redux/dashboard/analysis';
import { useParams } from 'react-router-dom';



const DashboardProductAnalyses = () => {

    let { id } = useParams();

    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(null);
  
    const handleCreateAnalysis = () => setOpenModal(true)
    const handleEditAnalysis = (analysis) => setOpenModal(analysis)
    const handleCloseModal= () => setOpenModal(false)

    const { analyses } = useSelector(state => state.dashboard.product);
    const { creatingProductAnalysis,  updatingProductAnalysis, deletingProductAnalysis, selectingProductAnalysis } = useSelector(state => state.dashboard.analysis);
    const fetching = creatingProductAnalysis || updatingProductAnalysis || selectingProductAnalysis

    const handleDeleteAnalysis = (data) => {
        if (window.confirm('Are you sure you want to delete this analysis?')) {
            const id = data?.id
            const product_id = data?.product_id
            dispatch({ type: ProductAnalysisTypes.DELETE_PRODUCT_ANALYSIS_REQUEST, id, product_id })
        }
    }

    const handleSelectAnalysis = (data) => {
        if (window.confirm('Are you sure you want to select this analysis?')) {
            const id = data?.id
            const product_id = data?.product_id
            dispatch({ type: ProductAnalysisTypes.SELECT_PRODUCT_ANALYSIS_REQUEST, id, product_id })
        }
    }

    return (
        <Card className='mt-3'>
            <div className='m-4 mt-3 mb-0 d-flex justify-content-between'>
                <h3>Product Tests</h3>
                <Button  
                    variant='outline-secondary'
                    // disabled={updatingProduct}
                    onClick={handleCreateAnalysis}
                    className="ml-auto p-2 col-example"
                    style={{}}
                >
                    <FontAwesomeIcon 
                        icon={faPlus} 
                        fixedWidth 
                        className='me-1'
                    />
                    Add an analysis
                </Button>
            </div>
            <Card.Body>
                {analyses?.length > 0 &&
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Ref</th>
                                <th>AU (ppm)</th>
                                <th>AG (ppm)</th>
                                <th>PD (ppm)</th>
                                <th>PT (ppm)</th>
                                <th>CU (%)</th>
                                <th>Created On</th>
                                <th>Updated On</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analyses?.map(el => (
                                <tr key={el?.id}>
                                    <td>
                                        {el?.selected ?
                                            <Globe
                                                color='green'
                                                className="p-1 ms-1" 
                                                size={25} 
                                            />
                                        : 
                                            <X 
                                                color='red'
                                                className="p-1 ms-1" 
                                                size={25} 
                                            />}
                                    </td>
                                    <td>{el?.ref}</td>
                                    <td>{el?.au_ppm}</td>
                                    <td>{el?.ag_ppm}</td>
                                    <td>{el?.pd_ppm}</td>
                                    <td>{el?.pt_ppm}</td>
                                    <td>{el?.cu_perc}</td>
                                    <td>{moment(el?.createdAt).format("MMM D, YYYY")}</td>
                                    <td>{moment(el?.updatedAt).format("MMM D, YYYY")}</td>
                                    <td style={{width: "10%"}}>
                                        {deletingProductAnalysis.includes(el?.id) ?
                                            <Spinner
                                                animation="border"
                                                variant={"primary"} 
                                            />
                                        : 
                                        <>
                                            <Edit2 
                                                onClick={() => handleEditAnalysis(el)} 
                                                className="p-1 me-1" 
                                                size={25}
                                                type="button"
                                            />
                                            <Trash 
                                                onClick={() => handleDeleteAnalysis(el)}
                                                className="p-1 ms-1" 
                                                size={25} 
                                                type="button"
                                            />
                                            {!el.selected &&
                                                <Check
                                                    onClick={() => handleSelectAnalysis(el)}
                                                    className="p-1 ms-1" 
                                                    size={25} 
                                                    type="button"
                                                />}
                                        </>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>}
            </Card.Body>
            {openModal &&
                <DashboardProductAnalysis
                    openModal={openModal}
                    closeModal={handleCloseModal}
                    analysis={typeof openModal == "object" ? openModal : null}
                />}
        </Card>
    )
}

  
export default DashboardProductAnalyses
