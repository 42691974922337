import React, { memo } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const MapFrame = ({coordinates}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(coordinates);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={{
          width:'100%',
          height: '40vh',
          maxWidth:'600px',
          maxHeight: '400px'
        }}
        center={coordinates}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={coordinates} />
      </GoogleMap>
  ) : <></>
}

export default memo(MapFrame)