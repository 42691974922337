import _, { add, capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import React, { memo, useEffect, useState } from "react";
import { Button, Table, Card, Col, Container, Form, ListGroup, Row, Modal, Spinner, FormControl } from "react-bootstrap";
import SearchBar from "../components/SearchBar";
import { BASE_URL } from "../services/api";
import { placeholderImage } from "./ui/Utils";
import * as Icon from "react-feather";
import { ProfileTypes } from "../redux/account/profile";
import { CartTypes } from "../redux/account/cart";
import { useNavigate } from "react-router-dom";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { useTranslation } from "react-i18next";
import { getFlagEmoji } from "../utils/commons";
import allCountries from "country-region-data/data.json";
import useAuth from "../hooks/useAuth";
import { Helmet } from "react-helmet-async";

const CartSubmissionConfirmation = ({
  data,
  total_winning,
  submitted,
  show,
  onSubmit,
  onHide
}) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  if (submitted) {
    return (
      <Modal
        show
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Header className="d-flex fs-2 fw-bolder justify-content-center">
            {t("cartSuccessfullySubmitted")}
          </Modal.Header>
          {submitted == "invalid_country" &&
            <Modal.Body className="fs-4">
              <p>{t("thankYouForUsingOurServices")}.</p>
              <p>{t("unfortunatelyWeDoNotPurchaseWeeeInYourCountryAtTheMoment")}.</p>
              <p>{t("wellMakeSureToNotifyYouWhenOurPurchasingServicesAreAvailableInYourCountry")}</p>
              <p>{t("bestRegards")},</p>
              <p><b>{t("theEcotradeGroupTeam")}</b></p>
            </Modal.Body>}
          <Modal.Footer className="justify-content-center">
            <Button 
              className="mt-3"
              variant="primary" 
              onClick={()=> navigate('/')}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal
      show={show}
      // show={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton onClick={onHide}>
        <Modal.Title className="fw-bolder fs-1" id="contained-modal-title-vcenter">
          {t("cartSubmission")?.toUpperCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>{t("youAreAboutToSubmitThisCart")}</h2>
        {/* <div className="pt-4 fs-3">
          {Object?.entries(data || {})?.map((el) => (
            <div className="fw-bolder fs-2 pb-3" key={el[0]}>
              {capitalize(el[0])}
              {Array.isArray(el[1]) ? el[0] == "products" ?
                el[1]?.map(({id, reference, quantity}) => (
                  <div className="fs-4 fw-normal" key={id}>
                    Ref: {reference} - QUANTITY: {quantity}
                  </div>
                )) : null 
              : Object?.entries(el[1] || {})?.map((el) => (
                <div className="fs-4 fw-normal" key={el[0]}>
                  {el[0]}: {el[1]}
                </div>
              ))}
            </div>
          ))}
          {total_winning && 
            <p className="mt-4 fs-2">
              <span className="fw-bolder"> MY WINNINGS: </span>
              {total_winning}
            </p>}
        </div> */}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button 
          className="fs-3"
          variant="success" 
          onClick={onSubmit}
        >
          {t("confirm")}
        </Button>
        <Button 
          className="fs-3"
          variant="danger" 
          onClick={onHide}
        >
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const CartUpdater = ({initialQuantity, product_id}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(initialQuantity)

  const updateProductQuantity = (newQuantity) => {
    if (isNaN(newQuantity) || newQuantity == initialQuantity) return
    dispatch({ type: CartTypes.ADD_CART_PRODUCT_REQUEST, product_id, quantity, newQuantity})    
  }

  const incrementQuantity = () => {
    const newQuantity = isNaN(quantity) ? 1 : quantity + 1
    setQuantity(newQuantity)
    dispatch({ type: CartTypes.ADD_CART_PRODUCT_REQUEST, product_id, quantity, newQuantity})    
  }

  const decrementQuantity = () => {
    if (isNaN(quantity) || quantity <= 1) return
    const newQuantity = quantity - 1
    setQuantity(newQuantity)
    dispatch({ type: CartTypes.ADD_CART_PRODUCT_REQUEST, product_id, quantity, newQuantity})    
  }

  return (
    <div className="d-flex">
      <Button onClick={decrementQuantity}>-</Button>
      <FormControl
        onChange={(e) => setQuantity(e?.target?.value)}
        onBlur={(e) => updateProductQuantity(e?.target?.value)}
        value={quantity}
        className='border-0 text-center w-50'
        style={{minWidth: 50}}
        type="number"
        min={0.1} 
        max={1000}
      />
     <Button onClick={incrementQuantity}>+</Button>
    </div>
  )
}

const CartSummary = memo(({fetching, products, cart}) => {
  const { i18n, t } = useTranslation();
  const language = i18n.language

  const dispatch = useDispatch()
  const handleDeleteProduct = (cart_product_id) => {
    if (window.confirm(t("areYouSureYouWantToRemoveThisProduct?"))) {
      dispatch({type: CartTypes.DELETE_CART_PRODUCT_REQUEST, cart_product_id})
    }
    
  }

  return (
    <>
      <Table striped hover={!fetching} responsive>
        <thead>
          <tr className="text-center">
            <th>{t("image")?.toUpperCase()}</th>
            <th>{t("priceUsdKg")?.toUpperCase()}</th>
            <th>{t("priceLocalKg")?.toUpperCase()}</th>
            <th>{t("reference")?.toUpperCase()}</th>
            <th>{t("quantityInKg")?.toUpperCase()}</th>
            <th>{t("total")?.toUpperCase()}</th>
            <th>{t("totalWinningLocalCurrency")?.toUpperCase()}</th>
            <th>{t("delete")?.toUpperCase()}</th>
          </tr>
        </thead>
        <tbody>
          {products?.map(el => {
            const img = el?.image?.url ? (BASE_URL+el?.image?.url) : placeholderImage
            const reference = el?.ref_translation?.[language] || el?.reference
            
            const cardImageStyle =  {
              objectFit: 'cover',
              height: 80,
              width: 120
            }

            return (
              <tr 
                key={el?.id} 
                className="align-items-center text-center"
              >
                <td>
                  <Card.Img 
                    style={cardImageStyle} 
                    src={img} 
                  />
                </td>
                <td>{"USD " + +(+el?.price_usd)?.toFixed(2) || "N/A"}</td>
                <td>{(cart?.cart_currency + " " + +(+el?.price_converted)?.toFixed(2)) || "N/A"}</td>
                <td>{reference}</td>
                <td>
                  <CartUpdater 
                    product_id={el?.id}
                    initialQuantity={el?.quantity}
                  />
                </td>
                <td>{"USD " + +(+el?.total_price_usd)?.toFixed(2) || "N/A"}</td>
                <td>{(cart?.cart_currency + " " + +(+el?.total_price_converted)?.toFixed(2)) || "N/A"}</td>
                <td className="text-center">
                  <Icon.Trash2
                    className='cursor-pointer'
                    onClick={()=> {
                      !fetching && handleDeleteProduct(el?.cart_product_id)
                    }}
                  />
                </td>
              </tr>
          )})}
        </tbody>
      </Table>
      <div className="d-flex mt-3 fs-1 fw-bolder justify-content-center">
        <span className="fw-bolder fs-2">{t("total")}:&nbsp;&nbsp;</span>
        {cart?.total_winning_with_format}
      </div>
    </>
  )
})

const StepForm = memo(({formId, fetching, valid, fields, setField}) => {
  return (
    <Row>
      {fields?.map(({label, id, placeholder, value, disabled}, index)=> (
        <Col 
          key={id} 
          sm={6}
          className="mb-3"
        >
          <Form.Group>
            <Form.Label className="fw-bolder">
              {label}
            </Form.Label>
            <Form.Control 
              disabled={fetching || disabled}
              onChange={(e) => setField(formId, index, e)}
              value={value || ""}
              id={id}
              placeholder={placeholder}
            >
            </Form.Control>
          </Form.Group>
        </Col>
      ))}
    </Row>
  )
})

const MyWinnings = memo(({cart, tos, setTos}) => {
  const { i18n, t } = useTranslation();
  const language = i18n.language
  
  return (
    <>
      <div style={{fontSize: "3rem"}} className="d-flex mt-3 fw-bolder justify-content-center">
        <span style={{fontSize: "3rem"}} className="fw-bolder">{t("totalWinning")}:&nbsp;&nbsp;</span>
        {cart?.total_winning_with_format}
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3 mb-1 fs-3 fw-bolder">
        <Form>
          <Form.Check
            type={"checkbox"}
            id={`checkbox`}
            checked={tos}
            label={
              <span>
                  {t("iAcknowledgeAndAcceptTheTermOfSales")} 
                  <span>&nbsp;|&nbsp;</span>
                  <a href={`${language}/tos`} className="fw-bold" target="_blank">
                    {t("read")}
                  </a>
              </span>
            }
            onChange={()=> setTos(!tos)}
          />
        </Form>
        &nbsp;
        
      </div>
    </>
  )
})

const OpenCartSteps = ({cart, products, error, fetching}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {id, user, ref, submitted, country, cart_currency, cart_currency_rate, total_quantity, total_winning, total_winning_with_format, total_winning_usd } = cart || {}
  const {first_name, last_name, phone, email, address, city, zip, bankInfo} = user || {}
  const {account_holder, account_number, bank, bank_branch, swift} = bankInfo || {}

  const topCountries = [country, "TH"]
  const countries = allCountries?.map(({countryName, countryShortCode}) => ({countryName, countryShortCode}))
                    .sort((x) => topCountries.includes(x.countryShortCode) ? -1 : 0)
                    .filter(el => el.countryShortCode != country)

  const [cartSubmitModal, setCartSubmitModal] = useState(false);
  const [step, setStep] = useState(1);

  const [tos, setTos] = useState(false);

  const [stepsForms, setStepForm] = useState({ 
    paymentInfo: {
      valid: account_holder && account_number && bank && bank_branch,
      data: [
        {id: "payment_method", label: t("payment_method"), value: "Bank Transfer", disabled: true},
        {id: "account_holder", label: t("account_holder"), value: account_holder},
        {id: "account_number", label: t("account_number"), value: account_number},
        {id: "bank", label: t("bank"), value: bank},
        {id: "bank_branch", label: t("bank_branch"), value: bank_branch},
        {id: "swift", label: t("swift"), value: swift, valid: true},
      ]
    },
    shippingInfo: {
      valid: true,
      data: [
        {id: "shipping_method", label: capitalize(t("shipping_method")), value: "Delivery", disabled: true}
      ]
    },
    userInfo: {
      valid: first_name && last_name && phone && email && country && address && city && zip,
      data: [
        {id: "first_name", label: t("firstName"), value: first_name},
        {id: "last_name", label: t("lastName"), value: last_name},
        {id: "phone", label: t("phoneNumber"), value: phone},
        {id: "email", label: t("emailAddress"), value: email, disabled: true},
        {id: "address", label: t("address"), value: address},
        {id: "city", label: t("city"), value: city},
        {id: "zip", label: t("zip"), value: zip}
      ]
    }
  })

  const setField = (formId, index, {target: {value}}) => {
    let newArr = [...stepsForms[formId].data]
    newArr[index] = {...newArr[index], value};
    setStepForm({
      ...stepsForms, 
      [formId]: {
        ...stepsForms[formId],
        valid: [...newArr]?.every(el => el?.value || el?.valid),
        data: [...newArr]
      }
    })
  }

  const data = Object.keys(stepsForms).reduce((a, v) => 
    ({ 
      ...a, 
      [v]: stepsForms[v].data.reduce((obj, item) => 
        (obj[item.id] = item.value, obj)
      ,{})
    }), 
  {
    products,
    financialData: { 
      country,
      currency: cart_currency, 
      currencyRate: cart_currency_rate,
      total_quantity: total_quantity, 
      total_winning: total_winning, 
      total_winning_with_format: total_winning_with_format,
      total_winning_usd: total_winning_usd
    }
  })

  const handleCartSubmission = () => {
    if (!id) return alert('Missing cart ID')
    dispatch({ type: CartTypes.SUBMIT_CART_REQUEST, id, data })
  }

  const steps = [
    {title: t("myCart")?.toUpperCase()}, 
    {
      title: t("paymentAndShipping")?.toUpperCase(), 
      form: true,
      formValid: stepsForms?.["paymentInfo"]?.valid
    }, 
    {
      title: t("myInfo")?.toUpperCase(), 
      form: true,
      formValid: stepsForms?.["shippingInfo"]?.valid && stepsForms?.["userInfo"]?.valid
    }, 
    {title: t("myWinning")?.toUpperCase()}
  ]

  const [selectedCountry, setSelectedCountry] = useState(null)

  useEffect(() => { 
    if (country) setSelectedCountry(country)
  }, [country])

  const handleSaveCountry = () => {
    dispatch({type: ProfileTypes.UPDATE_PROFILE_REQUEST, profile: {country: selectedCountry}})
  }

  return ( 
    <>
      <Helmet title={t("myCart")}/>
      <SearchBar title={t("myCart")} noBar/>
      {(!country && !submitted) ?
        <Card className="m-4 p-4 align-self-center">
          <Card.Title className="fw-bolder">
            {t("pleaseSelectAcountry")}
          </Card.Title>
          <Form.Select 
            disabled={fetching}
            onChange={e => setSelectedCountry(e?.target?.value)}
            name="country" 
            id="country"
            placeholder={""}
            className={"mt-3 mb-4"}
          >
            <option value={selectedCountry}>
              {selectedCountry ? `${getFlagEmoji(selectedCountry)} ${allCountries?.find(el => el.countryShortCode == selectedCountry)?.countryName || "--"}` : "--"}
            </option>
            {countries?.map(({countryShortCode, countryName}) => (
              <option 
                value={countryShortCode} 
                key={countryShortCode}
              >
                {countryShortCode ? `${getFlagEmoji(countryShortCode)} ${countryName}` : null}
              </option>
            ))}
          </Form.Select>
          <Button disabled={fetching} variant="primary" onClick={handleSaveCountry}>
            {t("saveChanges")}
          </Button>
        </Card>
      : <Container>
          <h1 className="fw-bolder pb-4 pt-5">
            {t("myCurrentCart")?.toUpperCase()} - #{ref} - {products?.length} {t("items")?.toLocaleUpperCase()}
          </h1>
          <Card className="shadow-lg p-4" style={{backgroundColor: "#F8F9FA"}}>
            <Card.Body>
              <div className="mb-7 px-4">
                <ProgressBar
                  percent={100-(((steps?.length - step)/(steps?.length-1))*100)}
                  filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                >
                  {steps?.map(({title, form, formValid}, index) => (
                    <Step 
                      key={index} 
                      transition="scale"
                    >
                      {({ accomplished }) => {
                        const selected = index + 1 == step
                        const borderColor = !accomplished || selected ? "warning" : (!form || formValid) ? "success" : "danger"
                        return (
                          <div 
                            style={{ cursor: "pointer"}}
                            className="d-flex justify-content-center align-items-center text-center"
                          >
                            <div
                              onClick={()=> setStep(index+1)}
                              className={`d-flex justify-content-center align-items-center mb-2 bg-primary border border-${borderColor} border-4 rounded-circle`}
                              style={{ 
                                filter: `grayscale(${accomplished ? 0 : 80}%)` ,
                                minHeight: 50,
                                maxHeight: 20,
                                height: "1.5rem",
                                minWidth: 50,
                                maxWidth: 100,
                                width: "1.5rem"
                              }}
                            >
                              <span className="fw-bolder">
                                {index + 1}
                              </span>
                            </div>
                            <p 
                              onClick={()=> setStep(index+1)}
                              className="position-absolute top-100 fs-5"
                            >
                              {title}
                            </p>
                          </div>
                        )
                      }}
                    </Step>
                  ))}
                </ProgressBar>
              </div>
              {step == 1 && 
                <CartSummary 
                  fetching={fetching}
                  products={products}
                  cart={cart}
                />}
              {step == 2 && 
                <>
                  <StepForm
                    formId={"paymentInfo"}
                    fetching={fetching}
                    valid={stepsForms["paymentInfo"]?.valid}
                    fields={stepsForms["paymentInfo"]?.data}
                    setField={setField}
                  />
                  <StepForm
                    formId={"shippingInfo"}
                    fetching={fetching}
                    valid={stepsForms["shippingInfo"]?.valid}
                    fields={stepsForms["shippingInfo"]?.data}
                    setField={setField}
                  />
                </>}
                {step == 3 && 
                  <StepForm
                    formId={"userInfo"}
                    fetching={fetching}
                    valid={stepsForms["userInfo"]?.valid}
                    fields={stepsForms["userInfo"]?.data}
                    setField={setField}
                  />}
              {step == 4 && 
                <MyWinnings 
                  fetching={fetching}
                  cart={cart}
                  tos={tos}
                  setTos={setTos}
                />}
              <>
                <div className="pt-4 d-flex justify-content-center">
                  {fetching ? 
                    <Spinner animation="border" variant="dark" size="md" />
                    :
                    step == 4 ?
                      <Button 
                        disabled={fetching || !Object.values(stepsForms).every(form => form?.valid) || !tos}
                        variant="primary" 
                        onClick={() => setCartSubmitModal(true)}
                      >
                        {t("submitCart")?.toUpperCase()}
                      </Button>
                    :
                      <Button 
                        disabled={fetching || (steps[step-1]?.form && !steps[step-1]?.formValid)}
                        variant="primary" 
                        onClick={() => setStep(step+1)}
                      >
                        {t("next")?.toUpperCase()}
                      </Button>}
                  <CartSubmissionConfirmation
                    show={cartSubmitModal || submitted}
                    submitted={submitted}
                    onHide={() => setCartSubmitModal(false)}
                    data={data}
                    total_winning={total_winning}
                    onSubmit={() => {
                      setCartSubmitModal(false)
                      handleCartSubmission()
                    }}
                  />
                </div>
                {error && <div className="pt-5 text-danger d-flex justify-content-center">
                  {error}
                </div>}
              </>
            </Card.Body>
          </Card>
        </Container>}
    </>
  );
}

const OpenCart = () => {
  const { openCart, error, fetching } = useSelector(state => state.account?.cart) || {}

  if (!openCart) return null
  return (
    <OpenCartSteps 
      cart={openCart} 
      products={openCart?.products} 
      error={error} 
      fetching={fetching}
    />
  )
}

export default OpenCart;
