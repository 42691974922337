/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { toast } from 'react-toastify';
import { call, put, select, delay } from 'redux-saga/effects'
import DashboardTranslationsActions from '../../redux/dashboard/translations'


// GET TRANSLATION FOR ALL CATEGORIES
export function * getCategoriesTranslationsDashboard (api) {
    const response = yield call(api.getCategoriesTranslationsDashboard)
    
    if (response.ok) {
        yield put(DashboardTranslationsActions.getCategoriesTranslationsSuccessDashboard(response?.data))
    } else {
        yield put(DashboardTranslationsActions.getCategoriesTranslationsErrorDashboard())
    }
}

// UPDATE CATEGORIES TRANSLATIONS
export function * updateCategoriesTranslationsDashboard (api, {data}) {
    const response = yield call(api.updateCategoriesTranslationsDashboard, {data})
    
    if (response.ok) {
        toast.success(response?.data?.message)
        yield put(DashboardTranslationsActions.getCategoriesTranslationsRequestDashboard())
    } else {
        yield put(DashboardTranslationsActions.getCategoriesTranslationsErrorDashboard())
    }
}

// AUTOFILL ALL MISSING CATEGORIES TRANSLATIONS
export function * autofillMissingCategoriesTranslationsDashboard (api) {
    const response = yield call(api.autofillMissingCategoriesTranslationsDashboard)
    
    if (response.ok) {
        toast.success(response?.data?.message)
        yield put(DashboardTranslationsActions.getCategoriesTranslationsRequestDashboard())
    } else {
        yield put(DashboardTranslationsActions.getCategoriesTranslationsErrorDashboard())
    }
}