/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { toast } from 'react-toastify'
import { call, put, delay } from 'redux-saga/effects'
import SettingsActions from '../../redux/dashboard/settings'

export function * getPricingSettings(api) {
    const response = yield call(api.getPricingSettings)

    if (response.ok) {
        yield put(SettingsActions.getPricingSettingsSuccess(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(SettingsActions.getPricingSettingsError(message))
    }
}

export function * updatePricingSettings(api, {id, data}) {
    const response = yield call(api.updatePricingSettings, {id, data})

    if (response.ok) {
        toast.success(response?.data?.message)
        yield put(SettingsActions.getPricingSettingsRequest())
    } else {
        yield put(SettingsActions.updatePricingSettingsError())
    }
}

export function * getMetalsSettings(api) {
    const response = yield call(api.getMetalsSettings)

    if (response.ok) {
        yield put(SettingsActions.getMetalsSettingsSuccess(response.data))
    } else {
        yield put(SettingsActions.getMetalsSettingsError())
    }
}

export function * updateMetalSettings(api, {id, data}) {
    const response = yield call(api.updateMetalSettings, {id, data})

    if (response.ok) {
        toast.success(response?.data?.message)
        yield put(SettingsActions.updateMetalSettingsSuccess(response.data))
    } else {
        yield put(SettingsActions.updateMetalSettingsError())
    }
}