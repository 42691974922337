import React, { useCallback } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './redux';
import { useResizeDetector } from "react-resize-detector";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CountrySelectorModal from "./components/CountrySelectorModal";
import "./i18n/i18n";
import routes from "./routes";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import { AuthProvider } from "./contexts/JWTContext";
import { isProduction } from "./services/api";
import useAnalytics from "./utils/useAnalytics";
import { useTranslation } from "react-i18next";
import Footer from "./components/Footer";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";


const App = () => {

  // Google Analytics
  useAnalytics();

  const content = useRoutes(routes);

  const onResize = useCallback((width, height) => {
    if (window.parent) { window.parent.postMessage(height, "*") }
  }, []);

  const {width, height, ref } = useResizeDetector({ onResize });

  const { t, i18n } = useTranslation()
  const locale = i18n?.language;

  return (
    <div ref={ref}>
      <HelmetProvider>
        <Helmet
          titleTemplate={t("ecoWeee") + " | %s"}
          defaultTitle={`${t("ecoWeee")} | ${t("homePageDefaultTitle")}`}
        >
          <html lang={locale}/>
          <meta name="description" content={t("homePageDescription")} />
          <meta name="keywords" content={t("homePageKeywords")} />
          <meta name="robots" content={isProduction ? "index,follow" : "noindex,nofollow"}/>
        </Helmet>
        <Provider store={configureStore().store}>
          <PersistGate loading={null} persistor={configureStore().persistor}>
            <ThemeProvider>
              <SidebarProvider>
                <LayoutProvider>
                  <CountrySelectorModal />
                  <ChartJsDefaults />
                  <AuthProvider>
                    {content}
                    <Footer />
                  </AuthProvider>
                </LayoutProvider>
              </SidebarProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default App;

/* global __DEV__ */
// import {useEffect, useCallback, useRef} from "react";
// import {
//   BrowserRouter,
//   Routes,
//   Route
// } from "react-router-dom";

// import { useResizeDetector } from 'react-resize-detector';

// import your route components too
// import Home from "./containers/Home";
// import About from "./containers/About";
// import NotFound from "./containers/NotFound";
// import Search from "./containers/Search";
// import Product from "./containers/Product";
// import Dashboard from "./containers/Dashboard";
// import TutorialsList from "./components/TutorialsList";
// import ProductUpload from "./containers/ProductUpload";

// const App = () => {

//   const onResize = useCallback(() => {
//     if (window.parent) { window.parent.postMessage(window.document.body.offsetHeight, "*") }
//   }, []);

//   const { ref } = useResizeDetector({ onResize });

//   return (
//     <div ref={ref}>
//       <BrowserRouter>
//         <Routes>
//           {/* <Route path="/" element={<Home />} /> */}
//           <Route path="/" element={<Search />} />
//           <Route path="/search" element={<Search />} />
//           <Route path="/product/:id" element={<Product />} />
//           <Route path="/about" element={<About />} />
//           {/* DASHBOARD */}
//           <Route path='/dashboard' element={<Dashboard />}>
//             <Route index element={<Search />} />
//             <Route path="products" element={<Search />} />
//             <Route path="upload" element={<ProductUpload />}/>
//           </Route>
//           {/* MISSING ROUTE CATCH */}
//           <Route path='*' element={<NotFound />} />
//         </Routes>
//       </BrowserRouter>
//     </div>
//   );
// };

// export default App
