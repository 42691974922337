import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  
  getProductAnalysisRequest: ["id"],
  getProductAnalysisSuccess: ["data"],
  getProductAnalysisError: ["error"],

  createProductAnalysisRequest: ["data"],
  createProductAnalysisSuccess: ["data"],
  createProductAnalysisError: ["error"],

  updateProductAnalysisRequest: ["id", "data"],
  updateProductAnalysisSuccess: null,
  updateProductAnalysisError: ["error"],
  updateProductAnalysisReset: null,

  deleteProductAnalysisRequest: ["id", "product_id"],
  deleteProductAnalysisSuccess: ["id", "message"],
  deleteProductAnalysisError: ["id", "message"],

  selectProductAnalysisRequest: ["id", "product_id"],
  selectProductAnalysisSuccess: ["id", "message"],
  selectProductAnalysisError: ["id", "message"],

  productAnalysisReset: null
})

export const ProductAnalysisTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    productAnalysis: {},
    gettingProductAnalysis: null,
    getProductAnalysisError: null,

    creatingProductAnalysis: false,
    createProductAnalysisSuccess: null,
    createProductAnalysisError: null,

    updatingProductAnalysis: false,
    updateProductAnalysisError: null,
    updateProductAnalysisSuccess: null,

    deletingProductAnalysis: [],
    deleteProductAnalysisSuccess: null,
    deleteProductAnalysisError: null,

    selectingProductAnalysis: false,
    selectProductAnalysisSuccess: null,
    selectProductAnalysisError: null
})

/* ------------- Reducers ------------- */


/* Get Product Analysis */
export const getProductAnalysisRequest = (state) =>
  state.merge({ productAnalysis: {},  gettingProductAnalysis: true, getProductAnalylisError: null})

export const getProductAnalysisSuccess = (state, {data}) =>
  state.merge({ productAnalysis: data, gettingProductAnalysis: false })

export const getProductAnalysisError = (state, {error}) =>
  state.merge({ gettingProductAnalysis: false, getProductAnalylisError: error})



/* Create Product Analysis */
export const createProductAnalysisRequest = (state) =>
  state.merge({ creatingProductAnalysis: true, createProductAnalysisError: null})

export const createProductAnalysisSuccess = (state) =>
  state.merge({ creatingProductAnalysis: false})

export const createProductAnalysisError = (state, {error}) =>
  state.merge({ creatingProductAnalysis: false, createProductAnalysisError: error})


  
/* Update Product analysis */
export const updateProductAnalysisRequest = (state) =>
  state.merge({ updatingProductAnalysis: true, updateProductAnalysisError: null, updateProductAnalysisSuccess: null})

export const updateProductAnalysisSuccess = (state) => {
  let updateProductAnalysisSuccess = "Product analysis successfully updated"
  return state.merge({ updatingProductAnalysis: false, updateProductAnalysisSuccess})
}

export const updateProductAnalysisError = (state, {error}) =>
  state.merge({ updatingProductAnalysis: false, updateProductAnalysisError: error})


/* Delete Product Analysis*/
export const deleteProductAnalysisRequest = (state, {id}) => {
  const deletingProductAnalysis =  [...state.deletingProductAnalysis, id]
  return state.merge({ deletingProductAnalysis, deleteProductAnalysisError: null, deleteProductAnalysisSuccess: null})
}

export const deleteProductAnalysisSuccess = (state, {id, message}) => {
  const deletingProductAnalysis =  [...state.deletingProductAnalysis].filter(el => el != id)
  return state.merge({ deletingProductAnalysis, deleteProductAnalysisSuccess: message})
}

export const deleteProductAnalysisError = (state, {id, error}) => {
  const deletingProductAnalysis =  [...state.deletingProductAnalysis].filter(el => el != id)
  return state.merge({ deletingProductAnalysis, deleteProductAnalysisError: error})
}


/* Select Product Analysis*/
export const selectProductAnalysisRequest = (state) => 
  state.merge({ selectingProductAnalysis: true, selectProductAnalysisError: null, selectProductAnalysisSuccess: null})

export const selectProductAnalysisSuccess = (state, {message}) => 
  state.merge({ selectingProductAnalysis: false, selectProductAnalysisSuccess: message})


export const selectProductAnalysisError = (state, {error}) =>
  state.merge({ selectingProductAnalysis: false, selectProductAnalysisError: error})


/* Reset Product Analysis State */
export const productAnalysisReset = () => INITIAL_STATE



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCT_ANALYSIS_REQUEST]: getProductAnalysisRequest,
  [Types.GET_PRODUCT_ANALYSIS_SUCCESS]: getProductAnalysisSuccess,
  [Types.GET_PRODUCT_ANALYSIS_ERROR]: getProductAnalysisError, 

  [Types.CREATE_PRODUCT_ANALYSIS_REQUEST]: createProductAnalysisRequest,
  [Types.CREATE_PRODUCT_ANALYSIS_SUCCESS]: createProductAnalysisSuccess,
  [Types.CREATE_PRODUCT_ANALYSIS_ERROR]: createProductAnalysisError,

  [Types.UPDATE_PRODUCT_ANALYSIS_REQUEST]: updateProductAnalysisRequest,
  [Types.UPDATE_PRODUCT_ANALYSIS_SUCCESS]: updateProductAnalysisSuccess,
  [Types.UPDATE_PRODUCT_ANALYSIS_ERROR]: updateProductAnalysisError,

  [Types.DELETE_PRODUCT_ANALYSIS_REQUEST]: deleteProductAnalysisRequest,
  [Types.DELETE_PRODUCT_ANALYSIS_SUCCESS]: deleteProductAnalysisSuccess,
  [Types.DELETE_PRODUCT_ANALYSIS_ERROR]: deleteProductAnalysisError,

  [Types.SELECT_PRODUCT_ANALYSIS_REQUEST]: selectProductAnalysisRequest,
  [Types.SELECT_PRODUCT_ANALYSIS_SUCCESS]: selectProductAnalysisSuccess,
  [Types.SELECT_PRODUCT_ANALYSIS_ERROR]: selectProductAnalysisError,

  [Types.PRODUCT_ANALYSIS_RESET]: productAnalysisReset
})