import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  
  getProductCategoriesRequest: null,
  getProductCategoriesSuccess: ["devices"],
  getProductCategoriesError: ["error"],
  addDeviceRequest: null,
  addDeviceSuccess: null,
  addDeviceError: null,
  deleteDeviceRequest: null,
  deleteDeviceSuccess: null,
  deleteDeviceError: null,
  updateDeviceRequest: null,
  addCategoryRequest: null,
  addCategorySuccess: null,
  addCategoryError: null,
  deleteCategoryRequest: null,
  deleteCategorySuccess: null,
  deleteCategoryError: null,
  updateCategoryRequest: null,
  addTypeRequest: null,
  addTypeSuccess: null,
  addTypeError: null,
  deleteTypeRequest: null,
  deleteTypeSuccess: null,
  deleteTypeeError: null,
})

export const ProductCategoriesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  devices: [],
  getProductCategoriesError: null,
  fetching: null
})

/* ------------- Reducers ------------- */


/* Get Product Categories */
export const getProductCategoriesRequest = (state) =>
  state.merge({ devices: [], fetching: true, getProductCategoriesError: null})

export const getProductCategoriesSuccess = (state, {devices}) =>
  state.merge({devices, fetching: false})

export const getProductCategoriesError = (state, {error}) =>
  state.merge({ fetching: false, getProductCategoriesError: error})

/* Add Device */
export const addDeviceRequest = (state) =>
  state.merge({ fetching: true })

export const addDeviceSuccess = (state) =>
  state.merge({fetching: false})

export const addDevicesError = (state) =>
  state.merge({ fetching: false})

/* Delete Device */
export const deleteDeviceRequest = (state) =>
  state.merge({ fetching: true })

export const deleteDeviceSuccess = (state) =>
  state.merge({fetching: false})

export const deleteDevicesError = (state) =>
  state.merge({ fetching: false})

/* Update Device */
export const updateDeviceRequest = (state) =>
  state.merge({ fetching: true})


/* Add Category */
export const addCategoryRequest = (state) =>
  state.merge({ fetching: true })

export const addCategorySuccess = (state) =>
  state.merge({fetching: false})

export const addCategoryError = (state) =>
  state.merge({ fetching: false})

/* Delete Category */
export const deleteCategoryRequest = (state) =>
  state.merge({ fetching: true })

export const deleteCategorySuccess = (state) =>
  state.merge({fetching: false})

export const deleteCategoryError = (state) =>
  state.merge({ fetching: false})

/* Update Category */
export const updateCategoryRequest = (state) =>
  state.merge({ fetching: true})


/* Add Type */
export const addTypeRequest = (state) =>
  state.merge({ fetching: true })

export const addTypeSuccess = (state) =>
  state.merge({fetching: false})

export const addTypeError = (state) =>
  state.merge({ fetching: false})

/* Delete Type */
export const deleteTypeRequest = (state) =>
  state.merge({ fetching: true })

export const deleteTypeSuccess = (state) =>
  state.merge({fetching: false})

export const deleteTypeError = (state) =>
  state.merge({ fetching: false})



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCT_CATEGORIES_REQUEST]: getProductCategoriesRequest,
  [Types.GET_PRODUCT_CATEGORIES_SUCCESS]: getProductCategoriesSuccess,
  [Types.GET_PRODUCT_CATEGORIES_ERROR]: getProductCategoriesError,
  [Types.ADD_DEVICE_REQUEST]: addDeviceRequest,
  [Types.ADD_DEVICE_SUCCESS]: addDeviceSuccess,
  [Types.ADD_DEVICE_ERROR]: addDevicesError,
  [Types.UPDATE_DEVICE_REQUEST]: updateDeviceRequest,
  [Types.DELETE_DEVICE_REQUEST]: deleteDeviceRequest,
  [Types.DELETE_DEVICE_SUCCESS]: deleteDeviceSuccess,
  [Types.DELETE_DEVICE_ERROR]: deleteDevicesError,
  [Types.ADD_CATEGORY_REQUEST]: addCategoryRequest,
  [Types.ADD_CATEGORY_SUCCESS]: addCategorySuccess,
  [Types.ADD_CATEGORY_ERROR]: addCategoryError,
  [Types.UPDATE_CATEGORY_REQUEST]: updateCategoryRequest,
  [Types.ADD_TYPE_REQUEST]: addTypeRequest,
  [Types.ADD_TYPE_SUCCESS]: addTypeSuccess,
  [Types.ADD_TYPE_ERROR]: addTypeError
})