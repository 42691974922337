import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Image } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { gapi } from 'gapi-script';
import googleLogo from '../../assets/img/social/google-logo.png';
import facebookLogo from '../../assets/img/social/facebook-logo.png';
import { useTranslation } from "react-i18next";
import { getFlagEmoji } from "../../utils/commons";
import allCountries from "country-region-data/data.json";

// GOOGLE LOGIN CLIENT ID
const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID

// FACEBOOK APP ID
const facebookAppId = process.env.REACT_APP_FACEBOOK_AUTH_APP_ID

function SignUp() {
  const { t } = useTranslation()
  const { signUp, googleSignIn, facebookSignIn } = useAuth();

  const topCountries = ["TH"]
  const countries = allCountries?.map(({countryName, countryShortCode}) => ({countryName, countryShortCode}))
                    .sort((x) => topCountries.includes(x.countryShortCode) ? -1 : 0)
                    .filter(el => el.countryShortCode != selectedCountry)


  const [selectedCountry, setSelectedCountry] = useState(null)

  useEffect(() => {
    const initClient = () => gapi.auth2.init({ clientId, scope: '' });
    gapi.load('client:auth2', initClient);
  });

  const onGoogleSuccess = async (res) => {
    try {
      googleSignIn(res?.tokenId)
    } catch (error) {
    }
  }

  const onGoogleFailure = (err) => {
    console.log('failed', err);
  };

  const responseFacebook = async (response) => {
    try {
      if (response?.accessToken) {
        const {first_name, last_name, email} = response
        facebookSignIn({first_name, last_name, email})
      }
    } catch (error) {
      console.log(error)
    }
  };

  
  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirmPassword: "",
        country: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required(t('firstNameIsRequired')),
        last_name: Yup.string().required(t('lastNameIsRequired')),
        email: Yup.string()
          .email(t("mustBeAValidEmail"))
          .max(255)
          .required(t("emailIsRequired")),
        password: Yup.string().max(255).min(4).required(t("passwordIsRequired")),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], t("passwordMustMatch")).required(t("confirmPasswordIsRequired")),
        country: Yup.string().required(t("countryIsRequired"))
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const { email, password, first_name, last_name, country } = values
        try {
          await signUp({email, password, first_name, last_name, country});
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* <Alert className="my-3" variant="primary">
            <div className="alert-message">
              Use <strong>demo@bootlab.io</strong> and{" "}
              <strong>unsafepassword</strong> to sign in
            </div>
          </Alert> */}
          {errors.submit && (
            <Alert className="mx-5 mb-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <div className="mb-4 mx-5">

            <Form.Group className="mb-3">
              <Form.Label className="fw-bolder">
                {t("firstName")}
              </Form.Label>
              <Form.Control
                disabled={isSubmitting}
                type="text"
                name="first_name"
                placeholder={t("firstName")}
                value={values.first_name}
                isInvalid={Boolean(touched.first_name && errors.first_name)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.first_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bolder">
                {t("lastName")}
              </Form.Label>
              <Form.Control
                disabled={isSubmitting}
                type="text"
                name="last_name"
                placeholder={t("lastName")}
                value={values.last_name}
                isInvalid={Boolean(touched.last_name && errors.last_name)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.last_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bolder">
                {t("email")}
              </Form.Label>
              <Form.Control
                disabled={isSubmitting}
                size="lg"
                type="email"
                name="email"
                placeholder={t("email")}
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bolder">
                {t("password")}
              </Form.Label>
              <Form.Control
                disabled={isSubmitting}
                size="lg"
                type="password"
                name="password"
                placeholder={t("password")}
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bolder">
                {t("confirmPassword")}
              </Form.Label>
              <Form.Control
                disabled={isSubmitting}
                size="lg"
                type="password"
                name="confirmPassword"
                placeholder={t("password")}
                value={values.confirmPassword}
                isInvalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.confirmPassword && (
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              )}
              {/* <div className="mt-2">
                <Link 
                  to="/auth/reset-password" 
                  className="mt-2" 
                  style={{fontSize: "0.7rem"}}
                >
                  Forgot password?
                </Link>
              </div> */}
            </Form.Group>

            <Form.Group>
              <Form.Label className="fw-bolder">
                {t("chooseAcountry")}
              </Form.Label>
              <Form.Select 
                disabled={isSubmitting}
                onChange={handleChange}
                name="country" 
                id="country"
                placeholder={"--"}
                className={"mt-3 mb-4"}
                isInvalid={Boolean(touched.country && errors.country)}
              >
                <option value={selectedCountry}>
                  {selectedCountry ? `${getFlagEmoji(selectedCountry)} ${allCountries?.find(el => el.countryShortCode == selectedCountry)?.countryName}` : null}
                </option>
                {countries?.map(({countryShortCode, countryName}) => (
                  <option 
                    value={countryShortCode} 
                    key={countryShortCode}
                  >
                    {countryShortCode ? `${getFlagEmoji(countryShortCode)} ${countryName}` : null}
                  </option>
                ))}
              </Form.Select>
              {!!touched.country && (
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* <div>
              <Form.Check
                type="checkbox"
                id="rememberMe"
                label="Remember me next time"
                defaultChecked
              />
            </div> */}
          </div>
          <div className="text-center mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {t("signUp")}
            </Button>
          </div>
          <div className="m-4 mt-4 fw-bolder text-center">
            {t("orSignUpWithOneClick")}
          </div>
          <div className="d-flex justify-content-center">
            <div className="m-2">
              <GoogleLogin
                clientId={clientId}
                buttonText="Sign in with Google"
                onSuccess={onGoogleSuccess}
                onFailure={onGoogleFailure}
                cookiePolicy={'single_host_origin'}
                // isSignedIn={true}
                // style={{width: 300}}
                render={({disabled, onClick}) => (
                  <Button
                    onClick={onClick} 
                    disabled={disabled || isSubmitting}
                    className={"text-black fs-3 shadow-lg"}
                    style={{
                      backgroundColor: "#fff", 
                      borderColor: "#fff"
                    }}
                  >
                    <Image
                      src={googleLogo} 
                      style={{width: "3rem"}}
                    />
                  </Button>
                )}
              />
            </div>
            <div className="m-2">
              <FacebookLogin
                appId={facebookAppId}
                fields="name,email,first_name,last_name"
                autoLoad={false}
                callback={responseFacebook} 
                icon="fa-facebook"
                disableMobileRedirect={true}
                render={({isDisabled, onClick}) => (
                  <Button
                    onClick={onClick} 
                    disabled={isDisabled || isSubmitting}
                    className={"text-black fs-3 shadow-lg"}
                    style={{
                      backgroundColor: "#fff", 
                      borderColor: "#fff",
                    }}
                  >
                    <Image
                      src={facebookLogo} 
                      style={{width: "3rem", padding: "0.32rem"}}
                    />  
                  </Button>
                )}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignUp;
