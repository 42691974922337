import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  Pagination
} from 'react-bootstrap';
import { SearchTypes } from '../redux/search';



const PaginationButtons = () => {
  
    const dispatch = useDispatch();
    const { currentPage, totalPages } = useSelector(state => state.search);
  
    const [pageArray, setPageArray] = useState([]);
    
    const setPageNumberAndSearch = (page) => dispatch({ type: SearchTypes.SEARCH_REQUEST, page })
    
    
    useEffect(()=> {

        var pageArr = [];
        if (totalPages > 1) {
            if (totalPages <= 9) {
            var i = 1;
            while (i <= totalPages) {
                pageArr.push(i);
                i++;
            }
            } else {
            
            if (currentPage <= 5) pageArr = [1, 2, 3, 4, 5, 6, 7, 8, "", totalPages];
            else if (totalPages - currentPage <= 4)
                pageArr = [
                1,
                "",
                totalPages - 7,
                totalPages - 6,
                totalPages - 5,
                totalPages - 4,
                totalPages - 3,
                totalPages - 2,
                totalPages - 1,
                totalPages
                ];
            else
                pageArr = [
                1,
                "",
                currentPage - 3,
                currentPage - 2,
                currentPage - 1,
                currentPage,
                currentPage + 1,
                currentPage + 2,
                currentPage + 3,
                "",
                totalPages
                ];
            }
        }
        setPageArray(pageArr);
      
    },[totalPages, currentPage])
  


    return (
        <Pagination className='m-0'>
            {pageArray.map((ele, ind) => {
                const toReturn = [];

                if (ind === 0 && currentPage > 1) {
                    toReturn.push(
                    <Pagination.First
                        key={"firstpage"}
                        onClick={() => setPageNumberAndSearch(1)}
                    />
                    );
                    toReturn.push(
                    <Pagination.Prev
                        key={"prevpage"}
                        onClick={() => setPageNumberAndSearch(currentPage - 1)}
                    />
                    );
                }

                if (ele === "") toReturn.push(<Pagination.Ellipsis key={ind} />);
                else
                toReturn.push(
                    <Pagination.Item
                    key={ind}
                    active={currentPage === ele}
                    onClick={
                        currentPage === ele
                        ? () => {}
                        : () => {
                            setPageNumberAndSearch(ele)
                            }
                    }
                    >
                    {ele}
                    </Pagination.Item>
                );

                if (ind === pageArray.length - 1 && currentPage < totalPages) {
                toReturn.push(
                    <Pagination.Next
                    key={"nextpage"}
                    onClick={
                        currentPage === ele
                        ? () => {}
                        : () => {
                            setPageNumberAndSearch(currentPage + 1)
                            }
                    }
                    />
                );

                toReturn.push(
                    <Pagination.Last
                    key={"lastpage"}
                    onClick={
                        currentPage === ele
                        ? () => {}
                        : () => {
                            setPageNumberAndSearch(ele)
                            }
                    }
                    />
                );
                }

                return toReturn;
            })}
        </Pagination>
    )
}

  
export default PaginationButtons
