import _ from 'lodash'
import Immutable from 'seamless-immutable'
import axios from "axios"
import { memo, useEffect, useState } from "react"
import { Image, Card, Alert, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import { DashboardProductTypes } from '../redux/dashboard/product';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { API, BASE_URL } from "../services/api"
import { placeholderImage } from './ui/Utils';
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";

const imageEndpoint = `${API}/dashboard/product/image`

const ImageBox = memo(({id, url, product_id, newProduct, order, code, toggleNewImage}) => {
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState()
  const [progress, setProgress] = useState()
  const [preview, setPreview] = useState()
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  

  useEffect(() => {
    if (!selectedFile) return setPreview(undefined)
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const submitHandler = e => {
    e.preventDefault() //prevent the form from submitting
    setProgress(null)
    setSuccess(null)
    setError(null)
    if (!selectedFile || !preview) return setError("Please select file")
    let formData = new FormData()
    
    if (id) formData.append("id", id)
    if (product_id) formData.append("product_id", product_id)
    if (newProduct) formData.append("newProduct", newProduct)
    if (code) formData.append("code", code?.toLowerCase())
    if (order) formData.append("order", order)
    if (url) formData.append("url", url)
    if (selectedFile) formData.append("file", selectedFile)   

    setProgress(30)
    axios.post(imageEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization":  `Bearer ${window.localStorage.getItem("accessToken")}`
      },
      onUploadProgress: data => setProgress(Math.round((100 * data.loaded) / data.total))
    })
    .then((res) => {
      const data = res?.data
      if (data && newProduct) {
        dispatch({ type: DashboardProductTypes.ADD_PRODUCT_IMAGE_DASHBOARD, data })
        toggleNewImage()
        setProgress(0)
      } else {
        setTimeout(() => {
          setProgress(100)
          setTimeout(() => {
            setProgress()
            setSuccess("Image successfully uploaded")
          }, 100);
        }, 700);
      }
    })
    .catch(error => {
      setProgress()
      const { code } = error?.response?.data
      console.log(error?.response?.data)
      switch (code) {
        case "FILE_MISSING":
          setError("Please select a file before uploading!")
          break
        case "LIMIT_FILE_SIZE":
          setError("File size is too large. Please upload files below 1MB!")
          break
        case "INVALID_TYPE":
          setError("This file type is not supported! Only .png, .jpg and .jpeg files are allowed")
          break
        default:
          setError("Sorry! Something went wrong. Please try again later")
          break
      }
    })
  }

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }
    setSelectedFile(e.target.files[0])
  } 

  const deleteImage = () => {
    if (!id && !code && !order && !product_id) return setError("Cannot delete")

    if (selectedFile || preview) {
      setPreview()
      setSelectedFile()
    } else if (window.confirm('Are you sure you want to delete this image?')) {
      setProgress(30)
      setTimeout(() => setProgress(50), 200);
      setTimeout(() => setProgress(80), 400);
      axios.delete(
        imageEndpoint, 
        {
          headers: {"Authorization":  `Bearer ${window.localStorage.getItem("accessToken")}`},
          data: {id, code: code?.toLowerCase(), product_id, order}
        }
      )
      .then((res) => {
        setTimeout(() => {
          setProgress(100)
          setTimeout(() => {
            setProgress()
            setSuccess("Image successfully deleted")
            const id = res?.data?.id
            if (id) {
              dispatch({ type: DashboardProductTypes.DELETE_PRODUCT_IMAGE_DASHBOARD, id })
            }
          }, 300);
        }, 700);
      })
      .catch(error => {
        const { code } = error?.response?.data
        console.log(error?.response?.data)
        setError("Sorry! Something went wrong. Please try again later")
      })
    }
  }

  const imageStyle = {
    width: "100%", 
    objectFit: "contain",
    height: '100%',
    maxHeight: '200px'
  }

  if (!id) return (
    <Card className='p-4 me-3 rounded shadow-lg'>
      <Form
        action={url}
        method="post"
        encType="multipart/form-data"
        onSubmit={submitHandler}
      >
        <Image
          src={preview || placeholderImage} 
          className={"rounded"}
          style={imageStyle}
        />
        <Form.Group className='my-3'>
            {/* <Form.Label>Select a File</Form.Label> */}
            <Form.Control
              type="file" 
              onChange={onSelectFile}
              disabled={progress}
            />
        </Form.Group>
        <Form.Group className='d-flex justify-content-between align-items-center'>
          {selectedFile && !progress &&
            <Button 
              variant="primary" 
              type="submit" 
              disabled={progress}
            >
              <FontAwesomeIcon icon={faUpload} /> 
              Upload
            </Button>}
          {preview && 
            <Button 
              variant="primary" 
              onClick={deleteImage} 
              className='align-middle' 
              disabled={progress}
            >
              <FontAwesomeIcon icon={faTrashAlt}/>
            </Button>}
        </Form.Group>
        {success && <Alert variant="success" className='mt-2'>{success}</Alert>}
        {error && <Alert variant="danger" className='mt-2'>{error}</Alert>}
        {!error && progress && <ProgressBar now={progress} label={`${progress}%`}/>}
      </Form>
      {/* <small>
          For best results, use an image at least 128px by 128px in .jpg
          format
      </small> */}  
    </Card>
  )

  return (
    <Card className='p-4 me-3 rounded shadow-lg'>
      <Form
        action={url}
        method="post"
        encType="multipart/form-data"
        onSubmit={submitHandler}
      >
        <Image
          src={preview || (url ? (BASE_URL + url) : null)} 
          className={"rounded"}
          style={imageStyle}
        />
        <Form.Group className='my-3 d-flex justify-content-between align-items-center'>
          <Form.Control
            type="file" 
            onChange={onSelectFile}
            disabled={progress}
            className={"me-2"}
          />
          <Button 
            variant="primary" 
            onClick={deleteImage} 
            className='align-middle' 
            disabled={progress}
          >
            <FontAwesomeIcon icon={faTrashAlt}/>
          </Button>
        </Form.Group>
        <div className='d-flex justify-content-center'>
          {selectedFile && !progress &&
              <Button 
                className='mt-2' 
                variant="primary" 
                type="submit" 
                disabled={progress}
              >
                <FontAwesomeIcon 
                  icon={faUpload}
                  onClick={deleteImage}  
                /> 
                Upload
              </Button>}
        </div>
        {success && <Alert variant="success" className='mt-2'>{success}</Alert>}
        {error && <Alert variant="danger" className='m-0'>{error}</Alert>}
        {!error && progress && <ProgressBar now={progress} label={`${progress}%`}/>}
      </Form>
      {/* <small>
          For best results, use an image at least 128px by 128px in .jpg
          format
      </small> */}  
    </Card>
  )
})

const SortableListItem = SortableElement(({ item }) => {

  const code = useSelector(state => state.dashboard.product.product.code);
  const product_id = useSelector(state => state.dashboard.product.product.id);

  return <ImageBox 
            product_id={product_id}
            id={item?.id}
            url={item?.url} 
            order={item?.order}
            code={code}
        />;
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <Row xs={1} sm={2} md={3} xxl={4}>
      {items.map((item, index) => 
        <SortableListItem 
          axis="xy" 
          key={index} 
          index={index} 
          item={item} 
        />
      )}
    </Row>
  );
});

const DashboardProductImages = () => {
  const dispatch = useDispatch();
  const [addImage, setAddImage] = useState()

  const code = useSelector(state => state.dashboard.product.product.code);
  const images = useSelector(state => state.dashboard.product.product.images);
  const product_id = useSelector(state => state.dashboard.product.product.id);

  const [items, setItems] = useState(images || []);

  const newImagesOrder = !_.isEqual(images, items)

  const toggleNewImage = () => setAddImage(!addImage)

  const saveNewImagesOrder = () => {
    const sortArray = items?.map((el, i) => ({id: el?.id, order: i+1}))
    dispatch({ 
      type: DashboardProductTypes.SORT_PRODUCT_IMAGES_REQUEST_DASHBOARD, 
      data: { product_id, sortArray} 
    })
  }
  
  useEffect(() => {
    setItems(images || [])
  }, [images])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(Immutable.asMutable(items), oldIndex, newIndex));
  };

  return (
    <>
      <SortableList axis={"xy"} items={items} onSortEnd={onSortEnd} />
      <Row xs={1} sm={2} md={3} xxl={4}>
        {(addImage || images?.length < 1) && 
          <ImageBox 
            product_id={product_id}
            newProduct={true} 
            order={images?.length + 1}
            code={code}
            toggleNewImage={toggleNewImage}
          />}
      </Row>
      {images?.length > 0 &&
        <Button 
          variant="primary"
          onClick={toggleNewImage}
        >
          <FontAwesomeIcon 
            icon={faPlus} 
            onClick={toggleNewImage} 
          /> 
          Add an image
        </Button>}
      {newImagesOrder &&
        <Button 
          variant="primary" 
          className='mx-2' 
          onClick={saveNewImagesOrder}
        >
          Save new images order
        </Button>}
    </>
  )
}

export default DashboardProductImages