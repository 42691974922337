import _ from 'lodash'
import moment from 'moment'
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Pagination, Card, Spinner, Form, Row, Col, Button, InputGroup, Container, ListGroup, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { API, BASE_URL } from "../services/api"
import { placeholderImage } from './ui/Utils';
import { toast } from 'react-toastify';

const cartImagesEndpoint = `${API}/dashboard/cart/image`

const ImageUploadBox = ({cart_id, type, url}) => {

    const [selectedFile, setSelectedFile] = useState()
    const [progress, setProgress] = useState()
    const [preview, setPreview] = useState()
    const [imageUrl, setImageUrl] = useState(url)

    useEffect(() => {
        return () => preview && URL.revokeObjectURL(preview)
      }, [])

    const submitHandler = e => {
        e.preventDefault() //prevent the form from submitting
        setProgress(null)
        if (!selectedFile || !preview) return toast.error("Please select file")
        if (!cart_id || !type) return toast.error("Missing params")
        let formData = new FormData()
        
        if (cart_id) formData.append("cart_id", cart_id)
        if (type) formData.append("type", type)
        if (selectedFile) formData.append("file", selectedFile)

        const accessToken = window.localStorage.getItem("accessToken"); 

        setProgress(30)
        axios.post(cartImagesEndpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization":  `Bearer ${accessToken}`
            },
            onUploadProgress: data => setProgress(Math.round((100 * data.loaded) / data.total))
        })
        .then((res) => {
            setTimeout(() => {
                setProgress(100)
                setTimeout(() => {
                    setProgress()
                    setPreview()
                    setSelectedFile()
                    setImageUrl(BASE_URL + res?.data?.url)
                    toast.success("Image successfully uploaded")
                }, 100);
            }, 700);
        })
        .catch(error => {
            setProgress()
            toast.error("Upload image error")
        })
    }

    const deleteImage = () => {
        if (!cart_id || !type) return toast.error("Cannot delete")
    
        if (selectedFile || preview) {
          setPreview()
          setSelectedFile()
        } else if (window.confirm('Are you sure you want to delete this image?')) {
          setProgress(30)
          setTimeout(() => setProgress(50), 200);
          setTimeout(() => setProgress(80), 400);
          axios.delete(
            `${cartImagesEndpoint}`, 
            {
                headers: {"Authorization":  `Bearer ${window.localStorage.getItem("accessToken")}`},
                data: {cart_id, type}
            }
        )
          .then((res) => {
            setTimeout(() => {
              setProgress(100)
              setTimeout(() => {
                setProgress()
                setPreview()
                setSelectedFile()
                setImageUrl()
                toast.success("Image successfully deleted")
              }, 300);
            }, 700);
          })
          .catch(error => {
            setProgress()
            toast.error("Delete image error")
          })
        }
      }

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return setSelectedFile(undefined)
        }
        setSelectedFile(e.target.files[0])
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setPreview(objectUrl)
    } 

    
    return (
        <Card.Body className='p-4 me-3 rounded shadow-lg'>
            <Form
                action={imageUrl}
                method="post"
                encType="multipart/form-data"
                onSubmit={submitHandler}
            >
                <Image
                    src={preview || imageUrl || placeholderImage} 
                    className={"rounded"}
                    style={{
                        width: "100%", 
                        objectFit: "contain",
                        height: '100%',
                        maxHeight: '200px'
                    }}
                />
                <Form.Group className='my-3'>
                    <Form.Label>Select a File</Form.Label>
                    <Form.Control
                        type="file" 
                        onChange={onSelectFile}
                        disabled={progress}
                    />
                </Form.Group>
                <Form.Group className='d-flex justify-content-between align-items-center'>
                {selectedFile &&
                    <Button 
                        variant="primary" 
                        type="submit" 
                        disabled={progress}
                    >
                    <FontAwesomeIcon icon={faUpload} /> 
                        {url ? "Replace" : "Upload"}
                    </Button>}
                {(preview || imageUrl) && 
                    <Button 
                        variant="primary" 
                        onClick={deleteImage} 
                        className='align-middle' 
                        disabled={progress}
                    >
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </Button>}
                </Form.Group>
            </Form>
        </Card.Body>
    )
}


const DashboardCartImages = ({cart_id, images}) => (
    <Card className='mt-3 p-0'>
        <h3 className='p-3 bg-light'>
            Uploads
        </h3>
        <Row className='p-3'>
            {['material', 'report', 'slip']?.map(el => {
                let url = null
                const imageUrl = Array.isArray(images) && images?.find(img => img?.type == el)?.url
                if (imageUrl) url = BASE_URL + imageUrl 
                return (
                    <Col className='mt-3' sm key={el}>
                        <ImageUploadBox 
                            type={el}
                            cart_id={cart_id}
                            url={url}
                        />
                    </Col>
                )
            })}
        </Row>
    </Card>
)

export default DashboardCartImages;