import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  devicesSectionsRequest: null,
  devicesSectionsSuccess: ["devicesSections"],
  devicesSectionsFailure: ["error"],
  filtersRequest: null,
  filtersSuccess: ["filters"],
  filtersFailure: ["filtersError"],
  setSearchField: ["searchField"],
  searchRequest: null,
  searchSuccess: ["results"],
  searchFailure: ["searchError"],
  addActiveFilter: ["category", "filter"],
  removeActiveFilter: ["category", "filter"],
  setPriceFilter: null,
  setDeviceFilter: null,
  resetActiveFilters: null,
  productCreated: ["data"],
  productUpdated: ["data"],
  productDeleted: ["id"]
})

export const SearchTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null, 
  devicesSections: [],
  devicesSecttionsError: null,
  fetchingFilters: false,
  filtersError: null,
  searchError: null,
  searchField: "",
  filters: [],
  activeFilters: {},
  products: [],
  currentPage: 1,
  totalItems: null,
  totalPages: null
})

/* ------------- Reducers ------------- */

/* Devices Sections */
export const devicesSectionsRequest = state => 
  ({ ...state, fetching: true, devicesSecttionsError: null, devicesSections: [] })

export const devicesSectionSuccess = (state, {devicesSections}) => 
  ({ ...state, fetching: false, devicesSections })


export const devicesSectionsFailure = (state, {error}) =>
  ({...state, fetching: false, devicesSecttionsError: error, devicesSections: [] })


/* Filters */
export const filtersRequest = state =>
  ({...state, fetchingFilters: false, filtersError: null, filters: [] })

export const filtersSuccess = (state, {filters}) => 
  ({...state, fetchingFilters: false, filtersError: null, filters })


export const filtersFailure = (state, {error}) =>
  ({...state, fetchingFilters: false, filtersError: error })

export const addActivefilter = (state, {category, filter}) => {
  let prevCategoryArray = state.activeFilters[category]
  return ({ 
    ...state,
    activeFilters: {
      ...state.activeFilters,
      [category]: prevCategoryArray ? [...new Set(prevCategoryArray.concat(filter))] : [filter]
    }
  })
}
  
export const removeActiveFilter = (state, {category, filter}) => {
  let prevCategoryArray = state.activeFilters[category]
  return (
    ({...state, 
      activeFilters: {
        ...state.activeFilters,
        [category]: [...prevCategoryArray].filter(el => el !== filter)
      }
    })
  )
}

export const setPriceFilter = (state, {price}) => 
  ({...state, activeFilters: {...state.activeFilters, price }})

export const setDeviceFilter = (state, {device_id}) => 
  ({...state, activeFilters: {...state.activeFilters, device_id }})

export const resetActiveFilters = (state) => 
  ({...state, 
    searchField: "", 
    activeFilters: {},
    products: [],
    currentPage: 0,
    totalItems: null,
    totalPages: null
  })

/* Search */
export const setSearchField = (state, {searchField}) => 
  ({...state, searchField })

export const searchRequest = (state, {page}) => {
  const currentPage = page || 1
  return ({...state, fetching: true, searchError: null, products: [], currentPage })
}

export const searchSuccess = (state, {results}) => {
  const { products, currentPage, totalItems, totalPages } = results
  return ({...state, fetching: false, searchError: null, products, currentPage, totalItems, totalPages })
}

export const searchFailure = (state, {searchError}) =>
  ({...state, fetchingFilters: false, searchError, products: [], fetching: false })


/* Products */
export const productCreated = (state, {data}) => 
  ({...state, products: [...data, ...state.products]})

export const productUpdated = (state) => {
  // alert(JSON.stringify(data))
  return ({...state })
}

export const productDeleted = (state, {id}) => {
  return ({...state, products: [...state.products]?.filter(el => el?.id != id)})
}



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DEVICES_SECTIONS_REQUEST]: devicesSectionsRequest,
  [Types.DEVICES_SECTIONS_SUCCESS]: devicesSectionSuccess,
  [Types.DEVICES_SECTIONS_FAILURE]: devicesSectionsFailure,
  [Types.SET_SEARCH_FIELD]: setSearchField,
  [Types.SEARCH_REQUEST]: searchRequest,
  [Types.SEARCH_SUCCESS]: searchSuccess,
  [Types.SEARCH_FAILURE]: searchFailure,
  [Types.SET_PRICE_FILTER]: setPriceFilter,
  [Types.FILTERS_REQUEST]: filtersRequest,
  [Types.FILTERS_SUCCESS]: filtersSuccess,
  [Types.FILTERS_FAILURE]: filtersFailure,
  [Types.ADD_ACTIVE_FILTER]: addActivefilter,
  [Types.REMOVE_ACTIVE_FILTER]: removeActiveFilter,
  [Types.RESET_ACTIVE_FILTERS]: resetActiveFilters,
  [Types.PRODUCT_CREATED]: productCreated,
  [Types.PRODUCT_UPDATED]: productUpdated,
  [Types.PRODUCT_DELETED]: productDeleted,
})
