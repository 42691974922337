import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getMetalsSettingsRequest: null,
  getMetalsSettingsSuccess: ["data"],
  getMetalsSettingsError: ["error"],

  setMetalSettingsValue: ["id", "field", "value"],
  updateMetalSettingsRequest: ["id", "data"],
  updateMetalSettingsSuccess: ["data"],
  updateMetalSettingsError: ["error"],

  getPricingSettingsRequest: null,
  getPricingSettingsSuccess: ["data"],
  getPricingSettingsError: null,
  updatePricingSettingsRequest: ["id", "data"],
  updatePricingSettingsSuccess: null,
  updatePricingSettingsError: null
})

export const SettingsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,

  metalsSettings: [],
  gettingMetalsSettings: false,
  getMetalsSettingsError: null,

  updatingMetalSettings: false,
  updateMetalSettingsError: null,

  pricing: {
    selling: { },
    buying: { }
  }

})

/* ------------- Reducers ------------- */
/* Get Metals Settings */
export const getMetalsSettingsRequest = (state) =>
  state.merge({ metalsSettings: [], gettingMetalsSettings: true, getMetalsSettingsError: null})

export const getMetalsSettingsSuccess = (state, {data}) => 
  state.merge({ metalsSettings: data, gettingMetalsSettings: false, getMetalsSettingsError: null})

export const getMetalsSettingsError = (state, {error}) =>
  state.merge({ gettingMetalsSettings: false, getMetalsSettingsError: error})


/* Update Metals Settings */
export const setMetalSettingsValue = (state, {id, field, value}) => {
  const metalsSettings = [...state.metalsSettings].map(obj => 
    obj.id == id ? {...obj, [field]: value} : obj
  );
  return state.merge({ metalsSettings })
}

export const updateMetalSettingsRequest = (state) =>
  state.merge({ updatingMetalsSettings: true, updateMetalsSettingsError: null})

export const updateMetalSettingsSuccess = (state) => 
  state.merge({ updatingMetalsSettings: true, updateMetalsSettingsError: null})

export const updateMetalSettingsError = (state, {error}) =>
  state.merge({ updatingMetalsSettings: true, updateMetalsSettingsError: null})
  

/*  Settings */
export const getPricingSettingsRequest = (state) =>
  state.merge({ fetching: true, pricing: {} })

export const getPricingSettingsSuccess = (state,  {data}) => {
  const pricing = {
    selling: data?.find(el => el?.type == "selling"),
    buying: data?.find(el => el?.type == "buying")
  }
  return state.merge({ fetching: false, pricing})
}

export const getPricingSettingsError = (state) =>
  state.merge({ fetching: false })

export const updatePricingSettingsRequest = (state) =>
  state.merge({ fetching: true })

export const updatePricingSettingsError = (state) =>
  state.merge({ fetching: false })


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_METALS_SETTINGS_REQUEST]: getMetalsSettingsRequest,
  [Types.GET_METALS_SETTINGS_SUCCESS]: getMetalsSettingsSuccess,
  [Types.GET_METALS_SETTINGS_ERROR]: getMetalsSettingsError,

  [Types.SET_METAL_SETTINGS_VALUE]: setMetalSettingsValue,
  [Types.UPDATE_METAL_SETTINGS_REQUEST]: updateMetalSettingsRequest,
  [Types.UPDATE_METAL_SETTINGS_SUCCESS]: updateMetalSettingsSuccess,
  [Types.UPDATE_METAL_SETTINGS_ERROR]: updateMetalSettingsError,

  [Types.GET_PRICING_SETTINGS_REQUEST]: getPricingSettingsRequest,
  [Types.GET_PRICING_SETTINGS_SUCCESS]: getPricingSettingsSuccess,
  [Types.GET_PRICING_SETTINGS_ERROR]: getPricingSettingsError,
  [Types.UPDATE_PRICING_SETTINGS_REQUEST]: updatePricingSettingsRequest,
  [Types.UPDATE_PRICING_SETTINGS_ERROR]: updatePricingSettingsError
})