module.exports = {
    en: {name: "English", flag: "gb", popular: true, file: () => require("./translations/en.json")},
    es: {name: "Spanish", flag: "es", popular: true, file: () => require("./translations/es.json")},
    fr: {name: "French", flag: "fr", popular: true, file: () => require("./translations/fr.json")},
    zh: {name: "Chinese", flag: "cn", popular: true, file: () => require("./translations/zh.json")},
    pt: {name: "Portuguese", flag: "pt", popular: true, file: () => require("./translations/pt.json")},
    de: {name: "German", flag: "de", popular: true, file: () => require("./translations/de.json")},
    it: {name: "Italian", flag: "it", popular: true, file: () => require("./translations/it.json")},
    ru: {name: "Russian", flag: "ru", popular: true, file: () => require("./translations/ru.json")},
    ja: {name: "Japanese", flag: "jp", popular: true, file: () => require('./translations/ja.json')},
    hi: {name: "Hindi", flag: "in", popular: true, file: () => require('./translations/hi.json')},
    nl: {name: "Dutch", flag: "nl", popular: true, file: () => require('./translations/nl.json')},
    ar: {name: "Arabic", flag: "ae", popular: true, file: () => require('./translations/ar.json')},
    pl: {name: "Polish", flag: "pl", file: () => require('./translations/pl.json')},
    bg: {name: "Bulgarian", flag: "bg", file: () => require('./translations/bg.json')},
    cs: {name: "Czech", flag: "cz", file: () => require('./translations/cs.json')},
    et: {name: "Estonian", flag: "ee", file: () => require('./translations/et.json')},
    id: {name: "Indonesian", flag: "id", file: () => require('./translations/id.json')},
    ko: {name: "Korean", flag: "kr", file: () => require('./translations/ko.json')},
    ms: {name: "Malay", flag: "my", file: () => require('./translations/ms.json')},
    no: {name: "Norwegian", flag: "no", file: () => require('./translations/no.json')},
    sl: {name: "Slovenian", flag: "si", file: () => require('./translations/sl.json')},
    th: {name: "Thai", flag: "th", file: () => require('./translations/th.json')},
    da: {name: "Danish", flag: "dk", file: () => require('./translations/da.json')},
    el: {name: "Greek", flag: "gr", file: () => require('./translations/el.json')},
    fi: {name: "Finnish",flag: "fi", file: () => require('./translations/fi.json')},
    he: {name: "Hebrew", flag: "il", file: () => require('./translations/he.json')},
    hu: {name: "Hungarian", flag: "hu", file: () => require('./translations/hu.json')},
    km: {name: "Khmer", flag: "kh", file: () => require('./translations/km.json')},
    lt: {name: "Lithuanian", flag: "lt", file: () => require('./translations/lt.json')},
    ro: {name: "Romanian", flag: "ro", file: () => require('./translations/ro.json')},
    sk: {name: "Slovak", flag: "sk", file: () => require('./translations/sk.json')},
    sv: {name: "Swedish", flag: "se", file: () => require('./translations/sv.json')},
    tr: {name: "Turkish", flag: "tr", file: () => require('./translations/tr.json')},
    uk: {name: "Ukranian", flag: "ua", file: () => require('./translations/uk.json')},
    vi: {name: "Vietnamese", flag: "vn", file: () => require('./translations/vi.json')}
};