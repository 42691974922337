import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getProfileRequest: null,
  getProfileSuccess: ["data"],
  getProfileError: ["error"],

  updateProfileRequest: null,
  updateProfileError: ["error"],

  updateProfileBankInfoRequest: null
})

export const ProfileTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: {},
    fetching: null,
    error: null
})

/* ------------- Reducers ------------- */


/* Profile Data */
export const getProfileRequest = (state) =>
  state.merge({ fetching: true, data: {}, error: null})

export const getProfileSuccess = (state, {data}) =>
  state.merge({ fetching: false, data})

export const getProfileError = (state, {error}) =>
  state.merge({ fetching: false, error})


/* Update Profile */
export const updateProfileRequest = (state) =>
  state.merge({ fetching: true, error: null})

export const updateProfileError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* Update Bank Info */
export const updateProfileBankInfoRequest = (state) =>
  state.merge({ fetching: true, error: null})


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROFILE_REQUEST]: getProfileRequest,
  [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [Types.GET_PROFILE_ERROR]: getProfileError,
  [Types.UPDATE_PROFILE_REQUEST]: updateProfileRequest,
  [Types.UPDATE_PROFILE_BANK_INFO_REQUEST]: updateProfileBankInfoRequest
})