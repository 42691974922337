import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import SignUp from "../../components/auth/SignUp";
import { useTranslation } from "react-i18next";

const SignUpPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {/* <Helmet title="Sign Up" /> */}
      <div className="text-center mt-4">
        {/* <h2>Welcome back, Chris</h2> */}
        <p className="lead fs-1 fw-bold">
          {t("signUpToYourAccountToContinue")}
        </p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <SignUp />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default SignUpPage;
