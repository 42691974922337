import moment from 'moment';
import _, { isEqual } from 'lodash'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Container, Modal, Table, Tab, Row, Col, Card, Form, Nav, Button, Spinner, ListGroup, Tabs } from 'react-bootstrap';
import { Check, Edit2, Trash2, Globe, X, ChevronRight, Plus, PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { DashboardProductTypes } from '../redux/dashboard/product';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLanguageFlagEmoji } from '../utils/commons';



const DashboardProductTranslations = () => {
    const  { t } = useTranslation()
    const navigate = useNavigate()
    const params_product_id = useParams()?.id;
    const urlCode = useLocation()?.pathname?.split("/")?.pop();
    const dispatch = useDispatch();

    const { translations, product, loadingTranslations } = useSelector(state => state.dashboard.product);
    const {ref_translations, desc_translations} = translations || {};

    const [key, setKey] = useState(urlCode?.length == 2 ? urlCode : 'en');
    
    const [referenceTranslations, setReferenceTranslations] = useState({})
    const [descriptionTranslations, setDescriptionTranslations] = useState({})

    const handleEditReferenceTranslation = (key, value) => {
        setReferenceTranslations({...referenceTranslations, [key]: value})
    }

    const handleEditDescriptionTranslation = (key, value) => {
        setDescriptionTranslations({...descriptionTranslations, [key]: value})
    }

    const handleSaveTranslations = (e) => {
        e.preventDefault()
        const data = Object.fromEntries(new FormData(e.target).entries())
        const product_id = e?.target?.id
        const lang = e?.target?.lang

        dispatch({ type: DashboardProductTypes.UPDATE_PRODUCT_TRANSLATIONS_REQUEST_DASHBOARD, product_id, lang, data })
    }

    const fetchTranslations = () => {
        const product_id = product?.id || params_product_id
        if (!product_id) return alert('Product ID missing')
        dispatch({ type: DashboardProductTypes.GET_PRODUCT_TRANSLATIONS_REQUEST_DASHBOARD, product_id })
    }
    
    useEffect(()=> fetchTranslations(), [])

    useEffect(()=> {
        if (!_.isEmpty(ref_translations) && !_.isEqual(ref_translations, referenceTranslations)) {
            setReferenceTranslations(ref_translations)
        }
        if (!_.isEmpty(desc_translations) && !_.isEqual(desc_translations, descriptionTranslations)) {
            setDescriptionTranslations(desc_translations)
        }
    }, [translations])



    return (
        <Card className='mt-3'>
            <div className='m-4 mt-3 mb-0 d-flex justify-content-between'>
                <h3>Product Translations  {getLanguageFlagEmoji(key?.replace("en", "gb"))} {key?.toLocaleUpperCase()}</h3>
            </div>
            <Card.Body>
                <Tabs
                    defaultActiveKey="en"
                    className="mb-3"
                    activeKey={key}
                    onSelect={(k) => {
                        navigate({pathname: `translations/${k}`})
                        setKey(k)
                    }}
                    // style={{ border: `1px solid gray`}}
                >
                    {Object.keys(referenceTranslations)?.map(lang => {
                        const referenceValue = lang == "en" ? product?.reference : (referenceTranslations?.[lang] || "")
                        const descriptionValue = lang == "en" ? product?.description : (descriptionTranslations?.[lang] || "")

                        return (

                            <Tab
                                key={lang}
                                className='shadow-none' 
                                eventKey={lang} 
                                title={`${getLanguageFlagEmoji(lang)} ${t(lang)?.toLocaleUpperCase()}`}
                            >
                                <Form id={product?.id} lang={lang} onSubmit={handleSaveTranslations}>
                                    <div className='mb-1 fw-bolder'>
                                        Reference
                                    </div>
                                    <div className='d-flex justify-content-center mb-4'>
                                        <Form.Control
                                            className='p-1 px-2'
                                            placeholder="Type translation"
                                            name={"ref_translation"}
                                            disabled={loadingTranslations || key == "en"}
                                            value={referenceValue || ""}
                                            onChange={(e) => handleEditReferenceTranslation(lang, e?.target?.value)}
                                        />
                                    </div>
                                    <div className='mb-1 fw-bolder'>
                                        Description
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Form.Control
                                            className='p-1 px-2'
                                            placeholder="Type translation"
                                            name={"desc_translation"}
                                            disabled={loadingTranslations || key == "en"}
                                            as="textarea"
                                            value={descriptionValue || ""}
                                            onChange={(e) => handleEditDescriptionTranslation(lang, e?.target?.value)}
                                        />
                                    </div>
                                    <div className="text-center">
                                        <Button
                                            type='submit'
                                            className='px-3 mt-3 float-center' 
                                            disabled={loadingTranslations || key == "en"}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            </Tab>
                        )}
                    )}
                </Tabs>
            </Card.Body>
        </Card>
    )
}

  
export default DashboardProductTranslations