import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    // filtersRequestDashboard: null,
    // filtersSuccessDashboard: ["filters"],
    // filtersFailureDashboard: ["filtersError"],
    // setSearchFieldDashboard: ["searchField"],
    cartsSearchRequestDashboard: ["params"],
    cartsSearchSuccessDashboard: ["results"],
    cartsSearchFailureDashboard: ["searchError"],
    selectActiveFilterDashboard: ["filter", "value"],
    // resetActiveFiltersDashboard: null,
    // productCreatedDashboard: ["data"],
    // productUpdatedDashboard: ["data"],
    // productDeletedDashboard: ["id"]
    trendingProductsRequestDashboard: null,
    trendingProductsSuccessDashboard: ["data"],
    trendingProductsErrorDashboard: null
})

export const DashboardCartsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    // fetchingFilters: false,
    // filtersError: null,
    fetchingCarts: null,
    searchError: null,
    // searchField: "",
    // filters: [],
    activeFilters: {
      status: "submitted"
    },
    carts: [],
    currentPage: 1,
    totalItems: null,
    totalPages: null,
    searchLimit: 20,
    trendingProducts: {
      fetching: null,
      data: []
    }
})

/* ------------- Reducers ------------- */


/* Filters */
// export const filtersRequest = state =>
//   state.merge({ fetchingFilters: false, filtersError: null, filters: [] })

// export const filtersSuccess = (state, {filters}) => 
//   state.merge({ fetchingFilters: false, filtersError: null, filters })

// export const filtersFailure = (state, {error}) =>
//   state.merge({ fetchingFilters: false, filtersError: error })

export const selectActiveFilter = (state, {filter, value}) => {
  const activeFilters = {...state.activeFilters, [filter]: value || undefined}
  // if (filter == "devices") {
  //   activeFilters.categories = undefined
  //   activeFilters.types = undefined
  // }
  // if (filter == "categories") activeFilters.types = undefined
  return state.merge({ activeFilters })
}

// export const removeActiveFilter = (state, {filter}) => 
//   state.merge({ activeFilters: { ...state.activeFilters, [filter]: null } })

// export const resetActiveFilters = (state) => 
//   state.merge({ 
//     searchField: "", 
//     activeFilters: {},
//     products: [],
//     currentPage: 1,
//     totalItems: null,
//     totalPages: null,
//     searchLimit: 20
//   })

  
/* Carts Search */
// export const setSearchField = (state, {searchField}) => 
//   state.merge({ searchField })

export const cartsSearchRequest = (state, {page, limit}) => {
  const currentPage = page || state.currentPage
  const searchLimit = limit || state.searchLimit
  
  return state.merge({ fetchingCarts: true, searchError: null, carts: [], currentPage, searchLimit })
}

export const cartsSearchSuccess = (state, {results}) => {
  const { carts, currentPage, totalItems, totalPages } = results
  
  let cartsResults = carts?.map(el => {
    // el = { 
    //   ...el,
    //   // au_ppm: el?.analyses?.[0]?.au_ppm,
    //   // ag_ppm: el?.analyses?.[0]?.ag_ppm,
    //   // pd_ppm: el?.analyses?.[0]?.pd_ppm,
    //   // pt_ppm: el?.analyses?.[0]?.pt_ppm,
    //   // cu_perc: el?.analyses?.[0]?.cu_perc,
    //   device: el?.device?.name,
    //   category: el?.category?.name,
    //   type: el?.type?.name
    // }

    // delete el?.analyses
    return el
  })

  return state.merge({ fetchingCarts: false, searchError: null, carts: cartsResults, currentPage, totalItems, totalPages })
}

export const cartsSearchFailure = (state, {searchError}) =>
  state.merge({ searchError, carts: [], fetchingCarts: false })


/* Carts */
// export const productCreated = (state, {data}) => 
//   state.merge({ products: [...data, ...state.product]})

// export const productUpdated = (state, {data}) => {
//   // alert(JSON.stringify(data))
//   return state.merge({  })
// }

// export const productDeleted = (state, {id}) => 
//   state.merge({ products: [...state.products]?.filter(el => el?.id != id)})


/* Trending Products */
export const trendingProductsRequest = (state) => 
  state.merge({ trendingProducts: {fetching: true, data: []} })

export const trendingProductsSuccess = (state, {data}) => 
  state.merge({ trendingProducts: {fetching: false, data} })

export const trendingProductsError = (state) => 
  state.merge({ trendingProducts: {fetching: false, data: []} })


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // [Types.SET_SEARCH_FIELD_DASHBOARD]: setSearchField,
  [Types.CARTS_SEARCH_REQUEST_DASHBOARD]: cartsSearchRequest,
  [Types.CARTS_SEARCH_SUCCESS_DASHBOARD]: cartsSearchSuccess,
  [Types.CARTS_SEARCH_FAILURE_DASHBOARD]: cartsSearchFailure,
  // [Types.FILTERS_REQUEST_DASHBOARD]: filtersRequest,
  // [Types.FILTERS_SUCCESS_DASHBOARD]: filtersSuccess,
  // [Types.FILTERS_FAILURE_DASHBOARD]: filtersFailure,
  [Types.SELECT_ACTIVE_FILTER_DASHBOARD]: selectActiveFilter,
  // [Types.RESET_ACTIVE_FILTERS_DASHBOARD]: resetActiveFilters,
  // [Types.PRODUCT_CREATED_DASHBOARD]: productCreated,
  // [Types.PRODUCT_UPDATED_DASHBOARD]: productUpdated,
  // [Types.PRODUCT_DELETED_DASHBOARD]: productDeleted,
  [Types.TRENDING_PRODUCTS_REQUEST_DASHBOARD]: trendingProductsRequest,
  [Types.TRENDING_PRODUCTS_SUCCESS_DASHBOARD]: trendingProductsSuccess,
  [Types.TRENDING_PRODUCTS_ERROR_DASHBOARD]: trendingProductsError
})
