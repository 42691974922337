import moment from 'moment';
import _, {capitalize} from 'lodash'
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { DashboardUsersTypes } from '../redux/dashboard/users'
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Pagination, Card, Spinner, Form, Row, Col, Button, InputGroup, Container } from 'react-bootstrap';
import Select from "react-select";
import * as Icon from "react-feather";
import { SpinnerCircular } from 'spinners-react';
import allCountries from "country-region-data/data.json";
import { useTranslation } from 'react-i18next';



const DashboardUsers = () => {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { 
        users, 
        currentPage, 
        totalPages,
        totalItems, 
        searchLimit,
        fetching,
        searchError,
        activeFilters
    } = useSelector(state => state.dashboard.users);

    const [page, setPage] = useState(currentPage)
    const [searchField, setSearchField] = useState("")

    useEffect(()=> {
        setPageAndSearch(1);
    }, [])
    

    const setPageAndSearch = (page, limit) => {
        setPage(page)
        dispatch({ type: DashboardUsersTypes.GET_USERS_REQUEST_DASHBOARD, page, limit })
    }

    const searchRequestWithField = () => {
        if (!searchField?.trim()) return setPageAndSearch(currentPage)
        dispatch({ type: DashboardUsersTypes.GET_USERS_REQUEST_DASHBOARD, page: currentPage, searchField})
    }

    const handleCountrySelectionChange = (filter, value) => {
        dispatch({ type: DashboardUsersTypes.SELECT_ACTIVE_FILTER_DASHBOARD, filter, value})
        dispatch({ type: DashboardUsersTypes.GET_USERS_REQUEST_DASHBOARD })
    }

    const handleResetFilters = () => {
        dispatch({ type: DashboardUsersTypes.RESET_ACTIVE_FILTERS_DASHBOARD})
        dispatch({ type: DashboardUsersTypes.GET_USERS_REQUEST_DASHBOARD })
    }

    const PageArrows = () => (
        <div className='d-flex justify-content-end'>
            <Pagination>
            <Pagination.First
                onClick={() => setPageAndSearch(1)}
                disabled={currentPage == 1}
            />
            <Pagination.Prev
                onClick={() => setPageAndSearch(currentPage-1)}
                disabled={currentPage <= 1}
            />
            <Pagination.Next
                onClick={() => setPageAndSearch(currentPage+1)}
                disabled={currentPage >= totalPages}
            />
            <Pagination.Last
                onClick={() => setPageAndSearch(totalPages)}
                disabled={currentPage >= totalPages}
            />
            </Pagination>
        </div>
    )

    const fields = [
        {field: "id", label: "ID"},
        {field: "fullname", label: "name"},
        {field: "email", label: "email"},
        {field: "country", label: "country"},
        {field: "phone", label: "phone"},
        {field: "createdAt", label: "Created On"},
        {field: "updatedAt", label: "Updated On"},
    ]

    const topCountries = [activeFilters?.country, "TH"]
    const countries = allCountries?.map(({countryName, countryShortCode}) => ({countryName, countryShortCode}))
                      ?.sort((x) => topCountries.includes(x.countryShortCode) ? -1 : 0)
                      ?.filter(el => el.countryShortCode != activeFilters?.country)
                      ?.map(el => ({label: el?.countryName, value: el?.countryShortCode}))
    const selectedCountry = countries?.find(el => el?.value == activeFilters?.country)


    return (
        <Card>
            <Card.Header>
                {/* <Button  
                    className="mt-2 mx-2"
                    onClick={() => navigate(`/dashboard/product/upload`)}
                    size='lg'
                    style={{minWidth: "12vw", minHeight: '6vh'}}
                >
                    New
                </Button> */}
                <Container fluid>
                    <Row  className="align-items-center">
                        <Col md="auto" className='mt-3 me-4'>
                            <h1 className="card-subtitle text-muted">
                                Users
                            </h1>
                        </Col>
                        <Col className='mt-3'>
                            <PageArrows />
                        </Col>
                    </Row>
                    <Row  className="align-items-center">
                        <Col md="auto" className='mt-3'>
                            <Form 
                                inline="true"
                                onSubmit={(e)=> {
                                    e.preventDefault();
                                    searchRequestWithField();
                                }}
                            >
                                <InputGroup 
                                    className="input-group-navbar"
                                    style={{maxWidth: 250}}
                                >
                                    <Form.Control 
                                        placeholder={t("search")} 
                                        aria-label="search"
                                        value={searchField}
                                        onSubmit={searchRequestWithField}
                                        onChange={e =>  setSearchField(e.target.value)} 
                                    />
                                    {searchField &&
                                        <Button className='m-0 px-1'>
                                            <Icon.X onClick={()=> setSearchField("")}/>
                                        </Button>}
                                    <Button className='m-0 px-1'>
                                        <Icon.Search onClick={searchRequestWithField}/>
                                    </Button>
                                </InputGroup>
                            </Form>
                        </Col>
                        <Col md="auto" className='mt-3 me-4 d-flex align-items-center'>
                            <Select
                                styles={{menu: ({ width, ...css }) => ({
                                        ...css,
                                        width: "max-content",
                                        minWidth: "100%",
                                        maxWidth: 200
                                })}}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={countries}
                                placeholder={"Select country"}
                                value={selectedCountry}
                                onChange={({value}) => handleCountrySelectionChange("country", value)}
                            />
                            <Button 
                                onClick={handleResetFilters}
                                className='mx-2'
                            >
                                {t("reset")}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            {fetching ? 
                <div 
                    style={{height: "70vh", justifyContent: "center"}}
                    className="d-flex pb-7"
                >
                    <SpinnerCircular 
                        size={100} 
                        thickness={100}
                        className="p-3"
                        color={"#F4BC2B"}
                        secondaryColor={"transparent"}
                    />
                </div>
            :
            Array.isArray(users) && users?.length > 0 ?
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            {fields?.map(({label}, i) => {
                                return (
                                    <th 
                                        key={label} 
                                        className="text-truncate"
                                    >
                                        {label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((row, i) => {
                            const userId = row.id
                            const tableCells = row

                            return (
                                <tr 
                                    key={userId}
                                    className='cursor-pointer'
                                    onClick={()=> userId && navigate(`/dashboard/user/${userId}`)}
                                >
                                    {fields?.map(({field}, i) => {
                                        let cellvalue = tableCells[field]

                                        if (field == "fullname") {
                                            if (tableCells["first_name"]) {
                                                cellvalue = tableCells["first_name"] + " " + tableCells["last_name"]
                                            }
                                        }

                                        return (
                                            <td key={i}>
                                                
                                                {field == "ref" ?
                                                    <a 
                                                        className="truncate text-dark" 
                                                        onClick={()=> userId && navigate(`/dashboard/user/${userId}`)}
                                                    >
                                                        {cellvalue}
                                                    </a>
                                                : field == "updatedAt" ?
                                                    <span className="truncate">
                                                        {moment(cellvalue).format("MMM D, YYYY")}
                                                    </span>

                                                : field == "createdAt" ?
                                                    <span className="truncate">
                                                        {moment(cellvalue).format("MMM D, YYYY")}
                                                    </span>

                                                // ANY OTHER ROW
                                                :   <span className="truncate">
                                                    {cellvalue}
                                                </span>}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                :
                searchError ?
                    <div className='text-center p-4'>
                        <span style={{fontWeight: "bold"}}>Search Error: </span> 
                        {searchError}
                    </div>
                : <div className='text-center p-4'>No result</div>}

            <Row className='mt-3 ms-2 me-2 d-flex justify-content-end'>
                <Col>
                    <span className="ms-3 me-2">Showing: {totalItems}</span>
                    <span className="mx-2">
                        Page{" "}
                    <strong>
                        {currentPage} of {totalPages}
                    </strong>
                    </span>
                </Col>
                <Col md="auto">
                    <span className="ms-3 me-2">Show:</span>
                    <Form.Select
                        className="d-inline-block w-auto"
                        value={searchLimit}
                        onChange={(e) => setPageAndSearch(currentPage, Number(e.target.value))}
                    >
                        {[10, 20, 30, 40, 50].map((searchLimit) => (
                            <option key={searchLimit} value={searchLimit}>
                                {searchLimit}
                            </option>
                        ))}
                    </Form.Select>
                    <span className="ms-3 me-2">Go to page:</span>
                    <Form.Control
                        className="d-inline-block"
                        type="number"
                        value={page}
                        onChange={(e) => {
                            const newPage = e.target.value ? Number(e.target.value) : "";
                            setPage(newPage)
                        }}
                        onKeyPress={(target)=> {
                            if (target.charCode==13) {
                                page >= 1 && page <= totalPages && setPageAndSearch(page);
                            }
                            
                        }}
                        style={{ width: "75px" }}
                    />
                </Col>
                <Col>
                    <PageArrows />
                </Col>
            </Row>
        </Card>
    );
};

  
export default DashboardUsers
