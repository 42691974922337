import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  
  getProductRequestDashboard: ["id"],
  getProductSuccessDashboard: ["data"],
  getProductErrorDashboard: ["error"],

  createProductRequestDashboard: ["data"],
  createProductSuccessDashboard: ["data"],
  createProductErrorDashboard: ["error"],

  duplicateProductRequestDashboard: null,
  duplicateProductSuccessDashboard: ["id"],
  duplicateProductErrorDashboard: null,

  setProductField: ["field", "value"],
  updateProductRequestDashboard: ["id", "data"],
  updateProductSuccessDashboard: null,
  updateProductErrorDashboard: ["error"],
  updateProductResetDashboard: null,

  updateProductAvailabilityRequestDashboard: ["id", "available"],
  updateProductAvailabilitySuccessDashboard: null,
  updateProductAvailabilityErrorDashboard: ["error"],

  deleteProductRequestDashboard: ["id"],
  deleteProductSuccessDashboard: ["id"],
  deleteProductErrorDashboard: ["id", "error"],

  getProductAnalysesRequestDashboard: ["id"],
  getProductAnalysesSuccessDashboard: ["data"],
  getProductAnalysesErrorDashboard: ["error"],

  getProductModelsRequestDashboard: ["product_id"],
  getProductModelsSuccessDashboard: ["data"],
  getProductModelsErrorDashboard: ["error"],

  addProductModelsRequestDashboard: ["id"],
  addProductModelsSuccessDashboard: ["data"],
  addProductModelsErrorDashboard: ["error"],

  deleteProductModelsRequestDashboard: ["id"],
  deleteProductModelsSuccessDashboard: ["id"],
  deleteProductModelsErrorDashboard: ["id","error"],

  getProductTranslationsRequestDashboard: ["product_id"],
  getProductTranslationsSuccessDashboard: ["translations"],
  getProductTranslationsErrorDashboard: null,
  updateProductTranslationsRequestDashboard: null,

  getProductsTranslationsRequestDashboard: null,
  getProductsTranslationsSuccessDashboard: ["data"],
  getProductsTranslationsErrorDashboard: null,
  updateProductsTranslationsRequestDashboard: null,
  autofillMissingProductsTranslationsRequestDashboard: null,

  sortProductImagesRequestDashboard: ["data"],
  addProductImageDashboard: ["data"],
  deleteProductImageDashboard: ["id"],

  productResetDashboard: null
})

export const DashboardProductTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    product: {},
    gettingProduct: null,
    getProductError: null,

    analyses: [],
    gettingAnalyses: null,
    getAnaysesError: null,

    createdProduct: null,
    creatingProduct: false,
    createProductSuccess: null,
    createProductError: null,

    duplicatingProduct: false,
    duplicatedProductId: null,

    updatedProduct: null,
    updatingProducts: [],
    updateProductError: null,
    updateProductSuccess: null,

    deletingProducts: [],
    deleteProductSuccess: null,
    deleteProductError: null,

    models: [],
    loadingModels: null,

    translations: null,
    loadingTranslations: null,

    productTranslations: null,
    availableLanguages: null,
    loadingAllTranslations: null
})

/* ------------- Reducers ------------- */


/* Get Product */
export const getProductRequest = (state) =>
  state.merge({ product: {}, analyses: [], gettingProduct: true, updatingProduct: false, getProductError: null})

export const getProductSuccess = (state, {data}) => {
  return state.merge({ gettingProduct: false, product: data, analyses: data?.analyses})
}

export const getProductError = (state, {error}) =>
  state.merge({ gettingProduct: false, getProductError: error})


/* Get Product Analyses */
export const getProductAnalysesRequest = (state) =>
  state.merge({ analyses: [], gettingAnalyses: true, getAnalysesError: null})

export const getProductAnalysesSuccess = (state, {data}) => {
  return state.merge({ gettingAnalyses: false, analyses: data})
}

export const getProductAnalysesError = (state, {error}) =>
  state.merge({ gettingAnalyses: false, getAnalysesError: error})



/* Create Product */
export const createProductRequest = (state) =>
  state.merge({ creatingProduct: true, createdProduct: null, createProductError: null, createProductSuccess: null})

export const createProductSuccess = (state, {data}) => {
  let createProductSuccess = "Product successfully created"
  return state.merge({ creatingProduct: false, createdProduct: data, createProductSuccess})
}

export const createProductError = (state, {error}) =>
  state.merge({ creatingProduct: false, createProductError: error})


/* Duplicate Product */
export const duplicateProductRequest = (state) =>
  state.merge({ duplicatingProduct: true, duplicatedProductId: null })

export const duplicateProductSuccess = (state, {id}) => 
  state.merge({ duplicatingProduct: false, duplicatedProductId: id })

export const duplicateProductError = (state) =>
  state.merge({ duplicatingProduct: false, duplicatedProductId: null })


/* Update Product */
export const setProductField = (state, {field, value}) => {
  const product = {...state.product, [field]: value}
  if (field == "device_id") {
    product.category_id = null
      product.type_id = null
  }
  if (field == "category_id") product.type_id = null
  return state.merge({ product })
}
  
export const updateProductRequest = (state) =>
  state.merge({ updatingProduct: true, updateProductError: null, updateProductSuccess: null})

export const updateProductSuccess = (state) => {
  let updateProductSuccess = "Product successfully updated"
  return state.merge({ updatingProduct: false, updateProductSuccess})
}

export const updateProductError = (state, {error}) =>
  state.merge({ updatingProduct: false, updateProductError: error})

export const updateProductReset = (state) =>
  state.merge({ updatingProduct: false, updateProductSuccess: null, updateProductError: null, product: {}})


/* Update Product Availability */
export const updateProductAvailabilityRequest = (state) =>
  state.merge({ updatingProduct: true })

export const updateProductAvailabilitySuccess = (state) =>
  state.merge({ updatingProduct: false })

export const updateProductAvailabilityError = (state) =>
  state.merge({ updatingProduct: false })

  
/* Delete Product */
export const deleteProductRequest = (state, {id}) => {
  let deletingProducts = [...state.deletingProducts, id]
  return state.merge({ deletingProducts, deleteProductError: null, deleteProductSuccess: null})
}

export const deleteProductSuccess = (state, {id}) => {
  let deleteProductSuccess = "Product successfully deleted"
  let deletingProducts = [...state.deletingProducts]?.filter(el => el != id)
  return state.merge({ deletingProducts, deleteProductSuccess})
}

export const deleteProductError = (state, {id, error}) => {
  let deletingProducts = [...state.deletingProducts]?.filter(el => el != id)
  return state.merge({ deletingProducts, deleteProductError: error})
}


/* Get Product Models */
export const getProductModelsRequest = (state) =>
  state.merge({ loadingModels: true, models: []})

export const getProductModelsSuccess = (state, {data}) => 
  state.merge({ loadingModels: false, models: data})

export const getProductModelsError = (state, {error}) =>
  state.merge({ loadingModels: false})


/* Add Product Model */
export const addProductModelsRequest = (state, {id}) => {
  // let deletingProducts = [...state.deletingProducts, id]
  return state.merge({ loadingModels: true})
}

export const addProductModelsSuccess = (state, {id}) => {
//   let deleteProductSuccess = "Product successfully deleted"
//   let deletingProducts = [...state.deletingProducts]?.filter(el => el != id)
  return state.merge({ loadingModels: false})
}

export const addProductModelsError = (state, {id, error}) => {
  // let deletingProducts = [...state.deletingProducts]?.filter(el => el != id)
  return state.merge({ loadingModels: false})
}

/* Delete Product Model */
export const deleteProductModelsRequest = (state, {id}) => {
  // let deletingProducts = [...state.deletingProducts, id]
  return state.merge({ loadingModels: true})
}

export const deleteProductModelsSuccess = (state, {id}) => {
//   let deleteProductSuccess = "Product successfully deleted"
//   let deletingProducts = [...state.deletingProducts]?.filter(el => el != id)
  return state.merge({ loadingModels: false})
}

export const deleteProductModelsError = (state, {id, error}) => {
  // let deletingProducts = [...state.deletingProducts]?.filter(el => el != id)
  return state.merge({ loadingModels: false})
}

/* Get or Update Product Translations */
export const getProductTranslationsRequest = (state) => 
  state.merge({ loadingTranslations: true, translations: null})

export const getProductTranslationsSuccess = (state, {translations}) => 
  state.merge({ loadingTranslations: false, translations})

export const getProductTranslationsError = (state) => 
  state.merge({ loadingTranslations: false})

export const updateProductTranslationsRequest = (state) => 
  state.merge({ loadingTranslations: true})


/* Get or Update All Product Translations */
export const getProductsTranslationsRequest = (state) => 
  state.merge({ loadingAllTranslations: true, allTranslations: null})

export const getProductsTranslationsSuccess = (state, {data: {availableLanguages, productsTranslations}}) =>
  state.merge({ loadingAllTranslations: false, availableLanguages, productsTranslations})

export const getProductsTranslationsError = (state) => 
  state.merge({ loadingAllTranslations: false})

export const updateProductsTranslationsRequest = (state) => 
  state.merge({ loadingAllTranslations: true})

export const autofillMissingProductsTranslationsRequest = (state) => 
  state.merge({ loadingAllTranslations: true})

/* Add Product Images */
export const addProductImage = (state, {data}) => {
  const images = [...state.product.images, data]
  const product = {...state.product, images }
  return state.merge({ product })
}

/* Delete Product Images */
export const deleteProductImage = (state, {id}) => {
  const images = [...state.product.images].filter(el => el?.id != id)
  const product = {...state.product, images }
  return state.merge({ product })
}


/* Reset Product State */
export const productReset = () => INITIAL_STATE



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.GET_PRODUCT_REQUEST_DASHBOARD]: getProductRequest,
  [Types.GET_PRODUCT_SUCCESS_DASHBOARD]: getProductSuccess,
  [Types.GET_PRODUCT_ERROR_DASHBOARD]: getProductError,

  [Types.GET_PRODUCT_ANALYSES_REQUEST_DASHBOARD]: getProductAnalysesRequest,
  [Types.GET_PRODUCT_ANALYSES_SUCCESS_DASHBOARD]: getProductAnalysesSuccess,
  [Types.GET_PRODUCT_ANALYSES_ERROR_DASHBOARD]: getProductAnalysesError,

  [Types.CREATE_PRODUCT_REQUEST_DASHBOARD]: createProductRequest,
  [Types.CREATE_PRODUCT_SUCCESS_DASHBOARD]: createProductSuccess,
  [Types.CREATE_PRODUCT_ERROR_DASHBOARD]: createProductError,

  [Types.DUPLICATE_PRODUCT_REQUEST_DASHBOARD]: duplicateProductRequest,
  [Types.DUPLICATE_PRODUCT_SUCCESS_DASHBOARD]: duplicateProductSuccess,
  [Types.DUPLICATE_PRODUCT_ERROR_DASHBOARD]: duplicateProductError,

  [Types.SET_PRODUCT_FIELD]: setProductField,
  [Types.UPDATE_PRODUCT_REQUEST_DASHBOARD]: updateProductRequest,
  [Types.UPDATE_PRODUCT_SUCCESS_DASHBOARD]: updateProductSuccess,
  [Types.UPDATE_PRODUCT_ERROR_DASHBOARD]: updateProductError,
  [Types.UPDATE_PRODUCT_RESET_DASHBOARD]: updateProductReset,

  [Types.UPDATE_PRODUCT_AVAILABILITY_REQUEST_DASHBOARD]: updateProductAvailabilityRequest,
  [Types.UPDATE_PRODUCT_AVAILABILITY_SUCCESS_DASHBOARD]: updateProductAvailabilitySuccess,
  [Types.UPDATE_PRODUCT_AVAILABILITY_ERROR_DASHBOARD]: updateProductAvailabilityError,

  [Types.DELETE_PRODUCT_REQUEST_DASHBOARD]: deleteProductRequest,
  [Types.DELETE_PRODUCT_SUCCESS_DASHBOARD]: deleteProductSuccess,
  [Types.DELETE_PRODUCT_ERROR_DASHBOARD]: deleteProductError,

  [Types.GET_PRODUCT_MODELS_REQUEST_DASHBOARD]: getProductModelsRequest,
  [Types.GET_PRODUCT_MODELS_SUCCESS_DASHBOARD]: getProductModelsSuccess,
  [Types.GET_PRODUCT_MODELS_ERROR_DASHBOARD]: getProductModelsError,

  [Types.ADD_PRODUCT_MODELS_REQUEST_DASHBOARD]: addProductModelsRequest,
  [Types.ADD_PRODUCT_MODELS_SUCCESS_DASHBOARD]: addProductModelsSuccess,
  [Types.ADD_PRODUCT_MODELS_ERROR_DASHBOARD]: addProductModelsError,

  [Types.DELETE_PRODUCT_MODELS_REQUEST_DASHBOARD]: deleteProductModelsRequest,
  [Types.DELETE_PRODUCT_MODELS_SUCCESS_DASHBOARD]: deleteProductModelsSuccess,
  [Types.DELETE_PRODUCT_MODELS_ERROR_DASHBOARD]: deleteProductModelsError,

  [Types.GET_PRODUCT_TRANSLATIONS_REQUEST_DASHBOARD]: getProductTranslationsRequest,
  [Types.GET_PRODUCT_TRANSLATIONS_SUCCESS_DASHBOARD]: getProductTranslationsSuccess,
  [Types.GET_PRODUCT_TRANSLATIONS_ERROR_DASHBOARD]: getProductTranslationsError,
  [Types.UPDATE_PRODUCT_TRANSLATIONS_REQUEST_DASHBOARD]: updateProductTranslationsRequest,
  [Types.GET_PRODUCTS_TRANSLATIONS_REQUEST_DASHBOARD]: getProductsTranslationsRequest,
  [Types.GET_PRODUCTS_TRANSLATIONS_SUCCESS_DASHBOARD]: getProductsTranslationsSuccess,
  [Types.GET_PRODUCTS_TRANSLATIONS_ERROR_DASHBOARD]: getProductsTranslationsError,
  [Types.UPDATE_PRODUCTS_TRANSLATIONS_REQUEST_DASHBOARD]: updateProductsTranslationsRequest,
  [Types.AUTOFILL_MISSING_PRODUCTS_TRANSLATIONS_REQUEST_DASHBOARD]: autofillMissingProductsTranslationsRequest,

  [Types.ADD_PRODUCT_IMAGE_DASHBOARD]: addProductImage,
  [Types.DELETE_PRODUCT_IMAGE_DASHBOARD]: deleteProductImage,
  
  [Types.PRODUCT_RESET_DASHBOARD]: productReset
})