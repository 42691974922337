import React from "react";
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import useSidebar from "../../hooks/useSidebar";
import NavbarLanguages from "./NavbarLanguages";
import NavbarCurrencies from "./NavbarCurrencies";
import NavbarUser from "./NavbarUser";
import useAuth from "../../hooks/useAuth";

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();
  const { type } = useAuth()
  const isAdmin = type == "admin" || type == "translator"
  const isUser = type == "user"

  return (
    <Navbar variant="light" expand className="navbar-bg">
      {isAdmin && 
        <span
          className="sidebar-toggle d-flex"
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="hamburger align-self-center" />
        </span>}
        
      {isUser &&
        <a className="sidebar-brand mx-2" style={{padding: 0}} href="/">
          <Logo style={{height: "2rem", width: "2rem"}}/>
          <span className="m-1 align-middle text-dark fs-3">Eco Weee</span>
        </a>}

      {/* <Form inline="true" className="d-none d-sm-inline-block">
        <InputGroup className="input-group-navbar">
          <Form.Control placeholder={T("search")} aria-label="search" />
          <Button variant="">
            <Search className="feather" />
          </Button>
        </InputGroup>
      </Form> */}

      <Navbar.Collapse>
        <Nav className="navbar-align">
          {/* <NavbarDropdown
            header="New Messages"
            footer="Show all messages"
            icon={MessageCircle}
            count={messages.length}
            showBadge
          >
            {messages.map((item, key) => {
              return (
                <NavbarDropdownItem
                  key={key}
                  icon={
                    <img
                      className="avatar img-fluid rounded-circle"
                      src={item.avatar}
                      alt={item.name}
                    />
                  }
                  title={item.name}
                  description={item.description}
                  time={item.time}
                  spacing
                />
              );
            })}
          </NavbarDropdown> */}

          {/* <NavbarDropdown
            header="New Notifications"
            footer="Show all notifications"
            icon={BellOff}
            count={notifications.length}
          >
            {notifications.map((item, key) => {
              let icon = <Bell size={18} className="text-warning" />;

              if (item.type === "important") {
                icon = <AlertCircle size={18} className="text-danger" />;
              }

              if (item.type === "login") {
                icon = <Home size={18} className="text-primary" />;
              }

              if (item.type === "request") {
                icon = <UserPlus size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.title}
                  description={item.description}
                  time={item.time}
                />
              );
            })}
          </NavbarDropdown> */}

          {!isUser && <NavbarCurrencies />}
          <NavbarLanguages />
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
