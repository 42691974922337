/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select, delay } from 'redux-saga/effects'
import DashboardSearchActions from '../../redux/dashboard/search'

export function * getProductsDashboard(api) {
    const reducers = yield select();
    const { 
        searchField, 
        searchLimit, 
        currentPage, 
        activeFilters
    } = reducers?.dashboard.search
    const { currency } = reducers.global

    let params = { 
        searchField, 
        size: searchLimit, 
        page: currentPage, 
        filters: activeFilters,
        currency
    }

    const response = yield call(api.getProductsDashboard, params)
    if (response.ok) {
        yield put(DashboardSearchActions.searchSuccessDashboard(response.data))
    } else {
        const error = response?.data?.message || "Server error"
        yield put(DashboardSearchActions.searchFailureDashboard(error))
    }
}


export function * getFiltersDashboard(api) {
    const response = yield call(api.getFiltersDashboard)
    if (response.ok) {
        yield put(DashboardSearchActions.filtersSuccessDashboard(response.data))
    } else {
        const error = response?.data?.message || "Server error"
        yield put(DashboardSearchActions.filtersFailureDashboard(error))
    }
}
