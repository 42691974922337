import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  metalsRatesRequest: null,
  metalsRatesSuccess: ["data"],
  metalsRatesError: ["error"]
})

export const MetalsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: [],
    fetching: null,
    error: null
})

/* ------------- Reducers ------------- */


/* Reset DB */
export const metalsRatesRequest = (state) =>
  state.merge({ fetching: true, data: [], error: null})

export const metalsRatesSuccess = (state, {data}) =>
  state.merge({ fetching: false, data})

export const metalsRatesError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.METALS_RATES_REQUEST]: metalsRatesRequest,
  [Types.METALS_RATES_SUCCESS]: metalsRatesSuccess,
  [Types.METALS_RATES_ERROR]: metalsRatesError
})