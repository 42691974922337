import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  resetDatabaseRequest: null,
  resetDatabaseSuccess: null,
  resetDatabaseError: null
})

export const DatabaseTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    resetting: null,
    success: null,
    error: null
})

/* ------------- Reducers ------------- */


/* Reset DB */
export const resetDatabaseRequest = (state) =>
  state.merge({ resetting: true,  success: null, error: null})

export const resetDatabaseSuccess = (state) =>
  state.merge({ resetting: false,  success: "Done"})

export const resetDatabaseError = (state) =>
  state.merge({ resetting: false, error: "Error"})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_DATABASE_REQUEST]: resetDatabaseRequest,
  [Types.RESET_DATABASE_SUCCESS]: resetDatabaseSuccess,
  [Types.RESET_DATABASE_ERROR]: resetDatabaseError
})