import { capitalize, debounce } from 'lodash'
import { useSearchParams, createSearchParams, useNavigate, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import SearchActions, { SearchTypes } from '../redux/search'
import React, { useEffect, useMemo, useState } from 'react';
import WeeBackground from "../assets/img/photos/search-background.jpeg";
import { 
  Container,
  InputGroup,
  Form,
  FormControl,
  FormLabel,
  Button,
  Breadcrumb
} from 'react-bootstrap';
import MiniNavBar from './MiniNavBar';
import { useTranslation } from 'react-i18next';
import { allLanguages } from '../i18n/i18n';



const SearchBar = ({
  noTopBar,
  noBar,
  title,
  productName,
  filters,
  activeFilters,
  resetActiveFilters,
  removeActiveFilter,
  addActiveFilter,
  searchField,
  setSearchField,
  searchRequest
}) => {
  const product = useSelector(state => state.product?.product)

  const { t, i18n } = useTranslation()
  const locale = i18n?.language;
  
  const [searchParams, setSearchParams] = useSearchParams({});
  const [priceFilter, setPriceFilter] = useState("best_match");
  
  const debouncedSearch = useMemo(
    () => debounce(()=> searchRequest(), 300)
  , [searchRequest]);

  const {pathname} = useLocation();


  const navigate = useNavigate()

  const navigateWithSearchField = () => {
    navigate({
      pathname: "/search",
      search: searchField ? `?${createSearchParams({ searchField })}` : null
    })
  }

  // const handlePriceFilterChange = (price) => {
  //   dispatch({ type: SearchTypes.SET_PRICE_FILTER, price })
  //   searchRequest()
  // }

  return (
    <>
      <MiniNavBar hidden={noTopBar}/>
      <Container 
        fluid={true}
        className="p-0"
        style={{
          backgroundImage: `url(${WeeBackground})`,
          backgroundSize: "cover"
        }}
      >
        <Container 
          fluid={true}
          className="centered bg-dark bg-opacity-50 p-5"
        >
          <Container style={{maxWidth: 1000}}>
            <h1 className="text-center fs-0 text-uppercase pt-1 pb-1 text-white fw-bolder">
              {title ? title : productName ? productName : t('weeeProducts')}
            </h1>
            {!noBar && 
              <Container xl={2} className='bg-white bg-opacity-25 rounded p-4'>
                <Form 
                  onSubmit={(e)=> {
                    e.preventDefault();
                    searchRequest();
                    navigateWithSearchField();
                  }}
                >
                  <FormLabel 
                    className='pb-2 fs-4 text-white fw-bolder'
                  >
                    {t("searchYourReference")}:
                  </FormLabel>
                  <InputGroup className="">
                    <FormControl
                      style={{borderRadius: "2rem"}}
                      className='p-2 ps-3 bg-transparent border-white border-2 text-white'
                      placeholder={t("code")}
                      aria-label="Code"
                      aria-describedby="basic-addon2"
                      value={searchField}
                      onSubmit={searchRequest}
                      onChange={(e)=> {
                        setSearchField(e.target.value)
                        // debouncedSearch()
                        // setSearchParams(
                        //   e.target.value ? { 
                        //     searchField: e.target.value 
                        //   } : {})
                      }}
                    />
                    <Button 
                      variant="primary" 
                      className='ms-2 p-2 border-primary'
                      style={{borderRadius: "2rem", border: "3px solid"}}
                      onClick={()=> {
                        searchRequest();
                        navigateWithSearchField();
                      }}
                    >
                      {t('search')?.toUpperCase()}
                    </Button>
                  </InputGroup>
                </Form>
              </Container>}
          </Container>
        </Container>
        <div className='ps-2 bg-dark bg-opacity-50'>
          <Container className='ps-0'>
            {pathname?.replace('/',"") ?
              <Breadcrumb className='ps-4 pb-1 text-white'>
                <Breadcrumb.Item onClick={()=> navigate('/')}>
                  {"Home"}
                </Breadcrumb.Item>
                {pathname?.replace('/',"") == "search" ?
                  <>
                    <Breadcrumb.Item>
                      {capitalize(t(pathname?.replace('/',"")))}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {capitalize(t('devices'))}
                    </Breadcrumb.Item>
                  </>
                  : pathname?.replace('/',"") == "account" ?
                    <>
                      <Breadcrumb.Item onClick={()=> navigate('/search')}>
                        {t('search')}
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        {capitalize(t(pathname?.replace('/',"")))}
                      </Breadcrumb.Item>
                    </>
                  : pathname?.replace('/',"") == "cart" ?
                    <>
                      <Breadcrumb.Item onClick={()=> navigate('/search')}>
                        {t('search')}
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        {capitalize(t(pathname?.replace('/',"")))}
                      </Breadcrumb.Item>
                    </>
                  : <>
                      {pathname?.replace('/',"")?.split('/')?.map((el, i) => {
                        const lastItem = (pathname?.replace('/',"")?.split('/')?.length - 1) == i
                        const productPage = /[^/]*$/.exec(pathname)[0] == product?.code
                        const productReference = product?.ref_translation?.[locale] || product?.reference

                        if (allLanguages?.map(lang => lang?.code)?.includes(el)) return

                        if (productPage && lastItem) {
                          return (
                            <Breadcrumb.Item key={el}>
                              {productReference}
                            </Breadcrumb.Item>
                          )
                        }

                        return (
                          <Breadcrumb.Item 
                            key={el}
                            onClick={()=> {
                              if (el == "product") {
                                return navigate("/search")
                              }
                              navigate(pathname)
                            }}
                          >
                            {capitalize(t(el))}
                          </Breadcrumb.Item>
                        )
                      })}
                    </>
                  }
                </Breadcrumb>
            : null}
          </Container>
        </div>
      </Container>
    </>
  )
};

const mapStateToProps = state => ({
  fetchingProducts: state.search.fetchingProducts,
  searchField: state.search.searchField,
  activeFilters: state.search.activeFilters,
  filters: state.search.filters,
})

const mapDispatchToProps = dispatch => ({
  setSearchField: (text) => dispatch(SearchActions.setSearchField(text)),
  searchRequest: () => dispatch(SearchActions.searchRequest()),
  addActiveFilter: (category, filter) => dispatch(SearchActions.addActiveFilter(category, filter)),
  removeActiveFilter: (category, filter) => dispatch(SearchActions.removeActiveFilter(category, filter)),
  resetActiveFilters: () => dispatch(SearchActions.resetActiveFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)