import _, { capitalize, upperFirst, debounce } from 'lodash'
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import SearchActions, { SearchTypes } from '../redux/search'
import ProductActions from '../redux/product'
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Card, Image, Button, Breadcrumb, BreadcrumbItem, InputGroup, Form } from 'react-bootstrap';
import * as Icon from "react-feather";
import { SpinnerCircular } from 'spinners-react';
import SearchBar from '../components/SearchBar';
import { placeholderImage } from './ui/Utils';
import PaginationButtons from '../components/PaginationButtons';
import { BASE_URL, isProduction } from '../services/api';
import PriceSelectionFilter from '../components/PriceSelectionFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCarSide, FaLaptop, FaMicrochip, FaMobile, FaServer, FaSignal } from "react-icons/fa";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { PiComputerTower } from "react-icons/pi";
import { ImMobile2 } from "react-icons/im";
import { CartTypes } from '../redux/account/cart';
import { ModalsTypes } from '../redux/modals';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import WeeeInfo from './WeeeInfo';

const CartUpdater = ({id}) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1)

  const { isAuthenticated, type } = useAuth();

  const handleAddToCart = () => {
    if (quantity == "" || isNaN(quantity) || quantity <= 0 || quantity > 100) {
      return alert("Invalid number")
    }
    dispatch({ type: CartTypes.ADD_CART_PRODUCT_REQUEST, product_id: id, quantity})    
  }

  if (!isAuthenticated || type != "user") return null

  return (
    <Row className='m-0' disabled>
      <Col   
        className='d-flex align-items-center justify-content-center bg-primary py-1' 
        // onClick={()=> alert('Add to favorites')}
        style={{cursor: "pointer"}} 
      >
        <Icon.Star
          fill="#fff"
          className='my-1'
          color="#fff"
        />
      </Col>
      <Col className='d-flex border-top border-bottom align-items-center justify-content-center py-1'>
          <Form.Control 
            onChange={(e) => setQuantity(e?.target?.value)}
            value={quantity}
            className='border-0 text-center'
            type="number"
          />
      </Col>
      <Col   
        style={{cursor: "pointer"}} 
        className='d-flex align-items-center justify-content-center bg-primary py-1' 
        onClick={handleAddToCart}
      >
          <Icon.ShoppingCart
            className='my-1'
            fill="#fff"
            color="#fff"
          />
      </Col>
    </Row>
  )
}

const Search = ({
  filters,
  activeFilters,
  resetActiveFilters,
  removeActiveFilter,
  addActiveFilter,
  searchField,
  setSearchField,
  searchRequest,
  searchResults,
  fetching,
  searchError,
  deleteProduct,
  deletingProducts
}) => {

  const locale = useTranslation()?.i18n?.language;
  const {pathname, search} = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageArray, setPageArray] = useState([]);

  const { t } = useTranslation();
  
  // const debouncedSearch = useMemo(
  //   () => debounce(()=> searchRequest(), 300)
  // , [searchRequest]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currency = useSelector(state => state.global.currency);
  const devicesSections = useSelector(state => state.search.devicesSections);

  const handleDevicesSectionsRequest = () => {
    dispatch({ type: SearchTypes.DEVICES_SECTIONS_REQUEST })
  }

  const handleSearchDevicesRequest = (device_id, active) => {
    if (!active) {
      dispatch({ type: SearchTypes.ADD_ACTIVE_FILTER, category: "devices", filter: device_id })
    } else {
      dispatch({ type: SearchTypes.REMOVE_ACTIVE_FILTER, category: "devices", filter: device_id })
    }
    dispatch({ type: SearchTypes.SEARCH_REQUEST })
    navigate({pathname: "/search"})
  }
  
  
  useEffect(()=> {
    if (!pathname?.includes('search')) {
      resetActiveFilters()
      handleDevicesSectionsRequest()
    } else {
      searchRequest()
    }

    const urlParams = new URLSearchParams(search);
    const scrollToElement = urlParams.get('scrollToElement');

    if (scrollToElement) {
      const element = document.getElementById(scrollToElement);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setSearchParams({})
      }
    }
  },[currency, pathname, locale, search])

  const cardImageStyle =  {
    objectFit: 'cover',
    width: '100%',
    height: '15vw',
    minHeight: '140px',
    maxHeight: '200px'
  }
  const getIcon = (category) => {
    category = category?.toString()?.toLowerCase()
    if (category?.includes("server")) return <FaServer size={'2.6em'} />
    if (category?.includes("telecommunication")) return <FaSignal size={'2.6em'} />
    if (category?.includes("mobile")) return <ImMobile2 size={'2.8em'} />
    if (category?.includes("laptop")) return <FaLaptop size={'2.8em'} />
    if (category?.includes("electronic")) return <FaMicrochip size={'2.6em'} />
    if (category?.includes("vehicle")) return <FaCarSide size={'2.9em'} />
    if (category?.includes("computer")) return <PiComputerTower size={'3em'}/>
  }

  return (
      <>
        <Helmet>
          <title>{t("weeeProducts")}</title>
        </Helmet>
        <SearchBar />
        <Container>
          {/* {pathname?.includes('search') &&
            <Breadcrumb className='pt-3 ps-2'>
              <Breadcrumb.Item onClick={()=> navigate('/',{ pathname: ""})}>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Devices</Breadcrumb.Item>
            </Breadcrumb>} */}
          {fetching ? 
            <p className="text-center p-4">
              <SpinnerCircular 
                size={100} 
                thickness={100}
                className="pt-7"
                color={"#F4BC2B"}
                secondaryColor={"transparent"}
              />
            </p>
            : Array.isArray(searchResults) && searchResults?.length ?
            <>
              <div className='p-2 pt-4 d-flex justify-content-between'>
                {/* <h1 className='text-uppercase'>{t("searchResults")}:</h1> */}
                <h1 className='text-uppercase'>{t("searchResults")?.toUpperCase()}:</h1>
                <div className='d-flex align-items-center'>
                  <div className='mx-4'>
                    <PriceSelectionFilter />
                  </div>
                  <PaginationButtons />
                </div>
              </div>
              {<div className="p-2 pt-3 fs-3">
                {devicesSections?.map(el => {
                  const active = activeFilters?.devices?.includes(el?.id)
                  return (
                    <Button 
                      key={el?.id}
                      style={{border: ".18rem solid", borderRadius: "2rem"}} 
                      className={`p-2 px-3 me-3 mb-3 border-primary ${active && "bg-primary fw-bolder"}`}
                      variant={"outline-primary"}
                      onClick={()=> handleSearchDevicesRequest(el?.id, active)}
                    >
                      <span style={{color: active ? "#404040" : "#000"}}>
                        {capitalize(t(el?.name?.toLowerCase()?.replace(" ", "_")))}
                      </span>
                    </Button>
                  )
                })}
              </div>}
              <Row xs={1} sm={2} md={3} xxl={4}>
                  {searchResults?.map(el => {
                    const img = el?.image?.url ? (BASE_URL+el?.image?.url) : placeholderImage
                    const reference = el?.ref_translation?.[locale] || el?.reference
                    
                    return (
                      <Col key={el.code}>
                        <Card className="shadow-lg mx-2 mt-3 mb-5">
                          <CartUpdater id={el?.id}/>
                          <div onClick={()=> navigate(`/product/${el?.code}`, { state: {product: el} })}>
                            <Card.Img style={cardImageStyle} src={img} />
                            <Card.Body style={{borderTop: ".18rem solid "}} className='border-primary p-0 m-0'>
                              <Row className='m-0 d-flex align-items-center text-end'>
                                <Col className='px-0 m-2 text-center' xs={3}>
                                  {getIcon(el?.device?.name)}
                                </Col>
                                <Col className="pt-2 pb-1">
                                  <Card.Text className='m-0 fs-5 fw-bold'>
                                    {reference}
                                  </Card.Text>
                                  <Card.Text className='m-0 fs-2 fw-bolder text-truncate text-primary'>
                                    {el?.price}
                                  </Card.Text>
                                </Col>
                              </Row>
                            </Card.Body>
                          </div>
                        </Card>
                      </Col>
                    )
                })}
              </Row>
            </>
            // Devices Sections
            : (!pathname?.includes('search') && devicesSections?.length > 0) ? 
            <div className='px-2 pt-4 fs-4'>
              <div className=''>
                {t("searchTopText")}
              </div>
              <Row xs={1} sm={2} md={3} xxl={4} className={"pt-4"}>
                {devicesSections?.map(el => {
                  return (
                    <Col 
                      key={el.id}
                      onClick={()=> handleSearchDevicesRequest(el?.id)}
                    >
                      <Card className="shadow-lg mt-3 mb-5">
                        <Card.Img 
                          style={cardImageStyle} 
                          src={`${BASE_URL}/images/sections/${el?.name?.toLowerCase()}.png`} 
                        />
                        <Card.Body 
                          style={{borderTop: ".18rem solid "}} 
                          className='border-primary p-0 m-0'
                        >
                          <Row className='px-3 d-flex align-items-center justify-content-between'>
                            <Col sm="10" className="py-3">
                              {/* <FontAwesomeIcon 
                                icon={getIcon(el?.device?.name)} 
                                size={'3x'}
                                color={"#173152"}
                              /> */}
                              <Card.Text 
                                className='truncate m-0 fs-2 fw-bold'
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                {upperFirst(t(el?.name?.toLowerCase()?.replace(" ", "_")))}
                              </Card.Text>
                            </Col>
                            <Col sm="2">
                              <FontAwesomeIcon icon={faArrowRight} />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
              <div>
                {t("searchBottomText")}
              </div>
            </div> 
            : searchError ?
              <p className='text-center p-4'>
                <span style={{fontWeight: "bold"}}>{t('searchError')}: </span> 
                {searchError}
              </p>
          : <p className='text-center p-4'>{t('noResult')}</p>}
          <div className='d-flex justify-content-center mb-4'>
            <PaginationButtons/>
          </div>
        </Container>
        {!isProduction && 
          <div id="weeeInfo">
            <WeeeInfo />
          </div>}
      </>
  )
};

const mapStateToProps = state => ({
  fetching: state.search.fetching,
  searchField: state.search.searchField,
  activeFilters: state.search.activeFilters,
  filters: state.search.filters,
  searchResults: state.search.products,
  searchError: state.search.searchError,
  deletingProducts: state.product.deletingProducts
})

const mapDispatchToProps = dispatch => ({
  setSearchField: (text) => dispatch(SearchActions.setSearchField(text)),
  searchRequest: (currency) => dispatch(SearchActions.searchRequest(currency)),
  addActiveFilter: (category, filter) => dispatch(SearchActions.addActiveFilter(category, filter)),
  removeActiveFilter: (category, filter) => dispatch(SearchActions.removeActiveFilter(category, filter)),
  resetActiveFilters: () => dispatch(SearchActions.resetActiveFilters()),
  deleteProduct: (id) => dispatch(ProductActions.deleteProductRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)