import moment from 'moment';
import _, { isEqual } from 'lodash'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Container, Modal, Table, Tab, Row, Col, Card, Form, Nav, Button, Spinner, ListGroup, Tabs } from 'react-bootstrap';
import { Check, Edit2, Trash2, Globe, X, ChevronRight, Plus, PlusCircle } from 'react-feather';
import Zoom from 'react-medium-image-zoom'
import { BASE_URL } from '../services/api';
import { useTranslation } from 'react-i18next';
import { DashboardProductTypes } from '../redux/dashboard/product';
import { DashboardTranslationsTypes } from '../redux/dashboard/translations';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFlagEmoji, getLanguageFlagEmoji } from '../utils/commons';
import * as Icon from "react-feather";



const DashboardProductsTranslations = () => {
    const  { t } = useTranslation()
    const navigate = useNavigate()
    const urlCode = useLocation()?.pathname?.split("/")?.pop();
    const dispatch = useDispatch();

    const categories = useSelector(state => state.dashboard?.translations?.categories)
    const categoriesTranslations = categories?.translations
    const loadingCategoriesTranslations = categories?.loading
    const { availableLanguages, productsTranslations, loadingAllTranslations } = useSelector(state => state.dashboard.product);

    const [key, setKey] = useState(urlCode?.length == 2 ? urlCode : 'en');

    const [translations, setTranslations] = useState()
    const [catTranslations, setCatTranslations] = useState()

    const onInput = (index, field, value) => {
        const newArray = [...translations]
        newArray[index] = {...newArray[index], [field]: value}
        setTranslations(newArray)
    }

    const onCategoryInput = (index, field, value) => {
        const newArray = [...catTranslations]
        newArray[index] = {...newArray[index], [field]: value}
        setCatTranslations(newArray)
    }

    const formSubmit = (e) => {
        e.preventDefault()
        const data = Object.fromEntries(new FormData(e.target).entries())
        const product_id = e?.target?.id
        const lang = e?.target?.lang

        dispatch({ type: DashboardProductTypes.UPDATE_PRODUCT_TRANSLATIONS_REQUEST_DASHBOARD, product_id, lang, data })
    }

    const categoriesFormSubmit = (e) => {
        e.preventDefault()
        const data = catTranslations.filter(item => {
            const targetCategory = categoriesTranslations.find(el => el?.id == item?.category_id)
            return targetCategory && targetCategory?.cat_translation?.[item?.language] != item?.cat_translation
        })
        if (data?.length < 1) return alert("No categories input changed")
        dispatch({ type: DashboardTranslationsTypes.UPDATE_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD, data })
    }

    const autoFillAllProductsTranslations = () => {
        if (window.confirm('Are you sure you want to autofill all missing products translations using Google translate?')) {
            dispatch({ type: DashboardProductTypes.AUTOFILL_MISSING_PRODUCTS_TRANSLATIONS_REQUEST_DASHBOARD})
        }
    }

    const autoFillAllCategoriesTranslations = () => {
        if (window.confirm('Are you sure you want to autofill all missing categories translations using Google translate?')) {
            dispatch({ type: DashboardTranslationsTypes.AUTOFILL_MISSING_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD})
        }
    }
    
    
    useEffect(()=> {
        dispatch({ type: DashboardProductTypes.GET_PRODUCTS_TRANSLATIONS_REQUEST_DASHBOARD})
        dispatch({ type: DashboardTranslationsTypes.GET_CATEGORIES_TRANSLATIONS_REQUEST_DASHBOARD})
    }, [])

    useEffect(()=> {
        if (categoriesTranslations) {
            setCatTranslations([...categoriesTranslations]?.map(el => ({
                    category_id: el?.id,
                    name: el?.name,
                    language: key,
                    cat_translation: el?.cat_translation?.[key] || ""
                })
            ))
        }
        if (productsTranslations) {
            setTranslations([...productsTranslations]?.map(el => ({
                    product_id: el?.id,
                    language: key,
                    code: el?.code,
                    image: el?.image,
                    reference: el?.reference,
                    description: el?.description,
                    ref_translation: el?.ref_translation?.[key] || "",
                    desc_translation: el?.desc_translation?.[key] || ""
                })
            ))
        }
    }, [productsTranslations, categoriesTranslations, key])

    const placeholderImage = 'https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640'

    return (
        <>
            <Row className='justify-content-left p-1'>
                {availableLanguages?.length > 0 && availableLanguages?.map(el => {
                    return (
                        <Col 
                            key={el} 
                            className='d-flex align-items-center rounded m-2'
                            style={{
                                cursor: "pointer",
                                border: `3px solid ${key == el ? "#F4BC2B" : "#EDEDED"}`,
                                fontSize: "1.2rem",
                                maxWidth: "5.5rem"
                            }}
                            onClick={() => {
                                navigate({pathname: `${el}`})
                                setKey(el)
                            }}
                        >
                            <div>
                                {el?.toLocaleUpperCase()}
                            </div>
                            <span style={{fontSize: "1.5rem"}} className='ps-2'>
                                {getLanguageFlagEmoji(el)}
                            </span>
                        </Col>
                    )
                })}
            </Row>
            <Card className='p-4'>
                <Row className='d-flex justify-content-end mb-5'>
                    <Col>
                        <h3 className='fw-bolder'>
                            Categories
                        </h3>
                    </Col>
                    <Col md="auto">
                        <Button
                            onClick={autoFillAllCategoriesTranslations}
                            className='p-1 justify-content-center' 
                            disabled={loadingCategoriesTranslations}
                        >
                            <Icon.Globe size={'1.2rem'}/>
                            <span className='ps-2'>
                                Autofill categories with google translate
                            </span>
                        </Button>
                    </Col>
                </Row>
                <Form 
                    lang={key}  
                    onSubmit={categoriesFormSubmit}
                >
                    <Row className='justify-content-left p-1'>
                        {catTranslations?.map(({category_id, name, cat_translation}, index) => {
                            return (
                                <Col key={category_id} className='rounded mb-3'>
                                    <Form.Group>
                                        <Form.Control
                                            style={{maxWidth: "5.5rem", minWidth: "20rem", fontSize: "1rem"}}
                                            className='p-3 py-2'
                                            type={"text"}
                                            value={name}
                                            disabled={true}
                                        />
                                        {key != "en" &&
                                            <Form.Control
                                                style={{maxWidth: "5.5rem", minWidth: "20rem", fontSize: "1rem"}}
                                                className='p-3 py-2'
                                                id={category_id}
                                                name={"cat_translation"}
                                                type={"text"}
                                                value={cat_translation || ""}
                                                onChange={({target: {name, value}}) => onCategoryInput(index, name, value)}
                                                disabled={loadingCategoriesTranslations}
                                            />}
                                    </Form.Group>
                                </Col>
                            )
                        })}
                        {key != "en" &&
                            <div className="text-center mt-2">
                                <Button
                                    type='submit'
                                    className='px-3 mt-3 float-center' 
                                    disabled={loadingCategoriesTranslations || key == "en"}
                                >
                                    Save All
                                </Button>
                            </div>}
                    </Row>
                </Form>
            </Card>
            <Row className='d-flex justify-content-end'>
                <Col>
                    <h3 className='fw-bolder mb-4'>
                        All Products Translations
                    </h3>
                </Col>
                <Col md="auto">
                    <Button
                        onClick={autoFillAllProductsTranslations}
                        className='p-1 justify-content-center' 
                        disabled={loadingAllTranslations}
                    >
                        <Icon.Globe size={'1.2rem'}/>
                        <span className='ps-2'>
                            Autofill products with google translate
                        </span>
                    </Button>
                </Col>
            </Row>
            {translations?.length > 0 && translations?.map(({
                product_id, 
                code,
                image,
                language,
                reference, 
                description,
                ref_translation,
                desc_translation
            }, index) => (
                <Row key={product_id}>
                    <Col>
                        <Card className='mt-3 p-3 pt-3' key={product_id}>
                            <Row className='d-flex justify-content-end'>
                                <Col>
                                    <h2>{code}</h2>
                                </Col>
                                <Col md={"auto"}>
                                    <Zoom
                                        overlayBgColorStart={'rgba(108, 122, 137, 0)'}
                                        overlayBgColorEnd={'rgba(108, 122, 137, 0.7)'}
                                    >
                                        <img
                                            src={image ? (BASE_URL + image) : placeholderImage}
                                            width="90"
                                            height="90"
                                            className="me-2"
                                            alt="product"
                                        />
                                    </Zoom>
                                </Col>
                            </Row>
                            <Card.Body className='p-0'>
                                <h4 className='my-2'>
                                    Reference
                                </h4>
                                <div style={{minHeight: '2rem'}} className='border p-1 ps-2 rounded'>
                                    {reference}
                                </div>
                                <h4 className='my-2 mt-3'>
                                    Description
                                </h4>
                                <div style={{minHeight: '6rem'}} className='border p-3 py-2 rounded'>
                                    {description?.split("•")?.map((el, i) => i > 0 && (
                                        <div 
                                            key={i}
                                            style={{whiteSpace: "pre-wrap"}}
                                        >
                                            {`•${el}`}
                                        </div>
                                    ))}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='mt-3 p-3 pt-2' key={product_id}>
                            <div 
                                className='d-flex align-items-center justify-content-center'
                                style={{minHeight: 105}}
                            >
                                <div style={{fontSize: "1.3rem"}}>
                                    {language?.toLocaleUpperCase()}
                                </div>
                                <span style={{fontSize: "1.5rem"}} className='ps-2'>
                                    {getLanguageFlagEmoji(key)}
                                </span>
                            </div>
                            <Card.Body className='p-0'>
                                <Form lang={key} id={product_id} onSubmit={formSubmit}>
                                    <Form.Group style={{minHeight: '2rem'}} className='my-2'>
                                        <h4>Reference</h4>
                                        <Form.Control
                                            name={"ref_translation"}
                                            type={"text"}
                                            as={"input"}
                                            className="mb-3"
                                            value={key == "en" ? (reference || "") : (ref_translation || "")}
                                            onChange={({target: {name, value}}) => onInput(index, name, value)}
                                            disabled={loadingAllTranslations || key == "en"}
                                        />
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <h4>Description</h4>
                                        <Form.Control
                                            style={{minHeight: '6rem'}}
                                            className='p-3 py-2'
                                            name={"desc_translation"}
                                            type={"text"}
                                            as={"textarea"}
                                            rows={4}
                                            value={key == "en" ? (description || "") : (desc_translation || "")}
                                            onChange={({target: {name, value}}) => onInput(index, name, value)}
                                            disabled={loadingAllTranslations || key == "en"}
                                        />
                                    </Form.Group>
                                    <div className="text-center">
                                        <Button
                                            type='submit'
                                            className='px-3 mt-3 float-center' 
                                            disabled={loadingAllTranslations || key == "en"}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ))}
        </>
    )
}

  
export default DashboardProductsTranslations