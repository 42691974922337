export const generateArrayOfYears = (ys) => {
    // var max = new Date().getFullYear()
    // var min = max - (ys || 10)
    // var years = []
  
    // for (var i = max; i >= min; i--) {
    //   years.push(i)
    // }
    // return years
}

export const findArrayDuplicates = array => array.filter((item, index) => array.indexOf(item) !== index)

export const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export const getLanguageFlagEmoji = (countryCode) => {
  const codePoints = (
    countryCode
    ?.replace("en", "gb")?.replace("el", "gr")?.replace("ja", "jp")?.replace("en", "gb")
    ?.replace("zh", "cn")?.replace("hi", "in")?.replace("vi", "vn")?.replace("my", "mm")
    ?.replace("tl", "ph")?.replace('km', 'kh')?.replace('ms', 'my')?.replace("ar", "ae")
  )
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}