/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, delay, select } from 'redux-saga/effects'
import AnalysisActions from '../../redux/dashboard/analysis'
import DashboardProductActions from '../../redux/dashboard/product'

export function * getProductAnalysis (api, {id}) {
    const response = yield call(api.getProductAnalysis, id)

    if (response.ok) {
        yield delay(3000)
        yield put(AnalysisActions.getProductAnalysisSuccess(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(AnalysisActions.getProductAnalysisError(message))
    }
}


export function * createProductAnalysis (api, {data}) {
    console.log(data)
    const response = yield call(api.createProductAnalysis, data)
    const id = data?.product_id

    if (response.ok) {
        yield put(AnalysisActions.createProductAnalysisSuccess(response.data))
        yield put(DashboardProductActions.getProductAnalysesRequestDashboard(id))
        yield put(DashboardProductActions.getProductRequestDashboard(id))
    } else {
        const message = response?.data?.message || "Error"
        yield put(AnalysisActions.createProductAnalysisError(message))
    }
}


export function * updateProductAnalysis (api, {id, data}) {
    const response = yield call(api.updateProductAnalysis, {id, data})

    if (response.ok) {
        yield put(AnalysisActions.updateProductAnalysisSuccess(response.data))
        yield put(DashboardProductActions.getProductAnalysesRequestDashboard(id = data?.product_id))
        yield put(DashboardProductActions.getProductRequestDashboard(id = data?.product_id))
    } else {
        const message = response?.data?.message || "Error"
        yield put(AnalysisActions.updateProductAnalysisError(message))
    }
}


export function * deleteProductAnalysis (api, {id, product_id}) {
    const response = yield call(api.deleteProductAnalysis, id)

    if (response.ok) {
        // yield delay(5000)
        const message = response?.data?.message || "Product analysis successfully deleted"
        yield put(AnalysisActions.deleteProductAnalysisSuccess(id, message))
        yield put(DashboardProductActions.getProductAnalysesRequestDashboard(id = product_id))
        yield put(DashboardProductActions.getProductRequestDashboard(id = product_id))
    } else {
        const message = response?.data?.message || "Error"
        yield put(AnalysisActions.deleteProductAnalysisError(id, message))
    }
}


export function * selectProductAnalysis(api, {id, product_id}) {
    const response = yield call(api.selectProductAnalysis, {id, product_id})

    if (response.ok) {
        // yield delay(5000)
        const message = response?.data?.message || "Product analysis successfully selected"
        yield put(AnalysisActions.selectProductAnalysisSuccess(id, message))
        yield put(DashboardProductActions.getProductRequestDashboard(id = product_id))
    } else {
        const message = response?.data?.message || "Error"
        yield put(AnalysisActions.selectProductAnalysisError(id, message))
    }
}