import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  
  getProductRequest: ["code"],
  getProductSuccess: ["data"],
  getProductError: ["error"],

  getModelsRequest: ["product_id"],
  getModelsSuccess: ["models"],
  getModelsError: ["error"],

  productReset: null
})

export const ProductTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    product: {},
    gettingProduct: null,
    getProductError: null,
    models: [],
    gettingModels: null,
    getModelsError: null
})

/* ------------- Reducers ------------- */


/* Get Product */
export const getProductRequest = (state) =>
  state.merge({ product: {}, gettingProduct: true, getProductError: null})

export const getProductSuccess = (state, {data}) => {
  const product = {
    id: data?.id,
    images: data?.images,
    device: data?.device?.name,
    category: data?.category?.name,
    cat_translation: data?.category?.cat_translation,
    type: data?.type?.name,
    reference: data?.reference,
    ref_translation: data?.ref_translation,
    desc_translation: data?.desc_translation,
    code: data?.code,
    description: data?.description,
    price: data?.price
  }
  return state.merge({ gettingProduct: false, product})
}
  

export const getProductError = (state, {error}) =>
  state.merge({ gettingProduct: false, getProductError: error})


/* Get Product Models */
export const getModelsRequest = (state) =>
  state.merge({ moodels: [], gettingModels: true, getModelsError: null})

export const getModelsSuccess = (state, {models}) =>
  state.merge({ models, gettingModels: false, getModelsError: false})

export const getModelsError = (state) =>
  state.merge({ gettingModels: false })


/* Reset Product State */
export const productReset = () => INITIAL_STATE



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCT_REQUEST]: getProductRequest,
  [Types.GET_PRODUCT_SUCCESS]: getProductSuccess,
  [Types.GET_PRODUCT_ERROR]: getProductError,
  [Types.GET_MODELS_REQUEST]: getModelsRequest,
  [Types.GET_MODELS_SUCCESS]: getModelsSuccess,
  [Types.GET_MODELS_ERROR]: getModelsError,
  [Types.PRODUCT_RESET]: productReset
})