/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { toast } from 'react-toastify';
import { call, put, select, delay } from 'redux-saga/effects'
import DashboardProductActions from '../../redux/dashboard/product'
import DashboardSearchActions from '../../redux/dashboard/search'

export function * getProductDashboard (api, {id}) {
    const reducers = yield select();
    const currency = reducers?.global.currency

    const response = yield call(api.getProductDashboard, id, currency)

    if (response.ok) {
        yield put(DashboardProductActions.getProductSuccessDashboard(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(DashboardProductActions.getProductErrorDashboard(message))
    }
}

export function * getProductAnalysesDashboard (api, {id}) {
    const response = yield call(api.getProductAnalysesDashboard, id)

    if (response.ok) {
        yield put(DashboardProductActions.getProductAnalysesSuccessDashboard(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(DashboardProductActions.getProductAnalysesErrorDashboard(message))
    }
}

export function * createProductDashboard (api, {data}) {

    const reducers = yield select();
    const { product } = reducers?.dashboard.product

    const response = yield call(api.createProductDashboard, product)
    
    // yield delay(2000)
    if (response.ok) {
        yield put(DashboardProductActions.createProductSuccessDashboard(response.data))
        yield put(DashboardProductActions.productResetDashboard())
    } else {
        const message = response?.data?.message || "Error"
        yield put(DashboardProductActions.createProductErrorDashboard(message))
    }
}

export function * duplicateProductDashboard (api, {oldCode, newCode}) {
    const response = yield call(api.duplicateProductDashboard, {oldCode, newCode})
    
    yield delay(3000)
    if (response.ok) {
        const {message, id} = response?.data || {}
        toast.success(message)
        yield delay(1500)
        yield put(DashboardProductActions.duplicateProductSuccessDashboard(id))
    } else {
        yield put(DashboardProductActions.duplicateProductErrorDashboard())
    }
}


export function * updateProductDashboard (api, {id, data}) {
    const response = yield call(api.updateProductDashboard, {id, data})

    if (response.ok) {
        if (response?.data?.priceUpdated) {
            yield put(DashboardProductActions.getProductRequestDashboard(id))
        }
        yield put(DashboardProductActions.updateProductSuccessDashboard(response.data))
        
    } else {
        const message = response?.data?.message || "Error"
        yield put(DashboardProductActions.updateProductErrorDashboard(message))
    }
}


export function * updateProductAvailabilityDashboard (api, {id, available}) {
    const response = yield call(api.updateProductDashboard, {id, data: {available}})

    if (response.ok) {
        yield put(DashboardSearchActions.searchRequestDashboard())
        yield put(DashboardProductActions.getProductRequestDashboard(id))
    } else {
        const message = response?.data?.message || "Error"
        alert(message)
        yield put(DashboardSearchActions.searchRequestDashboard())
    }
}


export function * deleteProductDashboard (api, {id, code}) {
    const response = yield call(api.deleteProductDashboard, {id, code})

    // yield delay(5000)
    if (response.ok) {
        yield put(DashboardProductActions.deleteProductSuccessDashboard(id))
        yield put(DashboardSearchActions.productDeletedDashboard(id))
        toast.success(response?.data?.message)
    } else {
        const message = response?.data?.message || "Error"
        yield put(DashboardProductActions.deleteProductErrorDashboard(id, message))
        alert(message)
    }
}

export function * sortProductImagesDashboard (api, {data}) {
    const {product_id, sortArray} = data
    const response = yield call(api.sortProductImagesDashboard, {product_id, sortArray: JSON.stringify(sortArray)})

    if (response.ok) {
        yield put(DashboardProductActions.getProductRequestDashboard(product_id))
    } else {
        const message = response?.data?.message || "Error"
        alert(message)
        yield put(DashboardProductActions.getProductRequestDashboard(product_id))
    }
}

export function * getProductModelsDashboard (api, {product_id}) {
    // alert(product_id)
    const response = yield call(api.getProductModelsDashboard, product_id)
    
    // yield delay(2000)
    if (response.ok) {
        yield put(DashboardProductActions.getProductModelsSuccessDashboard(response?.data))
    } else {
        const message = response?.data?.message || "Error"
        // alert(message)
        yield put(DashboardProductActions.getProductModelsErrorDashboard())
    }
}

export function * addProductModelsDashboard (api, {product_id, models}) {

    const response = yield call(api.addProductModelsDashboard, {product_id, data: {models}})
    
    // yield delay(2000)
    if (response.ok) {
        yield put(DashboardProductActions.getProductModelsRequestDashboard(product_id))
    } else {
        const message = response?.data?.message || "Error"
        alert(message)
        yield put(DashboardProductActions.addProductModelsErrorDashboard())
    }
}

export function * deleteProductModelsDashboard (api, {product_id, modelsIds}) {

    const response = yield call(api.deleteProductModelsDashboard, {modelsIds})
    
    // yield delay(2000)
    if (response.ok) {
        yield put(DashboardProductActions.getProductModelsRequestDashboard(product_id))
    } else {
        const message = response?.data?.message || "Error"
        alert(message)
        yield put(DashboardProductActions.addProductModelsErrorDashboard())
    }
}

// GET TRANSLATION FOR A SINGLE PRODUCT
export function * getProductTranslationsDashboard (api, {product_id}) {
    const response = yield call(api.getProductTranslationsDashboard, product_id)
    
    if (response.ok) {
        yield put(DashboardProductActions.getProductTranslationsSuccessDashboard(response?.data))
    } else {
        yield put(DashboardProductActions.getProductTranslationsErrorDashboard())
    }
}

export function * updateProductTranslationsDashboard (api, {product_id, lang, data}) {
    const response = yield call(api.updateProductTranslationsDashboard, {product_id, lang, data})
    
    if (response.ok) {
        toast.success(response?.data?.message)
        yield put(DashboardProductActions.getProductTranslationsRequestDashboard(product_id))
    } else {
        yield put(DashboardProductActions.getProductTranslationsErrorDashboard())
    }
}

// GET TRANSLATION FOR ALL PRODUCTS
export function * getProductsTranslationsDashboard (api) {
    const response = yield call(api.getProductsTranslationsDashboard)
    
    if (response.ok) {
        yield put(DashboardProductActions.getProductsTranslationsSuccessDashboard(response?.data))
    } else {
        yield put(DashboardProductActions.getProductsTranslationsErrorDashboard())
    }
}

// UPDATE TRANSLATION FOR ALL PRODUCTS
export function * updateProductsTranslationsDashboard (api, {product_id, lang, data}) {
    const response = yield call(api.updateProductTranslationsDashboard, {product_id, lang, data})
    
    if (response.ok) {
        toast.success(response?.data?.message)
        yield put(DashboardProductActions.getProductsTranslationsRequestDashboard())
    } else {
        yield put(DashboardProductActions.getProductsTranslationsErrorDashboard())
    }
}

// AUTOFILL ALL MISSING PRODUCTS TRANSLATIONS
export function * autofillMissingProductsTranslationsDashboard (api) {
    const response = yield call(api.autofillMissingProductsTranslationsDashboard)
    
    if (response.ok) {
        toast.success(response?.data?.message)
        yield put(DashboardProductActions.getProductsTranslationsRequestDashboard())
    } else {
        yield put(DashboardProductActions.getProductsTranslationsErrorDashboard())
    }
}