import React from "react";
import { useParams } from "react-router-dom";
import TosTh from "./tos/th";
import TosEn from "./tos/en";


const Tos = () => {
  const { lang } = useParams();
  if (lang == "th") return <TosTh />
  return <TosEn />
}

export default Tos;