import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  actionLogsRequest: null,
  actionLogsSuccess: ["actions"],
  actionLogsError: ["error"]
})

export const LogsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    actions: [],
    fetching: false
})

/* ------------- Reducers ------------- */


/* Currencies */
export const actionLogsRequest = (state) => 
  state.merge({ fetching: true, actions: []})

export const actionLogsSuccess = (state, {actions}) => 
  state.merge({ fetching: false, actions})

export const actionLogsError = (state, {actions}) =>
  state.merge({ fetching: false, actions})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACTION_LOGS_REQUEST]: actionLogsRequest,
  [Types.ACTION_LOGS_SUCCESS]: actionLogsSuccess,
  [Types.ACTION_LOGS_ERROR]: actionLogsError
})