import React from 'react';
import { Container, Row, Col, Image, Breadcrumb } from 'react-bootstrap';
import RisingImpactImage from '../assets/img/weee/The rising impact of E-waste recycling.png';
import EwasteExposure from '../assets/img/weee/E-waste exposure on human health.png';
import WhatIsEwaste from '../assets/img/weee/What is E-waste.png';
import ProperDisposalOfWeee from '../assets/img/weee/Proper disposal of weee.png';
import RecyclingRate from '../assets/img/weee/Recycling rate.png';
import LargestEwasteGenerators from '../assets/img/weee/Largest e-waste generators.png';
import EwasteInAsia from '../assets/img/weee/E-waste in Asia.png';
import WeeBackground from '../assets/img/photos/search-background.jpeg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import MiniNavBar from '../components/MiniNavBar';
import Textfit from 'react-textfit';

const WeeeImpact = () => {
    const {pathname} = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Helmet title={t("weeeImpactTitle")}>
                <meta name="description" content={t("weeeImpactDescription")} />
                <meta name="keywords" content={t("weeeImpactKeywords")} />
            </Helmet>
            <div
                style={{
                    backgroundImage: `url(${WeeBackground})`,
                    backgroundSize: "cover"
                }}
            >
                <MiniNavBar noCurrencies/>
                <div className="text-center p-7 bg-dark bg-opacity-50">
                    <h1 className="fw-bolder text-white">
                        {t("weeeImpactTitle")}
                    </h1>
                </div>
                {pathname?.includes('weee-impact') &&
                    <Breadcrumb className='px-7 pb-3 text-white bg-dark bg-opacity-50'>
                        <Breadcrumb.Item onClick={()=> navigate('/',{ pathname: ""})}>
                            {t("home")}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {t("weeeImpact")}
                        </Breadcrumb.Item>
                    </Breadcrumb>}
            </div>
            <Container className="p-3 pb-7 fs-4">
                {/* Row 1 alt image */}
                <Row>
                    <Col className='p-3'>
                        <Image
                            src={RisingImpactImage}
                            alt="The rising impact of E-waste recycling"
                            fluid
                        />
                    </Col>
                </Row>

                {/* Row 1 */}
                {/* <Row>
                    <Col className='p-3' style={{ position: "relative" }}>
                        <Image
                            src={RisingImpactImage}
                            alt="The rising impact of E-waste recycling"
                            fluid
                        />
                        
                            <Textfit 
                                mode="single" 
                                style={{
                                    color: "#fff", 
                                    position: "absolute", 
                                    top: "30%", 
                                    left: "25%",
                                    width: "50.5%"
                                }}
                            >
                                The Rising Impact of
                            </Textfit>
                            <Textfit 
                                mode="single" 
                                style={{
                                    color: "#ECC42E", 
                                    position: "absolute", 
                                    top: "45%", 
                                    left: "20%",
                                    width: "60%"
                                }}
                            >
                                E-Waste Recycling
                            </Textfit>
                    </Col>
                </Row> */}

                {/* Row 2 */}
                <Row>
                    <Col md={5} className='p-3'>
                        <Image
                            src={EwasteExposure}
                            alt="Mercury causes neurological problems, kidney damage, & development issues in children. Brominated Flame Retardants cause endocrine disruption, development, reproductive, thyroid-related problems. Polyvinyl Chloride (PVC) leads to respiratory issues, cancer risk, effects on the central nervous system & liver damage. Lead causes neurological damage, development issues, anemia, & high blood pressure. Cadmium leads to kidney damage, lung cancer, & bone diseases. Arsenic causes skin lesions, cardiovascular problems, & increased cancer risk."
                            fluid
                        />
                    </Col>
                    <Col md={7}>
                        <Row>
                            <Col md={12} className='p-3'>
                                <Image
                                    src={WhatIsEwaste}
                                    alt="What is e-Waste? ELECTRONIC WASTE a.k.a. WEEE Waste from Electrical & Electronic Equipments Refers to discarded or obsolete electronic devices and equipment, such as computers, smartphones, and appliances. These items contain hazardous materials and pose environmental and health risks when improperly disposed."
                                    fluid
                                />
                            </Col>
                            <Col md={12} className='p-3'>
                                <Image
                                    src={ProperDisposalOfWeee}
                                    alt="Proper Disposal of WEEE ensures toxins are safely contained and managed. Improper Disposal leads to substances to leach into the soil and water, posing risks to human health and our environment."
                                    fluid
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Row 3 */}
                <Row>
                    <Col className='p-3'>
                        <Image
                            src={RecyclingRate}
                            alt="53.6 million tons of e-waste were generated globally in 2019, or an average of 7.3 Kg/person. Global e-Waste Recycling Rate is less than 20%, reported being recycled in environmentally responsible facilities. The majority is dumped, traded, or partially recycled by the informal sector, without meeting environmental standards. Source: The Global E-Waste Monitor 2020 and Global Transboundary E-waste Flows Monitor 2022."
                            fluid
                        />
                    </Col>
                </Row>

                {/* Row 4 */}
                <Row>
                    <Col md={6} className='p-3'>
                        <Image
                            src={LargestEwasteGenerators}
                            alt="Largest e-waste generators by region: Asia - 24.9 Mt, Oceania - 0.7 Mt, Africa - 2.9 Mt, America - 13.1 Mt, Europe - 12 Mt. Source: Global Transboundary E-waste Flows Monitor 2022."
                            fluid
                        />
                    </Col>
                    <Col md={6} className='p-3'>
                        <Image
                            src={EwasteInAsia}
                            alt="e-Waste in Asia (2019): 24.9 million tons. China: 10.1 million tons, India: 3.2 million tons, Japan: 2.6 million tons. Source: The Global E-waste Monitor 2020."
                            fluid
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default WeeeImpact;