import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setCurrency: null,
  // setLanguage: null
})

export const GlobalTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  currency: "USD",
  // language: "en"
})

/* ------------- Reducers ------------- */
/* Set Currency */
export const setCurrency = (state, {currency}) => ({...state, currency })

/* Set Language */
// export const setLanguage = (state, {language}) => ({...state, language })


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CURRENCY]: setCurrency,
  // [Types.SET_LANGUAGE]: setLanguage,
})