import { useDispatch, useSelector } from 'react-redux';
import { DatabaseTypes } from '../redux/dashboard/database';
import React from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';


const DashboardDatabase = () => {

    const dispatch = useDispatch();

    const { 
        resetting,
        success,
        error
    } = useSelector(state => state.dashboard.database); 

    

    const resetDatabase = () => {
        if (window.confirm('Are you sure you want to reset the DataBase?')) {
            dispatch({ type: DatabaseTypes.RESET_DATABASE_REQUEST })
        }
    }

    return (
        <Card>
            <Card.Header>
                <h1 className="card-subtitle color-black fw-bolder">
                    Database cmd
                </h1>
            </Card.Header>
            {/* <Card.Body>
                <Button  
                    variant='primary'
                    disabled={resetting}
                    onClick={resetDatabase}
                    className="justify-center"
                    style={{height: 40, minWidth: 120}}
                >
                    {resetting ? 
                        <Spinner 
                            animation="border" 
                            variant="white" 
                            size="sm" 
                        /> 
                    : "Reset Database"}
                </Button>
            </Card.Body> */}
            {(success || error) &&
                <Card.Footer className='pt-0'>
                    {success || error || ""}
                </Card.Footer>}
        </Card>
    );
};

  
export default DashboardDatabase
