
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Page500 from "../../pages/auth/Page500";
import Loader from "../Loader";

// For routes that can only be accessed by authenticated users
const AdminGuard = ({ children }) => {
  const { isAuthenticated, isInitialized, type } = useAuth();

  if (isInitialized) {
    if (!isAuthenticated || !type) {
      return <Navigate to="/auth/sign-in" />;
    } 

    if (type == "user") {
      return <Navigate to="/account" />;
    }
  
    if (type == "admin" || type == "translator") {
      return children;
    }

    return <Page500 />
  }
  
  return <Loader />
}

export default AdminGuard;
