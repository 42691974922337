import _, { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import SearchBar from "../../components/SearchBar";
import { useTranslation } from "react-i18next";


const TosEn = () => {
  const { t } = useTranslation();

  return (
    <>
      <SearchBar title={capitalize(t("termsOfPurchase"))} noBar/>
      <Container className="p-4 pt-5 fs-4" style={{whiteSpace: "pre-wrap"}}>
        <h1 className="fw-bolder">GENERAL PURCHASING CONDITIONS</h1>
        <h4 className="pb-3">Last update: 26/01/2021</h4>
        <br/>
        <h3 className="fw-bolder">GENERAL</h3>
          <p>
            This website is operated by Ecotrade Group.
            <br/>Throughout the site, the terms “we”, “us”, “our”, refer to Ecotrade Group
            <br/>Ecotrade Group offers this website, including all information, tools and services available from this site to you, the user, conditional upon your acceptance of all terms, conditions, policies and notices stated here.
            <br/>If you do not agree or you do not accept the Terms of Purchase, please do not use our services.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 1: DEFINITIONS</h3>
          <p>
            « Buyer » means the Ecotrade branch or its Partner.
            <br/>« Goods » means all objects sent to an Ecotrade branch or its Partner by the seller.
            <br/>« Seller » means the client co-contracting with an Ecotrade branch or its Partner.
            <br/>« General Purchasing Conditions » means those general purchasing conditions applicable to the relationship between an Ecotrade branch or its Partner and the seller.
            <br/>« Date of payment » means the date an Ecotrade branch or its Partner issues the payment.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 2: SCOPE OF APPLICATIONS</h3>
          <p>
            The General Purchasing Conditions apply to any buying proposal between the Seller and the Buyer.
            <br/>These GPC define the relationship terms for all the buying and selling operations applicable between the Buyer and its sellers in acquiring Goods.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 3: PRECONDITIONS</h3>
          <p>
            The Seller guarantees to the Buyer:
            <br/>- To be over the age of legal majority and to have full legal capacity.
            <br/>- To own the Goods or be duly authorized to dispose of them. (The Buyer can demand, in its sole discretion, proof of property or right to dispose of the Goods - that the Goods are free of any rights, not subject to any dismemberment, not allocated to any debt obligation warranty, and not claimed by any other party).
            <br/>- That the Goods have not been acquired by any illegal act forbidden by penal law, such as theft, and cannot in any case be linked to one.
            <br/>- That the sale of the Goods does not have as its objective an illegal act is forbidden by penal law, such as concealment; that the mailing and/or the provision and/or the cession of the Waste of Electrical and Electronic Equipment(s) to the Buyer does not participate in laundering money or does not lead to the violation of the applicable law of the Buyer.
            <br/>- To act in its name and on its behalf, and not as an agent or a representative.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 4: ORDERS</h3>
          <p>
            <span style={{fontWeight: "bold", textDecorationLine: "underline"}}>
              4.1 Generalities
            </span>
            <br/>Goods must be delivered by the Seller to the Buyer’s premises.
            <br/>In accordance with legal provisions applicable to the fight against money laundering and legal compliance, the Seller may be required to give the Buyer a copy of his ID.
            <br/>The Buyer advises the Seller to take pictures of the Goods before sending them in order to have additional justification.
            <br/>The Seller must keep the shipping waybill, the unique justification testifying shipment was made to the Buyer.
            <br/>The Buyer reserves all rights not to accept shipments that seem damaged, opened, or mishandled during transportation.
            <br/>Such shipments shall be returned to the Seller without Ecotrade liability being engaged.
            {"\n\n"}
            <span style={{fontWeight: "bold", textDecorationLine: "underline"}}>
              4.2 Orders outside the website
            </span>
            <br/>The Buyer can only be held responsible for orders made by representatives legally empowered to carry out purchase contracts.
            {"\n\n"}
            <span style={{fontWeight: "bold", textDecorationLine: "underline"}}>
              4.3 Electronic orders
            </span>
            <br/>The Seller can identify the Goods he wants to sell by creating a cart via our web application or mobile application.
            <br/>The Seller is responsible for packing the goods and delivering them no later than three (3) business days after the cart has been submitted.
            <br/>Fees may be deducted while using prepaid return label service.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 5: NATURE OF THE GOODS</h3>
          <p>
            The Buyer buys used Goods for recycling and Selling prices are evaluated by the Buyer based on the weight of each precious metal contained in the object and not on the hypothetical value of an undamaged item.
            <br/>Consequently, the price offered by Ecotrade can be lower than the value disclosed on its app and mobile application.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 6: SALE CONTRACT</h3>
          <p>
            By sending the Goods to the Buyer, the Seller formulates, to the Buyer, a definitive offer to sell for the Goods.
            <br/>After expert evaluation of the Goods, the Buyer will decide if it wants to acquire the Goods.
            <br/>If he does, it will inform the Seller within forty-eight (48) hours after the evaluation, by postal or electronic mail, and will proceed to the payment.
            <br/>Notification of the sale acceptance by the Buyer is the date of the sale.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 7: TRANSFER OF OWNERSHIP</h3>
          <p>
            The transfer of ownership of the Goods and the transfer of the risk of loss and damages related to it will be effective once the goods have been accepted by the Buyer.
            <br/>The Goods travel therefore at the Seller‘s risk, who can, in case of damage, make a claim to the carrier under the regulatory conditions.
            <br/>The Buyer cannot be held responsible in any way.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 8: PRICE</h3>
          <p>
            Goods prices is fixed with the assesment of the content of precious metal and the precious metal stock market price.
            <br/>On payment of the selling price, the Buyer sends an email to the Seller containing the purchase contract including the relevant information: goods description, the quantity for every reference, its value, and payment terms.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 9: PAYMENT TERMS</h3>
          <p>
            For the purchase, the Buyer will proceed with the payment determined after the Goods evaluation immediately after notifying the Seller of its decision to buy the Goods, meaning within forty-eight (48) hours after the Goods evaluation.
            <br/>The forty-eight (48) hours delay aforementioned does not mean the effective credit entry on the bank account of the Seller but the transfer order made by the Buyer.
            <br/>If the forty-eight (48) hours delay aforementioned expires on a weekend or a bank holiday, these days are not taken into account in the calculation of the time period.
            <br/>The payment will be made by the means selected by the Seller.
            <br/>The Buyer would like to draw the Seller’s attention to be informed and to fulfill its tax obligation in accordance with relevant provisions.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 10: COMPLIANCE AND QUALITY OF PRODUCTS</h3>
          <p>
            Packages arrive at the Buyer by shipping services. After receiving, the Buyer checks the delivered Goods for quantity as well as quality.
            <br/>If after this quality check, the Buyer realizes there has been a mistake on the quantity or a quality defect, the Buyer will make a new offer to the Seller.
            <br/>If not accepted within seven (7) days of the new proposal being sent, the goods may be returned to the Seller.
            <br/>The fees linked to the return of the Goods are solely the responsibility of the Seller.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 11: RESPONSIBILITY</h3>
          <p>
            The Buyer is not responsible for direct damages suffered by the Seller as a result of non-execution or wrong execution of its obligation regarding these General Conditions and the sales contracts linked to it.
            <br/>If the goods are rejected by the Buyer as specified in Article 10 above, the Seller will not be able to hold the Buyer responsible on the basis of a loss of time suffered by the failure of the operation.
            <br/>The buyer cannot be held responsible for any delay of non-execution as a result of force majeure.
            <br/>The buyer reserves the right to begin any legal action against a Seller who cannot justify provenance of its Goods or is illegally involved in its ownership.
            <br/>Furthermore, the Seller warrants the Buyer against default and/or hidden default, in particular regarding the origin of Goods sold.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 12: MODIFICATION OF TERMS</h3>
          <p>
            The Buyer reserves the right to modify these Terms at any time. In such circumstances, the Seller shall remain subject to the Terms in effect at the date of sending its Goods to the Buyer.
          </p>
        <br/>
        <h3 className="fw-bolder">ARTICLE 13: TRANSFER - TRANSMISSION</h3>
          <p>
            The Buyer has the right to sell all or part of the rights and obligations it has under these Conditions without affecting the scope of rights and obligations of the Seller.
          </p>
        <br/> 
        <h3 className="fw-bolder">ARTICLE 14: WAIVER – TOLERANCE – NULLITY</h3>
          <p>
            The fact that the Buyer may renounce claiming the breach of any provision of these Conditions shall not prevent the subsequent implementation of this clause and may not be considered a waiver of any other breach.
            <br/>Similarly, the tolerance for the application of terms and conditions of these Terms will never, whatever might be the duration or frequency, be considered a modification or deletion of these Terms and Conditions.
            <br/>Finally, the nullity that may affect any provision of these Terms, in whole or part, will not affect the rest of the Terms, which shall remain in force without change.
          </p>
      </Container>
    </>
  );
}

export default TosEn
