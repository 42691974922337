import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { ProfileTypes } from "../redux/account/profile";
import { useTranslation } from "react-i18next";


const AccountBankInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const bankInfo = useSelector(state => state.account.profile.data.bankInfo) || {}
  const fetching = useSelector(state => state.account.profile.fetching)

  const [formValues, setFormValue] = useState({...bankInfo})

  const onInput = ({target: {value, id}}) => {
    setFormValue({...formValues, [id]: value})
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    let updatedBankInfo = formValues
    if (!_.isEmpty(bankInfo)) {
      updatedBankInfo = Object.keys(bankInfo).reduce((r, k) => {
          if (k == "id" || bankInfo[k] != formValues[k]) {
            r[k] = formValues[k];
          }
          return r
      }, {});
    }
    dispatch({
      type: ProfileTypes.UPDATE_PROFILE_BANK_INFO_REQUEST, 
      bankInfo: updatedBankInfo
    })
  }

  useEffect(() => {
    if (!_.isEmpty(bankInfo)) {
      setFormValue({...bankInfo})
    }
  }, [bankInfo])

  return (  
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("bankInfo")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={onFormSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="accountHolder">
                  {t("accountHolder")}
                </Form.Label>
                <Form.Control
                  disabled={fetching}
                  type="text"
                  name="text"
                  id="account_holder"
                  placeholder=""
                  onChange={onInput}
                  value={formValues?.account_holder || ""}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="accountNumber">
                  {t("accountNumber")}
                </Form.Label>
                <Form.Control
                  disabled={fetching}
                  type="text"
                  name="text"
                  id="account_number"
                  placeholder=""
                  onChange={onInput}
                  value={formValues?.account_number || ""}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="bank">
                  {t("bank")}
                </Form.Label>
                <Form.Control
                  disabled={fetching}
                  type="text"
                  name="text"
                  id="bank"
                  placeholder=""
                  onChange={onInput}
                  value={formValues?.bank || ""}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="bankBranch">
                  {t("bankBranch")}
                </Form.Label>
                <Form.Control
                  disabled={fetching}
                  type="text"
                  name="text"
                  id="bank_branch"
                  placeholder=""
                  onChange={onInput}
                  value={formValues?.bank_branch || ""}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="swift">
                  {t("swift")}
                </Form.Label>
                <Form.Control
                  disabled={fetching}
                  type="text"
                  name="text"
                  id="swift"
                  placeholder=""
                  onChange={onInput}
                  value={formValues?.swift || ""}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="iban">
                  {t("iban")}
                </Form.Label>
                <Form.Control
                  disabled={fetching}
                  type="text"
                  name="text"
                  id="iban"
                  placeholder=""
                  onChange={onInput}
                  value={formValues?.iban || ""}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button 
            variant="primary" 
            type="submit"
            disabled={fetching}
          >
            {t("saveChanges")}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default AccountBankInfo;
