import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

import Header from "./dashboards/Default/Header";
import Appointments from "./dashboards/Default/Appointments";
import BarChart from "./dashboards/Default/BarChart";
import Calendar from "./dashboards/Default/Calendar";
import Feed from "./dashboards/Default/Feed";
import PieChart from "./dashboards/Default/PieChart";
import Projects from "./dashboards/Default/Projects";
import Statistics from "./dashboards/Default/Statistics";
import Search from "./Search";
import DashboardProducts from "./DashboardProducts";

const Dashboard = () => (
  <React.Fragment>
    <Helmet title={"Dashbboard"} />
    <Container fluid className="p-0">
        <DashboardProducts />
      {/* <Header /> */}
      {/* <Statistics /> */}
      {/* <Row>
        <Col lg="8" className="d-flex">
          <BarChart />
        </Col>
        <Col lg="4" className="d-flex">
          <Feed />
        </Col>
      </Row>
      <Row>
        <Col lg="6" xl="4" className="d-flex">
          <Calendar />
        </Col>
        <Col lg="6" xl="4" className="d-flex">
          <PieChart />
        </Col>
        <Col lg="6" xl="4" className="d-flex">
          <Appointments />
        </Col>
      </Row> */}
      {/* <Projects /> */}
    </Container>
  </React.Fragment>
);

export default Dashboard;