import React, { useState } from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import equationSelling from "../assets/dashboard/images/equation-selling.png"
import equationBuying from "../assets/dashboard/images/equation-buying.png"
const DashboardProductFormulas = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Formulas
            </Button>
            <Modal
                show={show}
                // show={true}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <h2>Formulas</h2>
                </Modal.Header>
                <Modal.Body className="">
                    <p>
                        The <span className='fw-bolder'>selling price</span> of a product in $ is calculated with:<br/><br/>
                        <Image fluid src={equationSelling}/><br/><br/>
                        USD Lease (percentage) and R.T. (in dollars) can be defined in "Settings".<br/><br/>
                        The <span className='fw-bolder'>buying price</span> after margin is calculated with:<br/><br/>
                        buying price = selling price * (100 - margin) / 100<br/><br/>
                        "margin" can be defined in "Settings".<br/><br/>
                        The <span className='fw-bolder'>buying price of a product in a country and in the local money</span> is calculated with:<br/><br/>
                        <Image fluid src={equationBuying}/><br/><br/>
                        Price is the buying price after margin in USD.<br/>
                        Rate is the latest currency rate of the local money.<br/>
                        Logistic and margin are percentages defined in the country page.
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
}
         

  
export default DashboardProductFormulas
