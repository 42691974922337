import { SearchTypes } from '../redux/search'
import React, { useEffect, useMemo, useState } from 'react';
import Select from "react-select";
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';



const PriceSelectionFilter = () => {

  const { t } = useTranslation();

  const priceSelectionFilter = [
    {value: "best_match", label: t("bestMatch")},
    {value: "low_to_high", label: t("priceLowToHigh")},
    {value: "high_to_low", label: t("priceHighToLow")},
  ]

  const dispatch = useDispatch()

  const selection = useSelector(state => state.search.activeFilters.price)

  const handlePriceFilterChange = (price) => {
    dispatch({ type: SearchTypes.SET_PRICE_FILTER, price })
    dispatch({ type: SearchTypes.SEARCH_REQUEST })
  }

  return (
    <Form.Group>
      <Select
          styles={{menu: ({ width, ...css }) => ({
            ...css,
            width: "max-content",
            minWidth: "100%",
          })}}
          options={priceSelectionFilter}
          placeholder={t("bestMatch")}
          value={priceSelectionFilter?.find(el => selection == el?.value)}
          onChange={({value}) => handlePriceFilterChange(value)}
      />
    </Form.Group>
  )
};

export default PriceSelectionFilter