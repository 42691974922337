import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";

const EmailVerified = () => {

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token")
    if (token) {
      window.localStorage.setItem("accessToken", token);
      navigate('/')
    }
  }, [])

  return (
    (
      <React.Fragment>
        <div className="text-center p-6">
          <p className="h1 p-2 fw-bolder">Email Successfully Verified</p>
          <p className="h3 p-2">You can now log in to your account</p>
          <div className="m-3">
            <Link to="/">
              <Button variant="primary" size="lg">
                Return to website
              </Button>
            </Link>
          </div>
        </div>
      </React.Fragment>
    )
  )
};

export default EmailVerified;
