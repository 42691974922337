import moment from 'moment';
import _, { isEqual } from 'lodash'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Container, Modal, Table, Tab, Row, Col, Card, Form, Nav, Button, Spinner, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Check, Edit2, Trash2, Globe, X, ChevronRight, Plus, PlusCircle } from 'react-feather';
import { DashboardProductTypes } from '../redux/dashboard/product';
import { useParams } from 'react-router-dom';
import { findArrayDuplicates } from '../utils/commons';



const DashboardProductModels = () => {

    const dispatch = useDispatch();
    const params_product_id = useParams()?.id;

    const [selectedModels, setSelectedModels] = useState([])
    const [addModel, setAddModel] = useState(false)
    const [modelsNamesInput, setModelsNamesInput] = useState('')

    useEffect(()=> {
        fetchModels()
    }, [])

    const { models, product, loadingModels } = useSelector(state => state.dashboard.product);

    const fetchModels = () => {
        const product_id = product?.id || params_product_id
        if (!product_id) return alert('Product ID missing')
        dispatch({ type: DashboardProductTypes.GET_PRODUCT_MODELS_REQUEST_DASHBOARD, product_id })
    }

    const handleSelectModel = (id) => {
        if (!selectedModels?.includes(id)) setSelectedModels([...selectedModels, id])
        else setSelectedModels([...selectedModels].filter(el => el != id))
        setAddModel(false)
    }

    const switchAddModel = (type) => {
        setAddModel(type == "bulk" ? "bulk" : !addModel)
        setModelsNamesInput("")
    }
    
    const handleDeleteModels = (modelIds) => {
        const product_id = product?.id || params_product_id
        if (!product_id) return alert('Product ID missing')
        const selectedModelsList = models?.filter(model => selectedModels?.includes(model?.id))?.map(el => el?.model).toString().replaceAll(',', '\n');
        if (window.confirm('Are you sure you want to delete these models?\n\n' + selectedModelsList)) {
            dispatch({ type: DashboardProductTypes.DELETE_PRODUCT_MODELS_REQUEST_DASHBOARD, product_id, modelsIds: selectedModels })
            setSelectedModels([])
        }
    }

    const handleAddModels = () => {
        const product_id = product?.id || params_product_id
        if (!product_id) return alert('Product ID missing')
        const cleanModelsEntries = (arg) => (
            arg?.trim().replace(/ +(?= )/g,'').replace(/\s*,\s*/g, ",\n")
            .replace(/\n/g,',').replace(',,',',').split(',').map(el => el.trim())
        )
        const models = cleanModelsEntries(modelsNamesInput)
        if (addModel == "bulk" && models?.length > 1) {
            if (window.confirm(
                `Are you sure you want to add these ${models?.length} models?\n\n` + models?.map(el => el+"\n").join("")
            )) {
                const duplicatesValues = findArrayDuplicates(models)
                if (duplicatesValues?.length > 0) {
                    alert(`Before adding new models, please remove duplicates:\n\n${duplicatesValues?.map(el => el+"\n").join("")}`)
                } else {
                    dispatch({ type: DashboardProductTypes.ADD_PRODUCT_MODELS_REQUEST_DASHBOARD, product_id, models })
                }
            }
        } else {
            dispatch({ type: DashboardProductTypes.ADD_PRODUCT_MODELS_REQUEST_DASHBOARD, product_id, models })
        }
        setAddModel(false)
        setModelsNamesInput("")
    }

    return (
        <Card className='mt-3'>
            <div className='m-4 mt-3 mb-0 d-flex justify-content-between'>
                <h3>Product Models</h3>
                <div>
                    {selectedModels?.length == 0 && !addModel &&
                        <Button  
                            variant='outline-secondary'
                            disabled={loadingModels}
                            onClick={switchAddModel}
                            className="ml-auto p-2 ms-2 col-example"
                            style={{}}
                        >
                            <PlusCircle 
                                className='me-1'
                            />
                            Add a model
                        </Button>}
                    {selectedModels?.length == 0 && !addModel &&
                        <Button  
                            variant='outline-secondary'
                            disabled={loadingModels}
                            onClick={() => switchAddModel('bulk')}
                            className="ml-auto p-2 ms-2 col-example"
                            style={{}}
                        >
                            <PlusCircle className='me-1'/>
                            Add models bulk
                        </Button>}
                    {selectedModels?.length > 0 &&
                        <Button  
                            variant='outline-secondary'
                            disabled={loadingModels}
                            onClick={handleDeleteModels}
                            className="ml-auto ms-2 p-2 col-example"
                            style={{}}
                        >
                            <Trash2 className='me-1'/>
                            Delete models
                        </Button>}
                </div>
            </div>
            <Card.Body>
                <Row xs={2} sm={3} md={4} xl={5} xxl={6}>
                    {models?.map(el => {
                        const active = selectedModels?.includes(el?.id)
                        return (
                            <Col 
                                // action 
                                key={el?.id}
                                onClick={()=> handleSelectModel(el?.id)}
                                className="p-2 border d-flex justify-content-between align-items-start"
                            >
                                {el?.model}
                                <Form.Check 
                                    checked={active} 
                                    type={"checkbox"} 
                                    onChange={()=> handleSelectModel(el?.id)}
                                />
                            </Col>
                        )
                    })}
                </Row>
                {(models?.length == 0 && !addModel) && <div>No models</div>}   
                {addModel &&
                    <ListGroup.Item
                        className="d-flex p-2"
                    >
                        <Form.Control
                            className='border-0 p-3 me-1 ps-4'
                            placeholder="Model name"
                            type="text"
                            as={addModel == "bulk" ? "textarea" : "input"}
                            rows={10}
                            onChange={e => 
                                setModelsNamesInput(
                                    e?.target?.value?.replaceAll(',', 
                                    ()=> {
                                        if (addModel == "bulk") return ","
                                        return ""
                                    })
                                    .replace(/ +(?= )/g,'')
                                    .replace(/\s*,\s*/g, ",\n")
                                )
                            }
                            value={modelsNamesInput}
                            disabled={loadingModels}
                        />
                        <div className='d-flex align-items-center'>
                            <Button 
                                disabled={modelsNamesInput == ""}
                                className='m-0 p-2 ms-1'
                                onClick={handleAddModels}
                            >
                                Add
                            </Button>
                            <X
                                className='m-2 cursor-pointer'
                                onClick={switchAddModel}
                            />
                        </div>
                    </ListGroup.Item>}
            </Card.Body>
        </Card>
    )
}

  
export default DashboardProductModels