import { toast } from 'react-toastify'
import { call, delay, put, select } from 'redux-saga/effects'
import ModalsActions from '../../redux/modals'
import CartActions from '../../redux/account/cart'

export function * getOpenCart(api) {
    const response = yield call(api.getOpenCart)

    if (response.ok) {
        yield put(CartActions.getOpenCartSuccess(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(CartActions.getOpenCartError(message))
    }
}

export function * getCart(api) {
    const response = yield call(api.getCart)

    if (response.ok) {
        yield put(CartActions.getCartSuccess(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(CartActions.getCartError(message))
    }
}

export function * updateCart(api, {id, cart}) {
    const response = yield call(api.updateCart, id, cart)

    if (response.ok) {
        toast.success("Cart Updated")
        yield put(CartActions.getCartRequest())
    } else {
        const message = response?.data?.message || "Error"
        yield put(CartActions.getCartError(message))
    }
}


export function * addCartProduct(api, {product_id, quantity, newQuantity}) {
    const response = yield call(api.addCartProduct, {product_id, quantity, newQuantity })

    if (response.ok) {
        yield put(CartActions.getOpenCartRequest())
    } else {
        if (response?.data?.message?.includes('missing country')) {
            yield put(ModalsActions.openCountryModal())
        }
        const message = response?.data?.message || "Error"
        yield put(CartActions.addCartProductError(message))
    }
}

export function * deleteCartProduct(api, {cart_product_id}) {
    const response = yield call(api.deleteCartProduct, cart_product_id)

    if (response.ok) {
        yield put(CartActions.getOpenCartRequest())
    } else {
        const message = response?.data?.message || "Error"
        yield put(CartActions.deleteCartProductError(message))
    }
}

export function * submitCart(api, {id, data}) {
    const response = yield call(api.submitCart, id, data)
    if (response.ok) {
        yield put(CartActions.submitCartSuccess(response?.data?.error || true))
    } else {
        const message = response?.data?.message || "Error"
        yield put(CartActions.submitCartError(message))
    }
}

export function * getCartsHistory(api) {
    const response = yield call(api.getCartsHistory)

    if (response.ok) {
        yield put(CartActions.getCartsHistorySuccess(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(CartActions.getCartsHistoryError(message))
    }
}

export function * getCartInvoice(api, {id, ref}) {
    const response = yield call(api.getCartInvoice, id)

    if (response.ok) {
        const file = new Blob([response.data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        // return window.open(fileURL, '_blank');
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = `${ref}.pdf`;
        fileLink.click();
    }
}
