import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getOpenCartRequest: null,
  getOpenCartSuccess: ["data"],
  getOpenCartError: ["error"],

  getCartRequest: null,
  getCartSuccess: ["data"],
  getCartError: ["error"],

  updateCartRequest: null,
  updateCartError: ["error"],

  addCartProductRequest: null,
  addCartProductError: ["error"],

  deleteCartProductRequest: null,
  deleteCartProductError: ["error"],

  submitCartRequest: null,
  submitCartSuccess: ["submitted"],
  submitCartError: ["error"],

  getCartsHistoryRequest: null,
  getCartsHistorySuccess: ["history"],
  getCartsHistoryError: ["error"],

  getCartInvoiceRequest: null
})

export const CartTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    openCart: null,
    cart: {},
    history: [],
    fetching: null,
    error: null
})

/* ------------- Reducers ------------- */

/* Open Cart Data */
export const getOpenCartRequest = (state) =>
  state.merge({ fetching: true, openCart: null, error: null})

export const getOpenCartSuccess = (state, {data}) =>
  state.merge({ fetching: false, openCart: data})

export const getOpenCartError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* Cart Data */
export const getCartRequest = (state) =>
  state.merge({ fetching: true, data: {}, error: null})

export const getCartSuccess = (state, {data}) =>
  state.merge({ fetching: false, data})

export const getCartError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* Update Cart */
export const updateCartRequest = (state) =>
  state.merge({ fetching: true, error: null})

export const updateCartError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* Add Product to Cart */
export const addCartProductRequest = (state) =>
  state.merge({ fetching: true, error: null})

export const addCartProductError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* Delete Product to Cart */
export const deleteCartProductRequest = (state) =>
  state.merge({ fetching: true, error: null})

export const deleteCartProductError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* Cart Data */
export const submitCartRequest = (state) =>
  state.merge({ fetching: true, error: null})

export const submitCartSuccess = (state, {submitted}) => 
  state.merge({ fetching: false, data: {}, openCart: {submitted}})

export const submitCartError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* User Cart History */
export const getCartsHistoryRequest = (state) =>
  state.merge({ fetching: true, history: [], error: null})

export const getCartsHistorySuccess = (state, {history}) =>
  state.merge({ fetching: false, history})

export const getCartsHistoryError = (state, {error}) =>
  state.merge({ fetching: false, error})

/* Get Cart Invoice */
export const getCartInvoiceRequest = (state) => state

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_OPEN_CART_REQUEST]: getOpenCartRequest,
  [Types.GET_OPEN_CART_SUCCESS]: getOpenCartSuccess,
  [Types.GET_OPEN_CART_ERROR]: getOpenCartError,
  [Types.GET_CART_REQUEST]: getCartRequest,
  [Types.GET_CART_SUCCESS]: getCartSuccess,
  [Types.GET_CART_ERROR]: getCartError,
  [Types.UPDATE_CART_REQUEST]: updateCartRequest,
  [Types.UPDATE_CART_ERROR]: updateCartError,
  [Types.ADD_CART_PRODUCT_REQUEST]: addCartProductRequest,
  [Types.ADD_CART_PRODUCT_ERROR]: addCartProductError,
  [Types.DELETE_CART_PRODUCT_REQUEST]: deleteCartProductRequest,
  [Types.DELETE_CART_PRODUCT_ERROR]: deleteCartProductError,
  [Types.SUBMIT_CART_REQUEST]: submitCartRequest,
  [Types.SUBMIT_CART_SUCCESS]: submitCartSuccess,
  [Types.SUBMIT_CART_ERROR]: submitCartError,
  [Types.GET_CARTS_HISTORY_REQUEST]: getCartsHistoryRequest,
  [Types.GET_CARTS_HISTORY_SUCCESS]: getCartsHistorySuccess,
  [Types.GET_CARTS_HISTORY_ERROR]: getCartsHistoryError,
  [Types.GET_CART_INVOICE_REQUEST]: getCartInvoiceRequest,
})