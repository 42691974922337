/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This template uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select, delay } from 'redux-saga/effects'
import DashboardCartsActions from '../../redux/dashboard/carts'
import DashboardCartActions from '../../redux/dashboard/cart'

// Get all carts using filters
export function * getCartsDashboard(api, {searchField}) {
    const reducers = yield select();
    const {  
        searchLimit, 
        currentPage, 
        activeFilters
    } = reducers?.dashboard.carts

    let params = { 
        searchField, 
        size: searchLimit, 
        page: currentPage, 
        filters: activeFilters
    }

    const response = yield call(api.getCartsDashboard, params)
    if (response.ok) {
        yield put(DashboardCartsActions.cartsSearchSuccessDashboard(response.data))
    } else {
        const error = response?.data?.message || "Server error"
        yield put(DashboardCartsActions.cartsSearchFailureDashboard(error))
    }
}


// export function * getFiltersDashboard(api) {
//     const response = yield call(api.getFiltersDashboard)
//     if (response.ok) {
//         yield put(DashboardCartsActions.filtersSuccessDashboard(response.data))
//     } else {
//         const error = response?.data?.message || "Server error"
//         yield put(DashboardCartsActions.filtersFailureDashboard(error))
//     }
// }


// Get one cart by ID
export function * getCartDashboard (api, {id}) {
    const response = yield call(api.getCartDashboard, id)

    if (response.ok) {
        yield put(DashboardCartActions.getCartSuccessDashboard(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(DashboardCartActions.getCartErrorDashboard(message))
    }
}

// Get trending products
export function * getTrendingProductsDashboard (api, {id}) {
    const response = yield call(api.getTrendingProductsDashboard)

    if (response.ok) {
        yield put(DashboardCartsActions.trendingProductsSuccessDashboard(response.data))
    } else {
        const message = response?.data?.message || "Error"
        yield put(DashboardCartActions.trendingProductsErrorDashboard())
    }
}