import _ from 'lodash'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Modal, Image, Form, Button, Spinner } from 'react-bootstrap';
import { ProductAnalysisTypes } from '../redux/dashboard/analysis';
import LoadingOverlay from '../components/ui/LoadingOverlay';
import equationMetal from "../assets/dashboard/images/equation-metal.png"

const DashboardProductAnalysis = ({
    openModal,
    closeModal,
    analysis
}) => {

    const dispatch = useDispatch();
    const { product } = useSelector(state => state.dashboard.product);
    const product_id = product?.id

    const { 
        updateProductAnalysisError,
        deleteProductAnalysisError,
        createProductAnalysisError,
        creatingProductAnalysis
    } = useSelector(state => state.dashboard.analysis);

    const [fields, setFields] = useState({
        ref: "",
        description: "",
        cu_perc: null,
        pd_ppm: null,
        au_ppm: null,
        ag_ppm: null,
        pt_ppm: null
    })

    useEffect(() => {
        if (analysis) { setFields({...analysis}) }
    }, [])

    const handleChange = (e) => {
        e.preventDefault(); 

        setFields(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 
        const data = {...fields, product_id}

        if (analysis) {
            const id = analysis?.id
            dispatch({ type: ProductAnalysisTypes.UPDATE_PRODUCT_ANALYSIS_REQUEST, id, data })
        } else {
            dispatch({ type: ProductAnalysisTypes.CREATE_PRODUCT_ANALYSIS_REQUEST, data })
        }
    };

    const handleDelete = (e) => {
        e.preventDefault(); 
        const id = analysis?.id
        const product_id = analysis?.product_id
        dispatch({ type: ProductAnalysisTypes.DELETE_PRODUCT_ANALYSIS_REQUEST, id, product_id })
    };
        
  
    return (
        <Modal
            show={openModal}
            // show={true}
            onHide={closeModal}
            centered
        >
            <Modal.Header closeButton>
                <h2>{analysis ? `Update Anaylis` : "Create Analysis"}</h2>
            </Modal.Header>
            <LoadingOverlay active={creatingProductAnalysis}>
                <Modal.Body className="p-4 pb-3">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-5 d-flex">
                            <Form.Label 
                                style={{minWidth: 100}} 
                                className='pt-2 pe-3 text-left fw-bolder'
                            >
                                Ref
                            </Form.Label>
                            <Form.Control 
                                type="text"
                                required
                                name={"ref"}
                                value={fields?.ref || ""}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex">
                            <Form.Label 
                                style={{minWidth: 100}} 
                                className='pt-2 pe-3 text-left fw-bolder'
                            >
                                Description
                            </Form.Label>
                            <Form.Control 
                                type="text"
                                as="textarea" 
                                rows={2}
                                name={"description"}
                                value={fields?.description || ""}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <Form.Group className='me-3 text-center'>
                                <Form.Label className='fw-bolder'>
                                    AU (ppm)
                                </Form.Label>
                                <Form.Control 
                                    type="number"
                                    name={"au_ppm"}
                                    value={fields?.au_ppm || ""}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='me-3 text-center'>
                                <Form.Label className='fw-bolder'>
                                    AG (ppm)
                                </Form.Label>
                                <Form.Control 
                                    type="number"
                                    name={"ag_ppm"}
                                    value={fields?.ag_ppm || ""}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='me-3 text-center'>
                                <Form.Label className='fw-bolder'>
                                    PD (ppm)
                                </Form.Label>
                                <Form.Control 
                                    type="number"
                                    name={"pd_ppm"}
                                    value={fields?.pd_ppm || ""}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='me-3 text-center'>
                                <Form.Label className='fw-bolder'>
                                    PT (ppm)
                                </Form.Label>
                                <Form.Control 
                                    type="number"
                                    name={"pt_ppm"}
                                    value={fields?.pt_ppm || ""}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='me-3 text-center'>
                                <Form.Label className='fw-bolder'>
                                    CU (%)
                                </Form.Label>
                                <Form.Control 
                                    type="number"
                                    name={"cu_perc"}
                                    value={fields?.cu_perc || ""}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                           
                        </Form.Group>
                        <Form.Group className='text-center mt-4'>
                            <Button 
                                variant="primary" 
                                type="submit"
                            >
                                {analysis ? "Update" : "Create"}
                            </Button>
                            {analysis && 
                                <Button
                                    className='ms-3' 
                                    variant="primary" 
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>}
                        </Form.Group>
                    </Form>
                    {createProductAnalysisError && 
                        <p className="pt-3" style={{color: "red"}}>
                            {createProductAnalysisError}
                        </p>}
                    {updateProductAnalysisError && 
                        <p className="pt-3" style={{color: "red"}}>
                            {updateProductAnalysisError}
                        </p>}
                    {deleteProductAnalysisError && 
                        <p className="pt-3" style={{color: "red"}}>
                            {deleteProductAnalysisError}
                        </p>}
                    <p>
                        <br/>
                        These are in ppm (milligrams of metal per kilo of product).
                        <br/><br/>
                        The price of a metal per kg of product is calculated with:<br/><br/>
                        <Image fluid src={equationMetal}/><br/><br/>
                        market = the latest market price for 1 troy ounce of metal (1 troy ounce = 31.1035g)<br/><br/>
                        minus (in dollars), deduction (percentage) and lease (percentage) can be defined in the metal page (in "Settings")
                    </p>
                </Modal.Body>
            </LoadingOverlay>
        </Modal>
    )
}
         

  
export default DashboardProductAnalysis
