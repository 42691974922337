// ExpandableRows demo
const subRows = [
  {
    name: "Shad Decker",
    position: "Regional Director",
    office: "Edinburgh",
    age: 51,
    startDate: "2008/11/13",
    salary: "$183,000",
  },
  {
    name: "Michael Bruce",
    position: "Javascript Developer",
    office: "Singapore",
    age: 29,
    startDate: "2011/06/27",
    salary: "$183,000",
  },
  {
    name: "Donna Snider",
    position: "Customer Support",
    office: "New York",
    age: 27,
    startDate: "2011/01/25",
    salary: "$112,000",
  },
];

const tableData = [
  {
    name: "Tiger Nixon",
    position: "System Architect",
    office: "Edinburgh",
    age: 61,
    startDate: "2011/04/25",
    salary: "$320,800",
    subRows: subRows,
  },
];

const tableColumns = [
  {
    Header: "name",
    accessor: "name",
  },
  {
    Header: "Position",
    accessor: "position",
  },
  {
    Header: "Office",
    accessor: "office",
  },
  {
    Header: "Age",
    accessor: "age",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "Salary",
    accessor: "salary",
  },
];

export { tableData, tableColumns };
