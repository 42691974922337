import _, { capitalize } from 'lodash'
import Select from "react-select";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardUserTypes } from '../redux/dashboard/user';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Button, Card, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import allCountries from "country-region-data/data.json";
import DashboardUserBankInfo from './DashboardUserBankInfo';
import DashboardUserProofs from './DashboardUserProofs';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';


const Navigation = ({activeTab, setActiveTab}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item onClick={()=> setActiveTab(1)} action active={activeTab == 1}>
          {t("account")}
        </ListGroup.Item>
        <ListGroup.Item onClick={()=> setActiveTab(2)} action active={activeTab == 2}>
          {t("bankInfo")}
        </ListGroup.Item>
        {/* <ListGroup.Item onClick={()=> setActiveTab(3)} action active={activeTab == 3}>
          {t("supplierProof")}
        </ListGroup.Item> */}
      </ListGroup>
    </Card>
  );
}



const DashboardUserProfile = ({profile, fetching}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const [formValues, setFormValue] = useState({...profile})

  const topCountries = [formValues?.country, "TH"]
  const countries = allCountries?.map(({countryName, countryShortCode}) => ({countryName, countryShortCode}))
                    .sort((x) => topCountries.includes(x.countryShortCode) ? -1 : 0)
                    .filter(el => el.countryShortCode != formValues?.country)
  const selectedCountry = allCountries?.find(el => el.countryShortCode == formValues.country)?.countryName || "--"

  const regions = allCountries?.find(({countryShortCode}) => countryShortCode == formValues?.country)
                  ?.regions?.map(({name, shortCode}) => ({name, shortCode}))
                  .sort((x) => x.shortCode == formValues.region ? -1 : 0)
                  .filter(el => el.shortCode != formValues?.region)
  const selectedRegion = allCountries?.find(el => el.countryShortCode == formValues.country)
                        ?.regions?.find(el => el.shortCode == formValues?.region)?.name || "--"

  const onInput = ({target: {value, id}}) => {
    setFormValue({...formValues, [id]: value})
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    const updatedProfile = Object.keys(profile).reduce((r, k) => {
        if (k == "id" || profile[k] != formValues[k]) {
          r[k] = formValues[k];
        }
        return r
    }, {});
    dispatch({type: DashboardUserTypes.UPDATE_USER_REQUEST_DASHBOARD, id: profile?.id, data: updatedProfile})
  }

  useEffect(() => {
    if (!_.isEmpty(profile)) {
      setFormValue({...profile})
    }
  }, [profile])

  return ( 
    <Card>
        <Card.Header>
            <Card.Title tag="h5" className="mb-0">
            {t("profile")}
            </Card.Title>
        </Card.Header>
        <Card.Body>
            <Form onSubmit={onFormSubmit}>
                <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="first_name">
                        {t("firstName")}
                    </Form.Label>
                    <Form.Control
                        disabled={fetching}
                        type="text"
                        name="text"
                        id="first_name"
                        placeholder="First Name"
                        onChange={onInput}
                        value={formValues?.first_name || ""}
                    />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="last_name">
                        {t("lastName")}
                    </Form.Label>
                    <Form.Control
                        disabled={fetching}
                        type="text"
                        name="text"
                        id="last_name"
                        placeholder="Last Name"
                        onChange={onInput}
                        value={formValues?.last_name || ""}
                    />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">
                        {t("email")}
                    </Form.Label>
                    <Form.Control
                        disabled={fetching}
                        type="email"
                        name="email"
                        id="email"
                        placeholder="email"
                        readOnly
                        onChange={onInput}
                        value={formValues?.email || ""}
                    />
                    </Form.Group>
                </Col>
                </Row>
                <Form.Group className="mb-3">
                <Form.Label htmlFor="address">
                    {t("address")}
                </Form.Label>
                <Form.Control
                    disabled={fetching}
                    type="text"
                    name="address"
                    id="address"
                    placeholder="1234 Main St"
                    onChange={onInput}
                    value={formValues?.address || ""}
                />
                </Form.Group>
                <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="country">
                        {t("country")}
                    </Form.Label>
                    <Form.Select 
                        disabled={fetching}
                        onChange={onInput}
                        name="country" 
                        id="country"
                        placeholder="country"
                    >
                        <option value={formValues?.country}>
                        {selectedCountry}
                        </option>
                        {countries?.map(({countryShortCode, countryName}) => (
                        <option 
                            value={countryShortCode} 
                            key={countryShortCode}
                        >
                            {countryName}
                        </option>
                        ))}
                    </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="region">
                        {t("region")}
                    </Form.Label>
                    <Form.Select 
                        disabled={fetching || !formValues?.country}
                        onChange={onInput}
                        name="region" 
                        id="region"
                        placeholder="region"
                    >
                        <option value={formValues?.region}>
                        {selectedRegion}
                        </option>
                        {regions?.map(({name, shortCode})=> (
                        <option 
                            value={shortCode} 
                            key={shortCode
                        }>
                            {name}
                        </option>
                        ))}
                    </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="city">
                        {t("city")}
                    </Form.Label>
                    <Form.Control
                        disabled={fetching} 
                        type="text" 
                        name="city" 
                        id="city" 
                        placeholder="city"
                        onChange={onInput}
                        value={formValues?.city || ""}
                    />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="zipcode">
                        {t("zip")}
                    </Form.Label>
                    <Form.Control
                        disabled={fetching} 
                        type="text" 
                        name="zip" 
                        id="zip" 
                        placeholder="zip"
                        onChange={onInput}
                        value={formValues?.zip || ""}
                    />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="phone">
                        {t("phone")}
                    </Form.Label>
                    <Form.Control
                        disabled={fetching} 
                        type="text" 
                        name="phone" 
                        id="phone" 
                        placeholder="phone"
                        onChange={onInput}
                        value={formValues?.phone || ""}
                    />
                    </Form.Group>
                </Col>
                </Row>
                <Button 
                    variant="primary" 
                    type="submit"
                    disabled={fetching}
                >
                    {t("saveChanges")}
                </Button>
            </Form>
        </Card.Body>
    </Card>
  );
}

const DashboardUser = () => {

    const { connectAsUser } = useAuth()
    const dispatch = useDispatch();
    let { id } = useParams();
    const [activeTab, setActiveTab] = useState(1)
    const { data, fetching } = useSelector(state => state.dashboard.user) || {};

    useEffect(()=> {
        if (id) dispatch({ type: DashboardUserTypes.GET_USER_REQUEST_DASHBOARD, id })
    }, [id])

    const handleConnectAs = () => {
        if (id) connectAsUser(id)
    }
    

    return (
        <Card className='shadow-none'>
            <Card.Header>
                <Container fluid>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <h1 className="card-subtitle text-muted">
                                {data?.id} - {data?.fullname}
                            </h1>
                            <Button onClick={handleConnectAs}>
                                Connect as
                            </Button>
                        </Col>
                        <Container>
                            <Row className='pt-4'>
                                <Col md="3" xl="2">
                                    <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
                                </Col>
                                <Col md="9" xl="10">
                                    {activeTab == 1 && 
                                        <>
                                            <DashboardUserProfile profile={data} fetching={fetching}/>
                                            <DashboardUserProofs userId={data?.id} images={data?.supplierProofImages}/>
                                        </>
                                    }
                                    {activeTab == 2 && <DashboardUserBankInfo profileId={data?.id} bankInfo={data?.bankInfo} fetching={fetching}/>}
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </Card.Header>
        </Card>
    );
}

export default DashboardUser;
