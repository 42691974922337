// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import { toast } from "react-toastify";
import i18n from "i18next";
const environment = process.env.REACT_APP_NODE_ENV || 'test';
export const BASE_URL = (
  environment == "production" ? 'https://weee.ecotradegroup.com' 
  : environment == "development" ? 'https://weeedev.ecotradegroup.com' 
  : 'http://localhost:3000'
);
export const API = BASE_URL + "/api"
console.log(`ENV: ${environment}`)
export const isProduction = environment == "production"

// api "constructor"
const create = () => {

  const api = apisauce.create({
    baseURL: API,
    timeout: 10000,
    responseType: 'json',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLapiRequest',
      'Cache-Control': 'no-cache'
    }
  })

  // Adding token to header
  api.addAsyncRequestTransform(async (request) => {
    // Set language
    request.headers["Accept-Language"] = i18n?.language || "en"

    // Check token
    const authToken = await window.localStorage.getItem("accessToken")
    if (!authToken) return;
    request.headers["Authorization"] = "Bearer " + authToken;

    // Invoice pdf handler
    if (request?.url?.includes("/invoice")) {
      request.responseType = "arraybuffer"
    }
  });

  // General API Error Handling
  api.addResponseTransform(response => {
    const errorStatus = response?.status
    if (errorStatus == 500) {
      toast.error(response?.data?.message || "Internal Server Error");
    } else if ([400, 401, 403]?.includes(errorStatus)) {
      toast.error(JSON.stringify(response?.data?.message || "Error"));
    }
  })

  // SEARCH
  const getDevicesSections = () => api.get('categories/devices');
  const getProducts = (params) => api.get('products', params);

  // PRODUCT
  const getProduct = (code, currency) => api.get(`/products/${code}?currency=${currency}`)
  const getModels = product_id => api.get(`/products/${product_id}/models`)

  // // DATABASE
  // const resetDatabase = () => api.get(`/db/${environment}/reset`)

  // USER ACCOUNT
  const getProfile = () => api.get(`/profile`)
  const updateProfile = (profile) => api.put(`/profile`, profile);
  const updateProfileBankInfo = (bankInfo) => api.put(`/profile/bankinfo`, bankInfo);

  // USER CART
  const getOpenCart = () => api.get(`/cart/open`);
  const getCart = id => api.get(`/cart/${id}`);
  const updateCart = (id, params) => api.put(`/cart/${id}`, params);
  const addCartProduct = product => api.post(`/cart/product`, product);
  const deleteCartProduct = cart_product_id => api.delete(`/cart/product/${cart_product_id}`);
  const submitCart = (id, data) => api.post(`/cart/${id}/submit`, data);
  const getCartsHistory = () => api.get(`/cart/history`);
  const getCartInvoice = (id) => api.get(`/cart/${id}/invoice`);

  // DASHBOARD categories
  const getProductCategories = () => api.get('/dashboard/admin/categories');
  const addDevice = (name) => api.post('/dashboard/admin/categories/device', {name});
  const updateDevice = ({id, name}) => api.put(`/dashboard/admin/categories/device/${id}`, {name});
  const addCategory = ({name, device_id, codeFormat}) => api.post('/dashboard/admin/categories/category', {name, device_id, codeFormat});
  const updateCategory = ({id, name, codeFormat}) => api.put(`/dashboard/admin/categories/category/${id}`, {name, codeFormat});
  const addType = ({name, category_id}) => api.post('/dashboard/admin/categories/type', {name, category_id});
  const deleteDevice = id => api.delete(`/dashboard/admin/categories/device/${id}`);
  const deleteCategory = ({device_id, category_id}) => api.delete(`/dashboard/admin/categories/category/${device_id}/${category_id}`);
  const deleteType = id => api.delete(`/dashboard/admin/categories/type/${id}`);
  
  // DASHBOARD Search
  const getProductsDashboard = (params) => api.get(`/dashboard/products`, params)
  const getFiltersDashboard = () => api.get(`/dashboard/products/filters`)

  // DASHBOARD Product
  const getProductDashboard = (id, currency) => api.get(`/dashboard/products/${id}?currency=${currency}`)
  const getProductAnalysesDashboard = (id) => api.get(`/dashboard/product/${id}/analyses`)
  const createProductDashboard = data => api.post("/dashboard/products", data);
  const duplicateProductDashboard = data => api.post("/dashboard/product/duplicate", data);
  const updateProductDashboard = ({id, data}) => api.put(`/dashboard/products/${id}`, data);
  const deleteProductDashboard = ({id, code}) => api.delete(`/dashboard/products/${id}/${code}`);
  const getProductModelsDashboard = product_id => api.get(`/dashboard/product/${product_id}/models`);
  const getProductTranslationsDashboard = product_id => api.get(`/dashboard/translations/product/${product_id}`);
  const getProductsTranslationsDashboard = () => api.get(`/dashboard/translations/products`);
  const autofillMissingProductsTranslationsDashboard = () => api.get(`dashboard/translations/products/autofill`);
  const updateProductTranslationsDashboard = ({product_id, lang, data}) => api.put(`/dashboard/translations/product/${product_id}/${lang}`, data);
  const addProductModelsDashboard = ({product_id, data}) => api.post(`/dashboard/product/${product_id}/models`, data);
  const deleteProductModelsDashboard = data => api.post(`/dashboard/product/models`, data);
  const sortProductImagesDashboard = ({product_id, sortArray}) => api.get(`/dashboard/product/images/sort?product_id=${product_id}&sortArray=${sortArray}`);
  
  // DASHBOARD Users
  const getUsersDashboard = (params) => api.get(`/dashboard/users`, params)
  const getUserDashboard = (id) => api.get(`/dashboard/users/${id}`)
  const updateUserDashboard = ({id, data}) => api.put(`/dashboard/users/${id}`, data)

  // DASHBOARD Carts
  const getCartsDashboard = (params) => api.get(`/dashboard/carts`, params)
  const getTrendingProductsDashboard = () => api.get(`/dashboard/carts/trends`)
  const getCartDashboard = (id) => api.get(`/dashboard/carts/${id}`)

  // DASHBOARD Pricing Settings
  const getPricingSettings = () => api.get(`/dashboard/settings/pricing`)
  const updatePricingSettings = ({id, data}) => api.put(`/dashboard/settings/pricing/${id}`, data);

  // DASHBOARD Metals Settings
  const getMetalsSettings = () => api.get(`/dashboard/settings/metals`)
  const updateMetalSettings = ({id, data}) => api.put(`/dashboard/settings/metals/${id}`, data);

  // DASHBOARD Currencies
  const getCurrencies = () => api.get(`/dashboard/settings/currencies`)

  // DASHBOARD Logs
  const getActionLogs = () => api.get(`/dashboard/logs/actions`)

  // DASHBOARD Translations
  const getCategoriesTranslationsDashboard = () => api.get(`/dashboard/translations/categories`)
  const updateCategoriesTranslationsDashboard = (data) => api.put(`/dashboard/translations/categories`, data)
  const autofillMissingCategoriesTranslationsDashboard = () => api.get(`dashboard/translations/categories/autofill`);

  // DASHBOARD Product Categories
  // const addProductCategories = () => api.get(`/...../`)

   // DASHBOARD Product Analysis
   const getProductAnalysis = (id) => api.get(`/dashboard/product/analyses/${id}`)
   const createProductAnalysis = data => api.post("/dashboard/product/analyses", data);
   const updateProductAnalysis = ({id, data}) => api.put(`/dashboard/product/analyses/${id}`, data);
   const deleteProductAnalysis = id => api.delete(`/dashboard/product/analyses/${id}`);
   const selectProductAnalysis = ({id, product_id}) => api.put(`/dashboard/product/${product_id}/analyses/${id}`);

  // FINANCIAL DATA & CALCULATOR
  const getMetals = () => api.get('metals');
  const getMetalsRates = () => api.get('metals/rates');
  const getChartData = (params) => api.get('metals/search', params);
  const calculateReturn = settings => api.post('comparison', settings)
  const resetToEcotradeTerms = body => api.post('ecoweee-terms', body)


  return {
    // *** Database *** ///
    // resetDatabase,

    // *** Search *** ///
    getDevicesSections,
    getProducts,
    
    // *** Product *** ///
    getProduct,
    getModels,

    // *** User Profile *** ///
    getProfile,
    updateProfile,
    updateProfileBankInfo,

    // *** User Cart *** ///
    getOpenCart,
    getCart,
    updateCart,
    addCartProduct,
    deleteCartProduct,
    submitCart,
    getCartsHistory,
    getCartInvoice,

    // *** Dashboard Categories *** ///
    getProductCategories,
    addDevice,
    updateDevice,
    addCategory,
    updateCategory,
    addType,
    deleteDevice,
    deleteCategory,
    deleteType,

    // *** Dashboard Search *** ///
    getProductsDashboard,
    getFiltersDashboard,

    // *** Dashboard Product *** ///
    getProductDashboard,
    getProductAnalysesDashboard,
    createProductDashboard,
    duplicateProductDashboard,
    updateProductDashboard,
    deleteProductDashboard,
    sortProductImagesDashboard,
    getProductModelsDashboard,
    addProductModelsDashboard,
    deleteProductModelsDashboard,
    getProductTranslationsDashboard,
    updateProductTranslationsDashboard,
    getProductsTranslationsDashboard,
    autofillMissingProductsTranslationsDashboard,
    
    // *** Dashboard Users *** ///
    getUsersDashboard,
    getUserDashboard,
    updateUserDashboard,

    // *** Dashboard Carts *** ///
    getCartsDashboard,
    getTrendingProductsDashboard,
    getCartDashboard,

    // *** Dashboard Pricing Settings *** ///
    getPricingSettings,
    updatePricingSettings,

    // *** Dashboard Settings Metals *** ///
    getMetalsSettings,
    updateMetalSettings,

    // *** Dashboard Product Analysis *** ///
    getProductAnalysis,
    createProductAnalysis,
    updateProductAnalysis,
    deleteProductAnalysis,
    selectProductAnalysis,

    // *** Dashboard Currencies *** ///
    getCurrencies,

    // *** Dashboard Logs *** ///
    getActionLogs,

    // *** Dashboard Translations ///
    getCategoriesTranslationsDashboard,
    updateCategoriesTranslationsDashboard,
    autofillMissingCategoriesTranslationsDashboard,

    // *** Financial Data *** ///
    getChartData,
    getMetals,
    getMetalsRates,

    // *** Calculator *** //
    calculateReturn,
    resetToEcotradeTerms,
  }
}

const api = { create }

export default api
